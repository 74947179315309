import NativeCheckboxField, { NativeCheckboxFieldPublicProps } from '../../NativeCheckboxField'
import { withCustomisedStyle } from '../../../../../providers'
import type { CheckboxFieldContext } from '../../../../CheckboxField.composition'
import Customisations, {
  NativeCheckboxFieldCustomisations,
} from './DevNativeCheckboxField.customisations'
import Styles from './DevNativeCheckboxField.styles'
import Theme from '../../../../../themes/Developer/theme'

export const DevNativeCheckboxField = withCustomisedStyle<
  NativeCheckboxFieldPublicProps,
  CheckboxFieldContext,
  NativeCheckboxFieldCustomisations
>(NativeCheckboxField, Theme, Styles, Customisations, 'NativeCheckboxField')

export type DevNativeCheckboxFieldCustomisations = NativeCheckboxFieldCustomisations
export type DevNativeCheckboxFieldProps = NativeCheckboxFieldPublicProps &
  NativeCheckboxFieldCustomisations
