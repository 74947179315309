import * as theme from '../../../../themes/Developer/proxima-nova'
import type { InputStyleProps } from '../DevInput.styles'

export const fontSizes = {
  small: 'bodyXs',
  medium: 'bodyS',
  large: 'bodyM',
}

/** Font size  */
export const getFontSize = (props: InputStyleProps, defaultValue?: keyof theme.FontSize) =>
  theme.getFontSize(props, defaultValue || fontSizes[props.styleProps.size])

/** Font weight */
export const getFontWeight = (props: InputStyleProps, defaultValue?: keyof theme.FontWeight) =>
  theme.getFontWeight(props, defaultValue || 'regular')
