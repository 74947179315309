const useRef = (ref: any, value: any) => {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref) {
    /* eslint-disable-next-line no-param-reassign */
    ref.current = value
  }
}

export default useRef
