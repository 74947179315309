import * as React from 'react'
import * as PropTypes from 'prop-types'
import type { BannerContext } from './Banner.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'
import {
  faRegularTimes,
  faSolidCheckCircle,
  faSolidInfoCircle,
  faSolidExclamationCircle,
  faSolidTimesCircle,
  IconDefinition,
} from '../utils/fontawesome'

export interface BannerPublicProps {
  /**
   * Type of the banner
   */
  type?: 'warning' | 'success' | 'error' | 'info'
  /**
   * The variant to use.
   */
  variant?: 'standard' | 'outlined'
  /**
   * Handler to notify the user want to remove the banner
   */
  onClose?: () => void
  /**
   * Message to display as banner
   */
  children: React.ReactNode
}

export type BannerProps = BannerPublicProps & StyledProps<BannerContext>

export interface BannerCoreStyle {
  type: string
  variant: 'standard' | 'outlined'
}

export const Banner = React.forwardRef(
  (props: BannerProps, forwardRef: React.Ref<HTMLImageElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): BannerCoreStyle => ({
      type: props.type!,
      variant: props.variant!,
    })

    function renderIcon() {
      const { Icon } = props.styled!

      // TODO: set a configuration for each type of banner to avoid that bit tertiary combination
      let icon: IconDefinition = faSolidInfoCircle
      if (props.type === 'success') {
        icon = faSolidCheckCircle
      } else if (props.type === 'error') {
        icon = faSolidTimesCircle
      } else if (props.type === 'info') {
        icon = faSolidInfoCircle
      } else if (props.type === 'warning') {
        icon = faSolidExclamationCircle
      }

      return (
        <Icon
          src={icon}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'icon')}
        />
      )
    }

    const renderMessage = () => {
      const { Message } = props.styled!
      return (
        <Message
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'message')}
        >
          {props.children}
        </Message>
      )
    }

    const renderCrossIcon = () => {
      const { CrossIcon } = props.styled!
      return props.onClose ? (
        <CrossIcon
          src={faRegularTimes}
          onClick={props.onClose}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'cross')}
        />
      ) : null
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderIcon()}
        {renderMessage()}
        {renderCrossIcon()}
      </Root>
    )
  },
)

Banner.propTypes = {
  type: PropTypes.oneOf(['warning', 'success', 'error', 'info']),
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['standard', 'outlined']),
}

Banner.defaultProps = {
  type: 'warning',
  variant: 'standard',
}

export default Banner
