import StepContent, { StepContentPublicProps } from '../../StepContent'
import Theme from '../../../../../themes/Developer/theme'
import type { StepContentContext } from '../../StepContent.composition'
import { withStyle } from '../../../../../providers'
import Styles from './DevStepContent.styles'

export const DevStepContent = withStyle<StepContentPublicProps, StepContentContext>(
  StepContent,
  Theme,
  Styles,
  'StepContent',
)

export type DevStepContentProps = StepContentPublicProps
