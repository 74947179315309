import TableCell, { TableCellPublicProps } from '../../TableCell'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevTableCell.styles'
import Customisations from './DevTableCell.customisations'
import type { TableCellContext } from '../../TableCell.composition'
import type { TableCellCustomisations } from './customisations'

export const DevTableCell = withCustomisedStyle<
  TableCellPublicProps,
  TableCellContext,
  TableCellCustomisations
>(TableCell, Theme, Styles, Customisations, 'TableCell')

export type DevTableCellCustomisations = TableCellCustomisations
export type DevTableCellProps = TableCellPublicProps & TableCellCustomisations
