import React, {useState} from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import {CheckCircle, LensOutlined} from "@material-ui/icons";
import {Currency} from "../Utils";
import MockAccount from "./MockAccount";
import {AccountIdentifier, AccountIdentifierType} from "../SingleImmediatePayments/AccountIdentifier";

interface Props {
  accountType: AccountIdentifierType;
  currency: string;
  remitterAccount?: AccountIdentifier;
}

export const MockBankAccounts: React.FC<Props> = ({ accountType, currency, remitterAccount }) => {
  const generateBalanceInMinor = () => Math.floor(Math.random() * 10000000);

  const generateAccountId = (accountNumberOffset: number): string => {
    const accountNumber = 12345678 + accountNumberOffset;
    switch (accountType) {
      case AccountIdentifierType.Iban:
        return `GB00TRYE101010${accountNumber}`;
      case AccountIdentifierType.Bban:
        return `TRYE101010${accountNumber}`;
      case AccountIdentifierType.Nrb:
        return `00TRYE101010${accountNumber}`;
      case AccountIdentifierType.SortCodeAccountNumber:
      default:
        return `10-10-10 ${accountNumber}`;
    }
  }

  const getFormattedRemitterAccountId = (): string => {
    switch (remitterAccount?.type) {
      case 'sort_code_account_number':
        return `${remitterAccount.sort_code.substr(0, 2)}-${remitterAccount.sort_code.substr(2, 2)}-${remitterAccount.sort_code.substr(4, 2)} ${remitterAccount.account_number}`;
      case 'iban':
        return `${remitterAccount.iban}`;
      case 'bban':
        return `${remitterAccount.bban}`;
      case 'nrb':
        return `${remitterAccount.nrb}`;
    }
    return '';
  }

  const generateAccount = (type: string, accountNumberOffset: number): MockAccount => {
    return {
      type: type,
      accountId: generateAccountId(accountNumberOffset),
      balanceInMinor: generateBalanceInMinor(),
      currency: currency,
      selected: false,
      disabled: false
    };
  }

  const generateAccounts = (): MockAccount[] => {
    const accounts: MockAccount[] = [
      generateAccount('Current Account', 0),
      generateAccount('Current Account', 1),
      generateAccount('Savings Account', 2),
      generateAccount('Savings Account', 3),
    ];
    accounts[0].selected = true;
    if (remitterAccount) {
      accounts[0].accountId = getFormattedRemitterAccountId();
      accounts.forEach(x => x.disabled = true);
      accounts[0].disabled = false;
    }
    return accounts;
  }
  const [accounts, setAccounts] = useState<MockAccount[]>(generateAccounts());

  const selectAccount = (account: any) => {
    if (!account.disabled) {
      accounts.forEach(a => a.selected = false);
      account.selected = true;
      setAccounts(accounts.slice());
    }
  }

  const renderAccounts = (): JSX.Element[] => accounts.map((a, i) => {
    const id = `account-${i + 1}`;
    return (
      <Grid key={id} id={id} item xs={12} sm={6}>
        <Card variant="outlined" style={a.disabled ? {backgroundColor: '#f0f0f0'} : {}} onClick={() => selectAccount(a)}>
          <CardContent>
            <Typography variant="h3">{a.type}</Typography>
            <Typography variant="subtitle1" paragraph={true}>{a.accountId}</Typography>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h2"><Currency amount={a.balanceInMinor} currency={a.currency}/></Typography>
              </Grid>
              <Grid item xs={1}>
                {a.selected ? <CheckCircle style={{'color': 'green'}}/> : <LensOutlined/>}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  });

  return (
    <Grid container spacing={3}>
      {renderAccounts()}
    </Grid>
  );
}
