import React from "react";
import { Box, Card, CardContent, Container, Grid, Typography } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";

export const MockBankError: React.FC = () => {
  return (
    <Container>
      <Grid container>
        <Grid item sm={1} md={2} lg={3} />
        <Grid item sm={10} md={8} lg={6}>
          <Box py={15}>
            <Card variant="outlined">
              <Box px={5} py={30}>
                <CardContent>
                  <Box textAlign="center" pb={2}>
                    <ErrorOutline fontSize="large" />
                  </Box>
                  <Typography align="center" variant="h2" paragraph={true}>An unexpected error occurred</Typography>
                </CardContent>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid item sm={1} md={2} lg={3} />
      </Grid>
    </Container>
  );
}
