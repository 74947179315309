import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { SimpleBackdropCoreStyle } from '../../SimpleBackdrop'
import type { SimpleBackdropContext } from '../../SimpleBackdrop.composition'
import type { StyleProps } from '../../../../../types'
import { hexToRgba } from '../../../../../utils/color'

export type SimpleBackdropStyleProps = StyleProps<SimpleBackdropCoreStyle> & ThemeProps

const Invisible = css<SimpleBackdropStyleProps>`
  background-color: transparent;
`

const Root = styled.div<SimpleBackdropStyleProps>`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${(props) => hexToRgba(props.theme.colours.tertiary.neutral.charcoal, 0.2)};
  -webkit-tap-highlight-color: transparent;

  ${(props) => props.styleProps.invisible && Invisible}
`

const Components: SimpleBackdropContext = {
  Root,
}

export default Components
