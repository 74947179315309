import * as theme from '../../../../themes/Developer/proxima-nova'
import type { LinkStyleProps } from '../DevLink.styles'

export type FontSizes = Pick<theme.Spacing1Value, 'xs' | 'sm' | 'md' | 'lg' | 'xl'>

/** Font size  */
export const defaultFontSizes: FontSizes = {
  xs: 'captionS',
  sm: 'bodyS',
  md: 'h4',
  lg: 'h3',
  xl: 'h2',
}

export const getFontSize = (props: LinkStyleProps, defaultValue?: keyof theme.FontSize) => {
  if (typeof props.styleProps.size === 'number') {
    return `${props.styleProps.size}px`
  }

  const defaultFontSize =
    typeof props.styleProps.size === 'string' ? defaultFontSizes[props.styleProps.size] : 'captionM'

  return theme.getFontSize(props, defaultValue || defaultFontSize)
}
