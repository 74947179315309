import ListStatic, { ListStaticPublicProps } from '../../ListStatic'
import Theme from '../../../../../themes/Developer/theme'
import { ListContext } from '../../../../List.composition'

import { withStyle } from '../../../../../providers'

import Styles from './DevListStatic.styles'

export const DevListStatic = withStyle<ListStaticPublicProps, ListContext>(
  ListStatic,
  Theme,
  Styles,
  'List',
)

export type DevListStaticProps = ListStaticPublicProps
