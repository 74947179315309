import * as theme from '../../../../../../../../themes/Developer/proxima-nova'
import type { ListItemStaticStyleProps } from '../DevListItemStatic.styles'

/** Padding Left */
export const getPadding = (props: ListItemStaticStyleProps, weight: number) => {
  const padding = theme.getPadding(props, props.theme.spaces.xs)
  return padding
    .toString()
    .split(' ')
    .map((value, index) => {
      const number = theme.getValueAsNumber(value)
      return `${number * (index === 3 ? weight : 1)}px`
    })
    .join(' ')
}
