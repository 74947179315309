import React from "react";
import {Box} from "@material-ui/core";
import {DevLoader} from "@tl-prisma/core";

export const LoadingPanel: React.FC = () => {
  return (
    <Box style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
      <DevLoader size={"large"} />
    </Box>
  );
}
