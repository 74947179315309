import * as React from 'react'
import type { StyledProps } from '../providers'
import type { FormValidityTextContext } from './FormValidityText.composition'
import { useFormControl } from '../FormControl/FormControl.context'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'

export interface FormValidityTextPublicProps {
  /**
   * Component used as message for the validity text
   */
  children?: React.ReactNode
}

export interface FormValidityTextProps
  extends FormValidityTextPublicProps,
    StyledProps<FormValidityTextContext> {}

export interface FormValidityTextCoreStyle {
  size: string
  invalid: boolean
}

const FormValidityText = React.forwardRef(
  (props: FormValidityTextProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const formControl = useFormControl(props)

    const getCoreStyle = (): FormValidityTextCoreStyle => ({
      size: formControl.size!,
      invalid: formControl.invalid!,
    })

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

export default FormValidityText
