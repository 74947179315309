import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../../../Icon'
import { getCircleColour, getTextColour, StepIconCustomisations } from './customisations'
import { Customisations } from './DevStepIcon.customisations'
import type { StepIconCoreStyle } from '../../StepIcon'
import type { StepIconContext } from '../../StepIcon.composition'
import type { StyleProps } from '../../../../../types'
import * as theme from '../../../../../themes/Developer/proxima-nova'

export type StepIconStyleProps = StyleProps<StepIconCoreStyle, StepIconCustomisations> & ThemeProps

const DisabledRoot = css<StepIconStyleProps>`
  opacity: ${(props) => props.theme.visuals.opacity.disable};
`

const Icon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<StepIconStyleProps>``

const Text = styled.text<StepIconStyleProps>`
  fill: ${(props) => getTextColour(props)};
  font-size: ${(props) =>
    theme.getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyXs.px)};
`

const Circle = styled.circle<StepIconStyleProps>`
  fill: ${(props) => getCircleColour(props)};
  color: ${(props) => props.theme.colours.tertiary.neutral.white};
`

const Root = styled.svg<StepIconStyleProps>`
  ${(props) => props.styleProps.disableEffect && DisabledRoot}
  ${(props) => props.styleProps.disabled && DisabledRoot}
`

const Components: StepIconContext = {
  Root,
  Circle,
  Text,
  Icon,
}

export default Components
