import * as React from 'react'
import PreviewStyled from './Preview.styles'

export enum PreviewType {
  page = 'page',
  component = 'component',
}

interface PreviewProps {
  className?: string
  orientation?: string
  type?: PreviewType
  children?: React.ReactNode
}

const Preview = (props: PreviewProps) => {
  const { children } = props
  return <PreviewStyled {...props}>{children}</PreviewStyled>
}

Preview.defaultProps = {
  orientation: 'horizontal',
  type: PreviewType.component,
}

export default Preview
