import type { NativeCheckboxPublicProps } from '../../NativeCheckbox'
import type { NativeCheckboxStyleProps } from './DevNativeCheckbox.styles'
import type { FontAwesomeIconCustomisations as IconCustomisations } from '../../../../../Icon/components/FontAwesomeIcon/themes/Developer/customisations'
import { NativeCheckboxCustomisations, getTickColour } from './customisations'

export const Customisations = {
  Icon: (props: NativeCheckboxStyleProps): IconCustomisations => ({
    colour: getTickColour(props),
    hoverColour: getTickColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: NativeCheckboxPublicProps & NativeCheckboxCustomisations,
): NativeCheckboxCustomisations => ({
  Label: props.Label || {},
  Tick: props.Tick || {},
})
