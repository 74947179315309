import type { PaginationItemCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: PaginationItemCustomisations): PaginationItemCustomisations => ({
  bgColour: props.bgColour,
  hoverBgColour: props.hoverBgColour,
  focusBgColour: props.focusBgColour,
  selectedBgColour: props.selectedBgColour,
  border: props.border,
})
