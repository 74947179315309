import FormHelperText, { FormHelperTextPublicProps } from '../../FormHelperText'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevFormHelperText.styles'
import Customisations from './DevFormHelperText.customisations'
import type { FormHelperTextContext } from '../../FormHelperText.composition'
import type { FormHelperTextCustomisations } from './customisations'

export const DevFormHelperText = withCustomisedStyle<
  FormHelperTextPublicProps,
  FormHelperTextContext,
  FormHelperTextCustomisations
>(FormHelperText, Theme, Styles, Customisations, 'FormHelperText')

export type DevFormHelperTextCustomisations = FormHelperTextCustomisations
export type DevFormHelperTextProps = FormHelperTextPublicProps & FormHelperTextCustomisations
