import { css } from '../themed-component'

export const FontFamily = css`
  ${(props) =>
    props.theme.typography &&
    css`
      font-family: ${props.theme.typography.proximaNova.fontFamily};
    `};
`

export const LetterSpacing = css`
  ${(props) =>
    props.theme.typography &&
    css`
      letter-spacing: ${props.theme.typography.proximaNova.letterSpacing};
    `};
`
