import * as React from 'react'
import type { StyledProps } from '../providers'
import type { SwitchContext } from './Switch.composition'
import { useFormControl } from '../FormControl/FormControl.context'
import useResponsive from '../hooks/useResponsive'
import useControlled from '../hooks/useControlled'
import useCheckDirty from '../FormControl/hooks/useCheckDirty'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { isInputtable } from './Switch.utils'
import { newId } from '../utils'
import { attachIf } from '../utils/event-handler'
import { getTestId } from '../utils/test'

export interface SwitchPublicProps {
  /**
   * Id of the switch.
   */
  id?: string
  /**
   * Label of the switch.
   */
  label?: string
  /**
   *
   */
  labelPosition?: 'top' | 'start' | 'bottom' | 'end'
  /**
   * Name of the switch.
   */
  name?: string
  /**
   * Value of the switch.
   */
  value?: string | boolean
  /**
   * True if the switch is ticked, false otherwise.
   */
  checked?: boolean
  /**
   * The default value if the switch is ticked or not. Use when the component is not controlled.
   */
  defaultChecked?: boolean
  /**
   * Icon representing the tick.
   */
  icon?: any
  /**
   * If `true`, the switch can be unticked, false otherwise
   */
  untickable?: boolean
  /**
   * If `true`, the `switch` element will be disabled.
   */
  disabled?: boolean
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean
  /**
   * If `true`, the label will be displayed in an error state.
   */
  invalid?: boolean
  /**
   * If `true`, the component will be displayed in focused state.
   */
  focused?: boolean
  /**
   * If `true`, the component will be displayed in hovered state.
   */
  hovered?: boolean
  /**
   * Size of the switch.
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * -1 if the switch is not keyboard accessible, index in the sequential keyboard navigation otherwise
   */
  tabIndex?: number
  /**
   * Handler when the value changes. The new value is passed as parameter.
   */
  onChange?: (value?: string | boolean) => void
  /**
   * Handler when the user clicks on the switch.
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * Handler when the switch loses the focus. The new value is passed as parameter.
   */
  onBlur?: (event: React.FocusEvent) => void
  /**
   * Handler when the switch gets the focus.
   */
  onFocus?: (event: React.FocusEvent) => void
  /**
   * Handler when the switch is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the switch is not hovered
   */
  onLeave?: (event?: React.MouseEvent) => void
  /**
   * Component used as label
   */
  children?: React.ReactNode
}

export interface SwitchProps extends SwitchPublicProps, StyledProps<SwitchContext> {}

export interface SwitchCoreStyle {
  checked: boolean
  disabled: boolean
  readOnly: boolean
  invalid: boolean
  variant: string
  size: string
  filled: boolean
  hovered: boolean
  focused: boolean
  active: boolean
  labelPosition: string
}

const Switch = React.forwardRef((props: SwitchProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const inputRef = React.useRef<HTMLInputElement>(null)

  const formControl = useFormControl(props)
  const checkDirty = useCheckDirty(formControl)

  const [checked, setChecked] = useControlled({
    value: props.checked,
    default: props.defaultChecked,
    name: 'Switch',
  })

  const [hovered, setHovered] = React.useState(props.hovered)
  const [focused, setFocused] = React.useState(props.focused)
  const [active, setActive] = React.useState(false)

  const isActionable = isInputtable(formControl)

  const tabIndex = formControl.readOnly || formControl.disabled ? -1 : props.tabIndex

  React.useLayoutEffect(() => {
    inputRef.current.checked = checked
    checkDirty({ value: checked })
  }, [checked])

  // Remove the focus when the switch is disabled and has the focus
  React.useEffect(() => {
    if (!isInputtable(formControl) && props.focused) {
      inputRef.current!.blur()
    }
  }, [formControl, props.disabled, props.readOnly, props.focused])

  const generateId = React.useMemo(() => {
    return newId()
  }, [])

  const getCoreStyle = (): SwitchCoreStyle => ({
    labelPosition: props.labelPosition!,
    checked,
    disabled: formControl.disabled!,
    readOnly: formControl.readOnly!,
    invalid: formControl.invalid!,
    variant: formControl.variant!,
    size: formControl.size!,
    filled: formControl.filled!,
    focused,
    hovered,
    active,
  })

  const handleClick = (event: React.MouseEvent) => {
    if (props.onClick) {
      props.onClick(event)
    }

    handleChange(event)
  }

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return
    }

    handleChange(event)
  }

  const handleChange = (event: React.SyntheticEvent<any>) => {
    if (props.untickable || formControl.readOnly) {
      event.stopPropagation()
      return
    }

    const nextState = !checked
    setChecked(nextState)

    if (props.onChange) {
      const update = props.value === undefined ? nextState : props.value
      props.onChange(update)
    }
  }

  const handleFocus = (event: React.FocusEvent) => {
    if (props.onFocus) {
      props.onFocus(event)
    }

    setFocused(true)
  }

  const handleBlur = (event: React.FocusEvent) => {
    if (props.onBlur) {
      props.onBlur(event)
    }

    setFocused(false)
  }

  const handleHover = (event: React.MouseEvent) => {
    if (props.onHover) {
      props.onHover(event)
    }

    setHovered(true)
  }

  const handleLeave = (event?: React.MouseEvent) => {
    if (props.onLeave) {
      props.onLeave(event)
    }

    setHovered(false)
  }

  const renderThumb = () => {
    const { Thumb } = props.styled!
    return (
      <Thumb
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'thumb')}
      />
    )
  }

  const renderTrack = () => {
    const { Track } = props.styled!
    return (
      <Track
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'track')}
      />
    )
  }

  const renderInput = () => {
    const { Input } = props.styled!
    return (
      <Input
        ref={inputRef}
        type="checkbox"
        id={props.id || props.name || generateId}
        name={props.name || props.id || generateId}
        tabIndex={-1}
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        disabled={formControl.disabled}
        readOnly={formControl.readOnly}
        placeholder={props.label}
        onChange={() => null}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'input')}
      />
    )
  }

  const renderSwitch = () => {
    const { Switch: SwitchCore } = props.styled!
    return (
      <SwitchCore
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'core')}
      >
        {renderInput()}
        {renderTrack()}
        {renderThumb()}
      </SwitchCore>
    )
  }

  const renderLabel = () => {
    const { Label } = props.styled!
    return (
      <Label
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'label')}
      >
        {props.label}
      </Label>
    )
  }

  const { Root } = props.styled!
  return (
    <Root
      ref={ref}
      className={props.className}
      tabIndex={tabIndex}
      onClick={attachIf(handleClick, isActionable)}
      onFocus={attachIf(handleFocus, isActionable)}
      onBlur={attachIf(handleBlur, isActionable)}
      onMouseEnter={attachIf(handleHover, isActionable)}
      onMouseLeave={attachIf(handleLeave, isActionable)}
      onKeyUp={attachIf(handleKeyUp, isActionable)}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {renderLabel()}
      {renderSwitch()}
    </Root>
  )
})

Switch.defaultProps = {
  labelPosition: 'end',
  size: 'medium',
  untickable: false,
  tabIndex: 0,
}

export default Switch
