import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { Grid } from '../../../../../Grid'
import type { GridContainerProps, GridItemProps } from '../../../../../Grid/Grid'
import type { RadioGroupCoreStyle } from '../../../../RadioGroup'
import type { RadioGroupContext } from '../../../../RadioGroup.composition'
import type { StyleProps } from '../../../../../types'

export type RadioGroupStyleProps = StyleProps<RadioGroupCoreStyle> & ThemeProps

const Item = styled(Grid)<RadioGroupStyleProps & GridItemProps>``

const Root = styled(Grid)<RadioGroupStyleProps & GridContainerProps>``

const Components: RadioGroupContext = {
  Root,
  Item,
}

export default Components
