import TableHeader, { TableHeaderPublicProps } from '../../TableHeader'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Customisations from './DevTableHeader.customisations'
import type { TableHeaderContext } from '../../TableHeader.composition'
import type { TableHeaderCustomisations } from './customisations'
import Styles from './DevTableHeader.styles'

export const DevTableHeader = withCustomisedStyle<
  TableHeaderPublicProps,
  TableHeaderContext,
  TableHeaderCustomisations
>(TableHeader, Theme, Styles, Customisations, 'TableHeader')

export type DevTableHeaderCustomisations = TableHeaderCustomisations
export type DevTableHeaderProps = TableHeaderPublicProps & TableHeaderCustomisations
