import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Box, Card, CardContent, Container, Grid, Typography} from "@material-ui/core";
import {DevButton} from "@tl-prisma/core";
import MockBankHeader from "../MockBankHeader";
import MockBankAccounts from "../MockBankAccounts";
import BeneficiaryDetails from "../BeneficiaryDetails";
import SingleImmediatePayment from "../SingleImmediatePayments/SingleImmediatePayment";
import singleImmediatePaymentService from "../SingleImmediatePayments/SingleImmediatePaymentsService";
import SingleImmediatePaymentAction from "../SingleImmediatePayments/SingleImmediatePaymentAction";
import {AccountIdentifierType} from "../SingleImmediatePayments/AccountIdentifier";
import LoadingPanel from "../LoadingPanel";
import {redirectAfterSubmitPayment} from "../SingleImmediatePayments/RedirectAfterSubmitPayment";


export const MockBankConfirmation: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const [singleImmediatePayment, setSingleImmediatePayment] = useState<SingleImmediatePayment | null>();
  const { hash, search, state } = useLocation();
  const history = useHistory();

  const getAction = (): string | null => {
    const matches = search.match(/action=([^&]*)/)
    return matches && matches.length > 1 ? matches[1] : null
  }

  const getExecutionDelayInSeconds = (): number => getDelayInSeconds("execution_delay_in_seconds")

  const getSettlementDelayInSeconds = (): number => getDelayInSeconds("settlement_delay_in_seconds")

  const getDelayInSeconds = (param: string): number => {
    const matches = search.match(`${param}=([^&]*)`)
    return matches && matches.length > 1 ? Number(matches[1]) : 0
  }

  const getToken = (): string | null => {
    const matches = hash.match(/#token=([^=/]+)$/);
    return matches && matches.length > 1 ? matches[1] : null;
  }

  const getFromState = (key: string) => {
    return state ? (state as any)[key] : null;
  }

  const navigateToErrorPage = (): void => {
    history.push('/error');
  }

  const token = getToken();
  const action = getAction() as SingleImmediatePaymentAction;
  const executionDelayInSeconds = getExecutionDelayInSeconds()
  const settlementDelayInSeconds = getSettlementDelayInSeconds()

  useEffect(() => {
    if (!token || !action) {
      navigateToErrorPage();
    }
  }, []);

  useEffect(() => {
    const statePayment = getFromState('payment');
    if (statePayment) {
      setSingleImmediatePayment(statePayment);
      setIsLoading(false);
    } else {
      singleImmediatePaymentService.getPayment(id, token).then((result) => {
        if (!result || result.status !== 'Initiated') {
          navigateToErrorPage();
        }
        setSingleImmediatePayment(result);
        setIsLoading(false);
      }).catch(() => {
        navigateToErrorPage();
      });
    }
  }, []);

  const payment: SingleImmediatePayment = singleImmediatePayment!;

  const getAccountType = (): AccountIdentifierType => payment.remitter_account?.type ?? payment.beneficiary_account.type;

  const submit = (event: React.MouseEvent) => actionPayment(action);

  const cancel = (event: React.MouseEvent) => actionPayment("Cancel");

  const actionPayment = (action: SingleImmediatePaymentAction) => {
    singleImmediatePaymentService
      .submitPayment(payment.id, action, token, executionDelayInSeconds, settlementDelayInSeconds)
      .then(redirectAfterSubmitPayment(navigateToErrorPage))
      .catch(navigateToErrorPage)
  }

  return (
    <Container>
      <Grid container>
        <Grid item sm={1} md={2} lg={3} />
        <Grid item sm={10} md={8} lg={6}>
          {isLoading
            ? <LoadingPanel/>
            :
            <>
              <Box pt={8} pb={2}>
                <MockBankHeader name={payment.provider_ui_display_name} />
              </Box>
              <Card variant="outlined">
                <Box p={5}>
                  <CardContent>
                    <Typography variant="h2" paragraph={true}>Select account and confirm payment</Typography>
                    <Box pb={3}>
                      <BeneficiaryDetails
                        name={payment.beneficiary_name}
                        amountInMinor={payment.amount}
                        currency={payment.currency}
                        references={payment.references} />
                    </Box>

                    <MockBankAccounts currency={payment.currency} accountType={getAccountType()} remitterAccount={payment.remitter_account} />

                    <Box pt={10}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <DevButton id="cancel" type="secondary" label="Cancel" onClick={cancel} />
                        </Grid>
                        <Grid item xs={6}>
                          <DevButton id="continue" type="primary" label="Continue" onClick={submit} />
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </>
          }
        </Grid>
        <Grid item sm={1} md={2} lg={3} />
      </Grid>
    </Container>
  );
}
