import type { StepLabelPublicProps } from '../../StepLabel'
import type { StepLabelCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: StepLabelPublicProps & StepLabelCustomisations,
): StepLabelCustomisations => ({
  colour: props.colour,
  invalidColour: props.invalidColour,
  activeColour: props.activeColour,
  completeColour: props.completeColour,
  fontSize: props.fontSize,
})
