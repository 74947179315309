import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import {
  InputCustomisations,
  getBgColour,
  getBorder,
  getColour,
  getFontSize,
  getFontWeight,
  getHeight,
  getPlaceholderColour,
} from './customisations'
import { DisabledState, ReadOnlyState } from '../../../themes/Developer/styles'
import type { InputCoreStyle } from '../../Input'
import type { InputContext } from '../../Input.composition'
import type { StyleProps } from '../../../types'
import * as themeStyles from '../../../themes/Developer/styles'

export type InputStyleProps = StyleProps<InputCoreStyle, InputCustomisations> & ThemeProps

export const shrink = (props: InputStyleProps) =>
  props.styleProps.focused || props.styleProps.filled

export const getFilledInputPadding = (props: InputStyleProps) =>
  ({
    // edge-case for small size
    small: '2px',
    medium: props.theme.spaces.xxs,
    large: props.theme.spaces.xs,
  }[props.styleProps.size])

const filledInputFontSize = {
  small: 'bodyXs',
  medium: 'bodyS',
  large: 'bodyS',
}

const BlurInput = css<InputStyleProps>`
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
`

const NumberInput = css<InputStyleProps>`
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

const FilledRoot = css<InputStyleProps>`
  ${(props) =>
    shrink(props) &&
    css`
      align-items: flex-end;
    `}
`

const FilledInput = css<InputStyleProps>`
  /* Typography */
  font-size: ${(props) => getFontSize(props, filledInputFontSize[props.styleProps.size])};

  /** Spacing */
  padding: ${(props) => `${getFilledInputPadding(props)} ${props.theme.spaces.xs}`};
`

const Input = styled.input<InputStyleProps>`
  box-sizing: border-box;
  position: relative;
  cursor: inherit;
  min-width: 0;
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  background-color: inherit;
  font-family: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  outline: none;
  width: 100%;

  /* Colour */
  ::placeholder {
    color: ${(props) => getPlaceholderColour(props)};
  }

  /** Spacing */
  padding: ${(props) => props.theme.spaces.xs};

  ${NumberInput};

  ${(props) => props.styleProps.blurContent && BlurInput};
  ${(props) => props.styleProps.variant === 'filled' && FilledInput};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  &:-webkit-autofill:disabled,
  &:-internal-autofill-selected {
    background-color: inherit !important;
    color: inherit !important;
  }
`

const Root = styled.div<InputStyleProps>`
  ${themeStyles.FontFamily}

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-grow: 1;
  white-space: nowrap;
  box-sizing: border-box;

  /* Colours */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  /* Typography */
  font-size: ${(props) => getFontSize(props)};
  font-weight: ${(props) => getFontWeight(props)};

  /* Visual */
  height: ${(props) => getHeight(props)};
  border: ${(props) => getBorder(props)};

  ${(props) => props.styleProps.disabled && DisabledState};
  ${(props) => props.styleProps.readOnly && ReadOnlyState};

  ${(props) => props.styleProps.variant === 'filled' && FilledRoot};
`

const Components: InputContext = {
  Root,
  Input,
}

export default Components
