import type { RadioPublicProps } from '../../Radio'
import type { RadioStyleProps } from './DevRadio.styles'
import type { RadioCustomisations } from './customisations'
import type { FontAwesomeIconCustomisations as IconCustomisations } from '../../../Icon/components/FontAwesomeIcon/themes/Developer/customisations'

export const Customisations = {
  Icon: (props: RadioStyleProps): IconCustomisations => ({
    colour: 'inherit',
    hoverColour: 'inherit',
  }),
  TickIcon: (props: RadioStyleProps): IconCustomisations => ({
    colour: 'inherit',
    hoverColour: 'inherit',
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: RadioPublicProps & RadioCustomisations): RadioCustomisations => ({
  colour: props.colour,
  hoverColour: props.hoverColour,
  focusColour: props.focusColour,
  selectedColour: props.selectedColour,
  bgColour: props.bgColour,
  hoverBgColour: props.hoverBgColour,
  focusBgColour: props.focusBgColour,
  selectedBgColour: props.selectedBgColour,
  border: props.border,
})
