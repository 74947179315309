import SwitchField, { SwitchFieldPublicProps } from '../../SwitchField'
import Theme from '../../../themes/Developer/theme'
import type { SwitchFieldContext } from '../../SwitchField.composition'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevSwitchField.styles'
import Customisations, { SwitchFieldCustomisations } from './DevSwitchField.customisations'

export const DevSwitchField = withCustomisedStyle<
  SwitchFieldPublicProps,
  SwitchFieldContext,
  SwitchFieldCustomisations
>(SwitchField, Theme, Styles, Customisations, 'SwitchField')

export type DevSwitchFieldCustomisations = SwitchFieldCustomisations
export type DevSwitchFieldProps = SwitchFieldPublicProps & SwitchFieldCustomisations
