import styled, { ThemeProps } from '../../../../../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../../../../../Icon'
import { Customisations } from './DevTablePaginationActions.customisations'
import type { TablePaginationActionsContext } from '../../TablePaginationActions.composition'
import type { TablePaginationActionsCustomisations } from './customisations'
import type { StyleProps } from '../../../../../../../types'

export type TablePaginationActionsStyleProps = StyleProps<
  unknown,
  TablePaginationActionsCustomisations
> &
  ThemeProps

const NextItemButton = styled(DevFontAwesomeIcon).attrs(
  Customisations.Icon,
)<TablePaginationActionsStyleProps>``

const PreviousItemButton = styled(DevFontAwesomeIcon).attrs(
  Customisations.Icon,
)<TablePaginationActionsStyleProps>``

const LastItemButton = styled(DevFontAwesomeIcon).attrs(
  Customisations.Icon,
)<TablePaginationActionsStyleProps>``

const FirstItemButton = styled(DevFontAwesomeIcon).attrs(
  Customisations.Icon,
)<TablePaginationActionsStyleProps>``

const Root = styled.div<TablePaginationActionsStyleProps>`
  ${NextItemButton},
  ${PreviousItemButton},
  ${LastItemButton},
  ${FirstItemButton} {
    padding: ${(props) => `${props.theme.spaces.xs} ${props.theme.spaces.sm}`};
  }
`

const Components: TablePaginationActionsContext = {
  Root,
  FirstItemButton,
  LastItemButton,
  PreviousItemButton,
  NextItemButton,
}

export default Components
