import { useEffect } from 'react'

const useClickAway = (opts?: { key: string; isMobile?: boolean; callback?: () => void }): any => {
  const key = opts?.key
  const isMobile = opts?.isMobile || false
  const callback = opts?.callback

  const handleKeyDown = (event: any) => {
    if (event.key === key) {
      event.preventDefault()
      event.stopPropagation()

      if (callback) {
        callback()
      }
    }
  }

  useEffect(() => {
    if (!key) {
      return () => null
    }

    if (!isMobile) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (!isMobile) {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [key])

  return []
}

export default useClickAway
