import type { PaginationItemStyleProps } from '../DevPaginationItem.styles'
import * as theme from '../../../../../../themes/Developer/proxima-nova'

type DefaultColour = Pick<theme.ColourStates, 'colour' | 'selectedColour' | 'hoverColour'>

const defaultColour = (props: PaginationItemStyleProps): DefaultColour => ({
  colour: props.theme.colours.tints.charcoal[50],
  hoverColour: props.theme.colours.tints.kashmirBlue[100],
  selectedColour: props.theme.colours.tints.azure[100],
})

export const getColour = (props: PaginationItemStyleProps) =>
  theme.getColour(props, defaultColour(props), theme.getState(props.styleProps))

type DefaultBgColour = Pick<
  theme.BgColourStates,
  'bgColour' | 'hoverBgColour' | 'focusBgColour' | 'selectedBgColour'
>

/** Background colour */
const defaultBgColour = (props: PaginationItemStyleProps): DefaultBgColour => ({
  bgColour: 'transparent',
  hoverBgColour: 'transparent',
  focusBgColour: props.theme.colours.tints.lightGrey[130],
  selectedBgColour: props.theme.colours.tints.azure[10],
})

export const getBgColour = (props: PaginationItemStyleProps) =>
  theme.getBgColour(props, defaultBgColour(props), theme.getState(props.styleProps))

type BorderColour = Pick<
  theme.ColourStates,
  'colour' | 'hoverColour' | 'focusColour' | 'selectedColour'
>

/** Border colour */
const defaultBorderColour = (props: PaginationItemStyleProps): BorderColour => ({
  colour: props.theme.colours.tints.lightGrey[180],
  hoverColour: props.theme.colours.tints.kashmirBlue[100],
  focusColour: props.theme.colours.tints.lightGrey[150],
  selectedColour: props.theme.colours.tints.azure[100],
})

export const getBorderColour = (props: PaginationItemStyleProps) => {
  const currentState = theme.getState(props.styleProps)
  return theme.getBorderColour(props, defaultBorderColour(props), currentState)
}
