export enum AccountIdentifierType {
  SortCodeAccountNumber = 'sort_code_account_number',
  Iban = 'iban',
  Bban = 'bban',
  Nrb = 'nrb',
}

export type SortCodeAccountNumber = {
  type: AccountIdentifierType.SortCodeAccountNumber,
  sort_code: string,
  account_number: string,
}

export type Iban = {
  type: AccountIdentifierType.Iban,
  iban: string,
}

export type Bban = {
  type: AccountIdentifierType.Bban,
  bban: string,
}

export type Nrb = {
  type: AccountIdentifierType.Nrb,
  nrb: string,
}

export type AccountIdentifier = SortCodeAccountNumber | Iban | Bban | Nrb;
