import * as React from 'react'

import { StyledProps } from '../providers'
import { FieldPublicProps } from '../Field/Field'
import { CheckboxFieldContext } from './CheckboxField.composition'
import { CheckboxGroupPublicProps } from '../CheckboxGroup/CheckboxGroup'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

export type CheckboxFieldPublicProps = Omit<FieldPublicProps, 'variant'> & CheckboxGroupPublicProps

export interface CheckboxFieldProps
  extends CheckboxFieldPublicProps,
    StyledProps<CheckboxFieldContext> {}

const CheckboxField = React.forwardRef((props: CheckboxFieldProps, forwardRef: any) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const getCoreStyle = () => ({})

  const renderCheckboxGroup = () => {
    const { CheckboxGroup } = props.styled!
    return (
      <CheckboxGroup
        key={props.id}
        id={props.id || props.name}
        name={props.name || props.id}
        value={props.value}
        spacing={props.spacing}
        atLeastOne={props.atLeastOne}
        size={props.size}
        orientation={props.orientation}
        breakpoints={props.breakpoints}
        onChange={props.onChange}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, `group`)}
      >
        {props.children}
      </CheckboxGroup>
    )
  }

  const { Root } = props.styled!
  return (
    <Root
      className={props.className}
      ref={ref}
      id={props.id}
      label={props.label}
      helperText={props.helperText}
      disabled={props.disabled}
      invalid={props.invalid}
      required={props.required}
      readOnly={props.readOnly}
      size={props.size}
      breakpoint={props.breakpoint}
      error={props.error}
      tooltip={props.tooltip}
      withValidityStatus={props.withValidityStatus}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {renderCheckboxGroup()}
    </Root>
  )
})

CheckboxField.defaultProps = {}

export default CheckboxField
