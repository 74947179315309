import * as React from 'react'
import * as PropTypes from 'prop-types'
import * as ReactDOM from 'react-dom'
import useForkRef from '../hooks/useForkRef'
import useRef from '../hooks/useRef'
import getContainer from './Portal.utils'
import useEnhancedEffect from '../hooks/useEnhancedEffect'
import HTMLElementType from '../utils/HTMLElementType'

export interface PortalPublicProps {
  /**
   * A HTML element, component instance, or function that returns either.
   * The `container` will have the portal children appended to it.
   *
   * By default, it uses the body of the top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container?: Element | (() => Element | null) | null | unknown
  /**
   * The `children` will be inside the DOM hierarchy of the parent component.
   * @default false
   */
  disablePortal?: boolean
  /**
   * The children to render into the `container`.
   */
  children?: React.ReactNode
}

export type PortalProps = PortalPublicProps

/**
 * Portals provide a first-class way to render children into a DOM node
 * that exists outside the DOM hierarchy of the parent component.
 */
const Portal = React.forwardRef((props: PortalProps, ref: React.Ref<HTMLElement>) => {
  const [mountNode, setMountNode] = React.useState(null)

  const handleRef = useForkRef(
    React.isValidElement(props.children) ? (props.children as any).ref : null,
    ref,
  )

  // Mount the element that will have the portal children appended to it.
  useEnhancedEffect(() => {
    if (!props.disablePortal) {
      setMountNode(getContainer(props.container) || document.body)
    }
  }, [props.container, props.disablePortal])

  // Attach the mount element to the DOM ref
  useEnhancedEffect(() => {
    if (mountNode && !props.disablePortal) {
      useRef(ref, mountNode)
      return () => {
        useRef(ref, null)
      }
    }

    return undefined
  }, [ref, mountNode, props.disablePortal])

  if (props.disablePortal) {
    if (React.isValidElement(props.children)) {
      return React.cloneElement(props.children, {
        ref: handleRef,
      })
    }

    return props.children
  }

  const node = mountNode as any
  return node ? ReactDOM.createPortal(props.children, node) : node
})

Portal.propTypes = {
  container: PropTypes.oneOfType([HTMLElementType, PropTypes.func]),
  disablePortal: PropTypes.bool,
  children: PropTypes.node,
}

export default Portal
