import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'

import { ListContext } from '../../../../List.composition'
import { StyleProps } from '../../../../../types'
import * as Styles from '../../../../List.styles'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type ListStaticStyleProps = StyleProps & ThemeProps

const Root = styled.ul<ListStaticStyleProps>`
  ${Styles.Root}
  ${themeStyles.FontFamily}
`

const Components: ListContext = {
  Root,
}

export default Components
