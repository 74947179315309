import { useEffect, useRef } from 'react'

const useClickAway = (opts?: {
  refs?: any[]
  isMobile?: boolean
  default?: boolean
  callback?: () => void
}): any => {
  const references = opts?.refs || []
  const defaultValue = opts?.default || false

  const isMobile = opts?.isMobile || false
  const callback = opts?.callback

  const inside = useRef(defaultValue)

  const checkIfClickIsTriggeredOutsideTheElement = (event: any) => {
    const outside = references.every((reference: any) =>
      reference.current ? !reference.current.contains(event.target) : false,
    )

    if (!outside) {
      // Click inside the element
      if (!inside.current) {
        inside.current = true
      }

      return
    }

    // Click outside the element
    if (!inside.current) {
      // Previous click was already outside
      return
    }

    inside.current = false

    if (callback) {
      callback()
    }
  }

  const handleTouchStart = (event: any) => {
    event.preventDefault()

    checkIfClickIsTriggeredOutsideTheElement(event)
  }

  const handleMouseDown = (event: any) => {
    event.preventDefault()

    checkIfClickIsTriggeredOutsideTheElement(event)
  }

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('touchstart', handleTouchStart)
    } else {
      document.addEventListener('mousedown', handleMouseDown)
    }

    return () => {
      if (isMobile) {
        document.removeEventListener('touchstart', handleTouchStart)
      } else {
        document.removeEventListener('mousedown', handleMouseDown)
      }
    }
  }, [])

  return []
}

export default useClickAway
