import * as React from 'react'
import * as PropTypes from 'prop-types'
import type { FormControlContext as FormControlCoreContext } from './FormControl.composition'
import type { StyledProps } from '../providers'
import { FormControlContext, FormControlContextProps } from './FormControl.context'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'

export interface FormControlPublicProps {
  /**
   * True if the field is required, false otherwise
   */
  required?: boolean
  /**
   * True if the field is disabled, false otherwise
   */
  disabled?: boolean
  /**
   * If `true`, the label, input and helper text should be displayed in a disabled state.
   */
  invalid?: boolean
  /**
   * True if the field is readonly, false otherwise
   */
  readOnly?: boolean
  /**
   * The variant to use.
   */
  variant?: 'filled' | 'outlined' | 'standard'
  /**
   * If `true`, the component will take up the full width of its container.
   */
  fullWidth?: boolean
  /**
   * The size of the text field.
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * If `true`, the component will be displayed in focused state.
   */
  focused?: boolean
  /**
   * Component used as children
   */
  children?: React.ReactNode
}

interface FormControlProps extends FormControlPublicProps, StyledProps<FormControlCoreContext> {}

const FormControl = React.forwardRef(
  (props: FormControlProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const [filled, setFilled] = React.useState(false)
    const [focused, setFocused] = React.useState(false)
    const [hovered, setHovered] = React.useState(false)

    const onFilled = React.useCallback(() => setFilled(true), [])
    const onEmpty = React.useCallback(() => setFilled(false), [])

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)
    const onHover = () => setHovered(true)
    const onLeave = () => setHovered(false)

    const context: FormControlContextProps = {
      required: props.required,
      disabled: props.disabled,
      invalid: props.invalid,
      readOnly: props.readOnly,
      variant: props.variant,
      filled,
      focused,
      hovered,
      size: props.size!,
      onEmpty,
      onFilled,
      onFocus,
      onBlur,
      onHover,
      onLeave,
    }

    const getCoreStyle = () => ({})

    const { Root } = props.styled!
    return (
      <FormControlContext.Provider value={context}>
        <Root
          className={props.className}
          ref={ref}
          role="group"
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getAttributes(props, DataAttributesPrefix)}
        >
          {props.children}
        </Root>
      </FormControlContext.Provider>
    )
  },
)

FormControl.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  readOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  focused: PropTypes.bool,
}

FormControl.defaultProps = {
  variant: 'standard',
  size: 'medium',
}

export default FormControl
