import * as React from 'react'
import useResponsive from '../../../../../hooks/useResponsive'
import getAttributes from '../../../../../attributes'
import { DataAttributesPrefix } from '../../../../../constants'
import { attachIf } from '../../../../../utils/event-handler'
import { getTestId } from '../../../../../utils/test'
import type { StyledProps } from '../../../../../providers'
import type { TablePaginationActionsContext } from './TablePaginationActions.composition'
import {
  faSolidAngleDoubleLeft,
  faSolidAngleDoubleRight,
  faSolidAngleLeft,
  faSolidAngleRight,
} from '../../../../../utils/fontawesome'

export interface TablePaginationActionsPublicProps {
  /**
   * The zero-based index of the current page.
   */
  page: number
  /**
   * The total number of rows.
   *
   * To enable server side pagination for an unknown number of items, provide -1.
   */
  count: number
  /**
   * The number of rows per page.
   */
  rowsPerPage?: number
  /**
   * If `true`, show the first-page button.
   * @default false
   */
  showFirstButton?: boolean
  /**
   * If `true`, show the last-page button.
   * @default false
   */
  showLastButton?: boolean
  /**
   * If `true`, the actions will be disabled.
   */
  disabled?: boolean
  /**
   * It prevents the user from changing the value of the actions
   */
  readOnly?: boolean
  /**
   * Callback fired when the page is changed.
   *
   * @param {object} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onPageChange?: (event: React.MouseEvent, page: number) => void
}

export interface TablePaginationActionsProps
  extends TablePaginationActionsPublicProps,
    StyledProps<TablePaginationActionsContext> {}

const TablePaginationActions = React.forwardRef(
  (props: TablePaginationActionsProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive<HTMLDivElement>({ ref: forwardRef })

    const isActionable = !props.readOnly && !props.disabled

    const getCoreStyle = () => ({})

    const pageChange = (event: React.MouseEvent, page: number) => {
      if (props.onPageChange) {
        props.onPageChange(event, page)
      }
    }

    const handleFirstPageButtonClick = (event: React.MouseEvent) => {
      pageChange(event, 0)
    }

    const handleBackButtonClick = (event: React.MouseEvent) => {
      pageChange(event, props.page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent) => {
      pageChange(event, props.page + 1)
    }

    const handleLastPageButtonClick = (event) => {
      pageChange(event, Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1))
    }

    const renderFirstItemButton = () => {
      const { FirstItemButton } = props.styled
      return props.showFirstButton ? (
        <FirstItemButton
          src={faSolidAngleDoubleLeft}
          disabled={props.disabled || props.page === 0}
          readOnly={props.readOnly}
          onClick={attachIf(handleFirstPageButtonClick, isActionable)}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'first-item')}
        />
      ) : null
    }

    const renderPreviousItemButton = () => {
      const { PreviousItemButton } = props.styled
      return (
        <PreviousItemButton
          src={faSolidAngleLeft}
          disabled={props.disabled || props.page === 0}
          readOnly={props.readOnly}
          onClick={attachIf(handleBackButtonClick, isActionable)}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'previous-item')}
        />
      )
    }

    const renderNextItemButton = () => {
      let { disabled } = props
      if (!disabled) {
        disabled =
          props.count !== -1 ? props.page >= Math.ceil(props.count / props.rowsPerPage) - 1 : false
      }

      const { NextItemButton } = props.styled
      return (
        <NextItemButton
          src={faSolidAngleRight}
          disabled={disabled}
          readOnly={props.readOnly}
          onClick={attachIf(handleNextButtonClick, isActionable)}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'next-item')}
        />
      )
    }

    const renderLastItemButton = () => {
      let { disabled } = props
      if (!disabled) {
        disabled = props.page >= Math.ceil(props.count / props.rowsPerPage) - 1
      }

      const { LastItemButton } = props.styled
      return props.showLastButton ? (
        <LastItemButton
          src={faSolidAngleDoubleRight}
          disabled={disabled}
          readOnly={props.readOnly}
          onClick={attachIf(handleLastPageButtonClick, isActionable)}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'last-item')}
        />
      ) : null
    }

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderFirstItemButton()}
        {renderPreviousItemButton()}
        {renderNextItemButton()}
        {renderLastItemButton()}
      </Root>
    )
  },
)

TablePaginationActions.defaultProps = {
  rowsPerPage: 10,
  showFirstButton: false,
  showLastButton: false,
  disabled: false,
  readOnly: false,
}

export default TablePaginationActions
