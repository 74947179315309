import {
  MutableRefObject,
  Ref,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import { Context } from '../responsives'
import { getDevices } from '../devices'
import { getRootElement } from '../utils'

type ObservedSize = {
  width: number | undefined
  height: number | undefined
  device: string | undefined
  breakpoints?: any
}

type ResizeHandler = (size: ObservedSize) => void

const getDevice = (context: any, width: number): string => {
  const devices = getDevices(context)

  const currentKeys = Object.keys(devices)

  for (let i = currentKeys.length - 1; i >= 0; i -= 1) {
    if (width! > +devices[currentKeys[i]]) {
      return currentKeys[i]
    }
  }

  return 'xs'
}

/**
 * Compute the new breakpoint of the component depending on the size of the root element
 * @param opts options available using the hook
 * @param opts.ref reference of the dom element to observe
 * @param opts.onResize callback to be manage the new value outside of the hook
 * @returns Return an immutable object with the ref, width, height and device
 */
const useResponsive = <T extends HTMLElement | SVGElement>(opts?: {
  ref: Ref<T>
  onResize?: ResizeHandler
}): { ref: RefObject<T> } & ObservedSize => {
  const context = useContext(Context)

  // Saving the callback as a ref. With this, I don't need to put onResize in the
  // effect dep array, and just passing in an anonymous function without memoising
  // will not reinstantiate the hook's ResizeObserver
  // const onResize = opts.onResize;
  const onResizeRef = useRef<ResizeHandler | undefined>(undefined)
  onResizeRef.current = opts?.onResize

  // Using a single instance throughought the hook's lifetime
  const resizeObserverRef = useRef<ResizeObserver>() as MutableRefObject<ResizeObserver>

  const defaultRef = useRef<T>(null)
  const ref = opts?.ref || defaultRef
  const [infos, setInfos] = useState<{
    width?: number
    height?: number
    device?: string
  }>({
    width: undefined,
    height: undefined,
    device: undefined,
  })

  // Using a ref to track the previous width / height to avoid unnecessary renders
  const previous: {
    current: {
      width?: number
      height?: number
      device?: string
    }
  } = useRef({
    width: undefined,
    height: undefined,
    device: undefined,
  })

  useEffect(() => {
    if (resizeObserverRef.current) {
      return
    }

    resizeObserverRef.current = new ResizeObserver((entries: any) => {
      if (!Array.isArray(entries)) {
        return
      }

      // Since we only observe the one element, we don't need to loop over the
      // array
      if (!entries.length) {
        return
      }

      const entry = entries[0]

      // `Math.round` is in line with how CSS resolves sub-pixel values
      const newWidth = Math.round(entry.contentRect.width)
      const newHeight = Math.round(entry.contentRect.height)
      const newDevice = getDevice(context, entry.contentRect.width)

      if (
        previous.current.width !== newWidth ||
        previous.current.height !== newHeight ||
        previous.current.device !== newDevice
      ) {
        const newSize = {
          width: newWidth,
          height: newHeight,
          device: newDevice,
        }
        if (onResizeRef.current) {
          onResizeRef.current(newSize)
        } else {
          previous.current.width = newWidth
          previous.current.height = newHeight
          previous.current.device = newDevice

          setInfos(newSize)
        }
      }
    })
  }, [])

  useEffect(() => {
    if (typeof ref !== 'object' || ref.current === null || !(ref.current instanceof Element)) {
      return () => null
    }

    const element: any = getRootElement(ref.current)
    if (element == null) {
      return () => null
    }

    resizeObserverRef.current.observe(element)

    return () => resizeObserverRef.current.unobserve(element)
  }, [ref])

  return useMemo(
    () => ({
      ref,
      width: infos.width,
      height: infos.height,
      device: infos.device,
      breakpoints: context.breakpoints,
    }),
    [ref, infos ? infos.width : null, infos ? infos.height : null, infos ? infos.device : null],
  ) as { ref: RefObject<T> } & ObservedSize
}

export default useResponsive
