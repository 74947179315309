import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { Modal } from '../../../Modal'
import { DevPaper } from '../../../Paper'
import { getValue } from '../../../style'
import type { DialogContext } from '../../Dialog.composition'
import type { DialogCoreStyle } from '../../Dialog'
import type { StyleProps } from '../../../types'

export type DialogStyleProps = StyleProps<DialogCoreStyle> & ThemeProps

const isBodyScroll = (props: DialogStyleProps) => props.styleProps.scroll === 'body'
const isPaperScroll = (props: DialogStyleProps) => props.styleProps.scroll === 'paper'
const isFullScreen = (props: DialogStyleProps) => props.styleProps.fullScreen
const isFullWidth = (props: DialogStyleProps) => props.styleProps.fullWidth
const isMaxWidth = (props: DialogStyleProps) => props.styleProps.maxWidth
const margin = (props: DialogStyleProps) => props.theme.spaces.xl

const PaperMaxWidth = css<DialogStyleProps>`
  max-width: ${(props) =>
    `calc(${getValue(props.styleProps.maxWidth)} - calc(2 * ${margin(props)}))`};
`

const PaperFullWidth = css<DialogStyleProps>`
  width: ${(props) => `calc(100% - calc(2 * ${margin(props)}))`};
`

const PaperFullScreen = css<DialogStyleProps>`
  margin: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: none;
  border-radius: 0;

  ${(props) =>
    isPaperScroll(props) &&
    css`
      margin: 0;
      max-width: 100%;
    `}
`

const PaperScrollPaper = css<DialogStyleProps>`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => `calc(100% - calc(2 * ${margin(props)}))`};
`

const PaperScrollBody = css<DialogStyleProps>`
  display: inline-block;
  vertical-align: middle;
  text-align: left;
`

const ContainerScrollPaper = css<DialogStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContainerScrollBody = css<DialogStyleProps>`
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
  }
`

const Paper = styled(DevPaper)<DialogStyleProps>`
  margin: ${(props) => margin(props)};
  position: relative;
  overflow-y: auto;

  ${(props) => isBodyScroll(props) && PaperScrollBody};
  ${(props) => isPaperScroll(props) && PaperScrollPaper};
  ${(props) => isFullWidth(props) && PaperFullWidth};
  ${(props) => isFullScreen(props) && PaperFullScreen};
  ${(props) => isMaxWidth(props) && PaperMaxWidth};
`

const Container = styled.div<DialogStyleProps>`
  height: 100%;
  outline: 0;

  ${(props) => isBodyScroll(props) && ContainerScrollBody};
  ${(props) => isPaperScroll(props) && ContainerScrollPaper};
`

const Root = styled(Modal)<DialogStyleProps>``

const Components: DialogContext = {
  Root,
  Container,
  Paper,
}

export default Components
