import { Opacity } from '../../models'

export const getOpacity = (value: Opacity, defaultValue: number) => {
  if (value < 0 || value > 1) {
    console.warn('[PRISMA] Opacity has to be a value between 0 and 1.')
    return 0
  }

  return value.opacity || defaultValue
}
