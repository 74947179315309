import CheckboxGroup, { CheckboxGroupPublicProps } from '../../CheckboxGroup'
import Theme from '../../../themes/Developer/theme'
import type { CheckboxGroupContext } from '../../CheckboxGroup.composition'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevCheckboxGroup.styles'
import Customisations, {
  DevCheckboxGroupCustomisations as CheckboxGroupCustomisations,
} from './DevCheckboxGroup.customisations'

export const DevCheckboxGroup = withCustomisedStyle<
  CheckboxGroupPublicProps,
  CheckboxGroupContext,
  CheckboxGroupCustomisations
>(CheckboxGroup, Theme, Styles, Customisations, 'CheckboxGroup')

export type DevCheckboxGroupCustomisations = CheckboxGroupCustomisations
export type DevCheckboxGroupProps = CheckboxGroupPublicProps & CheckboxGroupCustomisations
