import OptionWithIcon, { OptionWithIconPublicProps } from '../../OptionWithIcon'
import Theme from '../../../../../themes/Developer/theme'
import type { OptionWithIconContext } from '../../OptionWithIcon.composition'
import { withStyle } from '../../../../../providers'
import Styles from './DevOptionWithIcon.styles'

export const DevOptionWithIcon = withStyle<OptionWithIconPublicProps, OptionWithIconContext>(
  OptionWithIcon,
  Theme,
  Styles,
  'OptionWithIcon',
)

export type DevOptionWithIconProps = OptionWithIconPublicProps
