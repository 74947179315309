import PaginationItem, { PaginationItemPublicProps } from '../../PaginationItem'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevPaginationItem.styles'
import Customisations from './DevPaginationItem.customisations'
import type { PaginationItemContext } from '../../PaginationItem.composition'
import type { PaginationItemCustomisations } from './customisations'

export const DevPaginationItem = withCustomisedStyle<
  PaginationItemPublicProps,
  PaginationItemContext,
  PaginationItemCustomisations
>(PaginationItem, Theme, Styles, Customisations, 'PaginationItem')

export type DevPaginationItemCustomisations = PaginationItemCustomisations
export type DevPaginationItemProps = PaginationItemPublicProps & PaginationItemCustomisations
