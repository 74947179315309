import type { SelectStyleProps } from './DevSelect.styles'
import type { SelectPublicProps } from '../../Select'
import { SelectCustomisations, getArrowBgColour, getArrowColour } from './customisations'

export const Customisations = {
  Arrow: (props: SelectStyleProps) => ({
    colour: getArrowColour(props),
    hoverColour: getArrowColour(props),
    bgColour: getArrowBgColour(props),
    hoverBgColour: getArrowBgColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: SelectPublicProps & SelectCustomisations): SelectCustomisations => ({
  border: props.border,
  paddingLeft: props.paddingLeft,
  Option: props.Option,
  Selection: props.Selection,
})
