import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { MenuGroupStyleProps } from '../DevMenuGroup.styles'

/** Header */
/** Colour */
export const getHeaderColour = (props: MenuGroupStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Header')
  return theme.getColour(
    customisations,
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )
}

/** Arrow */
/** Colour */
export const getArrowColour = (props: MenuGroupStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Header.Arrow')
  return theme.getColour(customisations, getHeaderColour(props), theme.getState(props.styleProps))
}

/** Background colour */
export const getHeaderBgColour = (props: MenuGroupStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Header')
  return theme.getBgColour(
    customisations,
    props.theme.colours.tertiary.neutral.white,
    theme.getState(props.styleProps),
  )
}

/** Content */
/** Color */
export const getContentColour = (props: MenuGroupStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Content')
  return theme.getColour(
    customisations,
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )
}

/** Background colour */
export const getContentBgColour = (props: MenuGroupStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Content')
  return theme.getBgColour(
    customisations,
    props.theme.colours.tertiary.neutral.white,
    theme.getState(props.styleProps),
  )
}
