import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { SearchableSelectStyleProps } from '../DevSearchableSelect.styles'

/** Search */
/** Colour */
export const getSearchColour = (props: SearchableSelectStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Selection.Search'),
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )

/** Background colour */
export const getSearchBgColour = (props: SearchableSelectStyleProps) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'Selection.Search'),
    'transparent',
    theme.getState(props.styleProps),
  )

/** Reset */
/** Colour */
const defaultResetColour = (props: SearchableSelectStyleProps) => ({
  colour: props.theme.colours.tertiary.neutral.charcoal,
  hoverColour: props.theme.colours.tertiary.neutral.lightGrey,
  focusColour: props.theme.colours.tertiary.neutral.lightGrey,
})

export const getResetColour = (props: SearchableSelectStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Selection.Reset'),
    defaultResetColour(props),
    theme.getState(props.styleProps),
  )

/** Background colour */
export const getResetBgColour = (props: SearchableSelectStyleProps) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'Selection.Reset'),
    'transparent',
    theme.getState(props.styleProps),
  )
