import SelectField, { SelectFieldPublicProps } from '../../SelectField'
import Theme from '../../../themes/Developer/theme'
import type { SelectFieldContext } from '../../SelectField.composition'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevSelectField.styles'
import Customisations, { SelectFieldCustomisations } from './DevSelectField.customisations'

export const DevSelectField = withCustomisedStyle<
  SelectFieldPublicProps,
  SelectFieldContext,
  SelectFieldCustomisations
>(SelectField, Theme, Styles, Customisations, 'SelectField')

export type DevSelectFieldCustomisations = SelectFieldCustomisations
export type DevSelectFieldProps = SelectFieldPublicProps & SelectFieldCustomisations
