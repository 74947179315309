import FontAwesomeIcon, { FontAwesomeIconPublicProps } from '../../FontAwesomeIcon'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevFontAwesomeIcon.styles'
import Customisations from './DevFontAwesomeIcon.customisations'
import type { FontAwesomeIconContext } from '../../FontAwesomeIcon.composition'
import type { FontAwesomeIconCustomisations } from './customisations'

export const DevFontAwesomeIcon = withCustomisedStyle<
  FontAwesomeIconPublicProps,
  FontAwesomeIconContext,
  FontAwesomeIconCustomisations
>(FontAwesomeIcon, Theme, Styles, Customisations, 'FontAwesomeIcon')

export type DevFontAwesomeIconCustomisations = FontAwesomeIconCustomisations
export type DevFontAwesomeIconProps = FontAwesomeIconPublicProps & FontAwesomeIconCustomisations
