import * as theme from '../../../../themes/Developer/proxima-nova'
import type { TabsStyleProps } from '../DevTabs.styles'

/** Tab */
/** Colour */
export const getTabColour = (props: TabsStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Tab'),
    props.theme.colours.tints.lightGrey[180],
    theme.getState(props.styleProps),
  )

/** Indicator  */
/** Background colour */
export const getIndicatorBgColour = (props: TabsStyleProps) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'Indicator'),
    props.theme.colours.tints.azure[100],
    theme.getState(props.styleProps),
  )

/** ScrollButton */
/** Colour */
export const getScrollButtonColour = (props: TabsStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'ScrollButton'),
    props.theme.colours.tints.lightGrey[180],
    theme.getState(props.styleProps),
  )

/** Background colour */
export const getScrollButtonBgColour = (props: TabsStyleProps) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'ScrollButton'),
    'transparent',
    theme.getState(props.styleProps),
  )

/** MoreButton */
/** Colour */
const moreButtonDefaultColour = (props: TabsStyleProps) => ({
  colour: props.theme.colours.tints.lightGrey[180],
  hoverColour: props.theme.colours.tints.azure[100],
  focusColour: props.theme.colours.tints.azure[100],
})

export const getMoreButtonColour = (props: TabsStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'MoreButton'),
    moreButtonDefaultColour(props),
    theme.getState(props.styleProps),
  )

/** Background colour */
export const getMoreButtonBgColour = (props: TabsStyleProps) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'MoreButton'),
    'transparent',
    theme.getState(props.styleProps),
  )
