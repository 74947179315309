import { DevTheme } from '../../../index'
import { keys } from '../../../../utils/lodash'
import { Tints } from '../models'

/**
 * Returns the percent tints associated to the hex colour
 * @param hex hexa of the colour
 * @param percent percent of the requested tint i.e 10, 30, etc
 */
export const getTint = (hex: string, percent: number) => {
  const properties = keys(DevTheme.colours.tints) as (keyof Tints)[]

  const tintRequested = properties.find((property) => {
    if (DevTheme.colours.tints[property]?.[100]) {
      // if 100% tint exists that means we deal with a tints object
      const tints = keys(DevTheme.colours.tints[property])
      return tints.find((tint: string) => DevTheme.colours.tints?.[property][tint] === hex)
    }

    return false
  })

  return tintRequested ? DevTheme.colours.tints?.[tintRequested][percent] : undefined
}
