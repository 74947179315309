import * as React from 'react'
import Radio, { RadioCoreStyle, RadioPublicProps } from '../../Radio'
import type { NativeRadioContext } from './NativeRadio.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import useControlled from '../../../hooks/useControlled'
import { useFormControl } from '../../../FormControl/FormControl.context'
import { faSolidCircle } from '../../../utils/fontawesome'
import { getTestId } from '../../../utils/test'

export type NativeRadioPublicProps = Omit<RadioPublicProps, 'icon' | 'adornment'>

export interface NativeRadioProps extends NativeRadioPublicProps, StyledProps<NativeRadioContext> {}

const NativeRadio = React.forwardRef(
  (props: NativeRadioProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const formControl = useFormControl(props)

    const [checked, setChecked] = useControlled({
      value: props.checked,
      default: props.defaultChecked,
      name: 'Checkbox',
    })

    const [hovered, setHovered] = React.useState(props.hovered!)
    const [focused, setFocused] = React.useState(props.focused!)

    const getCoreStyle = (): RadioCoreStyle => {
      return {
        checked,
        disabled: formControl.disabled!,
        readOnly: formControl.readOnly!,
        invalid: formControl.invalid!,
        variant: formControl.variant!,
        size: formControl.size!,
        filled: formControl.filled!,
        focused,
        hovered,
        active: focused,
      }
    }

    const handleChecked = (value: boolean) => {
      setChecked(value)

      if (props.onCheck) {
        props.onCheck(value)
      }
    }

    const handleFocus = (event: React.FocusEvent) => {
      setFocused(true)

      if (props.onFocus) {
        props.onFocus(event)
      }
    }

    const handleBlur = (event: React.FocusEvent) => {
      setFocused(false)

      if (props.onBlur) {
        props.onBlur(event)
      }
    }

    const handleHover = (event: React.MouseEvent) => {
      setHovered(true)

      if (props.onHover) {
        props.onHover(event)
      }
    }

    const handleLeave = (event: React.MouseEvent) => {
      setHovered(false)

      if (props.onLeave) {
        props.onLeave(event)
      }
    }

    const renderTickIcon = () => {
      const icon = checked ? faSolidCircle : undefined

      const { TickIcon } = props.styled!
      return icon ? (
        <TickIcon
          src={icon}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'tick-icon')}
        />
      ) : null
    }

    const renderTick = () => {
      const { Tick } = props.styled!
      return (
        <Tick
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'tick')}
        >
          {renderTickIcon()}
        </Tick>
      )
    }

    return (
      <Radio
        className={props.className}
        ref={ref}
        {...props}
        adornment={renderTick()}
        onCheck={handleChecked}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onHover={handleHover}
        onLeave={handleLeave}
      />
    )
  },
)

NativeRadio.defaultProps = {
  size: 'medium',
}

export default NativeRadio
