import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { getBgColour, SeparatorCustomisations } from './customisations'
import type { SeparatorContext } from '../../Separator.composition'
import type { SeparatorCoreStyle } from '../../Separator'
import type { StyleProps } from '../../../types'

export type SeparatorStyleProps = StyleProps<SeparatorCoreStyle, SeparatorCustomisations> &
  ThemeProps

const HorizontalRoot = css<SeparatorStyleProps>`
  width: 100%;
  height: 1px;
`

const VerticalRoot = css<SeparatorStyleProps>`
  width: 1px;
  height: 100%;
`

const Root = styled.div<SeparatorStyleProps>`
  background-color: ${(props) => getBgColour(props)};

  ${(props) => props.styleProps.orientation === 'horizontal' && HorizontalRoot};
  ${(props) => props.styleProps.orientation === 'vertical' && VerticalRoot};
`

const Components: SeparatorContext = {
  Root,
}

export default Components
