import React from "react";
import {Box, Card, CardContent, Typography} from "@material-ui/core";
import {Close} from '@material-ui/icons';
import {DevTable, DevTableBody, DevTableCell, DevTableContainer, DevTableHeader, DevTableRow} from "@tl-prisma/core";

interface Props {
  onClose: () => void;
}

export const ExecutionDelayInformation: React.FC<Props> = ({onClose}) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Box p={3}>
          <Box textAlign="right">
            <Close fontSize="large" onClick={onClose} />
          </Box>
          <Box textAlign="center">
            <Typography variant="h2" paragraph={true}>Execution delay</Typography>
          </Box>
            
          <Typography paragraph={true}>The table below shows the average execution times, broken down by country and payment scheme.</Typography>
            
          <DevTableContainer>
            <DevTable>
              <DevTableHeader>
                <DevTableRow bgColour={"white"}>
                  <DevTableCell align={"left"}>Country</DevTableCell>
                  <DevTableCell align={"left"}>Payment scheme</DevTableCell>
                  <DevTableCell align={"right"}>Average execution times</DevTableCell>
                </DevTableRow>
              </DevTableHeader>
              <DevTableBody>                  
                <DevTableRow bgColour={"white"}>
                  <DevTableCell>Austria</DevTableCell>
                  <DevTableCell>SEPA</DevTableCell>
                  <DevTableCell align={"right"}>16 hours</DevTableCell>
                </DevTableRow>
                  
                <DevTableRow bgColour={"white"}>
                  <DevTableCell>Finland</DevTableCell>
                  <DevTableCell>SEPA</DevTableCell>
                  <DevTableCell align={"right"}>1 minute</DevTableCell>
                </DevTableRow>
                  
                <DevTableRow bgColour={"white"}>
                  <DevTableCell rowSpan={2}>France</DevTableCell>
                  <DevTableCell>SEPA</DevTableCell>
                  <DevTableCell align={"right"}>15 hours</DevTableCell>
                </DevTableRow>
                <DevTableRow bgColour={"white"}>
                  <DevTableCell>SEPA Instant</DevTableCell>
                  <DevTableCell align={"right"}>1 minute</DevTableCell>
                </DevTableRow>
                  
                <DevTableRow bgColour={"white"}>
                  <DevTableCell rowSpan={2}>Germany</DevTableCell>
                  <DevTableCell>SEPA</DevTableCell>
                  <DevTableCell align={"right"}>3 hours</DevTableCell>
                </DevTableRow>
                <DevTableRow bgColour={"white"}>
                  <DevTableCell>SEPA Instant</DevTableCell>
                  <DevTableCell align={"right"}>3 minutes</DevTableCell>
                </DevTableRow>
                  
                <DevTableRow bgColour={"white"}>
                  <DevTableCell>Ireland</DevTableCell>
                  <DevTableCell>SEPA</DevTableCell>
                  <DevTableCell align={"right"}>4 hours</DevTableCell>
                </DevTableRow>

                <DevTableRow bgColour={"white"}>
                  <DevTableCell>Lithuania</DevTableCell>
                  <DevTableCell>SEPA</DevTableCell>
                  <DevTableCell align={"right"}>4 minutes</DevTableCell>
                </DevTableRow>

                <DevTableRow bgColour={"white"}>
                  <DevTableCell>Netherlands</DevTableCell>
                  <DevTableCell>SEPA</DevTableCell>
                  <DevTableCell align={"right"}>18 minutes</DevTableCell>
                </DevTableRow>

                <DevTableRow bgColour={"white"}>
                  <DevTableCell rowSpan={2}>Spain</DevTableCell>
                  <DevTableCell>SEPA</DevTableCell>
                  <DevTableCell align={"right"}>21 hours</DevTableCell>
                </DevTableRow>
                <DevTableRow bgColour={"white"}>
                  <DevTableCell>SEPA Instant</DevTableCell>
                  <DevTableCell align={"right"}>2 minutes</DevTableCell>
                </DevTableRow>

                <DevTableRow bgColour={"white"}>
                  <DevTableCell>UK</DevTableCell>
                  <DevTableCell>Faster Payments</DevTableCell>
                  <DevTableCell align={"right"}>1 minute</DevTableCell>
                </DevTableRow>
                  
              </DevTableBody>
            </DevTable>
          </DevTableContainer>
            
        </Box>
      </CardContent>
    </Card>
  );
}
