import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../../../Icon'
import { getBgColour, getColour, TabCustomisations } from './customisations'
import { Customisations } from './DevTab.customisations'
import { DisabledState, ReadOnlyState } from '../../../../../themes/Developer/styles/state'
import type { TabContext } from '../../Tab.composition'
import type { TabCoreStyle } from '../../Tab'
import type { StyleProps } from '../../../../../types'

export type TabStyleProps = StyleProps<TabCoreStyle, TabCustomisations> & ThemeProps

const FullWidthRoot = css`
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 'none';
`

const Icon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<TabStyleProps>`
  margin-bottom: ${(props) => props.theme.spaces.xs};
`

const Label = styled.span<TabStyleProps>`
  color: inherit;
`

const Root = styled.button<TabStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  padding: ${(props) => props.theme.spaces.xs};
  background-color: ${(props) => getBgColour(props)};
  color: ${(props) => getColour(props)};

  ${(props) => props.styleProps.disabled && DisabledState};
  ${(props) => props.styleProps.readOnly && ReadOnlyState};
  ${(props) => props.styleProps.fullWidth && FullWidthRoot};
`

const Components: TabContext = {
  Root,
  Label,
  Icon,
}

export default Components
