// utils
import * as helpers from '../../../Common/utils'
import type {
  MarginType,
  PaddingType,
  Spacing,
  ICustomisations,
  Margin,
  Padding,
} from '../../../Common'
import { ThemeProps } from '../../themed-component'

/** Padding  */
export const getPadding = (
  props: ICustomisations<Padding, ThemeProps>,
  defaultValue: Spacing = 0,
  type: PaddingType = 'padding',
) => helpers.getPadding(props.customisations, defaultValue, type)

/** Margin  */
export const getMargin = (
  props: ICustomisations<Margin, ThemeProps>,
  defaultValue: Spacing = 0,
  type: MarginType = 'margin',
) => helpers.getMargin(props.customisations, defaultValue, type)
