import * as theme from '../../../../themes/Developer/proxima-nova'
import type { SelectStyleProps } from '../DevSelect.styles'
import type { DeveloperTheme } from '../../../../themes/Developer/theme'
import type { State } from '../../../../themes/Common'

/** Global */
/** Border colour */
export const defaultBorderColour = ({ colours }: DeveloperTheme) => ({
  colour: colours.tertiary.neutral.grey,
  hoverColour: colours.tints.sun[100],
  activeColour: colours.tints.sun[50],
  focusColour: colours.tints.sun[100],
  invalidColour: colours.tertiary.semantic.red,
  selectedColour: colours.tertiary.neutral.grey,
})

/** Arrow */
/** Colour */
export const defaultArrowColour = ({ colours }: DeveloperTheme) => ({
  colour: colours.tints.charcoal[100],
  hoverColour: colours.tints.charcoal[100],
  focusColour: colours.tints.charcoal[100],
  invalidColour: colours.tertiary.semantic.red,
  selectedColour: colours.tints.charcoal[100],
})

export const getArrowColour = (props: SelectStyleProps) => {
  const currentState = theme.getState(props.styleProps)
  const borderColour = theme.getBorderColour(props, defaultArrowColour(props.theme), currentState)

  const customisations = theme.getCustomisations(props, 'Selection.Arrow')
  if (!customisations.customisations.colour) {
    // We take the global border colour if we don't have one provided by the arrow customisations
    customisations.customisations.colour = borderColour
  }

  return theme.getColour(customisations, defaultArrowColour(props.theme), currentState)
}

/** Background colour */
export const getArrowBgColour = (props: SelectStyleProps) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'Selection.Arrow'),
    'transparent',
    theme.getState(props.styleProps),
  )

/** Selection */
/** Colour  */
export const getSelectionColour = (props: SelectStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Selection'),
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )

/** Background colour */
export const getSelectionBgColour = (props: SelectStyleProps) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'Selection'),
    props.theme.colours.tertiary.neutral.white,
    theme.getState(props.styleProps),
  )

/** Option */
/** Colour  */
export const getOptionColour = (props: SelectStyleProps) => {
  return theme.getColour(
    theme.getCustomisations(props, 'Option'),
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )
}

/** Background colour */
const optionDefaultBgColour = (props: SelectStyleProps) => ({
  bgColour: props.theme.colours.tertiary.neutral.white,
  hoverBgColour: props.theme.colours.tints.sun[30],
  focusBgColour: props.theme.colours.tints.sun[30],
  selectedBgColour: props.theme.colours.tints.sun[10],
})

export const getOptionBgColour = (props: SelectStyleProps, state?: State) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'Option'),
    optionDefaultBgColour(props),
    state || theme.getState(props.styleProps.option || {}),
  )
