import TableFooter, { TableFooterPublicProps } from '../../TableFooter'
import Theme from '../../../../../themes/Developer/theme'
import type { TableFooterContext } from '../../TableFooter.composition'
import { withStyle } from '../../../../../providers'
import Styles from './DevTableFooter.styles'

export const DevTableFooter = withStyle<TableFooterPublicProps, TableFooterContext>(
  TableFooter,
  Theme,
  Styles,
  'TableFooter',
)

export type DevTableFooterProps = TableFooterPublicProps
