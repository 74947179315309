import * as theme from '../../../../themes/Developer/proxima-nova'
import type { FormValidityTextStyleProps } from '../DevFormValidityText.styles'

/** Colour */
const defaultColour = (props: FormValidityTextStyleProps) => ({
  colour: props.theme.colours.tertiary.semantic.green,
  invalidColour: props.theme.colours.tertiary.semantic.red,
})

export const getColour = (props: FormValidityTextStyleProps) =>
  theme.getColour(props, defaultColour(props), theme.getState(props.styleProps))
