import * as React from 'react'
import icons from './PaginationItem.utils'
import type { PaginationItemContext } from './PaginationItem.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { AriaAttributesPrefix, DataAttributesPrefix } from '../../../constants'
import { attachIf } from '../../../utils/event-handler'
import { getTestId } from '../../../utils/test'

export interface PaginationItemPublicProps {
  /**
   * The current page number.
   */
  page: number
  /**
   * If `true`, the item will be readOnly.
   * @default false
   */
  readOnly?: boolean
  /**
   * If `true`, the item will be disabled.
   * @default false
   */
  disabled?: boolean
  /**
   * If `true` the pagination item is selected.
   * @default false
   */
  selected?: boolean
  /**
   * The shape of the pagination item.
   * @default 'circular'
   */
  shape?: 'circular' | 'rounded'
  /**
   * The size of the pagination item.
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * The type of pagination item.
   * @default 'page'
   */
  type?: 'end-ellipsis' | 'first' | 'last' | 'next' | 'page' | 'previous' | 'start-ellipsis'
  /**
   * The pagination item variant.
   * @default 'text'
   */
  variant?: 'text' | 'outlined'
  /**
   * Handler triggered when the use clicks on the item
   */
  onClick: (event: React.MouseEvent) => void
}

export type PaginationItemProps = PaginationItemPublicProps & StyledProps<PaginationItemContext>

export interface PaginationItemCoreStyle {
  size: 'small' | 'medium' | 'large'
  variant: 'text' | 'outlined'
  shape: 'circular' | 'rounded'
  selected: boolean
  readOnly: boolean
  disabled: boolean
  hovered: boolean
  focused: boolean
  active: boolean
}

export const PaginationItem = React.forwardRef(
  (props: PaginationItemProps, forwardRef: React.Ref<HTMLButtonElement>) => {
    const { ref } = useResponsive<HTMLButtonElement>({ ref: forwardRef })

    const isActionable = !props.readOnly && !props.disabled
    const tabIndex = isActionable ? -1 : 0

    const [hovered, setHovered] = React.useState(false)
    const [focused, setFocused] = React.useState(false)
    const [active, setActive] = React.useState(false)

    const isEllipsis = () => props.type === 'start-ellipsis' || props.type === 'end-ellipsis'

    const getCoreStyle = (): PaginationItemCoreStyle => ({
      size: props.size!,
      variant: props.variant!,
      shape: props.shape!,
      selected: props.selected!,
      readOnly: props.readOnly,
      disabled: props.disabled!,
      hovered,
      focused,
      active,
    })

    const handleFocus = (event: React.FocusEvent) => {
      setFocused(true)
    }

    const handleBlur = (event: React.FocusEvent) => {
      setFocused(false)
    }

    const handleHover = (event: React.MouseEvent) => {
      setHovered(true)
    }

    const handleLeave = (event: React.MouseEvent) => {
      setHovered(false)
    }

    const renderIcon = () => {
      const icon = icons[props.type]

      const { Icon } = props.styled!
      return icon ? (
        <Icon
          src={icon}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'icon')}
        />
      ) : null
    }

    const renderEllipsis = () => {
      const { Ellipsis } = props.styled!
      return isEllipsis() ? (
        <Ellipsis
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'ellipsis')}
        >
          ...
        </Ellipsis>
      ) : null
    }

    const renderPage = () => {
      const { Page } = props.styled
      return !isEllipsis() ? (
        <Page
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'page')}
        >
          {props.type === 'page' && props.page}
          {renderIcon()}
        </Page>
      ) : null
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        tabIndex={tabIndex}
        onClick={attachIf(props.onClick, isActionable)}
        onFocus={attachIf(handleFocus, isActionable)}
        onBlur={attachIf(handleBlur, isActionable)}
        onMouseEnter={attachIf(handleHover, isActionable)}
        onMouseLeave={attachIf(handleLeave, isActionable)}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
        {...getAttributes(props, AriaAttributesPrefix)}
      >
        {renderEllipsis()}
        {renderPage()}
      </Root>
    )
  },
)

PaginationItem.defaultProps = {
  shape: 'rounded',
  size: 'medium',
  type: 'page',
  variant: 'text',
}

export default PaginationItem
