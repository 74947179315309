import type { Opacity, IVisuals } from './models'

const opacity: Opacity = {
  disable: 0.3,
  light: 0.1,
  normal: 0.5,
  dark: 0.8,
}

export const visuals: IVisuals = {
  borderRadius: '5px',
  opacity,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
  borderSize: '1px',
  letterSpace: '1.3px',
  lineHeight: 1.6,
}
