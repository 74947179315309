import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { attachIf } from '../../../utils/event-handler'
import { getTestId } from '../../../utils/test'
import type { StyledProps } from '../../../providers'
import type { TabContext } from './Tab.composition'

export interface TabPublicProps {
  /**
   * Label of the tab to display.
   */
  label?: string
  /**
   * You can provide your own value. Otherwise, we fallback to the child position index.
   */
  value?: any
  /**
   * Icon to display within the tab
   */
  icon?: any
  /**
   * If `true`, the `tab` element will be selected.
   */
  selected?: boolean
  /**
   * If `true`, the `tab` element will be disabled.
   */
  disabled?: boolean
  /**
   * It prevents the user from the tab
   */
  readOnly?: boolean
  /**
   * -1 if the tab is not keyboard accessible, index in the sequential keyboard navigation otherwise
   */
  tabIndex?: number
  /**
   * True if the tabs grow to use all the available space, false otherwise
   */
  fullWidth?: boolean
  /**
   * Handler when the user clicks on the tab
   */
  onClick?: (event: React.SyntheticEvent<HTMLElement>) => void
  /**
   * Handler when the user clicks on the tab
   */
  onChange?: (event: React.SyntheticEvent<HTMLElement>, value?: any) => void
  /**
   * Handler when the tab loses the focus. The new value is passed as parameter.
   */
  onBlur?: (event: React.FocusEvent) => void
  /**
   * Handler when the tab gets the focus.
   */
  onFocus?: (event: React.FocusEvent) => void
  /**
   * Handler when the tab is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the tab is not hovered
   */
  onLeave?: (event?: React.MouseEvent) => void
  /**
   * Component displayed as tab content
   */
  children?: React.ReactNode
}

export interface TabProps extends TabPublicProps, StyledProps<TabContext> {}

export interface TabCoreStyle {
  disabled?: boolean
  readOnly?: boolean
  selected?: boolean
  hovered: boolean
  focused: boolean
  fullWidth: boolean
}

const Tab: React.FC<TabProps> = React.forwardRef(
  (props: TabProps, forwardRef: React.Ref<HTMLButtonElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const [focused, setFocused] = React.useState(false)
    const [hovered, setHovered] = React.useState(false)

    const isActionable = !props.readOnly && !props.disabled

    const getCoreStyle = (): TabCoreStyle => ({
      disabled: props.disabled,
      readOnly: props.readOnly,
      selected: props.selected,
      fullWidth: props.fullWidth,
      hovered,
      focused,
    })

    const handleClick = (event: React.SyntheticEvent<HTMLElement>) => {
      if (!props.selected && props.onChange) {
        props.onChange(event, props.value)
      }

      if (props.onClick) {
        props.onClick(event)
      }
    }

    const handleFocus = (event: React.FocusEvent) => {
      if (props.onFocus) {
        props.onFocus(event)
      }

      setFocused(true)
    }

    const handleBlur = (event: React.FocusEvent) => {
      if (props.onBlur) {
        props.onBlur(event)
      }

      setFocused(false)
    }

    const handleHover = (event: React.MouseEvent) => {
      if (props.onHover) {
        props.onHover(event)
      }

      setHovered(true)
    }

    const handleLeave = (event?: React.MouseEvent) => {
      if (props.onLeave) {
        props.onLeave(event)
      }

      setHovered(false)
    }

    const renderIcon = () => {
      const { Icon } = props.styled!
      return props.icon ? (
        <Icon
          src={props.icon}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'icon')}
        />
      ) : null
    }

    const renderLabel = () => {
      if (props.children) {
        return props.children
      }

      const { Label } = props.styled!
      return (
        <Label
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label')}
        >
          {props.label}
        </Label>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        role="tab"
        tabIndex={props.selected ? 0 : -1}
        onClick={attachIf(handleClick, isActionable)}
        onTouchEnd={attachIf(handleClick, isActionable)}
        onFocus={attachIf(handleFocus, isActionable)}
        onBlur={attachIf(handleBlur, isActionable)}
        onMouseEnter={attachIf(handleHover, isActionable)}
        onMouseLeave={attachIf(handleLeave, isActionable)}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderIcon()}
        {renderLabel()}
      </Root>
    )
  },
)

Tab.defaultProps = {
  tabIndex: -1,
}

export default Tab
