import styled from 'styled-components'

const Preview: any = styled.div`
  display: flex;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 4px;
  flex-direction: ${(props: any) => (props.orientation === 'horizontal' ? 'row' : 'column')};
  height: ${(props: any) => (props.type === 'page' ? '100vh' : 'auto')};
  width: ${(props: any) => (props.type === 'page' ? '100vw' : 'auto')};

  &:not(.tl-preview--page) {
    border: 1px solid #ced4de;
  }
`

export default Preview
