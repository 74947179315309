import { get, isEmpty } from '../../../../utils/lodash'

/**
 * Return the requested customisations.
 * @param props object containing the customisations
 * @param name  name of the requested customisation
 */
export const getCustomisations = (props: any, name: string) => {
  const values = get(props.customisations, name) || {}
  return { customisations: values, theme: props.theme }
}

export const hasCustomisations = (props: any, name: string) => {
  const value = get(props.customisations, name) || {}
  return !isEmpty(value)
}
