import Collapse, { CollapsePublicProps } from '../../Collapse'
import Theme from '../../../themes/Developer/theme'
import Styles from './Collapse.styles'
import { withStyle } from '../../../providers'
import type { CollapseContext } from '../../Collapse.composition'

export const DevCollapse = withStyle<CollapsePublicProps, CollapseContext>(
  Collapse,
  Theme,
  Styles,
  'Collapse',
)

export type DevCollapseProps = CollapsePublicProps
