import FormValidityText, { FormValidityTextPublicProps } from '../../FormValidityText'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevFormValidityText.styles'
import type { FormValidityTextContext } from '../../FormValidityText.composition'
import Customisations from './DevFormValidityText.customisations'
import type { FormValidityTextCustomisations } from './customisations'

export const DevFormValidityText = withCustomisedStyle<
  FormValidityTextPublicProps,
  FormValidityTextContext,
  FormValidityTextCustomisations
>(FormValidityText, Theme, Styles, Customisations, 'FormValidityText')

export type DevFormValidityTextCustomisations = FormValidityTextCustomisations
export type DevFormValidityTextProps = FormValidityTextPublicProps & FormValidityTextCustomisations
