export const setBorderOnFirstChild = (width: string, radius: string, alone?: boolean) => `
    border-radius: ${radius};
    border-width: ${width};
    border-right-width: ${alone ? width : '0px'};
`

export const setBorderOnIntermediaryChild = (width: string, radius: string) => `
    border-radius: ${radius};
    border-width: ${width};
    border-left-width: 0px;
    border-right-width: 0px;
`

export const setBorderOnLastChild = (width: string, radius: string) => `
    border-radius: ${radius};
    border-width: ${width};
    border-left-width: 0px;
`

export const setPlaceholder = (style: any) => `
    ::-webkit-input-placeholder {
        ${style};
    }

    ::-moz-placeholder {
        ${style};
    }

    :-ms-input-placeholder {
        ${style};
    }

    :-moz-placeholder {
        ${style};
    }

    ::placeholder {
        ${style};
    }
`

export const setFocus = (style: any, children: any[]) => `
    ${children.map(
      (child: any) => `
            :focus {
                & > ${child} {
                    ${style};
                }
            }
        `,
    )}
`

export const setHover = (style: any, children: any[]) => `
    ${children.map(
      (child: any) => `
            :hover {
                & > ${child} {
                    ${style};
                }
            }
        `,
    )}
`

export const setActive = (style: any, children: any[]) => `
    ${children.map(
      (child: any) => `
            :active {
                & > ${child} {
                    ${style};
                }
            }
        `,
    )}
`

export const noSelection = `
    * {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }
`

export const autoOverflowY = `
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    ::-webkit-scrollbar { 
        display: none; 
    }

    scrollbar-width: none;
`
