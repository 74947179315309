import styled from '../../../themes/Developer/themed-component'
import type { GridContainerProps, GridItemProps } from '../../../Grid/Grid'
import type { SwitchGroupContext } from '../../SwitchGroup.composition'
import { Grid } from '../../../Grid'

const Item = styled(Grid)<GridItemProps>``

const Root = styled(Grid)<GridContainerProps>``

const Components: SwitchGroupContext = {
  Root,
  Item,
}

export default Components
