import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../Icon'
import type { StarsCoreStyle } from '../../Stars'
import type { StarsContext } from '../../Stars.composition'
import type { StyleProps } from '../../../types'
import { getLabelColour, getLabelFontSize, StarsCustomisations } from './customisations'
import { Customisations } from './DevStars.customisations'
import * as themeStyles from '../../../themes/Developer/styles'

export type StarsStyleProps = StyleProps<StarsCoreStyle, StarsCustomisations> & ThemeProps

const Icon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<StarsStyleProps>``

const StarContainer = styled.div<StarsStyleProps>`
  display: flex;

  & > ${Icon}:not(:last-child) {
    margin-right: ${(props) => props.theme.spaces.xs};
  }
`

const Label = styled.label<StarsStyleProps>`
  color: ${(props) => getLabelColour(props)};
  font-size: ${(props) => getLabelFontSize(props)};
  margin-top: ${(props) => props.theme.spaces.xs};
`

const Root = styled.div<StarsStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: StarsContext = {
  Root,
  Label,
  Icon,
  StarContainer,
}

export default Components
