import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import type { LogoCoreStyle } from '../../Logo'
import type { LogoContext } from '../../Logo.composition'
import type { StyleProps } from '../../../types'

export type LogoStyleProps = StyleProps<LogoCoreStyle> & ThemeProps

const ClickableRoot = css<LogoStyleProps>`
  cursor: pointer;
`

const Image = styled.img<LogoStyleProps>`
  width: inherit;
  height: inherit;
`

const Root = styled.div<LogoStyleProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: inherit;

  ${(props) => props.styleProps.isClickable && ClickableRoot}
`

const Components: LogoContext = {
  Image,
  Root,
}

export default Components
