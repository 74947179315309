import TableContainer, { TableContainerPublicProps } from '../../TableContainer'
import Theme from '../../../../../themes/Developer/theme'
import type { TableContainerContext } from '../../TableContainer.composition'
import { withStyle } from '../../../../../providers'
import Styles from './DevTableContainer.styles'

export const DevTableContainer = withStyle<TableContainerPublicProps, TableContainerContext>(
  TableContainer,
  Theme,
  Styles,
  'TableContainer',
)

export type DevTableContainerProps = TableContainerPublicProps
