import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { DevField } from '../../../Field'
import { DevRadioGroup } from '../../../RadioGroup'
import { Customisations, RadioFieldCustomisations } from './DevRadioField.customisations'
import type { RadioFieldContext } from '../../RadioField.composition'
import type { StyleProps } from '../../../types'

export type RadioFieldStyleProps = StyleProps<{}, RadioFieldCustomisations> & ThemeProps

const RadioGroup = styled(DevRadioGroup).attrs(Customisations.RadioGroup)<RadioFieldStyleProps>``

const Root = styled(DevField).attrs(Customisations.Field)<RadioFieldStyleProps>``

const Components: RadioFieldContext = {
  Root,
  RadioGroup,
}

export default Components
