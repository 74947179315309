import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import defaultLabelDisplayedRows from './TablePagination.utils'
import { getTestId } from '../../../utils/test'
import { DataAttributesPrefix } from '../../../constants'
import type { StyledProps } from '../../../providers'
import type { TablePaginationContext } from './TablePagination.composition'

export interface TablePaginationPublicProps {
  /**
   * The total number of rows.
   *
   * To enable server side pagination for an unknown number of items, provide -1.
   */
  count: number
  /**
   * The zero-based index of the current page.
   */
  page: number
  /**
   * Customize the displayed rows label. Invoked with a `{ from, to, count, page }`
   * object.
   *
   * @default function defaultLabelDisplayedRows({ from, to, count }) {
   *   return `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`;
   * }
   */
  labelDisplayedRows?: (from: number, to: number, count: number) => string
  /**
   * Customize the rows per page label.
   *
   * @default 'Rows per page:'
   */
  labelRowsPerPage?: string
  /**
   * The number of rows per page.
   */
  rowsPerPage?: number
  /**
   * Customizes the options of the rows per page select field. If less than two options are
   * available, no select field will be displayed.
   * @default [10, 25, 50, 100]
   */
  rowsPerPageOptions?: number[] | { label: string; value: number }[]
  /**
   * If `true`, show the first-page button.
   * @default false
   */
  showFirstButton?: boolean
  /**
   * If `true`, show the last-page button.
   * @default false
   */
  showLastButton?: boolean
  /**
   * If `true`, the actions will be disabled.
   */
  disabled?: boolean
  /**
   * It prevents the user from changing the value of the actions
   */
  readOnly?: boolean
  /**
   * Callback fired when the page is changed.
   *
   * @param {object} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onPageChange?: (event: React.MouseEvent, page: number) => void
  /**
   * Callback fired when the number of rows per page is changed.
   *
   * @param {object} event The event source of the callback.
   */
  onRowsPerPageChange?: (value: string) => void
}

export type TablePaginationProps = TablePaginationPublicProps & StyledProps<TablePaginationContext>

const TablePagination = React.forwardRef(
  (props: TablePaginationProps, forwardRef: React.Ref<HTMLTableCellElement>) => {
    const { ref } = useResponsive<HTMLTableCellElement>({ ref: forwardRef })

    const getCoreStyle = (): unknown => ({})

    const renderLabelRowsPerPage = () => {
      const { LabelRowsPerPage } = props.styled!
      return (
        <LabelRowsPerPage
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label-rows-per-page')}
        >
          {props.labelRowsPerPage}
        </LabelRowsPerPage>
      )
    }

    const renderPageSelection = () => {
      const { PageSelection } = props.styled!
      return props.rowsPerPageOptions.length > 1 ? (
        <PageSelection
          id="page-selection"
          size="small"
          direction="rtl"
          disabled={props.disabled}
          readOnly={props.readOnly}
          options={props.rowsPerPageOptions!}
          value={props.rowsPerPage}
          onChange={props.onRowsPerPageChange}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'page-selection')}
        />
      ) : null
    }

    const renderLabelDisplayedRows = () => {
      const from = props.count === 0 ? 0 : props.page * props.rowsPerPage + 1
      const to =
        props.count !== -1
          ? Math.min(props.count, (props.page + 1) * props.rowsPerPage)
          : (props.page + 1) * props.rowsPerPage
      const count = props.count === -1 ? -1 : props.count

      const { LabelDisplayedRows } = props.styled!
      return (
        <LabelDisplayedRows
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label-display-rows')}
        >
          {props.labelDisplayedRows(from, to, count)}
        </LabelDisplayedRows>
      )
    }

    const renderActions = () => {
      const { Actions } = props.styled!
      return (
        <Actions
          count={props.count}
          page={props.page}
          disabled={props.disabled}
          readOnly={props.readOnly}
          rowsPerPage={props.rowsPerPage}
          showFirstButton={props.showFirstButton}
          showLastButton={props.showLastButton}
          onPageChange={props.onPageChange}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'actions')}
        />
      )
    }

    const renderSpacer = () => {
      const { Spacer } = props.styled!
      return (
        <Spacer
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'spacer')}
        />
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderSpacer()}
        {renderLabelRowsPerPage()}
        {renderPageSelection()}
        {renderLabelDisplayedRows()}
        {renderActions()}
      </Root>
    )
  },
)

TablePagination.defaultProps = {
  labelDisplayedRows: defaultLabelDisplayedRows,
  labelRowsPerPage: 'Rows per page:',
  rowsPerPageOptions: [10, 25, 50, 100],
  rowsPerPage: 10,
  showFirstButton: false,
  showLastButton: false,
}

export default TablePagination
