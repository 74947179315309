import type { IconStyleProps } from '../DevIcon.styles'
import * as theme from '../../../../themes/Developer/proxima-nova'

/* Border */
export const getBorder = (props: IconStyleProps) => {
  return theme.getBorder(props, 'none', 'border')
}

export const getBorderRadius = (props: IconStyleProps, defaultValue?: string | number) =>
  theme.getBorderRadius(props, theme.getValueAsPixel(defaultValue) || 0)
