import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../Icon'
import {
  getIndicatorBgColour,
  getMoreButtonBgColour,
  getMoreButtonColour,
  getScrollButtonBgColour,
  getTabColour,
  TabsCustomisations,
} from './customisations'
import { Customisations } from './DevTabs.customisations'
import type { TabsCoreStyle } from '../../Tabs'
import type { TabsContext } from '../../Tabs.composition'
import type { StyleProps } from '../../../types'

export type TabsStyleProps = StyleProps<TabsCoreStyle, TabsCustomisations> & ThemeProps

const isHorizontalOrientation = (props: TabsStyleProps) =>
  props.styleProps.orientation === 'horizontal'

const isFullWidthVariant = (props: TabsStyleProps) => props.styleProps.fullWidth

const isScrollableVariant = (props: TabsStyleProps) => props.styleProps.variant === 'scrollable'

const HorizontalExtraTabList = css<TabsStyleProps>`
  right: 0;
  min-width: 100px;
  max-width: 200px;
  top: ${(props) => `calc(100% + ${props.theme.visuals.borderSize})`};

  & > * {
    border: ${(props) => props.theme.visuals.borderSize} solid
      ${(props) => getTabColour(props)}!important;
    border-top: none !important;
    width: 100%;
  }
`

const VerticalExtraTabList = css<TabsStyleProps>`
  right: ${(props) => `calc(-100% - calc(${props.theme.visuals.borderSize} * 2))`};
  bottom: 0;

  & > * {
    border: ${(props) => props.theme.visuals.borderSize} solid
      ${(props) => getTabColour(props)}!important;
    border-left: none !important;
    width: 100%;
  }

  & > *:not(:first-child) {
    border-top: none !important;
  }
`

const HorizontalMoreButton = css<TabsStyleProps>`
  width: 50px;
  max-width: 50px;
`

const VerticalMoreButton = css<TabsStyleProps>`
  width: 100%;
  padding: ${(props) => `${props.theme.spaces.xs} ${props.theme.spaces.sm}`};
`

const HorizontalIndicator = css<TabsStyleProps>`
  height: 2px;
  width: 100%;
  bottom: 0;
`

const VerticalIndicator = css<TabsStyleProps>`
  width: 2px;
  height: 100%;
  right: 0;
`

const ScrollButton = css<TabsStyleProps>`
  background-color: ${(props) => getScrollButtonBgColour(props)};

  ${(props) =>
    !props.styleProps.isVisible &&
    css`
      opacity: 0;
    `}
`

const HorizontalScrollButton = css<TabsStyleProps>`
  ${ScrollButton};
  height: unset;
  padding: 0px ${(props) => props.theme.spaces.sm};
`

const VerticalScrollButton = css<TabsStyleProps>`
  ${ScrollButton};
  width: 100%;
  padding: ${(props) => props.theme.spaces.sm} 0px;
`

const HideScrollbar = css<TabsStyleProps>`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const FullWidthVariant = css<TabsStyleProps>`
  & > * {
    flex-grow: 1;
    width: 100%;
  }
`

const HorizontalRoot = css<TabsStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: ${(props) => props.theme.visuals.borderSize} solid
    ${(props) => props.theme.colours.tints.charcoal[10]};
`

const VerticalRoot = css<TabsStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  border-right: ${(props) => props.theme.visuals.borderSize} solid
    ${(props) => props.theme.colours.tints.charcoal[10]};
`

const ScrollButtonStart = styled(DevFontAwesomeIcon).attrs(
  Customisations.ScrollButton,
)<TabsStyleProps>`
  ${(props) => isHorizontalOrientation(props) && HorizontalScrollButton};
  ${(props) => !isHorizontalOrientation(props) && VerticalScrollButton};
`

const ScrollButtonEnd = styled(DevFontAwesomeIcon).attrs(
  Customisations.ScrollButton,
)<TabsStyleProps>`
  ${(props) => isHorizontalOrientation(props) && HorizontalScrollButton};
  ${(props) => !isHorizontalOrientation(props) && VerticalScrollButton};
`

const MoreButton = styled.button<TabsStyleProps>`
  height: 100%;
  border: none;
  border-radius: 0;
  outline: 0;
  cursor: pointer;
  color: ${(props) => getMoreButtonColour(props)};
  background-color: ${(props) => getMoreButtonBgColour(props)};

  ${(props) => isHorizontalOrientation(props) && HorizontalMoreButton};
  ${(props) => !isHorizontalOrientation(props) && VerticalMoreButton};

  ${(props) =>
    !props.styleProps.isVisible &&
    css`
      opacity: 0;
    `}
`

const MoreSection = styled.div<TabsStyleProps>`
  position: relative;
`

const ExtraTabList = styled.div<TabsStyleProps>`
  position: absolute;
  z-index: 100;
  margin: 0;
  padding: 0;
  background-color: ${(props) => props.theme.colours.tertiary.neutral.white};

  ${(props) => isHorizontalOrientation(props) && HorizontalExtraTabList};
  ${(props) => !isHorizontalOrientation(props) && VerticalExtraTabList};
`

const TabIndicator = styled.span<TabsStyleProps>`
  position: absolute;
  background-color: ${(props) => getIndicatorBgColour(props)};

  ${(props) => isHorizontalOrientation(props) && HorizontalIndicator};
  ${(props) => !isHorizontalOrientation(props) && VerticalIndicator};
`

const TabList = styled.div<TabsStyleProps>`
  display: flex;
  flex-direction: ${(props) => (isHorizontalOrientation(props) ? 'row' : 'column')};

  ${(props) => isHorizontalOrientation(props) && isFullWidthVariant(props) && FullWidthVariant};

  ${(props) => `*:nth-child(n+${props.styleProps.numberOfTabToDisplay + 1}) { 
        visibility: hidden;
    }`}
`

const Scrollable = styled.div<TabsStyleProps>`
  position: relative;
  white-space: nowrap;
  ${HideScrollbar};

  ${(props) =>
    isScrollableVariant(props) &&
    css`
      overflow-x: scroll;
      flex: 1 1 auto;
      display: inline-block;
      height: 100%;
    `}

  ${(props) =>
    !isScrollableVariant(props) &&
    css`
      overflow: hidden;
      flex-grow: 1;
    `}
`

const Root = styled.div<TabsStyleProps>`
  position: relative;
  background-color: ${(props) => props.theme.colours.tertiary.neutral.white};
  white-space: nowrap;

  ${(props) => isHorizontalOrientation(props) && HorizontalRoot};
  ${(props) => !isHorizontalOrientation(props) && VerticalRoot};
`

const Components: TabsContext = {
  Root,
  Scrollable,
  TabList,
  TabIndicator,
  ScrollButtonStart,
  ScrollButtonEnd,
  MoreSection,
  MoreButton,
  ExtraTabList,
}

export default Components
