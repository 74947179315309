import { FontVariants } from '../../Common'

import { FontSize, LineHeight, LetterSpacing } from './models'

export const fontSize: FontSize = {
  codeM: { rem: 0.75, px: 12 },
  codeS: { rem: 0.625, px: 10 },
}

export const fontFamily = `Consolas, monaco, monospace`

export const fontWeight: FontVariants = {
  heading: 700,
  bold: 700,
  regular: 400,
  thin: 300,
}

export const lineHeight: LineHeight = {
  codeM: '14px',
  codeS: '11px',
}

export const letterSpacing: LetterSpacing = '0.4px'

export default {
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
}

export interface ITypography {
  fontFamily?: string
  fontSize?: FontSize
  lineHeight?: LineHeight
  letterSpacing?: LetterSpacing
  fontWeight?: FontVariants
}
