import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { MenuGroupStyleProps } from '../DevMenuGroup.styles'

/** Global */
/** Border */
export const getBorder = (props: MenuGroupStyleProps, type?: theme.BorderType) => {
  return theme.getBorder(props, 'none', type)
}

/** Header */
/** Border */
export const getHeaderBorder = (props: MenuGroupStyleProps, type?: theme.BorderType) => {
  const customisations = theme.getCustomisations(props, 'Header')
  return theme.getBorder(customisations, 'none', type, theme.getState(props.styleProps))
}

/** Content */
/** Border */
export const getContentBorder = (props: MenuGroupStyleProps, type?: theme.BorderType) => {
  const customisations = theme.getCustomisations(props, 'Content')
  return theme.getBorder(customisations, 'none', type, theme.getState(props.styleProps))
}
