import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from "styled-components";
import { DevTheme } from "@tl-prisma/core";
import theme from './theme';
import { Home } from './Home';
import { SingleImmediatePayments } from './SingleImmediatePayments';
import MockBankLogin from "./MockBankLogin";
import {MockBankMandatesLogin} from "./MockBankLogin";
import MockBankConfirmation from "./MockBankConfirmation";
import { MockBankMandatesConfirmation } from "./MockBankConfirmation";
import MockBankError from "./MockBankError";
import MockBankDecoupled from "./MockBankDecoupled";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={DevTheme}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            <Route path="/single-immediate-payments/:id" component={SingleImmediatePayments} />
            <Route path="/mandates/login/:id" component={MockBankMandatesLogin} />
            <Route path="/login/:id" component={MockBankLogin} />
            <Route path="/confirm/:id" component={MockBankConfirmation} />
            <Route path="/mandates/confirm/:id" component={MockBankMandatesConfirmation} />
            <Route path="/decoupled/:id" component={MockBankDecoupled} />
            <Route path="/error" component={MockBankError} />
            <Route path="*" component={Home} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default App;
