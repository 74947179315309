import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'
import type { StyledProps } from '../../../providers'
import type { ListItemTextContext } from './ListItemText.composition'

export interface ListItemTextPublicProps {
  /**
   * The main content element.
   */
  primary: string | React.ReactNode
  /**
   * The secondary content element.
   */
  secondary?: string | React.ReactNode
}

type ListItemTextProps = ListItemTextPublicProps & StyledProps<ListItemTextContext>

const ListItemText = React.forwardRef(
  (props: ListItemTextProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = () => ({})

    const renderPrimary = () => {
      if (typeof props.primary !== 'string') {
        return props.primary
      }

      const { Primary } = props.styled!
      return (
        <Primary
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'primary')}
        >
          {props.primary}
        </Primary>
      )
    }

    const renderSecondary = () => {
      if (!props.secondary) {
        return null
      }

      if (typeof props.secondary !== 'string') {
        return props.secondary
      }

      const { Secondary } = props.styled!
      return (
        <Secondary
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'secondary')}
        >
          {props.secondary}
        </Secondary>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderPrimary()}
        {renderSecondary()}
      </Root>
    )
  },
)

export default ListItemText
