import * as React from 'react'
import * as PropTypes from 'prop-types'
import { faSolidStar } from '../utils/fontawesome'
import type { StyledProps } from '../providers'
import type { StarsContext } from './Stars.composition'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'
import { times } from '../utils/lodash'

type Size = 'sm' | 'md' | 'lg'

export interface StarsPublicProps {
  /**
   * Total number of stars.
   */
  total: number
  /**
   * Number of stars selected.
   */
  selected: number
  /**
   * Text below the stars
   */
  label?: string
  /**
   * Size of the stars
   */
  size?: Size
}

export interface StarsProps extends StarsPublicProps, StyledProps<StarsContext> {}

export interface StarsCoreStyle {
  size: string | number
  selected: number
}

const Stars: React.FC<StarsProps> = React.forwardRef(
  (props: StarsProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (extra?: any): StarsCoreStyle => ({
      size: props.size,
      ...extra,
    })

    const renderStar = (index: number) => {
      const styleProps = {
        selected: index < props.selected,
      }

      const { Icon } = props.styled!
      return (
        <Icon
          key={`start-${index + 1}`}
          src={faSolidStar}
          size={props.size}
          styleProps={getCoreStyle(styleProps)}
          customisations={props.customisations}
          {...getTestId(props, `star-${index + 1}`)}
        />
      )
    }

    const renderStars = () => {
      const { StarContainer } = props.styled!
      return (
        <StarContainer
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'container')}
        >
          {times(props.total, renderStar)}
        </StarContainer>
      )
    }

    const renderLabel = () => {
      const { Label } = props.styled!
      return props.label ? (
        <Label
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label')}
        >
          {props.label}
        </Label>
      ) : null
    }

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderStars()}
        {renderLabel()}
      </Root>
    )
  },
)

Stars.defaultProps = {
  size: 'lg',
}

Stars.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.number,
  size: PropTypes.oneOf<Size>(['sm', 'md', 'lg']),
}

export default Stars
