import styled, { ThemeProps } from '../../../../../../../themes/Developer/themed-component'
import { getColour, getPadding, ListItemStaticCustomisations } from './customisations'
import type { StyleProps } from '../../../../../../../types'
import type { ListItemContext } from '../../../../ListItem.composition'
import type { ListItemStaticCoreStyle } from '../../ListStaticItem'
import * as themeStyles from '../../../../../../../themes/Developer/styles'

export type ListItemStaticStyleProps = StyleProps<
  ListItemStaticCoreStyle,
  ListItemStaticCustomisations
> &
  ThemeProps

const Root = styled.li<ListItemStaticStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;

  /** Color */
  color: ${(props) => getColour(props)};
  background-color: inherit;

  /** Visual */
  line-height: ${(props) => props.theme.typography.proximaNova.lineHeight.bodyXs};

  /** Spacing */
  padding: ${(props) => getPadding(props, props.styleProps.level)};

  // override the top and bottom padding from getPadding()
  padding-top: ${(props) => props.theme.spaces.sm};
  padding-bottom: ${(props) => props.theme.spaces.sm};

  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: ListItemContext = {
  Root,
}

export default Components
