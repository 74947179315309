import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { getColour, getSize, getSpinnerColour, LoaderCustomisations } from './customisations'
import type { LoaderCoreStyle } from '../../Loader'
import type { LoaderContext } from '../../Loader.composition'
import type { StyleProps } from '../../../types'

export type LoaderStyleProps = StyleProps<LoaderCoreStyle, LoaderCustomisations> & ThemeProps

const Content = styled.div`
  margin-top: ${(props) => props.theme.spaces.xxs};

  & > *:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 40%;
    width: auto;
    font-size: ${(props) => props.theme.typography.proximaNova.fontSize.bodyXs.px};
  }
`

const SpinnerAnimation = styled.circle<LoaderStyleProps>`
  stroke: ${(props) => getSpinnerColour(props)};
`

const SpinnerBackground = styled.circle<LoaderStyleProps>`
  stroke: ${(props) => getColour(props)};
`

const Spinner = styled.svg<LoaderStyleProps>`
  display: block;
  margin: 0 auto;
  position: relative;
  width: ${(props) => getSize(props)}px;
  height: ${(props) => getSize(props)}px;
`

const Loader = styled.div<LoaderStyleProps>`
  position: relative;
`

const Root = styled.div<LoaderStyleProps>`
  text-align: center;
`

const Components: LoaderContext = {
  Root,
  Loader,
  Spinner,
  SpinnerBackground,
  SpinnerAnimation,
  Content,
}

export default Components
