import * as React from 'react'

import useResponsive from '../../../hooks/useResponsive'
import { StyledProps } from '../../../providers'
import { ListItemAvatarContext } from './ListItemAvatar.composition'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'

export interface ListItemAvatarPublicProps {
  children: React.ReactNode
}

type ListItemAvatarProps = ListItemAvatarPublicProps & StyledProps<ListItemAvatarContext>

const ListItemAvatar = React.forwardRef(
  (props: ListItemAvatarProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })
    const { children } = props

    const getCoreStyle = () => ({})

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {children}
      </Root>
    )
  },
)

export default ListItemAvatar
