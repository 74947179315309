import * as React from 'react'
import type { RadioGroupContext } from './RadioGroup.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import { getBreakpointValue } from '../devices'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

export interface RadioBreakpoint {
  [key: string]: number
}

export interface RadioGroupPublicProps {
  /**
   * Id of the group of Radio
   */
  id: string
  /**
   * Name of the group of Radio
   */
  name?: string
  /**
   * Current value of the group of Radio
   */
  value?: string
  /**
   * Space between the Radio
   */
  spacing?: number
  /**
   * True if the group of Radio is disabled, false otherwise
   */
  disabled?: boolean
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean
  /**
   * If `true`, the label will be displayed in an error state.
   */
  invalid?: boolean
  /**
   * Size of the input.
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Orientation of the checkbox inside the group
   */
  orientation?: 'horizontal' | 'vertical'
  /**
   * List of breakpoint with the number of columns the group has to display per line
   */
  breakpoints?: RadioBreakpoint[]
  /**
   * Handler triggered when the current value of the group of Radio changed
   */
  onChange?: (value: string) => void
  /**
   * List of radio components to display as a group
   */
  children?: React.ReactNode
}

export interface RadioGroupCoreStyle {
  removeBorderOverlap: boolean
  hasCurrent?: boolean
}

export type RadioGroupProps = RadioGroupPublicProps & StyledProps<RadioGroupContext>

const RadioGroup = React.forwardRef(
  (props: RadioGroupProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref, device } = useResponsive({ ref: forwardRef })

    const radios = React.Children.toArray(props.children)

    const [value, setValue] = React.useState(props.value!)
    const [hovered, setHovered] = React.useState(-1)
    const [focused, setFocused] = React.useState(-1)

    const direction = props.orientation === 'vertical' ? 'column' : 'row'

    React.useEffect(() => {
      setValue(props.value!)
    }, [props.value])

    const getCoreStyle = (extra?: any): RadioGroupCoreStyle => ({
      removeBorderOverlap: props.spacing === 0,
      ...extra,
    })

    const getPropertyValue = <T extends unknown>(child: any, name: string): T =>
      typeof child.props[name] === 'undefined' ? props[name] : child.props[name]

    const getItemProperties = () => {
      let itemProperties = {}

      if (props.breakpoints) {
        const numberOfColumn = getBreakpointValue(props.breakpoints, device)

        if (device) {
          itemProperties = {
            [device!]: 12 / numberOfColumn,
          }
        }
      } else {
        itemProperties = { xs: true }
      }

      return itemProperties
    }

    const handleChange = (nextValue: string) => {
      setValue(nextValue)

      if (props.onChange) {
        props.onChange(nextValue)
      }
    }

    const handleFocus = (event: React.FocusEvent) => {
      const element: any = event.currentTarget

      const index = +element.getAttribute('data-cell-index')
      setFocused(index)
    }

    const handleBlur = () => setFocused(-1)

    const handleHover = (event: React.MouseEvent) => {
      const element: any = event.currentTarget

      const index = +element.getAttribute('data-cell-index')
      setHovered(index)
    }

    const handleLeave = () => setHovered(-1)

    const renderItem = (child: any, index: number) => {
      const checked = value ? value === child.props.value : index === 0
      const dataAttributes = {
        ...getTestId(props, `radio-${index + 1}`),
        ...getAttributes(child.props, DataAttributesPrefix),
        'data-cell-index': index,
      }

      const customisations: any = props.customisations || {}

      const component = React.cloneElement(child, {
        ...customisations,
        ...child.props,
        ...dataAttributes,
        checked,
        size: getPropertyValue(child, 'size'),
        disabled: getPropertyValue(child, 'disabled'),
        readOnly: getPropertyValue(child, 'readOnly'),
        invalid: getPropertyValue(child, 'invalid'),
        untickable: checked,
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onHover: handleHover,
        onLeave: handleLeave,
      })

      const styleProps = {
        hasCurrent: focused === index || hovered === index || checked,
      }

      const { Item } = props.styled!
      return (
        <Item
          key={`item-${index + 1}`}
          {...getItemProperties()}
          styleProps={getCoreStyle(styleProps)}
          customisations={props.customisations}
        >
          {component}
        </Item>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        container
        direction={direction}
        spacing={props.spacing}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {radios.map(renderItem)}
      </Root>
    )
  },
)

RadioGroup.defaultProps = {
  size: 'medium',
  spacing: 0,
}

export default RadioGroup
