import * as React from 'react'

import Checkbox, { CheckboxPublicProps } from '../../Checkbox'
import type { CheckboxContext } from '../../Checkbox.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'

export type NativeCheckboxPublicProps = Omit<CheckboxPublicProps, 'icon'>

export interface NativeCheckboxProps
  extends NativeCheckboxPublicProps,
    StyledProps<CheckboxContext> {}

const NativeCheckbox = React.forwardRef(
  (props: NativeCheckboxProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    return <Checkbox className={props.className} ref={ref} {...props} />
  },
)

export default NativeCheckbox
