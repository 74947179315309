import * as React from 'react'
import * as PropTypes from 'prop-types'
import logo from '@assets/placeholder.png'

import type { PlaceholderContext } from './Placeholder.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'

export interface PlaceholderPublicProps {
  /**
   * Image to display
   */
  image?: string
}

export type PlaceholderProps = PlaceholderPublicProps & StyledProps<PlaceholderContext>

export const Placeholder = React.forwardRef(
  (props: PlaceholderProps, forwardRef: React.Ref<HTMLImageElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = () => ({})

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        src={props.image}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      />
    )
  },
)

Placeholder.propTypes = {
  image: PropTypes.string,
}

Placeholder.defaultProps = {
  image: logo,
}

export default Placeholder
