import * as React from 'react'
import type { LinearProgressContext } from './LinearProgress.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'

export interface LinearProgressPublicProps {
  /**
   * Percent of the progress
   */
  value?: number
  /**
   * Label displayed in the center of the progress
   */
  label?: string | React.ReactNode
  /**
   * Thickness of the loader
   */
  thickness?: 'small' | 'medium' | 'large'
}

export type LinearProgressProps = LinearProgressPublicProps & StyledProps<LinearProgressContext>

export interface LinearProgressCoreStyle {
  thickness: string | number
  indeterminate: boolean
  value: number
  hasLabel: boolean
}

export const LinearProgress = React.forwardRef(
  (props: LinearProgressProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const indeterminate = () => typeof props.value === 'undefined'
    const hasLabel = () => typeof props.label !== 'undefined'

    const getCoreStyle = (): LinearProgressCoreStyle => ({
      thickness: props.thickness!,
      indeterminate: indeterminate(),
      hasLabel: hasLabel(),
      value: (props.value || 0) - 100,
    })

    const renderLabel = () => {
      const { Label } = props.styled!
      return props.label ? (
        <Label
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label')}
        >
          {props.label}
        </Label>
      ) : null
    }

    const renderLine1 = () => {
      const { Line1 } = props.styled!
      return (
        <Line1
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'spinner-line1')}
        />
      )
    }

    const renderLine2 = () => {
      const { Line2 } = props.styled!
      return (
        <Line2
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'spinner-line2')}
        />
      )
    }

    const renderSpinner = () => {
      const { Spinner } = props.styled!
      return (
        <Spinner
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'spinner')}
        >
          {renderLine1()}
          {renderLine2()}
        </Spinner>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderSpinner()}
        {renderLabel()}
      </Root>
    )
  },
)

LinearProgress.defaultProps = {
  thickness: 'medium',
}

export default LinearProgress
