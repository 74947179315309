import * as theme from '../../../../../../themes/Developer/proxima-nova'
import { defaultBorderColour } from '../../../../../themes/Developer/customisations'
import type { SearchableSelectStyleProps } from '../DevSearchableSelect.styles'

/** Border */
/** Search */
export const getSearchBorderRight = (props: SearchableSelectStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Selection.Search')
  const currentState = theme.getState(props.styleProps)
  const defaultBorderColours = theme.hasCustomisations(customisations, 'borderRight')
    ? defaultBorderColour(props.theme)
    : 'none'
  return theme.getBorder(customisations, defaultBorderColours, 'borderRight', currentState)
}

/** Reset */
export const getResetBorderLeft = (props: SearchableSelectStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Selection.Reset')
  const currentState = theme.getState(props.styleProps)
  const defaultBorderColours = theme.hasCustomisations(customisations, 'borderLeft')
    ? defaultBorderColour(props.theme)
    : 'none'
  return theme.getBorder(customisations, defaultBorderColours, 'borderLeft', currentState)
}

/** Selection */
/** Height */
export const getSelectionHeight = (props: SearchableSelectStyleProps) =>
  `${theme.inputSize[props.styleProps.size]}px`
