/* eslint-disable no-param-reassign */
import * as React from 'react'
import type { BreadCrumbsContext } from './BreadCrumbs.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

export interface BreadCrumbsPublicProps {
  /**
   * Custom separator node.
   * @default '/'
   */
  separator?: string
  /**
   * The breadcrumb children.
   */
  children?: React.ReactNode
}

export interface BreadCrumbsProps extends BreadCrumbsPublicProps, StyledProps<BreadCrumbsContext> {}

export interface BreadCrumbsCoreStyle {
  separator: boolean
}

const BreadCrumbs = React.forwardRef(
  (props: BreadCrumbsProps, forwardRef: React.Ref<HTMLOListElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const children = React.Children.toArray(props.children)

    const getCoreStyle = (extra?: any): BreadCrumbsCoreStyle => ({
      ...extra,
    })

    const insertSeparators = (items) => {
      return items.reduce((acc, current, index) => {
        if (index < items.length - 1) {
          acc = acc.concat(current, renderSeparator(index))
        } else {
          acc.push(current)
        }

        return acc
      }, [])
    }

    const renderSeparator = (index: number) => {
      const { Item } = props.styled!
      return (
        <Item
          key={`separator-${index + 1}`}
          aria-hidden
          styleProps={getCoreStyle({ separator: true })}
          customisations={props.customisations}
          {...getTestId(props, 'separator')}
        >
          {props.separator}
        </Item>
      )
    }

    const renderItem = (child: any, index: number) => {
      const { Item } = props.styled!
      return (
        <Item
          key={`child-${index + 1}`}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'item')}
        >
          {child}
        </Item>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {insertSeparators(children.map(renderItem))}
      </Root>
    )
  },
)

BreadCrumbs.defaultProps = {
  separator: '/',
}

export default BreadCrumbs
