import type { DialogContentPublicProps } from '../../DialogContent'
import type { DialogContentCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: DialogContentPublicProps & DialogContentCustomisations,
): DialogContentCustomisations => ({
  padding: props.padding,
})
