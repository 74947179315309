import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import type { SwitchContext } from '../../Switch.composition'
import type { SwitchCoreStyle } from '../../Switch'
import { StyleProps } from '../../../types'
import { DisabledState, ReadOnlyState } from '../../../themes/Developer/styles'
import {
  getLabelColour,
  getThumbBgColour,
  getThumbBoxShadowColour,
  getTrackBgColour,
  SwitchCustomisations,
} from './customisations'
import {
  getFontSize,
  getOrientation,
  getThumbSize,
  getTrackHeight,
  getTrackWidth,
} from './DevSwitch.utils'
import * as themeStyles from '../../../themes/Developer/styles'

export type SwitchStyleProps = StyleProps<SwitchCoreStyle, SwitchCustomisations> & ThemeProps

const PositionLabelToTop = (Components: SwitchContext) => css<SwitchStyleProps>`
  ${Components.Label} {
    margin-bottom: ${(props) => props.theme.spaces.xs};
  }
`

const PositionLabelToEnd = (Components: SwitchContext) => css<SwitchStyleProps>`
  ${Components.Label} {
    margin-left: ${(props) => props.theme.spaces.xs};
    order: 1;
  }
`

const PositionLabelToBottom = (Components: SwitchContext) => css<SwitchStyleProps>`
  ${Components.Label} {
    margin-top: ${(props) => props.theme.spaces.xs};
    order: 1;
  }
`

const PositionLabelToStart = (Components: SwitchContext) => css<SwitchStyleProps>`
  ${Components.Label} {
    margin-right: ${(props) => props.theme.spaces.xs};
  }
`

const SwitchChecked = css<SwitchStyleProps>`
  opacity: 1;
  transition: opacity 0.25s ease;
`

const Input = styled.input<SwitchStyleProps>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const Thumb = styled.div<SwitchStyleProps>`
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 50%;
  border-radius: 50%;
  border: none;
  transform: translateY(-50%);
  box-sizing: border-box;
  transition: all 0.25s ease;
  left: ${(props) =>
    props.styleProps.checked
      ? `calc(${getTrackWidth(props)} - calc(${getThumbSize(props)} - 1px))`
      : '-1px'};
  width: ${(props) => getThumbSize(props)};
  height: ${(props) => getThumbSize(props)};
  background-color: ${(props) => getThumbBgColour(props)};
  box-shadow: 0 0 2px ${(props) => getThumbBoxShadowColour(props)};

  :hover {
    box-shadow: 0 0 3px ${(props) => getThumbBoxShadowColour(props)};
  }
`

const Track = styled.div<SwitchStyleProps>`
  padding: 0;
  border-radius: 30px;
  transition: all 0.2s ease;
  background-color: ${(props) => getTrackBgColour(props)};
  width: ${(props) => getTrackWidth(props)};
  height: ${(props) => getTrackHeight(props)};
`

const Switch = styled.div<SwitchStyleProps>`
  display: flex;
  align-items: center;
  touch-action: pan-x;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;

  ${(props) => props.styleProps.checked && SwitchChecked};
`

const Label = styled.label<SwitchStyleProps>`
  box-sizing: border-box;
  align-self: center;
  font-size: inherit;
  font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.regular};
  color: ${(props) => getLabelColour(props)};
  background-color: 'transparent';
`

const Root = styled.div<SwitchStyleProps>`
  display: flex;
  flex-direction: ${(props) => getOrientation(props)};
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  font-size: ${(props) => getFontSize(props)};
  outline: none;

  /** Typography */
  ${themeStyles.FontFamily}

  /** State */
  ${(props) => props.styleProps.disabled && DisabledState}
  ${(props) => props.styleProps.readOnly && ReadOnlyState}

  ${(props) => props.styleProps.labelPosition === 'start' && PositionLabelToStart(Components)};
  ${(props) => props.styleProps.labelPosition === 'top' && PositionLabelToTop(Components)};
  ${(props) => props.styleProps.labelPosition === 'end' && PositionLabelToEnd(Components)};
  ${(props) => props.styleProps.labelPosition === 'bottom' && PositionLabelToBottom(Components)};

  * {
    cursor: inherit !important;
  }
`

const Components: SwitchContext = {
  Root,
  Switch,
  Track,
  Thumb,
  Label,
  Input,
}

export default Components
