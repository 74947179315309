import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { Grid } from '../../../Grid'
import type { GridContainerProps, GridItemProps } from '../../../Grid/Grid'
import type { RadioGroupCoreStyle } from '../../RadioGroup'
import type { RadioGroupContext } from '../../RadioGroup.composition'
import type { StyleProps } from '../../../types'

export type RadioGroupStyleProps = StyleProps<RadioGroupCoreStyle> & ThemeProps

const RemoveBorderOverlap = (Components: RadioGroupContext) => css`
  padding-top: ${(props) => props.theme.visuals.borderSize};
  padding-left: ${(props) => props.theme.visuals.borderSize};

  ${Components.Item} {
    margin-top: -${(props) => props.theme.visuals.borderSize};
    margin-left: -${(props) => props.theme.visuals.borderSize};
  }
`

const Item = styled(Grid)<RadioGroupStyleProps & GridItemProps>`
  ${(props) =>
    props.styleProps.hasCurrent &&
    css`
      z-index: 1;
    `};
`

const Root = styled(Grid)<RadioGroupStyleProps & GridContainerProps>`
  ${(props) => props.styleProps.removeBorderOverlap && RemoveBorderOverlap(Components)};
`

const Components: RadioGroupContext = {
  Root,
  Item,
}

export default Components
