import Loader, { LoaderPublicProps } from '../../Loader'
import { withCustomisedStyle } from '../../../providers'
import Theme from '../../../themes/Developer/theme'
import Styles from './DevLoader.styles'
import Customisations from './DevLoader.customisations'
import type { LoaderContext } from '../../Loader.composition'
import type { LoaderCustomisations } from './customisations'

export const DevLoader = withCustomisedStyle<
  LoaderPublicProps,
  LoaderContext,
  LoaderCustomisations
>(Loader, Theme, Styles, Customisations, 'Loader')

export type DevLoaderCustomisations = LoaderCustomisations
export type DevLoaderProps = LoaderPublicProps & LoaderCustomisations
