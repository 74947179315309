import type { DialogTitlePublicProps } from '../../DialogTitle'
import type { DialogTitleCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: DialogTitlePublicProps & DialogTitleCustomisations,
): DialogTitleCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
  padding: props.padding,
})
