import * as React from 'react'
import { StyledProps } from '../../../providers'
import { ListContext } from '../../List.composition'
import List, { ListPublicProps } from '../../List'
import useResponsive from '../../../hooks/useResponsive'

export type ListStaticPublicProps = ListPublicProps

export interface ListStaticProps extends ListStaticPublicProps, StyledProps<ListContext> {}

const ListStatic = React.forwardRef(
  (props: ListStaticProps, forwardRef: React.Ref<HTMLUListElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = () => ({})

    return <List ref={ref} {...props} styleProps={getCoreStyle()} />
  },
)

ListStatic.propTypes = {
  children: (props, propName, componentName) => {
    const prop = props[propName]

    let error = null
    React.Children.forEach(prop, (child) => {
      if (child.type.displayName !== 'ListStaticItem') {
        error = new Error(`\`${componentName}\` children should be of type \`ListStaticItem\`.`)
      }
    })

    return error
  },
}

export default ListStatic
