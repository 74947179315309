import type { SwitchFieldStyleProps } from './DevSwitchField.styles'
import type { SwitchFieldPublicProps } from '../../SwitchField'
import type { DevSwitchGroupCustomisations as SwitchGroupCustomisations } from '../../../SwitchGroup/themes/Developer/DevSwitchGroup.customisations'
import type { FieldCustomisations } from '../../../Field/themes/Developer/DevField.customisations'

export interface SwitchFieldCustomisations extends FieldCustomisations {
  SwitchGroup?: SwitchGroupCustomisations
}

export const Customisations = {
  SwitchGroup: (props: SwitchFieldStyleProps) => props.customisations.SwitchGroup!,
  Field: (props: SwitchFieldStyleProps) => ({
    Label: props.customisations?.Label,
    HelperText: props.customisations.HelperText,
    ValidityText: props.customisations.ValidityText,
    Tooltip: props.customisations.Tooltip,
    ValidityStatus: props.customisations.ValidityStatus,
  }),
}

export default (
  props: SwitchFieldPublicProps & SwitchFieldCustomisations,
): SwitchFieldCustomisations => ({
  SwitchGroup: props.SwitchGroup || {},
  Label: props.Label || {},
  HelperText: props.HelperText || {},
  ValidityText: props.ValidityText || {},
  Tooltip: props.Tooltip || {},
  ValidityStatus: props.ValidityStatus || {},
})
