import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { CircularProgressCoreStyle } from '../../CircularProgress'
import {
  getColour,
  getLabelColour,
  getLabelFontSize,
  getSpinnerColour,
  getSize,
  CircularProgressCustomisations,
} from './customisations'
import type { CircularProgressContext } from '../../CircularProgress.composition'
import type { StyleProps } from '../../../../../types'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type CircularProgressStyleProps = StyleProps<
  CircularProgressCoreStyle,
  CircularProgressCustomisations
> &
  ThemeProps

const Label = styled.div<CircularProgressStyleProps>`
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  color: ${(props) => getLabelColour(props)};

  /** Typography */
  font-size: ${(props) => getLabelFontSize(props)};
  line-height: ${(props) => props.theme.typography.proximaNova.lineHeight.bodyXs};
`

const SpinnerAnimation = styled.circle<CircularProgressStyleProps>`
  stroke: ${(props) => getSpinnerColour(props)};
`

const SpinnerBackground = styled.circle<CircularProgressStyleProps>`
  stroke: ${(props) => getColour(props)};
`

const Spinner = styled.svg<CircularProgressStyleProps>`
  display: block;
  margin: 0 auto;
  position: relative;
  width: ${(props) => getSize(props)}px;
  height: ${(props) => getSize(props)}px;
`

const Root = styled.div<CircularProgressStyleProps>`
  position: relative;

  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: CircularProgressContext = {
  Root,
  Spinner,
  SpinnerBackground,
  SpinnerAnimation,
  Label,
}

export default Components
