import React from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import {Currency} from "../Utils";
import References from "../SingleImmediatePayments/References";

interface Props {
  name: string;
  amountInMinor: number;
  currency: string;
  references: References;
}

export const BeneficiaryDetails: React.FC<Props> = ({name, amountInMinor, currency, references}) => {
  return (
    <Card variant="outlined" style={{backgroundColor: 'rgb(232,241,201)'}}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Typography align="center" variant="h3">You are paying to</Typography>
            <Typography align="center" variant="subtitle1">{name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography align="center" variant="h3">Amount</Typography>
            <Typography align="center" variant="subtitle1"><Currency amount={amountInMinor} currency={currency} /></Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Typography align="center" variant="h3">End to end reference</Typography>
            <Typography align="center" variant="subtitle1">{references.reconciliation_identifier}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography align="center" variant="h3">Statement reference</Typography>
            <Typography align="center" variant="subtitle1">{references.statement_reference}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
