import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { getColour, getFontSize, LegalNoteCustomisations } from './customisations'
import type { LegalNoteContext } from '../../LegalNote.composition'
import type { StyleProps } from '../../../types'
import * as themeStyles from '../../../themes/Developer/styles'

export type LegalNoteStyleProps = StyleProps<unknown, LegalNoteCustomisations> & ThemeProps

const Root = styled.p<LegalNoteStyleProps>`
  /** Colour */
  color: ${(props) => getColour(props)};

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};
`

const Components: LegalNoteContext = {
  Root,
}

export default Components
