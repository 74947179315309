import type { NativeCheckboxStyleProps } from '../DevNativeCheckbox.styles'
import * as theme from '../../../../../../themes/Developer/proxima-nova'

/** Label */
/** Colour */
export const getLabelColour = (props: NativeCheckboxStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Label')
  const currentState = theme.getState(props.styleProps)
  return theme.getColour(customisations, props.theme.colours.tints.charcoal[100], currentState)
}

/** Background colour */
export const getLabelBgColour = (props: NativeCheckboxStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Label')
  const currentState = theme.getState(props.styleProps)
  return theme.getBgColour(customisations, undefined, currentState)
}

/** Tick */
/** Colour */
const tickDefaultColour = (
  props: NativeCheckboxStyleProps,
): Pick<theme.ColourStates, 'colour' | 'selectedColour' | 'invalidColour'> => ({
  colour: props.theme.colours.tints.lightGrey[100],
  selectedColour: props.theme.colours.tertiary.semantic.green,
  invalidColour: props.theme.colours.tertiary.semantic.red,
})

export const getTickColour = (props: NativeCheckboxStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Tick')
  const currentState = theme.getState(props.styleProps)
  return theme.getColour(customisations, tickDefaultColour(props), currentState)
}

/** Background colour */
export const getTickBgColour = (props: NativeCheckboxStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Tick')
  const currentState = theme.getState(props.styleProps)
  return theme.getBgColour(customisations, undefined, currentState)
}

/** Border */
export const getBorderColour = (props: NativeCheckboxStyleProps): theme.ColourStates => ({
  colour: props.theme.colours.tints.charcoal[80],
  hoverColour: props.theme.colours.tints.sun[80],
  activeColour: props.theme.colours.tints.sun[80],
  focusColour: props.theme.colours.tints.sun[50],
  invalidColour: props.theme.colours.tertiary.semantic.red,
  selectedColour: props.theme.colours.tints.charcoal[80],
  hoverSelectedColour: props.theme.colours.tints.sun[80],
  focusSelectedColour: props.theme.colours.tints.sun[80],
})
