import type { Property } from '..'
import type { Opacity } from './opacity'

export * from './border'
export * from './height'
export * from './opacity'
export * from './width'

export interface IVisuals {
  borderRadius: Property
  boxShadow: Property
  borderSize: Property
  opacity: Opacity
  letterSpace: Property
  lineHeight: Property
}
