import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevSelect } from '../../../../themes/Developer/DevSelect'
import { DevFontAwesomeIcon } from '../../../../../Icon'
import {
  getResetBorderLeft,
  getSearchBorderRight,
  getSelectionHeight,
  SearchableSelectCustomisations,
} from './customisations'
import { Customisations } from './DevSearchableSelect.customisations'
import type { SearchableSelectCoreStyle } from '../../SearchableSelect'
import type { SearchableSelectContext } from '../../SearchableSelect.composition'
import type { StyleProps } from '../../../../../types'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type SearchableSelectStyleProps = StyleProps<
  SearchableSelectCoreStyle,
  SearchableSelectCustomisations
> &
  ThemeProps

const Reset = styled(DevFontAwesomeIcon).attrs(Customisations.Reset)<SearchableSelectStyleProps>`
  cursor: pointer;
  width: ${(props) => getSelectionHeight(props)};
  flex-shrink: 0;

  /** Padding */
  margin-left: ${(props) => props.theme.spaces.xs};

  /** Visual */
  border-left: ${(props) => getResetBorderLeft(props)};
`

const Search = styled(DevFontAwesomeIcon).attrs(Customisations.Search)<SearchableSelectStyleProps>`
  cursor: pointer;
  width: ${(props) => getSelectionHeight(props)};
  flex-shrink: 0;

  /** Visual */
  border-right: ${(props) => getSearchBorderRight(props)};
`

const Select = styled(DevSelect)<SearchableSelectStyleProps>``

const Root = styled.div<SearchableSelectStyleProps>`
  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: SearchableSelectContext = {
  Root,
  Select,
  Search,
  Reset,
}

export default Components as SearchableSelectContext
