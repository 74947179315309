import * as React from 'react'

export interface TableLevel2ContextProps {
  variant: 'header' | 'body' | 'footer'
}

const TableLevel2Context = React.createContext<TableLevel2ContextProps | undefined>(undefined)
TableLevel2Context.displayName = 'TableLevel2Context'

export default TableLevel2Context
