import * as theme from '../../../../themes/Developer/proxima-nova'
import type { FormHelperTextStyleProps } from '../DevFormHelperText.styles'

/** Colour */
const defaultColour = (props: FormHelperTextStyleProps) => ({
  colour: props.theme.colours.tertiary.neutral.grey,
  invalidColour: props.theme.colours.tertiary.semantic.red,
})

export const getColour = (props: FormHelperTextStyleProps) =>
  theme.getColour(props, defaultColour(props), theme.getState(props.styleProps))
