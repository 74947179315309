import * as React from 'react'
import useEnhancedEffect from './useEnhancedEffect'

/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 * @param {function} fn
 */
const useEventCallback = (fn: any) => {
  const ref = React.useRef(fn)

  useEnhancedEffect(() => {
    ref.current = fn
  })

  /* eslint-disable-next-line no-void */
  return React.useCallback((...args) => (void 0, ref.current)(...args), [])
}

export default useEventCallback
