import React from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import {ControlParameters as MandateControlParameters} from "../Mandates/ControlParameters";
import {Currency} from "../Utils";

interface Props {
    parameters: MandateControlParameters;
    currency: string;
    beneficiaryName: string;
    reference: string;
}

export const ControlParameters: React.FC<Props> = ({parameters, currency, beneficiaryName, reference}) => {
    return (
      <Card variant="outlined" style={{backgroundColor: 'rgb(232,241,201)'}}>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} sm={4}>
              <Typography align="center" variant="h3">You are authorising a consent to</Typography>
              <Typography align="center" variant="subtitle1">{beneficiaryName}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="center" variant="h3">Max individual amount</Typography>
              <Typography align="center" variant="subtitle1"><Currency amount={parameters.max_individual_amount} currency={currency}/></Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="center" variant="h3">Reference</Typography>
              <Typography align="center" variant="subtitle1">{reference}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography align="center" variant="subtitle1">&lrm;</Typography>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Typography align="center" variant="h3">Dates</Typography>
              <Typography align="center" variant="subtitle1">{parameters.valid_from}</Typography>
              <Typography align="center" variant="subtitle2">to</Typography>
              <Typography align="center" variant="subtitle1">{parameters.valid_to}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography align="center" variant="h3">Periodic Limits</Typography>
              {parameters.periodic_limits.map(pl =>
                (<Typography align="center" variant="subtitle1"><Currency amount={pl.max_amount} currency={currency} /> / {pl.period_type} ({pl.period_alignment})</Typography>)
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      );
}
