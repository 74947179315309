import * as React from 'react'

export interface StepContextProps {
  /**
   * Index of the step
   */
  index: number
  /**
   * True if the step is the last one
   */
  last: boolean
  /**
   * True if the content step is expanded, false otherwise
   */
  expanded: boolean
  /**
   * True if the step is the active one
   */
  active: boolean
  /**
   * True if the step is disabled
   */
  disabled: boolean
  /**
   * True if the step is completed
   */
  completed: boolean
  /**
   * True the icon will have a disable effect when the step is complete, false otherwise
   */
  widthDisableEffectOnComplete: boolean
}

const StepperContext = React.createContext<StepContextProps>({
  index: 0,
  last: false,
  expanded: false,
  active: false,
  disabled: false,
  completed: false,
  widthDisableEffectOnComplete: false,
})
StepperContext.displayName = 'StepContext'

export default StepperContext
