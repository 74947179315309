import * as React from 'react'
import * as PropTypes from 'prop-types'
import type { ParagraphContext } from './Paragraph.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'

export interface ParagraphPublicProps {
  /**
   * Weight of the message
   */
  weight?: 'thin' | 'regular' | 'bold'
  /**
   * Component representing the message to display as paragraph
   */
  children?: React.ReactNode
}

export type ParagraphProps = ParagraphPublicProps & StyledProps<ParagraphContext>

export interface ParagraphCoreStyle {
  weight: string
  device?: string
}

export const Paragraph = React.forwardRef(
  (props: ParagraphProps, forwardRef: React.Ref<HTMLParagraphElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): ParagraphCoreStyle => ({
      weight: props.weight!,
    })

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

Paragraph.propTypes = {
  weight: PropTypes.oneOf(['thin', 'regular', 'bold']),
}

Paragraph.defaultProps = {
  weight: 'regular',
}

export default Paragraph
