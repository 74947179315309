import type { SearchableSelectStyleProps } from './DevSearchableSelect.styles'
import type { SearchableSelectPublicProps } from '../../SearchableSelect'
import type { SearchableSelectCustomisations } from './customisations'
import ConvertSelectCustomisations from '../../../../themes/Developer/DevSelect.customisations'

export const Customisations = {
  Search: (props: SearchableSelectStyleProps) => ({
    colour: props.theme.colours.tertiary.neutral.charcoal,
    hoverColour: props.theme.colours.tertiary.neutral.charcoal,
    bgColour: 'transparent',
    hoverBgColour: 'transparent',
  }),
  Reset: (props: SearchableSelectStyleProps) => ({
    colour: props.theme.colours.tertiary.neutral.charcoal,
    hoverColour: props.theme.colours.tints.kashmirBlue[100],
    bgColour: 'transparent',
    hoverBgColour: 'transparent',
  }),
  Select: (props: SearchableSelectStyleProps) => ({
    paddingLeft: 0,
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: SearchableSelectPublicProps & SearchableSelectCustomisations,
): SearchableSelectCustomisations => {
  const search = props.Selection?.Search || {}
  const reset = props.Selection?.Reset || {}
  return {
    ...ConvertSelectCustomisations(props),
    Selection: {
      ...props.Selection,
      Search: {
        colour: search.colour,
        bgColour: search.bgColour,
        borderRight: search.borderRight,
      },
      Reset: {
        colour: reset.colour,
        bgColour: reset.bgColour,
        borderLeft: reset.borderLeft,
      },
    },
  }
}
