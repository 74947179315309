import OptionWithCheckbox, { OptionWithCheckboxPublicProps } from '../../OptionWithCheckbox'
import Theme from '../../../../../themes/Developer/theme'
import type { OptionWithCheckboxContext } from '../../OptionWithCheckbox.composition'
import { withStyle } from '../../../../../providers'
import Styles from './DevOptionWithCheckbox.styles'

export const DevOptionWithCheckbox = withStyle<
  OptionWithCheckboxPublicProps,
  OptionWithCheckboxContext
>(OptionWithCheckbox, Theme, Styles, 'OptionWithCheckbox')

export type DevOptionWithCheckboxProps = OptionWithCheckboxPublicProps
