import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../../../Icon/components/FontAwesomeIcon/index'
import type { RadioCoreStyle } from '../../../../Radio'
import type { NativeRadioContext } from '../../NativeRadio.composition'
import type { StyleProps } from '../../../../../types'
import { Customisations } from './DevNativeRadio.customisations'
import { DisabledState, ReadOnlyState } from '../../../../../themes/Developer/styles'
import {
  getFontSize,
  getLabelBgColour,
  getLabelBorderRadius,
  getLabelColour,
  getTickBorder,
  getTickHeight,
  NativeRadioCustomisations,
} from './customisations'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type NativeRadioStyleProps = StyleProps<RadioCoreStyle, NativeRadioCustomisations> &
  ThemeProps

const Icon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<NativeRadioStyleProps>`
  font-size: ${(props) => `calc(${getFontSize(props)} - 2px)`};
`

const TickIcon = styled(DevFontAwesomeIcon).attrs(Customisations.TickIcon)<NativeRadioStyleProps>`
  font-size: ${(props) => `calc(${getFontSize(props)} - 2px)`};
`

const Tick = styled.div<NativeRadioStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-shrink: 0;
  line-height: inherit;
  font-size: inherit;
  border-radius: 50%;

  /** Size */
  width: ${(props) => getTickHeight(props)};
  height: ${(props) => getTickHeight(props)};

  /** Visual */
  border: ${(props) => getTickBorder(props)};
`

const Label = styled.label<NativeRadioStyleProps>`
  box-sizing: border-box;
  font-size: inherit;
  flex-grow: 1;
  text-align: left;
  padding: ${(props) => props.theme.spaces.xxs};
  margin-left: ${(props) => props.theme.spaces.xxs};

  /** Colour */
  color: ${(props) => getLabelColour(props)};
  background-color: ${(props) => getLabelBgColour(props)};

  /** Typography */
  font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.regular};

  /** Visual */
  border-radius: ${(props) => getLabelBorderRadius(props)};
`

const Input = styled.input<NativeRadioStyleProps>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const Root = styled.div<NativeRadioStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};

  /* Visual */
  line-height: ${(props) => props.theme.visuals.lineHeight};

  /** State */
  ${(props) => props.styleProps.disabled && DisabledState}
  ${(props) => props.styleProps.readOnly && ReadOnlyState}

  * {
    cursor: inherit !important;
  }
`

const Components: NativeRadioContext = {
  Root,
  Input,
  Tick,
  TickIcon,
  Icon,
  Label,
}

export default Components
