import { FormControlContextProps } from '../FormControl/FormControl.context'

/**
 * Determine if the input is inputtable or not
 * @param formControl form control context
 * @returns {boolean} True the input is inputtable
 *                    False the input is not inputtable
 */
export const isInputtable = (formControl: FormControlContextProps) =>
  !formControl.disabled && !formControl.readOnly

/**
 * Get the placeholder with the asterisk is the input is required
 * @param placeholder placeholder to display in the input
 * @param required true if the input is required, false otherwise
 * @returns {string} return the new placeholder formatted
 */
export const getPlaceholder = (placeholder?: string, required?: boolean) =>
  placeholder && required ? `${placeholder}*` : placeholder
