import CheckboxCustomise, {
  Customisations as CheckboxCustomisations,
} from '../../../../themes/Developer/DevCheckboxField.customisations'
import type { DevCheckboxFieldCustomisations } from '../../../../themes/Developer'

export type NativeCheckboxFieldCustomisations = DevCheckboxFieldCustomisations

export const Customisations = {
  CheckboxGroup: CheckboxCustomisations.CheckboxGroup,
  Field: CheckboxCustomisations.Field,
}

export default CheckboxCustomise
