import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import { getBgColour, getColour, getPadding, DialogActionsCustomisations } from './customisations'
import type { DialogActionsCoreStyle } from '../../DialogActions'
import type { DialogActionsContext } from '../../DialogActions.composition'
import type { StyleProps } from '../../../../../types'

export type DialogActionsStyleProps = StyleProps<
  DialogActionsCoreStyle,
  DialogActionsCustomisations
> &
  ThemeProps

const Padding = css<DialogActionsStyleProps>`
  padding: ${(props) => getPadding(props)};
`

const Root = styled.div<DialogActionsStyleProps>`
  display: flex;
  align-items: center;
  margin: 0;
  flex: 0 0 auto;
  justify-content: ${(props) => props.styleProps.justify};

  /** Colour */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  /** Spacing */
  ${(props) => !props.styleProps.disableSpacing && Padding}
`

const Components: DialogActionsContext = {
  Root,
}

export default Components
