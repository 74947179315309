import * as theme from '../../../../themes/Developer/proxima-nova'
import type { FormLabelStyleProps } from '../DevFormLabel.styles'

/** Colour */
const defaultColour = (props: FormLabelStyleProps) => ({
  colour: props.theme.colours.tints.charcoal[100],
  invalidColour: props.theme.colours.tertiary.semantic.red,
})

export const getColour = (props: FormLabelStyleProps) =>
  theme.getColour(props, defaultColour(props), theme.getState(props.styleProps))

/** Background colour */
export const getBgColour = (props: FormLabelStyleProps) => {
  if (props.styleProps.variant === 'outlined')
    if (props.styleProps.shrink) return props.theme.colours.tertiary.neutral.white

  return 'initial'
}
