import * as theme from '../../../../themes/Developer/proxima-nova'
import type { CheckboxStyleProps } from '../DevCheckbox.styles'

/** Global */
/** Background colour */
export const getBgColour = (props: CheckboxStyleProps) =>
  theme.getBgColour(
    props,
    props.theme.colours.tertiary.neutral.white,
    theme.getState(props.styleProps),
  )

/** Border colour */
export const getBorderColour = (props: CheckboxStyleProps): theme.BorderProps => ({
  colour: props.theme.colours.tints.charcoal[80],
  hoverColour: props.theme.colours.tints.sun[80],
  activeColour: props.theme.colours.tints.sun[80],
  focusColour: props.theme.colours.tints.sun[50],
  invalidColour: props.theme.colours.tertiary.semantic.red,
  selectedColour: props.theme.colours.tints.charcoal[80],
  hoverSelectedColour: props.theme.colours.tints.sun[80],
  focusSelectedColour: props.theme.colours.tints.sun[80],
})

/** Label */
/** Colour */
export const getLabelColour = (props: CheckboxStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Label')
  const currentState = theme.getState(props.styleProps)
  return theme.getColour(customisations, props.theme.colours.tints.charcoal[100], currentState)
}

/** Tick */
/** Colour */
const tickDefaultColour = (props: CheckboxStyleProps) => ({
  colour: props.theme.colours.tints.charcoal[80],
  selectedColour: props.theme.colours.tertiary.semantic.green,
  invalidColour: props.theme.colours.tertiary.semantic.red,
})

export const getTickColour = (props: CheckboxStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Tick')
  const currentState = theme.getState(props.styleProps)
  return theme.getColour(customisations, tickDefaultColour(props), currentState)
}
