import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { StyleProps } from '../../../../../types'
import type { ListItemSecondaryActionContext } from '../../ListItemSecondaryAction.composition'

export type ListItemSecondaryActionStyleProps = StyleProps & ThemeProps

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: ${(props) => props.theme.spaces.xs};

  & > *:not(:first-child) {
    margin-left: ${(props) => props.theme.spaces.xs};
  }
`

const Components: ListItemSecondaryActionContext = {
  Root,
}

export default Components
