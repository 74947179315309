import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { DevField } from '../../../Field'
import { DevSelect } from '../../../Select'
import { Customisations, SelectFieldCustomisations } from './DevSelectField.customisations'
import type { SelectFieldContext } from '../../SelectField.composition'
import type { StyleProps } from '../../../types'

export type SelectFieldStyleProps = StyleProps<unknown, SelectFieldCustomisations> & ThemeProps

const Select = styled(DevSelect).attrs(Customisations.Select)<SelectFieldStyleProps>``

const Root = styled(DevField).attrs(Customisations.Field)<SelectFieldStyleProps>``

const Components: SelectFieldContext = {
  Root,
  Select,
}

export default Components
