import { getColour } from '../colours'
import { omit } from '../../../../utils/lodash'
import { Border, BorderProps, BorderType } from '../../models'
import { State } from '../../models/states'

const isCorrectBorderProps = (value: BorderProps) => value.width && value.style && value.colour

/**
 * Return the border as width-style-colour i.e `1px solid #00FF00`
 * @param props Customisations for the border
 * @param defaultValue Default border
 * @param type Type of border requested
 */
export const getBorder = (
  value: Border,
  defaultValue: BorderProps | 'none',
  type: BorderType = 'border',
  state?: State,
  asObject?: boolean,
) => {
  const hasIndividualBorder =
    value.borderRight || value.borderLeft || value.borderTop || value.borderBottom

  // Check default value is provided correctly
  if (defaultValue !== 'none' && !isCorrectBorderProps(defaultValue as BorderProps)) {
    console.warn(
      "The default value has to be 'none' or an object with width, style and colour provided.",
    )
    return 'none'
  }

  // Check border and (borderRight|borderLeft|boarderTop|boarderBottom) are not used at the same time
  if (value.border && hasIndividualBorder) {
    console.warn('You can not mix border. Use border or the other.')
    return 'none'
  }

  let border = value[type] || value.border
  if (border === 'none') {
    // No border has been provided for the requested type
    return 'none'
  }

  if (!border && defaultValue === 'none') {
    // No default border has been provided
    return 'none'
  }

  border = border || {}
  const defaultBorder = (defaultValue as BorderProps) || {}

  let { width = undefined } = border
  if (!width) {
    width = defaultBorder?.width
  }

  let { style = undefined } = border
  if (!style) {
    style = defaultBorder?.style
  }

  const defaultColours = omit(defaultBorder, ['width', 'style'])
  const colour = getColour(border, defaultColours, state)

  return asObject ? { width, style, colour } : `${width} ${style} ${colour}`
}
