import { getColour, TabCustomisations } from './customisations'
import type { TabStyleProps } from './DevTab.styles'
import type { TabPublicProps } from '../../Tab'

export const Customisations = {
  Icon: (props: TabStyleProps) => ({
    colour: getColour(props),
    hoverColour: getColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: TabPublicProps & TabCustomisations): TabCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
  selectedColour: props.selectedColour,
})
