import Banner, { BannerPublicProps } from '../../Banner'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevBanner.styles'
import Customisations from './DevBanner.customisations'
import type { BannerContext } from '../../Banner.composition'
import type { BannerCustomisations } from './customisations'

export const DevBanner = withCustomisedStyle<
  BannerPublicProps,
  BannerContext,
  BannerCustomisations
>(Banner, Theme, Styles, Customisations, 'Banner')

export type DevBannerCustomisations = BannerCustomisations
export type DevBannerProps = BannerPublicProps & BannerCustomisations
