import type { TableCellPublicProps } from '../../TableCell'
import type { TableCellCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: TableCellPublicProps & TableCellCustomisations,
): TableCellCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
  border: props.border,
  fontSize: props.fontSize,
  padding: props.padding,
})
