import type { InputFieldStyleProps } from './DevInputField.styles'
import type { InputFieldPublicProps } from '../../InputField'
import type { InputCustomisations } from '../../../Input/themes/Developer/customisations'
import ConvertFieldCustomisations, {
  FieldCustomisations,
} from '../../../Field/themes/Developer/DevField.customisations'

export interface InputFieldCustomisations extends FieldCustomisations {
  Input?: InputCustomisations
}

export const Customisations = {
  Input: (props: InputFieldStyleProps) => props.customisations.Input!,
  Field: (props: InputFieldStyleProps) => ({
    Label: props.customisations?.Label,
    HelperText: props.customisations.HelperText,
    ValidityText: props.customisations.ValidityText,
    Tooltip: props.customisations.Tooltip,
    ValidityStatus: props.customisations.ValidityStatus,
  }),
}

export default (
  props: InputFieldPublicProps & InputFieldCustomisations,
): InputFieldCustomisations => ({
  Input: props.Input || {},
  ...ConvertFieldCustomisations(props),
})
