import FormLabel, { FormLabelPublicProps } from '../../FormLabel'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevFormLabel.styles'
import Customisations from './DevFormLabel.customisations'
import type { FormLabelContext } from '../../FormLabel.composition'
import type { FormLabelCustomisations } from './customisations'

export const DevFormLabel = withCustomisedStyle<
  FormLabelPublicProps,
  FormLabelContext,
  FormLabelCustomisations
>(FormLabel, Theme, Styles, Customisations, 'FormLabel')

export type DevFormLabelCustomisations = FormLabelCustomisations
export type DevFormLabelProps = FormLabelPublicProps & FormLabelCustomisations
