import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../../../Icon'
import { Customisations } from './DevTableSortLabel.customisations'
import { getColour, TableSortLabelCustomisations } from './customisations'
import type { TableSortLabelCoreStyle } from '../../TableSortLabel'
import type { TableSortLabelContext } from '../../TableSortLabel.composition'
import type { StyleProps } from '../../../../../types'

export type TableSortLabelStyleProps = StyleProps<
  TableSortLabelCoreStyle,
  TableSortLabelCustomisations
> &
  ThemeProps

const LeftIcon = css<TableSortLabelStyleProps>`
  order: 0;
`

const AscIcon = css<TableSortLabelStyleProps>`
  transform: rotate(180deg);
`

const DescIcon = css<TableSortLabelStyleProps>`
  transform: rotate(0deg);
`

const RightLabel = css<TableSortLabelStyleProps>`
  order: 1;
`

const Icon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<TableSortLabelStyleProps>`
  font-size: 14px;
  padding: 0px ${(props) => props.theme.spaces.xxs};
  cursor: inherit;

  ${(props) => props.styleProps.direction === 'asc' && AscIcon};
  ${(props) => props.styleProps.direction === 'desc' && DescIcon};
  ${(props) => props.styleProps.iconPosition === 'left' && LeftIcon};
`

const Label = styled.span<TableSortLabelStyleProps>`
  ${(props) =>
    props.styleProps.labelCanGrow &&
    css`
      flex-grow: 1;
    `}

  ${(props) => props.styleProps.iconPosition === 'left' && RightLabel};
`

const Root = styled.span<TableSortLabelStyleProps>`
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: inherit;
  align-items: center;
  cursor: pointer;
  width: 100%;

  /** Colour */
  color: ${(props) => getColour(props)};
`

const Components: TableSortLabelContext = {
  Root,
  Label,
  Icon,
}

export default Components
