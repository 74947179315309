import Link, { LinkPublicProps } from '../../Link'
import Theme from '../../../themes/Developer/theme'

import { withCustomisedStyle } from '../../../providers'

import Styles from './DevLink.styles'
import { LinkContext } from '../../Link.composition'

import Customisations from './DevLink.customisations'
import { LinkCustomisations } from './customisations'

export const DevLink = withCustomisedStyle<LinkPublicProps, LinkContext, LinkCustomisations>(
  Link,
  Theme,
  Styles,
  Customisations,
  'Link',
)

export type DevLinkCustomisations = LinkCustomisations
export type DevLinkProps = LinkPublicProps & LinkCustomisations
