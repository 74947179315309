import LegalNote, { LegalNotePublicProps } from '../../LegalNote'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevLegalNote.styles'
import Customisations from './DevLegalNote.customisations'
import type { LegalNoteContext } from '../../LegalNote.composition'
import type { LegalNoteCustomisations } from './customisations'

export const DevLegalNote = withCustomisedStyle<
  LegalNotePublicProps,
  LegalNoteContext,
  LegalNoteCustomisations
>(LegalNote, Theme, Styles, Customisations, 'LegalNote')

export type DevLegalNoteCustomisations = LegalNoteCustomisations
export type DevLegalNoteProps = LegalNotePublicProps & LegalNoteCustomisations
