import * as React from 'react'
import * as PropTypes from 'prop-types'
import type { FormHelperTextContext } from './FormHelperText.composition'
import type { StyledProps } from '../providers'
import { useFormControl } from '../FormControl/FormControl.context'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'

export interface FormHelperTextPublicProps {
  /**
   * The id of the `helper text` element.
   */
  id?: string
  /**
   * Component used as message for the helper text
   */
  children?: React.ReactNode
}

export interface FormHelperTextProps
  extends FormHelperTextPublicProps,
    StyledProps<FormHelperTextContext> {}

export interface FormHelperTextCoreStyle {
  size: string
  invalid: boolean
}

const FormHelperText = React.forwardRef(
  (props: FormHelperTextProps, forwardRef: React.Ref<HTMLParagraphElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const formControl = useFormControl(props)

    const getCoreStyle = (): FormHelperTextCoreStyle => ({
      size: formControl.size!,
      invalid: formControl.invalid!,
    })

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        id={props.id}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

FormHelperText.propTypes = {
  id: PropTypes.string,
}

FormHelperText.defaultProps = {}

export default FormHelperText
