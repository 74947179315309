import * as React from 'react'
import type { SeparatorContext } from './Separator.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'

export interface SeparatorPublicProps {
  /**
   * Orientation of the separator
   */
  orientation: 'horizontal' | 'vetical'
}

export interface SeparatorProps extends SeparatorPublicProps, StyledProps<SeparatorContext> {}

export interface SeparatorCoreStyle {
  orientation: string
}

export const Separator = React.forwardRef(
  (props: SeparatorProps, forwardRef: React.Ref<HTMLImageElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): SeparatorCoreStyle => ({
      orientation: props.orientation!,
    })

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      />
    )
  },
)

Separator.defaultProps = {
  orientation: 'horizontal',
}

export default Separator
