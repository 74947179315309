/** FontAwesome Solid Icons */
export { faSearch as faSolidSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
export { faCheck as faSolidCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
export { faBuilding as faSolidBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding'
export { faInfoCircle as faSolidInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
export { faQuestionCircle as faSolidQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
export { faTimes as faSolidTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
export { faTimesCircle as faSolidTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
export { faAngleDown as faSolidAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
export { faAngleUp as faSolidAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp'
export { faAngleRight as faSolidAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
export { faAngleLeft as faSolidAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft'
export { faAngleDoubleRight as faSolidAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons/faAngleDoubleRight'
export { faAngleDoubleLeft as faSolidAngleDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleDoubleLeft'
export { faDownload as faSolidDownload } from '@fortawesome/pro-solid-svg-icons/faDownload'
export { faCircle as faSolidCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
export { faTrash as faSolidTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
export { faUserEdit as faSolidUserEdit } from '@fortawesome/pro-solid-svg-icons/faUserEdit'
export { faUserTie as faSolidUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie'
export { faCog as faSolidCog } from '@fortawesome/pro-solid-svg-icons/faCog'
export { faUser as faSolidUser } from '@fortawesome/pro-solid-svg-icons/faUser'
export { faCheckCircle as faSolidCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
export { faExclamationCircle as faSolidExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
export { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons/faStar'
export { faExclamationTriangle as faSolidExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
export { faUsersCog as faSolidUsersCog } from '@fortawesome/pro-solid-svg-icons/faUsersCog'
export { faVideo as faSolidVideo } from '@fortawesome/pro-solid-svg-icons/faVideo'
export { faArrowDown as faSolidArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown'
export { faSpinnerThird as faSolidSpinnerThird } from '@fortawesome/pro-solid-svg-icons/faSpinnerThird'
export { faPlusCircle as faSolidPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle'
export { faSquare as faSolidSquare } from '@fortawesome/pro-solid-svg-icons/faSquare'

/** FontAwesome Regular Icons */
export { faAngleDown as faRegularAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
export { faTimes as faRegularTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
export { faSearch as faRegularSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'

/** FontAwesome Light Icons */
export { faBuilding as faLightBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding'
export { faAngleDown as faLightAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
export { faAngleUp as faLightAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp'
export { faArrowUp as faLightArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp'
export { faArrowDown as faLightArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown'
export { faCog as faLightCog } from '@fortawesome/pro-light-svg-icons/faCog'
export { faPlusCircle as faLightPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle'
export { faCheck as faLightCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
export { faPencilAlt as faLightPencilAlt } from '@fortawesome/pro-light-svg-icons/faPencilAlt'
export { faTimes as faLightTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
export { faTimesCircle as faLightTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle'
export { faAngleLeft as faLightAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft'
export { faAngleRight as faLightAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
export { faAngleDoubleRight as faLightAngleDoubleRight } from '@fortawesome/pro-light-svg-icons/faAngleDoubleRight'
export { faAngleDoubleLeft as faLightAngleDoubleLeft } from '@fortawesome/pro-light-svg-icons/faAngleDoubleLeft'

export { faTrashAlt as faLightTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt'
export { faSearch as faLightSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
export { faUser as faLightUser } from '@fortawesome/pro-light-svg-icons/faUser'
export { faCircle as faLightCircle } from '@fortawesome/pro-light-svg-icons/faCircle'
export { faCopy as faLightCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
export { faEdit as faLightEdit } from '@fortawesome/pro-light-svg-icons/faEdit'
export { faEye as faLightEye } from '@fortawesome/pro-light-svg-icons/faEye'

/** FontAwesome types */
export type { IconDefinition } from '@fortawesome/fontawesome-svg-core'

/** React FontAwesome types */
export { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
