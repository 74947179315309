import * as React from 'react'
import StepperContext from '../../Stepper.context'
import StepContext from '../Step/Step.context'
import type { StepConnectorContext as StepConnectorComposition } from './StepConnector.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'

export interface StepConnectorProps extends StyledProps<StepConnectorComposition> {}

export interface StepConnectorCoreStyle {
  orientation: 'horizontal' | 'vertical'
  alternativeLabel?: boolean
  active?: boolean
  disabled?: boolean
  completed?: boolean
}

const StepConnector: React.FC<StepConnectorProps> = React.forwardRef(
  (props: StepConnectorProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const { alternativeLabel, orientation } = React.useContext(StepperContext)!
    const { active, disabled, completed } = React.useContext(StepContext)!

    const getCoreStyle = (): StepConnectorCoreStyle => ({
      orientation: orientation!,
      alternativeLabel,
      active,
      disabled,
      completed,
    })

    const renderLine = () => {
      const { Line } = props.styled!
      return (
        <Line
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'line')}
        />
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderLine()}
      </Root>
    )
  },
)

StepConnector.defaultProps = {}

export default StepConnector
