import type { NativeCheckboxStyleProps } from '../DevNativeCheckbox.styles'

export const fontSizes = {
  small: 12,
  medium: 14,
  large: 16,
}

/** Font size  */
export const getFontSize = (props: NativeCheckboxStyleProps) =>
  `${fontSizes[props.styleProps.size]}px`
