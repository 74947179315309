import MenuGroup, { MenuGroupPublicProps } from '../../MenuGroup'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevMenuGroup.styles'
import Customisations from './DevMenuGroup.customisations'
import type { MenuGroupContext } from '../../MenuGroup.composition'
import type { MenuGroupCustomisations } from './customisations'

export const DevMenuGroup = withCustomisedStyle<
  MenuGroupPublicProps,
  MenuGroupContext,
  MenuGroupCustomisations
>(MenuGroup, Theme, Styles, Customisations, 'MenuGroup')

export type DevMenuGroupCustomisations = MenuGroupCustomisations
export type DevMenuGroupProps = MenuGroupPublicProps & MenuGroupCustomisations
