import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevField } from '../../../../../Field'
import type { RadioFieldContext } from '../../../../RadioField.composition'
import { DevNativeRadioGroup } from '../../../../../RadioGroup'
import {
  Customisations,
  NativeRadioFieldCustomisations,
} from './DevNativeRadioField.customisations'
import type { StyleProps } from '../../../../../types'

export type RadioFieldStyleProps = StyleProps<{}, NativeRadioFieldCustomisations> & ThemeProps

const RadioGroup = styled(DevNativeRadioGroup).attrs(
  Customisations.RadioGroup,
)<RadioFieldStyleProps>``

const Root = styled(DevField).attrs(Customisations.Field)<RadioFieldStyleProps>``

const Components: RadioFieldContext = {
  Root,
  RadioGroup,
}

export default Components
