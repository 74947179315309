import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { OptionWithCheckboxCoreStyle } from '../../OptionWithCheckbox'
import type { OptionWithCheckboxContext } from '../../OptionWithCheckbox.composition'
import type { StyleProps } from '../../../../../types'

export type OptionWithCheckboxStyleProps = StyleProps<OptionWithCheckboxCoreStyle> & ThemeProps

const Label = styled.label<OptionWithCheckboxStyleProps>`
  flex-grow: 1;
  text-align: start;
`

const Checkbox = styled.input<OptionWithCheckboxStyleProps>`
  margin-right: ${(props) => props.theme.spaces.xs};
`

const Root = styled.div<OptionWithCheckboxStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: inherit;

  * {
    cursor: inherit;
  }
`

const Components: OptionWithCheckboxContext = {
  Root,
  Checkbox,
  Label,
}

export default Components
