import type { LinearProgressPublicProps } from '../../LinearProgress'
import type { LinearProgressCustomisations } from './customisations'

export default (
  props: LinearProgressPublicProps & LinearProgressCustomisations,
): LinearProgressCustomisations => ({
  colour: props.colour,
  Spinner: {
    colour: props.Spinner?.colour,
  },
  Label: {
    colour: props.Label?.colour,
  },
})
