
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";

import singleImmediatePaymentService from "../SingleImmediatePayments/SingleImmediatePaymentsService";
import SingleImmediatePayment from "../SingleImmediatePayments/SingleImmediatePayment";
import LoadingPanel from "../LoadingPanel";
import { FormValue, MockBankLoginForm } from "./MockBankForm";
import {
  getDelayInSeconds,
  mapUsernameToActionForPayments,
  useRoutes,
  useToken
} from "./utils";
import { redirectAfterSubmitPayment } from "../SingleImmediatePayments/RedirectAfterSubmitPayment";

export function MockBankLogin() {
  const [payment, setPayment] = useState<SingleImmediatePayment | null>(null);

  const { navigateToErrorPage, navigateToPage } = useRoutes();

  const token = useToken();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {

    singleImmediatePaymentService
      .getPayment(id, token)
      .then(result => {
        if (!result || result.status !== "Initiated") {
          navigateToErrorPage();
        }
        setPayment(result);
      })
      .catch(navigateToErrorPage);
  }, []);

  function onCancel() {
    singleImmediatePaymentService
      .submitPayment(id, "Cancel", token)
      .then(redirectAfterSubmitPayment(navigateToErrorPage))
      .catch(navigateToErrorPage);
  }


  function onSubmit(formValues: FormValue) {
    const username = formValues.username;
    const executionDelayId = formValues["execution-delay"];
    const settlementDelayId = formValues["settlement-delay"];
    const action = mapUsernameToActionForPayments(username);

    if (payment?.redirect_after_login) {
      singleImmediatePaymentService
        .submitPayment(
          payment!.id,
          action!,
          token,
          getDelayInSeconds(executionDelayId),
          getDelayInSeconds(settlementDelayId),
          true
        )
        .then(redirectAfterSubmitPayment(navigateToErrorPage))
        .catch(navigateToErrorPage);
    } else {
      navigateToPage(
        `/confirm/${
          payment?.id
        }?action=${action}&execution_delay_in_seconds=${getDelayInSeconds(
          executionDelayId
        )}&settlement_delay_in_seconds=${getDelayInSeconds(
          settlementDelayId
        )}#token=${token}`,
        {
          payment
        }
      );
    }
  }

  return (
    <Container>
      <Grid container>
        {payment !== null ? (
          <MockBankLoginForm
            payment={payment}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        ) : (
          <LoadingPanel />
        )}
      </Grid>
    </Container>
  );
}
