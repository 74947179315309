import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { DevField } from '../../../Field'
import { DevSwitchGroup } from '../../../SwitchGroup'
import { Customisations, SwitchFieldCustomisations } from './DevSwitchField.customisations'
import type { SwitchFieldContext } from '../../SwitchField.composition'
import type { StyleProps } from '../../../types'

export type SwitchFieldStyleProps = StyleProps<unknown, SwitchFieldCustomisations> & ThemeProps

const SwitchGroup = styled(DevSwitchGroup).attrs(
  Customisations.SwitchGroup,
)<SwitchFieldStyleProps>``

const Root = styled(DevField).attrs(Customisations.Field)<SwitchFieldStyleProps>``

const Components: SwitchFieldContext = {
  Root,
  SwitchGroup,
}

export default Components
