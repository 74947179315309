import styled, { css } from 'styled-components'

import ExtSlick from 'react-slick'

export const Measure = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  right: 0;
  top: -60px;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #ced4de;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 12px;
  color: #7991a2;

  p:first-child {
    margin-right: 10px;
  }
`

export const Slick: any = styled(ExtSlick)`
  height: inherit;
  min-height: 100px;
  user-select: text;

  & > .slick-list {
    height: inherit !important;
    min-height: 100px;
    padding: 0px 0px 20px 0px !important;
    overflow: visible;
    transform: none !important;

    & > .slick-track {
      height: inherit;
      min-height: 100px;
      transform: none !important;

      & > .slick-slide {
        height: inherit;
        min-height: 100px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;

        & > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          ${(props: any) =>
            !props.fitContent &&
            css`
              width: 100%;
            `};
        }

        &.slick-active {
          z-index: 1;
          /* z-index: 100; */

          & > div {
            height: inherit;
            min-height: 100px;
            max-height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            ${(props: any) =>
              !props.fitContent &&
              css`
                & > * {
                  flex-grow: 1;
                  max-height: 100%;
                }
              `}

            * {
              ${(props) =>
                props.theme.typography &&
                css`
                  font-family: ${props.theme.typography.fontFamily}!important;
                `};
            }
          }
        }
      }
    }
  }
`

export const Container = styled.div`
  position: relative;
`
