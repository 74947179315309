import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { TableCellStyleProps } from '../DevTableCell.styles'

/** Colour */
export const getColour = (props: TableCellStyleProps) =>
  theme.getColour(props, 'inherit', theme.getState(props.styleProps))

/** Background colour */
export const getBgColour = (props: TableCellStyleProps) =>
  theme.getBgColour(props, 'inherit', theme.getState(props.styleProps))

/** Header */
/** Colour */
export const defaultHeaderColour = (props: TableCellStyleProps) => ({
  colour: props.theme.colours.tints.charcoal[50],
})

export const getHeaderColour = (props: TableCellStyleProps) =>
  theme.getColour(props, defaultHeaderColour(props), theme.getState(props.styleProps))

/** Border colour */
export const getBorderColour = (props: TableCellStyleProps) => ({
  colour: props.theme.colours.tints.lightGrey[130],
  hoverColour: props.theme.colours.tints.sun[80],
  activeColour: props.theme.colours.tints.sun[80],
  focusColour: props.theme.colours.tints.sun[80],
  invalidColour: props.theme.colours.tertiary.semantic.red,
  selectedColour: props.theme.colours.tints.lightGrey[180],
})
