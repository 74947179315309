import { RadioStyleProps } from './DevRadio.styles'

export const FieldHeight = {
  small: 36,
  medium: 44,
  large: 48,
}

export const FieldFontSize = {
  small: 12,
  medium: 14,
  large: 14,
}

export const FieldLineHeight = {
  small: 16,
  medium: 20,
  large: 20,
}

const TickHeight = {
  small: 14,
  medium: 16,
  large: 19,
}

export const getFontSize = (props: RadioStyleProps) => `${FieldFontSize[props.styleProps.size!]}px`

export const getHeight = (props: RadioStyleProps) => `${FieldHeight[props.styleProps.size!]}px`

export const getLineHeight = (props: RadioStyleProps) =>
  `${FieldLineHeight[props.styleProps.size!]}px`

export const getTickHeight = (props: RadioStyleProps) => `${TickHeight[props.styleProps.size!]}px`
