import { ButtonStyleProps } from '../DevButton.styles'
import * as theme from '../../../../themes/Developer/proxima-nova'

/**
 * func. which creates default colour
 * @param props button style props
 */
const defaultColour = (props: ButtonStyleProps) => ({
  primary: {
    colour: props.theme.colours.tertiary.neutral.white,
    hoverColour: props.theme.colours.tertiary.neutral.white,
    focusColour: props.theme.colours.tertiary.neutral.white,
  },
  secondary: {
    colour: props.theme.colours.tints.azure[80],
    hoverColour: props.theme.colours.tertiary.neutral.white,
    focusColour: props.theme.colours.tertiary.neutral.white,
  },
  tertiary: {
    colour: props.theme.colours.tints.azure[100],
    hoverColour: props.theme.colours.tints.kashmirBlue[100],
    focusColour: props.theme.colours.tints.azure[100],
  },
})

/**
 * Primary:
 *  - Default: match the default colour
 *  - Hover: match the hover colour
 * Secondary:
 *  - Default: match the default colour
 *  - Hover: white
 * Tertiary:
 *  - Default: match the default colour
 *  - Hover: match the hover colour
 * @param props
 * @param state
 */
export const getColour = (props: ButtonStyleProps, state?: theme.State) => {
  if (props.styleProps.type === 'secondary' && state === 'hover')
    return props.theme.colours.tertiary.neutral.white

  return theme.getColour(props, defaultColour(props)[props.styleProps.type], state)
}

/** Background colour */
const defaultBgColour = (props: ButtonStyleProps) => ({
  primary: {
    bgColour: props.theme.colours.tints.azure[100],
    hoverBgColour: props.theme.colours.tints.cloudBurst[80],
    focusBgColour: props.theme.colours.tints.cloudBurst[80],
  },
  secondary: {
    bgColour: 'transparent',
    hoverBgColour: 'transparent',
    focusBgColour: 'transparent',
  },
  tertiary: {
    bgColour: 'transparent',
    hoverBgColour: 'transparent',
    focusBgColour: 'transparent',
  },
})

/**
 * Primary:
 *  - Default: match the default background colour
 *  - Hover: match the hover background colour
 * Secondary:
 *  - Default: transparent
 *  - Hover: match the default colour
 * Tertiary: no background colour
 * @param props
 * @param state
 */
export const getBgColour = (props: ButtonStyleProps, state?: theme.State) => {
  if (props.styleProps.type === 'secondary' && state === 'hover') {
    const statesColour = defaultColour(props)[props.styleProps.type]
    return theme.getColour(props, statesColour.colour)
  }

  return theme.getBgColour(props, defaultBgColour(props)[props.styleProps.type], state)
}

/** Border colour */
const defaultBorderColour = (props: ButtonStyleProps) => ({
  primary: {
    colour: props.theme.colours.tints.azure[100],
    hoverColour: props.theme.colours.tints.cloudBurst[80],
    focusColour: props.theme.colours.tints.cloudBurst[80],
  },
  secondary: {
    colour: props.theme.colours.tints.azure[100],
    hoverColour: props.theme.colours.tertiary.neutral.white,
    focusColour: props.theme.colours.tertiary.neutral.white,
  },
  tertiary: {
    focusColour: props.theme.colours.tints.azure[100],
  },
})

/**
 * Primary: the border matches the background colour
 * Secondary: the border matches the colour
 * Tertiary: adds border on focus
 * @param props
 * @param state
 */
export const getBorderColour = (props: ButtonStyleProps, state?: theme.State) => {
  if (props.styleProps.type === 'tertiary') {
    if (state === 'focus') {
      return theme.getColour(props, defaultBorderColour(props)[props.styleProps.type], state)
    }

    return 'transparent'
  }

  if (props.styleProps.type === 'secondary') {
    return theme.getColour(props, defaultBorderColour(props)[props.styleProps.type], state)
  }

  return theme.getBgColour(props, defaultBgColour(props)[props.styleProps.type], state)
}
