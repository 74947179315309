import React from "react";
import { colours } from "../theme";

export function AnchorDialog(props: {
  onClick(): void;
  children: React.ReactNode;
}) {
  return (
    <button
      type="button"
      onClick={props.onClick}
      style={{ background: "none", border: "none" }}
    >
      <u style={{ cursor: "pointer", color: colours.Grey }}>{props.children}</u>
    </button>
  );
}
