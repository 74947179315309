import * as React from 'react'
import {
  faSolidAngleDown,
  faSolidAngleLeft,
  faSolidAngleRight,
  faSolidAngleUp,
} from '../utils/fontawesome'
import type { StyledProps } from '../providers'
import type { TabsContext } from './Tabs.composition'
import checkChildIsNotAFragment from './Tabs.checks'
import useResponsive from '../hooks/useResponsive'
import useEventCallback from '../hooks/useEventCallback'
import useClickAway from '../hooks/useClickAway'
import { detectScrollType, getNormalizedScrollLeft } from '../utils/scrollLeft'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import animate from '../utils/animate'
import { isMobile } from '../devices'
import { getTestId } from '../utils/test'
import { debounce, takeRight } from '../utils/lodash'

export interface TabsPublicProps {
  /**
   * The value of the currently selected `Tab`.
   * If you don't want any selected `Tab`, you can set this prop to `false`.
   */
  value: any
  /**
   * The tabs orientation (layout flow direction).
   * @default 'horizontal'
   */
  orientation?: 'horizontal' | 'vertical'
  /**
   * Determines additional display behavior of the tabs:
   *
   *  - `scrollable` will invoke scrolling properties and allow for horizontally
   *  scrolling (or swiping) of the tab bar.
   *  -`fullWidth` will make the tabs grow to use all the available space,
   *  which should be used for small views, like on mobile.
   *  - `standard` will render the default state.
   * @default 'standard'
   */
  variant?: 'standard' | 'fullWidth' | 'scrollable'
  /**
   * True if the tabs grow to use all the available space, false otherwise
   */
  fullWidth?: boolean
  /**
   * Callback fired when the value changes.
   *
   * @param {object} event The event source of the callback. **Warning**: This is a generic event not a change event.
   * @param {any} value We default to the index of the child (number)
   */
  onChange?: (event: React.SyntheticEvent<HTMLElement>, value?: any) => void
  /**
   * Component displayed as tab content
   */
  children?: React.ReactNode
}

export interface TabsProps extends TabsPublicProps, StyledProps<TabsContext> {}

export interface TabsCoreStyle {
  orientation: 'horizontal' | 'vertical'
  variant: 'standard' | 'scrollable'
  fullWidth?: boolean
  isVisible?: boolean
  numberOfTabToDisplay: number
  hovered: boolean
  focused: boolean
}

// TODO: Manage the extra tab closing better (click outside + escape) via context?
const Tabs: React.FC<TabsProps> = React.forwardRef(
  (props: TabsProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref, width } = useResponsive<HTMLDivElement>({ ref: forwardRef })

    const children = React.Children.toArray(props.children)
    const scrollRef: any = React.useRef<HTMLDivElement>(null)
    const tabListRef = React.useRef<HTMLDivElement>(null)
    const moreSectionRef = React.useRef<HTMLDivElement>(null)
    const moreRef = React.useRef<HTMLButtonElement>(null)
    const extraTabsRef = React.useRef<HTMLDivElement>(null)

    const valueToIndex = new Map()

    const isRtl = false
    const scrollable = props.variant === 'scrollable'
    const vertical = props.orientation === 'vertical'
    const scrollStart = vertical ? 'scrollTop' : 'scrollLeft'
    const start = vertical ? 'top' : 'left'
    const end = vertical ? 'bottom' : 'right'
    const clientSize = vertical ? 'clientHeight' : 'clientWidth'
    const size = vertical ? 'height' : 'width'

    const [indicatorStyle, setIndicatorStyle] = React.useState({})
    const [displayScroll, setDisplayScroll] = React.useState({
      start: false,
      end: false,
    })
    const [displayExtraTabs, setDisplayExtraTabs] = React.useState(false)
    const [numberOfTabToDisplay, setNumberOfTabToDisplay] = React.useState(children.length)

    const [hovered, setHovered] = React.useState(false)
    const [focused, setFocused] = React.useState(false)
    const [active, setActive] = React.useState(false)

    useClickAway({
      refs: [moreRef, extraTabsRef],
      isMobile,
      callback: () => handleMoreSectionClosing(),
    })

    const scrollSelectedIntoView = useEventCallback(() => {
      if (!scrollable) {
        return
      }

      const { tabsMeta, tabMeta } = getTabsMeta()

      if (!tabMeta || !tabsMeta) {
        return
      }

      if (tabMeta[start] < tabsMeta[start]) {
        // left side of button is out of view
        const nextScrollStart = tabsMeta[scrollStart] + (tabMeta[start] - tabsMeta[start])
        scroll(nextScrollStart)
      } else if (tabMeta[end] > tabsMeta[end]) {
        // right side of button is out of view
        const nextScrollStart = tabsMeta[scrollStart] + (tabMeta[end] - tabsMeta[end])
        scroll(nextScrollStart)
      }
    })

    const updateIndicatorState = useEventCallback(() => {
      const { tabsMeta, tabMeta, tabIndex } = getTabsMeta()
      const hidden = tabIndex >= numberOfTabToDisplay

      let startValue = 0

      if (!hidden && tabMeta && tabsMeta) {
        if (vertical) {
          startValue = tabMeta.top - tabsMeta.top + tabsMeta.scrollTop
        } else {
          startValue = tabMeta.left - tabsMeta.left + tabsMeta.scrollLeft
        }
      }

      const newIndicatorStyle = {
        [start]: startValue,
        // May be wrong until the font is loaded.
        [size]: !hidden && tabMeta ? tabMeta[size] : 0,
      }

      // IE 11 support, replace with Number.isNaN
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(indicatorStyle[start]) || isNaN(indicatorStyle[size])) {
        setIndicatorStyle(newIndicatorStyle)
      } else {
        const dStart = Math.abs(indicatorStyle[start] - newIndicatorStyle[start])
        const dSize = Math.abs(indicatorStyle[size] - newIndicatorStyle[size])

        if (dStart >= 1 || dSize >= 1) {
          setIndicatorStyle(newIndicatorStyle)
        }
      }
    })

    const updateScrollButtonState = useEventCallback(() => {
      if (scrollable) {
        const {
          scrollTop,
          scrollHeight,
          clientHeight,
          scrollWidth,
          clientWidth,
        } = (scrollRef as any).current
        let showStartScroll
        let showEndScroll

        if (vertical) {
          showStartScroll = scrollTop > 1
          showEndScroll = scrollTop < scrollHeight - clientHeight - 1
        } else {
          const scrollLeft = getNormalizedScrollLeft((scrollRef as any).current, 'ltr')

          // use 1 for the potential rounding error with browser zooms.
          showStartScroll = isRtl ? scrollLeft < scrollWidth - clientWidth - 1 : scrollLeft > 1
          showEndScroll = !isRtl ? scrollLeft < scrollWidth - clientWidth - 1 : scrollLeft > 1
        }

        if (showStartScroll !== displayScroll.start || showEndScroll !== displayScroll.end) {
          setDisplayScroll({ start: showStartScroll, end: showEndScroll })
        }
      }
    })

    const updateMoreSectionState = useEventCallback(() => {
      if (!scrollable) {
        const tabsNode = (scrollRef as any).current
        if (tabsNode) {
          const tabsSize = getMeta(tabsNode)[size]

          const tabs = (tabListRef as any).current.children

          let totalWidth = 0
          let numberOfTab = 0
          for (let i = 0; i < tabs.length; i += 1) {
            const tabSize = getMeta(tabs[i])[size]

            if (totalWidth + tabSize > tabsSize) {
              break
            } else {
              totalWidth += tabSize
              numberOfTab = i + 1
            }
          }

          if (numberOfTab !== numberOfTabToDisplay) {
            setNumberOfTabToDisplay(numberOfTab)
          }
        }
      }
    })

    React.useEffect(() => {
      updateIndicatorState()
      updateScrollButtonState()
      updateMoreSectionState()
    }, [width])

    React.useEffect(() => {
      updateIndicatorState()
      updateScrollButtonState()
      updateMoreSectionState()
    })

    React.useEffect(() => {
      scrollSelectedIntoView()
    }, [scrollSelectedIntoView, indicatorStyle])

    const getCoreStyle = (extra?: any): TabsCoreStyle => ({
      orientation: props.orientation,
      variant: props.variant!,
      fullWidth: props.fullWidth,
      hovered,
      focused,
      ...extra,
    })

    const getTabsMeta = () => {
      const tabsNode = (scrollRef as any).current

      let tabsMeta
      if (tabsNode) {
        const rect = tabsNode.getBoundingClientRect()

        tabsMeta = {
          clientWidth: tabsNode.clientWidth,
          clientHeight: tabsNode.clientHeight,
          scrollLeft: tabsNode.scrollLeft,
          scrollTop: tabsNode.scrollTop,
          scrollWidth: tabsNode.scrollWidth,
          top: rect.top,
          bottom: rect.bottom,
          left: rect.left,
          right: rect.right,
        }
      }

      let tabMeta: any
      let tabIndex: any
      if (tabsNode && props.value !== false) {
        const { children: tabChildren } = tabListRef!.current!

        if (tabChildren.length > 0) {
          const tab = tabChildren[valueToIndex.get(props.value)]
          tabMeta = tab ? tab.getBoundingClientRect() : null
          tabIndex = tab ? valueToIndex.get(props.value) : null
        }
      }

      return { tabsMeta, tabMeta, tabIndex }
    }

    const getMeta = (node: any) => {
      return { height: node.offsetHeight, width: node.offsetWidth }
    }

    const getScrollSize = () => {
      const containerSize = (scrollRef as any).current[clientSize]

      let totalSize = 0
      const tabChildren = Array.from((tabListRef as any).current.children)

      for (let i = 0; i < tabChildren.length; i += 1) {
        const tab: any = tabChildren[i]
        if (totalSize + tab[clientSize] > containerSize) {
          break
        }
        totalSize += tab[clientSize]
      }

      return totalSize
    }

    const scroll = (scrollValue: number) => {
      animate(scrollStart, (scrollRef as any).current, scrollValue)
    }

    const moveTabsScroll = (delta: number) => {
      let scrollValue = (scrollRef as any).current[scrollStart]

      if (vertical) {
        scrollValue += delta
      } else {
        scrollValue += delta * (isRtl ? -1 : 1)
        // Fix for Edge
        scrollValue *= isRtl && detectScrollType() === 'reverse' ? -1 : 1
      }

      scroll(scrollValue)
    }

    const handleStartScrollClick = () => {
      moveTabsScroll(-1 * getScrollSize())
    }

    const handleEndScrollClick = () => {
      moveTabsScroll(getScrollSize())
    }

    const handleTabsScroll = React.useMemo(
      () =>
        debounce(() => {
          updateScrollButtonState()
        }),
      [updateScrollButtonState],
    )

    const handleMoreClick = (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      setDisplayExtraTabs(!displayExtraTabs)
    }

    const handleMoreButtonFocus = (event: React.FocusEvent) => {
      setFocused(true)
    }

    const handleMoreButtonBlur = (event: React.FocusEvent) => {
      setFocused(false)
    }

    const handleMoreButtonHover = (event: React.MouseEvent) => {
      setHovered(true)
    }

    const handleMoreButtonLeave = (event: React.MouseEvent) => {
      setHovered(false)
    }

    const handleTabChange = (event: React.SyntheticEvent<HTMLElement>, value?: any) => {
      if (displayExtraTabs) {
        handleMoreSectionClosing()
      }

      if (props.onChange) {
        props.onChange(event, value)
      }
    }

    const handleMoreSectionClosing = () => {
      setDisplayExtraTabs(false)

      const moreButtonElement: HTMLButtonElement | null = moreRef.current
      if (moreButtonElement) {
        moreButtonElement.focus()
      }
    }

    const renderScrollButtonStart = () => {
      const style = {
        isVisible: displayScroll.start,
      }

      const icon = vertical ? faSolidAngleUp : faSolidAngleLeft

      const { ScrollButtonStart } = props.styled!
      return scrollable ? (
        <ScrollButtonStart
          src={icon}
          disabled={!displayScroll.start}
          onClick={handleStartScrollClick}
          styleProps={getCoreStyle(style)}
          customisations={props.customisations}
          {...getTestId(props, 'scrollbar-start')}
        />
      ) : null
    }

    const renderScrollButtonEnd = () => {
      const style = {
        isVisible: displayScroll.end,
      }

      const icon = vertical ? faSolidAngleDown : faSolidAngleRight

      const { ScrollButtonEnd } = props.styled!
      return scrollable ? (
        <ScrollButtonEnd
          src={icon}
          disabled={!displayScroll.end}
          onClick={handleEndScrollClick}
          styleProps={getCoreStyle(style)}
          customisations={props.customisations}
          {...getTestId(props, 'scrollbar-end')}
        />
      ) : null
    }

    const renderExtraTabs = () => {
      const tabs = takeRight(children, children.length - numberOfTabToDisplay)

      const { ExtraTabList } = props.styled!
      return displayExtraTabs ? (
        <ExtraTabList
          ref={extraTabsRef}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'more-tabs')}
        >
          {tabs.map((tab: any, index: number) => renderTab(tab, numberOfTabToDisplay + index))}
        </ExtraTabList>
      ) : null
    }

    const renderMoreButton = () => {
      const style = {
        isVisible: numberOfTabToDisplay !== children.length,
      }

      const { MoreButton } = props.styled!
      return (
        <MoreButton
          ref={moreRef}
          onClick={handleMoreClick}
          onFocus={handleMoreButtonFocus}
          onBlur={handleMoreButtonBlur}
          onMouseEnter={handleMoreButtonHover}
          onMouseLeave={handleMoreButtonLeave}
          styleProps={getCoreStyle(style)}
          customisations={props.customisations}
          {...getTestId(props, 'more-btn')}
        >
          ...
        </MoreButton>
      )
    }

    const renderMoreSection = () => {
      const { MoreSection } = props.styled!
      return !scrollable ? (
        <MoreSection
          ref={moreSectionRef}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'more')}
        >
          {renderMoreButton()}
          {renderExtraTabs()}
        </MoreSection>
      ) : null
    }

    const renderTab = (child: React.ReactNode, index: number) => {
      if (!React.isValidElement(child)) {
        return null
      }

      checkChildIsNotAFragment(child)

      const childValue = child.props.value === undefined ? index : child.props.value
      valueToIndex.set(childValue, index)
      const selected = childValue === props.value

      const customisations: any = props.customisations || {}

      return React.cloneElement(child, {
        ...child.props,
        key: `tab-${index + 1}`,
        selected,
        value: childValue,
        fullWidth: props.variant === 'fullWidth',
        ...customisations.Tab,
        onChange: handleTabChange,
        tabIndex: index < numberOfTabToDisplay || displayExtraTabs ? 0 : -1,
        ...getTestId(props, `tab-${index + 1}`),
      })
    }

    const renderIndicator = () => {
      const { TabIndicator } = props.styled!
      return (
        <TabIndicator
          style={indicatorStyle}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'indicator')}
        />
      )
    }

    const renderTabList = () => {
      const style = {
        numberOfTabToDisplay,
      }

      const { TabList } = props.styled!
      return (
        <TabList
          ref={tabListRef}
          role="tablist"
          styleProps={getCoreStyle(style)}
          customisations={props.customisations}
          {...getTestId(props, 'list')}
        >
          {children.map(renderTab)}
        </TabList>
      )
    }

    const onScroll = scrollable ? handleTabsScroll : undefined

    const { Root, Scrollable } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderScrollButtonStart()}
        <Scrollable
          ref={scrollRef}
          onScroll={onScroll}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'container')}
        >
          {renderTabList()}
          {renderIndicator()}
        </Scrollable>
        {renderScrollButtonEnd()}
        {renderMoreSection()}
      </Root>
    )
  },
)

Tabs.defaultProps = {
  orientation: 'horizontal',
  variant: 'standard',
}

export default Tabs
