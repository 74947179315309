import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../Icon'
import { SelectCoreStyle } from '../../Select'
import {
  getArrowBorderLeft,
  getBorder,
  getFontSize,
  getOptionBgColour,
  getOptionColour,
  getOptionFontWeight,
  getOptionHeight,
  getPaddingLeft,
  getSelectionBgColour,
  getSelectionColour,
  getSelectionFontWeight,
  getSelectionHeight,
  SelectCustomisations,
} from './customisations'
import { Customisations } from './DevSelect.customisations'
import { DisabledState, ReadOnlyState } from '../../../themes/Developer/styles'
import { SelectContext } from '../../Select.composition'
import { StyleProps } from '../../../types'
import * as themeStyles from '../../../themes/Developer/styles'

export type SelectStyleProps = StyleProps<SelectCoreStyle, SelectCustomisations> & ThemeProps

export const shrink = (props: SelectStyleProps) =>
  props.styleProps.focused || props.styleProps.filled

const LeftToRightValue = css<SelectStyleProps>``

const RightToLeftValue = css<SelectStyleProps>`
  margin-left: auto;
`

const PlaceholderInput = css<SelectStyleProps>`
  color: ${(props) => props.theme.colours.tints.charcoal[100]};
`

const SearchInput = css<SelectStyleProps>``

const FilledSelectionValue = css<SelectStyleProps>`
  ${(props) =>
    shrink(props) &&
    css`
      align-items: flex-end;
    `}
`

const OptionLink = styled.a<SelectStyleProps>`
  width: 100%;
  color: inherit;
  text-decoration: none;
  overflow: hidden;
  outline: none;
`

const Option = styled.li<SelectStyleProps>`
  display: flex;
  flex-direction: row;
  align-content: center;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;

  /** Colour */
  color: ${(props) => getOptionColour(props)};
  background-color: ${(props) => getOptionBgColour(props)};

  :hover {
    background-color: ${(props) => getOptionBgColour(props, 'hover')};
  }

  :focus {
    background-color: ${(props) => getOptionBgColour(props, 'focus')};
  }

  /** Typography */
  font-weight: ${(props) => getOptionFontWeight(props)};

  /* Visual */
  height: ${(props) => getOptionHeight(props)};
  line-height: ${(props) => getOptionHeight(props)};

  /** Spacing */
  padding: ${(props) => `0px 0px 0px ${props.theme.spaces.xs}`};

  & > * {
    flex-grow: 1;
  }
`

const Options = styled.ul<SelectStyleProps>`
  position: absolute;
  left: 0px;
  top: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 200;
  outline: none;
  cursor: inherit;
  font-size: inherit;
  max-height: 150px;
  overflow-y: auto;

  /** Visual */
  border-right: ${(props) => getBorder(props)};
  border-bottom: ${(props) => getBorder(props)};
  border-left: ${(props) => getBorder(props)};
`

const Arrow = styled(DevFontAwesomeIcon).attrs(Customisations.Arrow)<SelectStyleProps>`
  cursor: pointer;
  width: ${(props) => getSelectionHeight(props)};
  flex-shrink: 0;

  /** Visual */
  border-left: ${(props) => getArrowBorderLeft(props)};
`

const Input = styled.input<SelectStyleProps>`
  box-sizing: border-box;
  position: relative;
  cursor: inherit;
  min-width: 0;
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  background-color: inherit;
  font-family: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  outline: none;
  width: 100%;
  direction: ${(props) => props.styleProps.direction};

  ${(props) => props.styleProps.type === 'search' && SearchInput};

  /** Spacing */
  padding: ${(props) => getPaddingLeft(props)};
`

const TruncatedValue = styled.div<SelectStyleProps>`
  ${(props) => props.styleProps.direction === 'ltr' && LeftToRightValue};
  ${(props) => props.styleProps.direction === 'rtl' && RightToLeftValue};
`

const SelectionValue = styled.div<SelectStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  cursor: inherit;
  color: inherit;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;

  ${(props) => props.styleProps.variant === 'filled' && FilledSelectionValue};

  * {
    white-space: nowrap;
  }

  & > *:not(:first-child) {
    margin-left: ${(props) => props.theme.spaces.xs};
  }
`

const Selection = styled.div<SelectStyleProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  outline: none;
  font-size: inherit;
  cursor: inherit;

  /** Colour */
  color: ${(props) => getSelectionColour(props)};
  background-color: ${(props) => getSelectionBgColour(props)};

  /** Typography */
  font-weight: ${(props) => getSelectionFontWeight(props)};

  /** Visual */
  border: ${(props) => getBorder(props)};

  /* Visual */
  height: ${(props) => getSelectionHeight(props)};

  ${(props) => props.styleProps.isPlaceholderDisplayed && PlaceholderInput};
`

const Root = styled.div<SelectStyleProps>`
  position: relative;
  box-sizing: border-box;
  flex-grow: 1;
  outline: none;
  cursor: pointer;

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};

  ${(props) => props.styleProps.disabled && DisabledState};
  ${(props) => props.styleProps.readOnly && ReadOnlyState};

  * {
    box-sizing: border-box;
  }
`

const Components: SelectContext = {
  Root,
  Selection,
  SelectionValue,
  TruncatedValue,
  Input,
  Arrow,
  Options,
  Option,
  OptionLink,
}

export default Components as SelectContext
