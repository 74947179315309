import Stepper, { StepperPublicProps } from '../../Stepper'
import Theme from '../../../themes/Developer/theme'
import type { StepperContext } from '../../Stepper.composition'
import { withStyle } from '../../../providers'
import Styles from './DevStepper.styles'

export const DevStepper = withStyle<StepperPublicProps, StepperContext>(
  Stepper,
  Theme,
  Styles,
  'Stepper',
)

export type DevStepperProps = StepperPublicProps
