import SingleImmediatePaymentSubmitActionResult from "./MandateSubmitActionResult";
import MandateSubmitActionResult from "./MandateSubmitActionResult";

export const redirectAfterSubmitMandate = (
  onError: () => void) => (response: MandateSubmitActionResult) => {
  switch (response.type) {
    case 'Accepted':
      window.location.href = response.return_uri;
      break;
    default:
      onError()
      break;
  }
}
