import * as React from 'react'

export interface StepperContextProps {
  activeStep: number
  alternativeLabel: boolean
  connector?: React.ReactNode
  nonLinear: boolean
  orientation: 'horizontal' | 'vertical'
}

const StepperContext = React.createContext<StepperContextProps>({
  orientation: 'horizontal',
  activeStep: 0,
  alternativeLabel: false,
  nonLinear: false,
})
StepperContext.displayName = 'StepperContext'

export default StepperContext
