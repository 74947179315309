import * as React from 'react'
import MenuContext from '../../Menu.context'
import type { MenuGroupContext } from './MenuGroup.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { includes } from '../../../utils/lodash'
import { faSolidAngleDown, faSolidAngleUp } from '../../../utils/fontawesome'
import { attachIf } from '../../../utils/event-handler'
import { getTestId } from '../../../utils/test'

export interface MenuGroupPublicProps {
  /**
   * True if the menu item is at the root level of the menu, false otherwise
   */
  root?: boolean
  /**
   * Title of the menu group.
   */
  title?: string | React.ReactNode
  /**
   * Orientation of the group content
   */
  orientation?: 'horizontal' | 'vertical'
  /**
   * True if the group is elapsed, false otherwise
   */
  elapsed?: boolean
  /**
   * True if an one item of the group is selected, false otherwise
   */
  selected?: boolean
  /**
   * Component to display before the title.
   */
  startAdornment?: React.ReactNode
  /**
   * Component to display after the title.
   */
  endAdornment?: React.ReactNode
  /**
   * True if the menu group is disabled, false otherwise
   */
  disabled?: boolean
  /**
   * True if the menu group is focused, false otherwise
   */
  focused?: boolean
  /**
   * Component displayed as a label of the menu item
   */
  children?: React.ReactNode
  /**
   * Handler when the group item is clicked
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * Handler when the menu group loses the focus.
   */
  onBlur?: (event: React.FocusEvent) => void
  /**
   * Handler when the menu group gets the focus.
   */
  onFocus?: (event: React.FocusEvent) => void
  /**
   * Handler when the menu group is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the menu group is not hovered
   */
  onLeave?: (event: React.MouseEvent) => void
  /**
   * Handler when the group item has the focus and a key has been pushed
   */
  onKeyUp?: (event: React.KeyboardEvent) => void
}

export type MenuGroupProps = MenuGroupPublicProps & StyledProps<MenuGroupContext>

export interface MenuGroupCoreStyle {
  disabled: boolean
  startAdornment: boolean
  endAdornment: boolean
  variant: 'dropdown' | 'fly-out' | 'accordion' | 'tab'
  elapsed: boolean
  orientation?: 'horizontal' | 'vertical'
  hovered: boolean
  focused: boolean
  root: boolean
  selected: boolean
}

const MenuGroup = React.forwardRef(
  (props: MenuGroupProps, forwardRef: React.Ref<HTMLLIElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const headerRef = React.useRef<HTMLButtonElement>(null)
    const contentRef = React.useRef<HTMLUListElement>(null)
    const { variant, adjustSize } = React.useContext(MenuContext)

    // Use to collapse the group item correctly
    const [hovered, setHovered] = React.useState(false)
    const [focused, setFocused] = React.useState(false)

    const canElapseByHovering = () => includes(['dropdown', 'fly-out'], variant)
    const isDefined = (value: any) => value !== undefined && value !== null

    const tabVariant = () => variant === 'tab'
    const accordionVariant = () => variant === 'accordion'
    const isActionnable = !props.disabled

    React.useEffect(() => {
      adjustSize!()
    }, [props.elapsed])

    // TODO work for key accessible but it is not appropriate when using the mouse
    React.useEffect(() => {
      if (!props.focused) {
        return
      }

      if (headerRef.current) {
        headerRef.current.focus()
      }
    }, [props.focused])

    const getCoreStyle = (): MenuGroupCoreStyle => ({
      disabled: props.disabled!,
      orientation: props.orientation,
      startAdornment: isDefined(props.startAdornment),
      endAdornment: isDefined(props.endAdornment) || accordionVariant(),
      variant,
      elapsed: props.elapsed,
      hovered,
      focused,
      root: props.root!,
      selected: props.selected!,
    })

    const simulateHeaderClick = () => {
      if (canElapseByHovering()) {
        if (headerRef.current) {
          headerRef.current.click()
        }
      }
    }

    const handleKeyUp = (event: React.KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault()

        if (!props.elapsed) {
          return
        }

        event.stopPropagation()

        if (headerRef.current) {
          headerRef.current.focus()
        }

        if (props.onKeyUp) {
          props.onKeyUp(event)
        }
      }
    }

    const handleClick = (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      if (props.onClick) {
        props.onClick(event)
      }
    }

    const handleFocus = (event: React.FocusEvent) => {
      if (props.onFocus) {
        props.onFocus(event)
      }

      setFocused(true)
    }

    const handleBlur = (event: React.FocusEvent) => {
      if (props.onBlur) {
        props.onBlur(event)
      }

      setFocused(false)
    }

    const handleHover = (event: React.MouseEvent) => {
      if (props.onHover) {
        props.onHover(event)
      }

      simulateHeaderClick()
      setHovered(true)
    }

    const handleLeave = (event: React.MouseEvent) => {
      if (props.onLeave) {
        props.onLeave(event)
      }

      simulateHeaderClick()
      setHovered(false)
    }

    const renderContent = () => {
      const { Content } = props.styled!
      return props.elapsed ? (
        <Content
          ref={contentRef}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'content')}
        >
          {variant === 'tab' && renderTitle()}
          {props.children}
        </Content>
      ) : null
    }

    const renderTitle = () => {
      if (React.isValidElement(props.title)) {
        return props.title
      }

      const { Title } = props.styled!
      return props.title ? (
        <Title
          data-root={props.root}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'title')}
        >
          {props.title}
        </Title>
      ) : null
    }

    const renderEndAdornment = () => {
      if (!accordionVariant()) {
        return props.endAdornment
      }

      const icon = props.elapsed ? faSolidAngleUp : faSolidAngleDown

      const { Arrow } = props.styled!
      return (
        <Arrow
          src={icon}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'header-arrow')}
        />
      )
    }

    const renderHeader = () => {
      const { Header } = props.styled!
      return (
        <Header
          ref={headerRef}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'header')}
        >
          {props.startAdornment}
          {variant !== 'tab' && renderTitle()}
          {renderEndAdornment()}
        </Header>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        onClick={attachIf(handleClick, isActionnable)}
        onKeyUp={attachIf(handleKeyUp, isActionnable)}
        onFocus={attachIf(handleFocus, isActionnable)}
        onBlur={attachIf(handleBlur, isActionnable)}
        onMouseEnter={attachIf(handleHover, isActionnable)}
        onMouseLeave={attachIf(handleLeave, isActionnable)}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderHeader()}
        {renderContent()}
      </Root>
    )
  },
)

MenuGroup.defaultProps = {
  disabled: false,
  elapsed: false,
  root: false,
}

export default MenuGroup
