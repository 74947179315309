import CircularProgress, { CircularProgressPublicProps } from '../../CircularProgress'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevCircularProgress.styles'
import Customisations from './DevCircularProgress.customisations'
import type { CircularProgressContext } from '../../CircularProgress.composition'
import type { CircularProgressCustomisations } from './customisations'

export const DevCircularProgress = withCustomisedStyle<
  CircularProgressPublicProps,
  CircularProgressContext,
  CircularProgressCustomisations
>(CircularProgress, Theme, Styles, Customisations, 'CircularProgress')

export type DevCircularProgressCustomisations = CircularProgressCustomisations
export type DevCircularProgressProps = CircularProgressPublicProps & CircularProgressCustomisations
