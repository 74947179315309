import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { NativeRadioStyleProps } from '../DevNativeRadio.styles'

const defaultLabelColour = (props: NativeRadioStyleProps) => ({
  colour: props.theme.colours.tints.charcoal[80],
  hoverColour: props.theme.colours.tints.charcoal[100],
  focusColour: props.theme.colours.tints.charcoal[100],
  selectedColour: props.theme.colours.tints.charcoal[100],
})

/** Label */
/** Colour */
export const getLabelColour = (props: NativeRadioStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Label'),
    defaultLabelColour(props),
    theme.getState(props.styleProps),
  )

/** Background colour */
export const getLabelBgColour = (props: NativeRadioStyleProps) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'Label'),
    'inherit',
    theme.getState(props.styleProps),
  )

type TickDefaultColour = Pick<theme.ColourStates, 'colour' | 'selectedColour' | 'invalidColour'>

const tickDefaultColour = (props: NativeRadioStyleProps): TickDefaultColour => ({
  colour: props.theme.colours.tints.lightGrey[100],
  selectedColour: props.theme.colours.tertiary.semantic.green,
  invalidColour: props.theme.colours.tertiary.semantic.red,
})

export const getTickColour = (props: NativeRadioStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Tick'),
    tickDefaultColour(props),
    theme.getState(props.styleProps),
  )

export const getTickBorderColour = (props: NativeRadioStyleProps): theme.ColourStates => ({
  colour: props.theme.colours.tints.charcoal[80],
  hoverColour: props.theme.colours.tints.sun[80],
  activeColour: props.theme.colours.tints.lightGrey[180],
  focusColour: props.theme.colours.tints.sun[50],
  invalidColour: props.theme.colours.tertiary.semantic.red,
  selectedColour: props.theme.colours.tints.charcoal[80],
  hoverSelectedColour: props.theme.colours.tints.sun[80],
  focusSelectedColour: props.theme.colours.tints.sun[80],
})
