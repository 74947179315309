import type { DialogActionsPublicProps } from '../../DialogActions'
import type { DialogActionsCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: DialogActionsPublicProps & DialogActionsCustomisations,
): DialogActionsCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
  padding: props.padding,
})
