import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { DialogTitleStyleProps } from '../DevDialogTitle.styles'

/** Colour */
export const getColour = (props: DialogTitleStyleProps) =>
  theme.getColour(props, 'inherit', theme.getState(props.styleProps))

/** Background colour */
export const getBgColour = (props: DialogTitleStyleProps) =>
  theme.getBgColour(props, 'inherit', theme.getState(props.styleProps))
