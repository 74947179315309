import ListStaticItem, { ListItemStaticPublicProps } from '../../ListStaticItem'
import Theme from '../../../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../../../providers'
import Customisations from './DevListItemStatic.customisations'
import type { ListItemContext } from '../../../../ListItem.composition'
import type { ListItemStaticCustomisations } from './customisations'
import Styles from './DevListItemStatic.styles'

export const DevListStaticItem = withCustomisedStyle<
  ListItemStaticPublicProps,
  ListItemContext,
  ListItemStaticCustomisations
>(ListStaticItem, Theme, Styles, Customisations, 'ListStaticItem')

export type DevListStaticItemCustomisations = ListItemStaticCustomisations
export type DevListStaticItemProps = ListItemStaticPublicProps & ListItemStaticCustomisations
