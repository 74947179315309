import LinearProgress, { LinearProgressPublicProps } from '../../LinearProgress'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevLinearProgress.styles'
import Customisations from './DevLinearProgress.customisations'
import type { LinearProgressContext } from '../../LinearProgress.composition'
import type { LinearProgressCustomisations } from './customisations'

export const DevLinearProgress = withCustomisedStyle<
  LinearProgressPublicProps,
  LinearProgressContext,
  LinearProgressCustomisations
>(LinearProgress, Theme, Styles, Customisations, 'LinearProgress')

export type DevLinearProgressCustomisations = LinearProgressCustomisations
export type DevLinearProgressProps = LinearProgressPublicProps & LinearProgressCustomisations
