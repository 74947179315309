import SingleImmediatePaymentSubmitActionResult from "./SingleImmediatePaymentSubmitActionResult";

export const redirectAfterSubmitPayment = (
  onError: () => void) => (response: SingleImmediatePaymentSubmitActionResult) => {
  switch (response.type) {
    case 'Accepted':
      window.location.href = response.return_uri;
      break;
    default:
      onError()
      break;
  }
}