import * as React from 'react'
import type { StyledProps } from '../providers'
import type { SelectFieldContext } from './SelectField.composition'
import type { SelectPublicProps } from '../Select/Select'
import type { FieldPublicProps } from '../Field/Field'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'
import { isEmpty } from '../utils/lodash'

export type SelectFieldPublicProps = FieldPublicProps & SelectPublicProps

export type SelectFieldProps = SelectFieldPublicProps & StyledProps<SelectFieldContext>

export const SelectField: React.FC<SelectFieldProps> = React.forwardRef(
  (props: SelectFieldProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = () => ({})

    const getPlaceholder = () => {
      if (props.variant === 'standard') {
        return props.placeholder
      }

      if (isEmpty(props.label)) {
        return props.placeholder
      }

      return undefined
    }

    const renderSelect = () => {
      const { Select } = props.styled!
      return (
        <Select
          id={props.id}
          name={props.name}
          type={props.type}
          size={props.size}
          multiple={props.multiple}
          displayName={props.displayName}
          displayValue={props.displayValue}
          value={props.value}
          defaultValue={props.defaultValue}
          options={props.options}
          placeholder={getPlaceholder()}
          fullWidth={props.fullWidth}
          keepSelection={props.keepSelection}
          searchNotFoundLabel={props.searchNotFoundLabel}
          searchType={props.searchType}
          selectionTemplate={props.selectionTemplate}
          optionTemplate={props.optionTemplate}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          onHover={props.onHover}
          onLeave={props.onLeave}
          onClick={props.onClick}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, `select`)}
        />
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        id={props.id}
        label={props.label}
        helperText={props.helperText}
        disabled={props.disabled}
        invalid={props.invalid}
        required={props.required}
        readOnly={props.readOnly}
        variant={props.variant}
        size={props.size}
        breakpoint={props.breakpoint}
        error={props.error}
        tooltip={props.tooltip}
        withValidityStatus={props.withValidityStatus}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderSelect()}
      </Root>
    )
  },
)

SelectField.defaultProps = {
  variant: 'standard',
}

export default SelectField
