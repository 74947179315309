import * as React from 'react'
import type { DropzoneContext } from './Dropzone.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix, DefaultPlaceholder } from '../constants'
import { getTestId } from '../utils/test'
import { getImage } from './Dropzone.util'
import { useDidMount } from '../hooks'

export interface DropzonePublicProps {
  /**
   * Logo to display
   */
  logo?: any
  /**
   * Label of the dropzone, by default "Choose a file"
   */
  label?: string
  /**
   * List of validations to execute on the uploaded image
   */
  validations?: any[]
  /**
   * Handler when a new file have been uploaded by the user
   */
  onChange?: (file: any, image: any) => void
  /**
   * Handler when there are some errors
   */
  onErrors?: (errors: string[]) => void
}

interface DropzoneProps extends DropzonePublicProps, StyledProps<DropzoneContext> {}

export interface DropzoneCoreStyle {
  hasIcon: boolean
}

export const Dropzone = React.forwardRef(
  (props: DropzoneProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const inputRef = React.useRef<HTMLInputElement>(null)

    const [file, setFile] = React.useState<any>({ blob: props.logo })

    useDidMount(() => {
      if (props.onChange) {
        props.onChange(file.blob, file.image)
      }
    }, [file])

    const getCoreStyle = (): DropzoneCoreStyle => ({
      hasIcon: props.logo !== undefined,
    })

    const validate = (newFile: any, image: any) => {
      const errors: any[] = []

      props.validations!.forEach((validation: any) => {
        if (!validation.isValid(newFile, image)) {
          errors.push(validation.message)
        }
      })

      return errors
    }

    const notifyErrors = (errors: string[]) => {
      if (props.onErrors) {
        props.onErrors(errors)
      }
    }

    const change = (newFile: any, image: any, blob: string) => {
      const errors = validate(newFile, image)

      if (errors.length === 0) {
        setFile({ blob, image })
      } else {
        notifyErrors(errors)
      }
    }

    const handleClick = (event: React.MouseEvent) => {
      if (inputRef.current) {
        inputRef.current.value = ''
        inputRef.current.click()
      }
    }

    const handleChange = async (event: React.SyntheticEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files || []

      if (files.length > 0) {
        const currentFile = files[0]
        // const blob = URL.createObjectURL(currentFile);

        const { image, blob } = await getImage(currentFile)

        change(currentFile, image, blob)
        // const image = new Image();
        // image.onload = () => {
        //   console.log("LOADED")
        //   change(currentFile, image, blob);
        //   return true;
        // };

        // console.log("SET BLOB", blob, blob.length, (image as any).tata)
        // image.src = blob;
      }
    }

    const renderLabel = () => {
      const { Label } = props.styled!
      return props.label ? (
        <Label
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label')}
        >
          {props.label}
        </Label>
      ) : null
    }

    const renderIcon = () => {
      const { Icon } = props.styled!
      return file ? (
        <Icon
          src={file.blob}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'icon')}
        />
      ) : null
    }

    const renderInput = () => {
      const { Input } = props.styled!
      return (
        <Input
          ref={inputRef}
          type="file"
          onChange={handleChange}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'input')}
        />
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        onClick={handleClick}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderInput()}
        {renderIcon()}
        {renderLabel()}
      </Root>
    )
  },
)

Dropzone.defaultProps = {
  validations: [],
  logo: DefaultPlaceholder,
}

export default Dropzone
