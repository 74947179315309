import RadioCustomise, {
  Customisations as RadioCustomisations,
} from '../../../../themes/Developer/DevRadioField.customisations'
import type { DevRadioFieldCustomisations } from '../../../../index'

export type NativeRadioFieldCustomisations = DevRadioFieldCustomisations

export const Customisations = {
  RadioGroup: RadioCustomisations.RadioGroup,
  Field: RadioCustomisations.Field,
}

export default RadioCustomise
