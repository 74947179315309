import ListItemSecondaryActionCore, {
  ListItemSecondaryActionPublicProps,
} from '../../ListItemSecondaryAction'
import Theme from '../../../../../themes/Developer/theme'
import type { ListItemSecondaryActionContext } from '../../ListItemSecondaryAction.composition'
import { withStyle } from '../../../../../providers'
import Styles from '../../themes/Developer/DevListItemSecondaryAction.styles'

export const DevListInteractiveItemSecondaryAction = withStyle<
  ListItemSecondaryActionPublicProps,
  ListItemSecondaryActionContext
>(ListItemSecondaryActionCore, Theme, Styles, 'ListInteractiveItemSecondaryAction')

export type DevListInteractiveItemSecondaryActionProps = ListItemSecondaryActionPublicProps
