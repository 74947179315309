import * as React from 'react'
import { usePopper } from 'react-popper'
import { faSolidQuestionCircle, IconDefinition } from '../utils/fontawesome'
import Portal from '../Portal/Portal'
import useResponsive from '../hooks/useResponsive'
import useHover from '../hooks/useHover'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'
import type { StyledProps } from '../providers'
import type { TooltipContext } from './Tooltip.composition'

export interface TooltipPublicProps {
  /**
   * @ignore
   */
  className?: string
  /**
   * Icon of the tooltip
   */
  icon?: IconDefinition
  /**
   * Tooltip message.
   */
  message?: string | React.ReactNode
  /**
   * Placement of the tooltip
   */
  placement?:
    | 'top'
    | 'right'
    | 'left'
    | 'bottom'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
  /**
   * True to display the arrow, false otherwise
   */
  withArrow?: boolean
  /**
   * Max width of the tooltip
   */
  maxWidth?: number
  /**
   * The stack order of an element
   */
  zIndex?: number
  /**
   * Message to display in the tooltip
   */
  children?: React.ReactNode
}

export interface TooltipProps extends TooltipPublicProps, StyledProps<TooltipContext> {}

export interface TooltipCoreStyle {
  placement: string
  withArrow?: boolean
  maxWidth: number
  zIndex?: number
}

const Tooltip = React.forwardRef((props: TooltipProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const [iconRef, hovered] = useHover<HTMLDivElement>()

  const [popperElement, setPopperElement] = React.useState<any>(null)
  const [arrowElement, setArrowElement] = React.useState<any>(null)

  const { styles, attributes } = usePopper(iconRef.current, popperElement, {
    placement: props.placement!,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
          padding: 8,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  })

  const getCoreStyle = (): TooltipCoreStyle => ({
    placement: props.placement!,
    withArrow: props.withArrow,
    maxWidth: props.maxWidth!,
    zIndex: props.zIndex,
  })

  const renderIcon = () => {
    const children = React.Children.toArray(props.children)
    if (children.length > 0) {
      const child: any = children[0]
      return React.cloneElement(child, {
        ...child.props,
        ref: iconRef,
      })
    }

    const { Icon } = props.styled!
    return (
      <Icon
        ref={iconRef}
        src={props.icon || faSolidQuestionCircle}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'icon')}
      />
    )
  }

  const renderArrow = () => {
    const { Arrow } = props.styled!
    return props.withArrow ? (
      <Arrow
        ref={setArrowElement}
        style={styles.arrow}
        data-popper-arrow
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'arrow')}
      />
    ) : null
  }

  const renderMessage = () => {
    const { Message } = props.styled!
    return hovered ? (
      <Portal>
        <Message
          ref={setPopperElement}
          role="tooltip"
          style={styles.popper}
          {...attributes.popper}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'message')}
        >
          {props.message}
          {renderArrow()}
        </Message>
      </Portal>
    ) : null
  }

  const { Root } = props.styled!
  return (
    <Root
      ref={ref}
      className={props.className}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {renderIcon()}
      {renderMessage()}
    </Root>
  )
})

Tooltip.defaultProps = {
  placement: 'bottom',
  withArrow: true,
  maxWidth: 300,
}

export default Tooltip
