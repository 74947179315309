import ListSubheaderCore, { ListSubheaderPublicProps } from '../../ListSubheader'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Customisations from '../../themes/Developer/DevListSubheader.customisations'
import type { ListSubheaderContext } from '../../ListSubheader.composition'
import type { ListSubheaderCustomisations } from '../../themes/Developer/customisations'
import Styles from '../../themes/Developer/DevListSubheader.styles'

export const DevListStaticSubheader = withCustomisedStyle<
  ListSubheaderPublicProps,
  ListSubheaderContext,
  ListSubheaderCustomisations
>(ListSubheaderCore, Theme, Styles, Customisations, 'ListStaticSubheader')

export type DevListStaticSubheaderCustomisations = ListSubheaderCustomisations
export type DevListStaticSubheaderProps = ListSubheaderPublicProps & ListSubheaderCustomisations
