import Checkbox, { CheckboxPublicProps } from '../../Checkbox'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevCheckbox.styles'
import Customisations from './DevCheckbox.customisations'
import type { CheckboxContext } from '../../Checkbox.composition'
import type { CheckboxCustomisations } from './customisations'

export const DevCheckbox = withCustomisedStyle<
  CheckboxPublicProps,
  CheckboxContext,
  CheckboxCustomisations
>(Checkbox, Theme, Styles, Customisations, 'Checkbox')

export type DevCheckboxCustomisations = CheckboxCustomisations
export type DevCheckboxProps = CheckboxPublicProps & CheckboxCustomisations
