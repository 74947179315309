import StepLabel, { StepLabelPublicProps } from '../../StepLabel'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevStepLabel.styles'
import Customisations from './DevStepLabel.customisations'
import type { StepLabelContext } from '../../StepLabel.composition'
import type { StepLabelCustomisations } from './customisations'

export const DevStepLabel = withCustomisedStyle<
  StepLabelPublicProps,
  StepLabelContext,
  StepLabelCustomisations
>(StepLabel, Theme, Styles, Customisations, 'StepLabel')

export type DevStepLabelCustomisations = StepLabelCustomisations
export type DevStepLabelProps = StepLabelPublicProps & StepLabelCustomisations
