import type { SwitchPublicProps } from '../../Switch'
import type { SwitchCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: SwitchPublicProps & SwitchCustomisations): SwitchCustomisations => ({
  Label: props.Label || {},
  Track: props.Track || {},
  Thumb: props.Thumb || {},
})
