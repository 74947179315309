import type { ListInteractiveItemPublicProps } from '../../ListInteractiveItem'
import type { ListInteractiveItemCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: ListInteractiveItemPublicProps & ListInteractiveItemCustomisations,
): ListInteractiveItemCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
  hoverBgColour: props.hoverBgColour,
  selectedBgColour: props.selectedBgColour,
  padding: props.padding,
})
