import type { TableRowPublicProps } from '../../TableRow'
import type { TableRowCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: TableRowPublicProps & TableRowCustomisations): TableRowCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
  hoverBgColour: props.hoverBgColour,
})
