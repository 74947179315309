import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../Icon/components/FontAwesomeIcon/index'
import type { RadioCoreStyle } from '../../Radio'
import type { RadioContext } from '../../Radio.composition'
import type { StyleProps } from '../../../types'
import { getBgColour, getBorder, getColour, RadioCustomisations } from './customisations'
import { Customisations } from './DevRadio.customisations'
import { getFontSize, getHeight, getLineHeight } from './DevRadio.utils'
import { DisabledState, ReadOnlyState } from '../../../themes/Developer/styles'
import * as themeStyles from '../../../themes/Developer/styles'

export type RadioStyleProps = StyleProps<RadioCoreStyle, RadioCustomisations> & ThemeProps

const Icon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<RadioStyleProps>`
  font-size: ${(props) => `calc(${getFontSize(props)} - 2px)`};
  padding-right: ${(props) => props.theme.spaces.xs};
`

const Label = styled.label<RadioStyleProps>`
  box-sizing: border-box;
  font-size: inherit;
  color: inherit;
  font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.regular};
  line-height: ${(props) => getLineHeight(props)};
`

const Input = styled.input<RadioStyleProps>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const Root = styled.div<RadioStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  padding: 0px ${(props) => props.theme.spaces.xs};

  /** Colour */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};

  /** Visual */
  height: ${(props) => getHeight(props)};
  border: ${(props) => getBorder(props)};

  /** State */
  ${(props) => props.styleProps.disabled && DisabledState}
  ${(props) => props.styleProps.readOnly && ReadOnlyState}

  * {
    cursor: inherit !important;
  }
`

const Components: RadioContext = {
  Root,
  Input,
  Icon,
  Label,
}

export default Components
