import TableSortLabel, { TableSortLabelPublicProps } from '../../TableSortLabel'
import Theme from '../../../../../themes/Developer/theme'
import Styles from './DevTableSortLabel.styles'
import Customisations from './DevTableSortLabel.customisations'
import { withCustomisedStyle } from '../../../../../providers'
import type { TableSortLabelContext } from '../../TableSortLabel.composition'
import type { TableSortLabelCustomisations } from './customisations'

export const DevTableSortLabel = withCustomisedStyle<
  TableSortLabelPublicProps,
  TableSortLabelContext,
  TableSortLabelCustomisations
>(TableSortLabel, Theme, Styles, Customisations, 'TableSortLabel')

export type DevTableSortLabelCustomisations = TableSortLabelCustomisations
export type DevTableSortLabelProps = TableSortLabelPublicProps & TableSortLabelCustomisations
