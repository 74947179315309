import RadioGroup, { RadioGroupPublicProps } from '../../RadioGroup'
import Theme from '../../../themes/Developer/theme'
import type { RadioGroupContext } from '../../RadioGroup.composition'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevRadioGroup.styles'
import Customisations, {
  DevRadioGroupCustomisations as RadioGroupCustomisations,
} from './DevRadioGroup.customisations'

export const DevRadioGroup = withCustomisedStyle<
  RadioGroupPublicProps,
  RadioGroupContext,
  RadioGroupCustomisations
>(RadioGroup, Theme, Styles, Customisations, 'RadioGroup')

export type DevRadioGroupCustomisations = RadioGroupCustomisations
export type DevRadioGroupProps = RadioGroupPublicProps & RadioGroupCustomisations
