import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { StepLabelStyleProps } from '../DevStepLabel.styles'

const invalidColour = (props: StepLabelStyleProps) => props.theme.colours.tertiary.semantic.red

/** Colour */
export const getColour = (props: StepLabelStyleProps) => {
  const currentState = theme.getState(props.styleProps)

  if (props.styleProps.active) {
    if (props.styleProps.invalid) {
      return theme.getColour(props, invalidColour(props), 'invalid')
    }

    return props.customisations.activeColour || props.theme.colours.tertiary.neutral.charcoal
  }

  if (props.styleProps.completed) {
    if (props.styleProps.invalid) {
      return theme.getColour(props, invalidColour(props), 'invalid')
    }

    return props.customisations.completeColour || props.theme.colours.tertiary.semantic.green
  }

  return theme.getColour(props, props.theme.colours.tints.charcoal[50], currentState)
}
