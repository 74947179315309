import SingleImmediatePayment from "./SingleImmediatePayment";
import SingleImmediatePaymentAction from "./SingleImmediatePaymentAction";
import SingleImmediatePaymentSubmitActionResult from "./SingleImmediatePaymentSubmitActionResult";

class SingleImmediatePaymentService {
  async getPayment(
    id: string,
    token: string | null
  ): Promise<SingleImmediatePayment | null> {
        const response = await fetch(`/api/single-immediate-payments/${id}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        switch (response.status) {
          case 200:
                return response.json();
          case 404:
                return null;
            default:
                throw new Error(response.statusText);
        }
  }

  async submitPayment(
    id: string,
    action: SingleImmediatePaymentAction,
    token: string | null,
    executionDelayInSeconds: number = 0,
    settlementDelayInSeconds: number = 0,
    redirect: boolean = false
  ): Promise<SingleImmediatePaymentSubmitActionResult> {
    const response = await fetch(
      `/api/single-immediate-payments/${id}/action`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          action,
          redirect,
          execution_delay_in_seconds: executionDelayInSeconds,
          settlement_delay_in_seconds: settlementDelayInSeconds
        })
      }
    );
    switch (response.status) {
      case 202:
        return { type: "Accepted", return_uri: await response.text() };
      case 409:
        return { type: "AlreadyActioned", return_uri: await response.text() };
      default:
        throw new Error(response.statusText);
    }
  }
}

const singleImmediatePaymentService = new SingleImmediatePaymentService();

export default singleImmediatePaymentService;
