import * as devicesDetect from 'react-device-detect'
import { includes, union, sortBy, zipObject } from './utils/lodash'

export type Device = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const DEVICES = ['xs', 'sm', 'md', 'lg', 'xl']

export const defaultDevices: Devices = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1400,
}

export interface Devices {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

export const getDevices = (props: any) => {
  const customBreakpoints = {
    ...defaultDevices,
    ...props.breakpoints,
  }
  const sorted = sortBy(Object.keys(customBreakpoints), (x: string) => +customBreakpoints[x])
  return zipObject(
    sorted,
    sorted.map((x: any) => customBreakpoints[x]),
  )
}

export const getBreakpointValue = (breakpoints: any, device?: string) => {
  if (!device) {
    return 0
  }

  const value = breakpoints[device]
  if (value) {
    return value
  }

  const keys = Object.keys(breakpoints)
  let i = keys.indexOf(device) !== -1 ? keys.indexOf(device) : keys.length
  for (; i >= 0; i -= 1) {
    const key = keys[i]
    if (breakpoints[key]) {
      return breakpoints[key]
    }
  }

  return 0
}

export const getBreakpoints = (props: any) => {
  const customBreakpoints = {
    ...defaultDevices,
    ...props.breakpoints,
  }
  return sortBy(Object.keys(customBreakpoints), (x: string) => +customBreakpoints[x])
}

export const getBreakpoint = (props: any, device?: string) => {
  const devices = getDevices(props)
  const breakpoints = Object.keys(devices)

  const currentDevice = device || props.device
  let i = currentDevice ? breakpoints.indexOf(currentDevice) : breakpoints.length

  for (; i >= 0; i -= 1) {
    const key = breakpoints[i]
    if (props[key] !== undefined) {
      return key
    }
  }

  return 'xs'
}

export const isShortVersion = (device: string, shortBreakpoints?: string[]) => {
  const customBreakpoints = union(['xs', 'sm'], shortBreakpoints || [])
  return includes(customBreakpoints, device)
}

export const isBreakpointUpTo = (props: any, current: string, breakpoint: string) => {
  const devices = getDevices(props)
  const breakpoints = Object.keys(devices)
  return breakpoints.indexOf(current) < breakpoints.indexOf(breakpoint)
}

export const isBreakpointLowerThan = (props: any, current: string, breakpoint: string) => {
  const devices = getDevices(props)
  const breakpoints = Object.keys(devices)
  return breakpoints.indexOf(current) < breakpoints.indexOf(breakpoint)
}

export const isBreakpointGreaterOrEqualThan = (props: any, current: string, breakpoint: string) => {
  const devices = getDevices(props)
  const breakpoints = Object.keys(devices)
  return breakpoints.indexOf(current) >= breakpoints.indexOf(breakpoint)
}

export const { isMobile } = devicesDetect
