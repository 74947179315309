import Radio, { RadioPublicProps } from '../../Radio'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Customisations from './DevRadio.customisations'
import type { RadioContext } from '../../Radio.composition'
import type { RadioCustomisations } from './customisations'

import Styles from './DevRadio.styles'

export const DevRadio = withCustomisedStyle<RadioPublicProps, RadioContext, RadioCustomisations>(
  Radio,
  Theme,
  Styles,
  Customisations,
  'Radio',
)

export type DevRadioCustomisations = RadioCustomisations
export type DevRadioProps = RadioPublicProps & RadioCustomisations
