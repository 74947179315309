import type { TooltipStyleProps } from '../DevTooltip.styles'
import * as theme from '../../../../themes/Developer/proxima-nova'

/** Colour */
export const getColour = (props: TooltipStyleProps) =>
  theme.getColour(
    props,
    props.theme.colours.tertiary.neutral.white,
    theme.getState(props.styleProps),
  )

/** Background colour */
export const getBgColour = (props: TooltipStyleProps) =>
  theme.getBgColour(
    props,
    props.theme.colours.tints.charcoal[100],
    theme.getState(props.styleProps),
  )

/** Border */
/** Colour */
export const defaultBorderColour = (props: TooltipStyleProps) => ({
  colour: props.theme.colours.tints.charcoal[100],
})

/** Icon */
export const defaultIconColour = (props: TooltipStyleProps) => ({
  colour: props.theme.colours.tertiary.neutral.grey,
  hoverColour: props.theme.colours.tints.sun[80],
  focusColour: props.theme.colours.tertiary.neutral.grey,
})

export const getIconColour = (props: TooltipStyleProps, state?: theme.State) => {
  const customisations = theme.getCustomisations(props, 'Icon')
  return theme.getColour(customisations, defaultIconColour(props), state)
}

export const defaultIconBorderColour = (props: TooltipStyleProps) => ({
  colour: 'transparent',
  focusColour: props.theme.colours.tints.azure[80],
})

export const getIconBorderColour = (props: TooltipStyleProps, state?: theme.State) => {
  const customisations = theme.getCustomisations(props, 'Icon')
  return theme.getBorderColour(customisations, defaultIconBorderColour(props), state)
}
