import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import type { IconCoreStyle } from '../../Icon'
import type { IconContext } from '../../Icon.composition'
import type { StyleProps } from '../../../types'
import {
  getBorder,
  getBorderColour,
  getBorderRadius,
  getPadding,
  getBgColour,
} from './customisations'
import { DisabledState, ReadOnlyState } from '../../../themes/Developer/styles'
import { getSize } from './DevIcon.utils'

export type IconStyleProps = StyleProps<IconCoreStyle> & ThemeProps

const Clickable = css<IconStyleProps>`
  cursor: pointer;
  border: none;
  outline: none;
`

const FitContainer = (Components: IconContext) => css<IconStyleProps>`
  width: inherit;
  height: inherit;
`

const FocusRoot = css<IconStyleProps>`
  :focus {
    border-color: ${(props) => getBorderColour(props, 'focus')};
  }
`

const Image = styled.img<IconStyleProps>`
  color: inherit;
  background-color: inherit;
  cursor: inherit !important;
  width: ${(props) => getSize(props)}px;
  height: ${(props) => getSize(props)}px;
`

const ImageContainer = styled.div<IconStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Root = styled.div<IconStyleProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => getBgColour(props)};
  cursor: default;

  /** Visuals */
  border: ${(props) => getBorder(props)};
  border-radius: ${(props) => getBorderRadius(props)};

  /** Spacing */
  padding: ${(props) => getPadding(props)};

  /** States */
  ${FocusRoot}
  ${(props) => props.styleProps.disabled && DisabledState};
  ${(props) => props.styleProps.readOnly && ReadOnlyState};
  ${(props) => props.styleProps.isClickable && Clickable}
  ${(props) => props.styleProps.mustFit && FitContainer(Components)};
`

const Components: IconContext = {
  Root,
  ImageContainer,
  Image,
}

export default Components
