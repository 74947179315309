import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { StepIconStyleProps } from '../DevStepIcon.styles'

const invalidColour = (props: StepIconStyleProps) => props.theme.colours.tertiary.semantic.red

/** Circle */
/** Colour */
export const getCircleColour = (props: StepIconStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Circle')
  const currentState = theme.getState(props.styleProps)

  if (props.styleProps.active) {
    if (props.styleProps.invalid) {
      return theme.getColour(customisations, invalidColour(props), 'invalid')
    }

    return customisations.customisations.activeColour || props.theme.colours.tints.cloudBurst[100]
  }

  if (props.styleProps.completed) {
    if (props.styleProps.invalid) {
      return theme.getColour(customisations, invalidColour(props), 'invalid')
    }

    return (
      customisations.customisations.completeColour || props.theme.colours.tertiary.semantic.green
    )
  }

  return theme.getColour(customisations, props.theme.colours.tints.cloudBurst[30], currentState)
}

/** Text */
/** Colour */
export const getTextColour = (props: StepIconStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Text'),
    props.theme.colours.tertiary.neutral.white,
    theme.getState(props.styleProps),
  )
