import type { MenuItemPublicProps } from '../../MenuItem'
import type { MenuItemCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: MenuItemPublicProps & MenuItemCustomisations): MenuItemCustomisations => ({
  colour: props.colour,
  hoverColour: props.hoverColour,
  focusColour: props.focusColour,
  activeColour: props.activeColour,
  selectedColour: props.selectedColour,
  hoverSelectedColour: props.hoverSelectedColour,
  focusSelectedColour: props.focusSelectedColour,
  bgColour: props.bgColour,
  hoverBgColour: props.hoverBgColour,
  focusBgColour: props.focusBgColour,
  activeBgColour: props.activeBgColour,
  selectedBgColour: props.selectedBgColour,
  hoverSelectedBgColour: props.hoverSelectedBgColour,
  focusSelectedBgColour: props.focusSelectedBgColour,
  border: props.border,
  borderBottom: props.borderBottom,
  borderTop: props.borderTop,
  borderRight: props.borderRight,
  borderLeft: props.borderLeft,
  padding: props.padding,
})
