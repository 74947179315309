import * as React from 'react'
import type { IconContext } from './Icon.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { AriaAttributesPrefix, DataAttributesPrefix } from '../constants'
import { checkImageFeature } from './Icon.checks'
import { attachIf } from '../utils/event-handler'
import { getTestId } from '../utils/test'

export interface IconPublicProps extends React.AriaAttributes {
  /**
   * Icon to display
   */
  src?: string
  /**
   * Alternative information for the image
   */
  alt?: string
  /**
   * Icon size to display
   */
  size?: IconSizes | number
  /**
   * True if the icon has to fit the container
   */
  mustFit?: boolean
  /**
   * True if the icon is disabled, false otherwise
   */
  disabled?: boolean
  /**
   * It prevents the user from clicking on the icon if it is a clickable icon
   */
  readOnly?: boolean
  /**
   * -1 if the icon is not keyboard accessible, index in the sequential keyboard navigation otherwise
   */
  tabIndex?: number
  /**
   * Handler when the icon is clicked
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * Handler when the icon is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the icon is not hovered
   */
  onLeave?: (event?: React.MouseEvent) => void
  /**
   * Component to display as icon
   */
  children?: React.ReactNode
}

type IconSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type IconProps = IconPublicProps & StyledProps<IconContext>

export interface IconCoreStyle {
  isClickable: boolean
  size: string | number
  mustFit: boolean
  disabled: boolean
  readOnly: boolean
}

export const Icon = React.forwardRef((props: IconProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const isClickable = props.onClick
  const isActionable = !props.readOnly && !props.disabled

  checkImageFeature(props)

  const getCoreStyle = (): IconCoreStyle => ({
    isClickable: props.onClick !== undefined,
    size: props.size!,
    mustFit: props.mustFit!,
    disabled: !!props.disabled!,
    readOnly: props.readOnly,
  })

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (props.onClick) {
      props.onClick(event)
    }
  }

  const renderImage = () => {
    if (props.children) {
      return props.children
    }

    const { Image } = props.styled!
    return props.src ? (
      <Image
        src={props.src}
        alt={props.alt}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'image')}
      />
    ) : null
  }

  const tag = isClickable ? 'button' : 'div'

  const buttonProperties = isClickable
    ? {
        tabIndex: props.tabIndex,
        disabled: props.disabled,
        'aria-disabled': props.disabled,
        onClick: attachIf(handleClick, isActionable),
      }
    : {}

  const { ImageContainer, Root } = props.styled!
  return (
    <Root
      className={props.className}
      ref={ref}
      as={tag}
      role="img"
      onMouseEnter={attachIf(props.onHover, isActionable)}
      onMouseLeave={attachIf(props.onLeave, isActionable)}
      {...buttonProperties}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
      {...getAttributes(props, AriaAttributesPrefix)}
    >
      <ImageContainer
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'container')}
      >
        {renderImage()}
      </ImageContainer>
    </Root>
  )
})

Icon.defaultProps = {
  size: 'sm',
  tabIndex: 0,
  disabled: false,
  readOnly: false,
}

export default Icon
