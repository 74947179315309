import * as React from 'react'

import useResponsive from '../../../../../hooks/useResponsive'
import { StyledProps } from '../../../../../providers'
import { ListItemContext } from '../../ListItem.composition'
import { attachIf } from '../../../../../utils/event-handler'
import ListItemBase, { ListItemPublicProps } from '../../ListItem'

export interface ListInteractiveItemPublicProps extends ListItemPublicProps {
  /**
   * True if the item is selected, false otherwise.
   */
  selected?: boolean
  /**
   * True if the item is disabled, false otherwise.
   */
  disabled?: boolean
  /**
   * Handler when the item loses the focus.
   */
  onBlur?: (event: React.FocusEvent) => void
  /**
   * Handler when the item gets the focus.
   */
  onFocus?: (event: React.FocusEvent) => void
  /**
   * Handler when the item is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the item is not hovered
   */
  onLeave?: (event?: React.MouseEvent) => void
  /**
   * Handler when the user clicks on the item.
   */
  onClick?: () => void
}

export interface ListInteractiveItemProps
  extends ListInteractiveItemPublicProps,
    StyledProps<ListItemContext> {}

export interface ListInteractiveItemCoreStyle {
  selected: boolean
  disabled: boolean
}

const ListInteractiveItem = React.forwardRef(
  (props: ListInteractiveItemProps, forwardRef: React.Ref<HTMLLIElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const isClickable = !props.disabled

    const getCoreStyle = (): ListInteractiveItemCoreStyle => ({
      selected: props.selected!,
      disabled: props.disabled!,
    })

    const handleKeyUp = (event: React.KeyboardEvent<HTMLLIElement>) => {
      if (event.key === 'Enter') {
        if (props.onClick) {
          props.onClick()
        }
      }
    }

    return (
      <ListItemBase
        ref={ref}
        as="li"
        rootProps={{
          tabIndex: 0,
          onFocus: attachIf(props.onFocus, isClickable),
          onBlur: attachIf(props.onBlur, isClickable),
          onMouseEnter: attachIf(props.onHover, isClickable),
          onMouseLeave: attachIf(props.onLeave, isClickable),
          onClick: attachIf(props.onClick, isClickable),
          onKeyUp: handleKeyUp,
        }}
        {...props}
        styleProps={getCoreStyle()}
      />
    )
  },
)

ListInteractiveItem.defaultProps = {
  selected: false,
  disabled: false,
}

export default ListInteractiveItem
