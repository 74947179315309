export const sizes = {
  small: 35,
  medium: 53,
  large: 84,
}

export const thickness = {
  small: 1,
  medium: 4,
  large: 7,
}
