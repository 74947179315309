import * as React from 'react'
import { ThemeProvider } from 'styled-components'

/**
 * Interface used by the based component
 */
export interface StyledProps<S, C extends any = unknown> {
  /**
   * Styled Component className to plug to the Root element of the component to be able to override its style
   */
  className?: string
  /**
   * Styled Component(s) used by the component to build its representation
   */
  styled?: S
  /**
   * Customisations used by the component
   */
  customisations?: C
}

/**
 * Attached the styled components + the customisations to the base component
 * @param Component Base component
 * @param Theme Theme used by the component
 * @param Components Styled components to inject in the base component
 * @param Customisations Customisations to inject in the base component
 * @param name Name of the component
 * @returns A new instance of Component with 2 new properties: styled + customisations.
 * The contract of that component will be P + C which means Public properties + Customisations properties
 */
export function withCustomisedStyle<P, S, C extends any = unknown>(
  Component:
    | React.ComponentClass<any>
    | React.FunctionComponent<any>
    | React.ForwardRefExoticComponent<any>,
  Theme: any,
  Components: S,
  Customisations?: (props: P & C) => C,
  name?: string,
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<P & C> & React.RefAttributes<HTMLElement>
> & { className?: string } {
  const component = React.forwardRef<HTMLElement, P & C>((props, forwardRef) => {
    const customisations = Customisations ? Customisations(props) : {}

    return (
      <ThemeProvider theme={Theme}>
        <Component
          ref={forwardRef}
          {...props}
          styled={Components}
          customisations={customisations}
        />
      </ThemeProvider>
    )
  })

  component.displayName =
    name || Component.displayName || Object.getPrototypeOf(Component).constructor.name

  return component
}

/**
 * Attached the styled components + the customisations to the base component
 * @param Component Base component
 * @param Components Styled components to inject in the base component
 * @param name Name of the component
 * @returns A new instance of Component with 1 new property: styled
 * The contract of that component will be P + C which means Public properties + Customisations properties
 */
export function withStyle<P, S, C extends any = unknown>(
  Component:
    | React.ComponentClass<any>
    | React.FunctionComponent<any>
    | React.ForwardRefExoticComponent<any>,
  Theme: any,
  Components: S,
  name?: string,
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<P & C> & React.PropsWithRef<unknown> & { className?: string }
> {
  const component = React.forwardRef<HTMLElement, P & C>((props, forwardRef) => (
    <ThemeProvider theme={Theme}>
      <Component ref={forwardRef} {...props} styled={Components} />
    </ThemeProvider>
  ))

  component.displayName =
    name || Component.displayName || Object.getPrototypeOf(Component).constructor.name

  return component
}
