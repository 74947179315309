import * as React from 'react'

import { find, forEach, isEmpty, isEqual, keys } from '../utils/lodash'

interface FormInProps {
  ref?: React.Ref<HTMLFormElement>
  values?: any
  validations?: any
  validationType?: 'onSubmit' | 'onBlur' | 'onChange'
  onSubmit?: (values: any) => void
}

interface FormOutProps {
  getAll: () => any
  get: (key: string) => any
  getError: (key: string) => string
  isValid: (key: string) => boolean
  update: (key: string, value: any) => void
  remove: (key: string) => void

  onChange: (key: string, value: any) => void
  onBlur: (key: string, value: any) => void
  onSubmit: () => void
  onReset: () => void
}

const useForm = (opts?: FormInProps): FormOutProps => {
  const defaultValues = opts?.values || {}
  const validations = opts?.validations || {}
  const validationType = opts?.validationType || 'onChange'

  const [values, setValues] = React.useState(defaultValues)
  const [dirties, setDirties] = React.useState({})
  const [errors, setErrors] = React.useState({})

  const previous = React.useRef(defaultValues)

  const isValid = (key: string): boolean => isEmpty(errors[key])

  const getError = (key: string): string => {
    const error = find(errors[key], (x: any) => !x.isValid)
    return error?.message
  }

  const getErrors = (key: string, value: any) => {
    let fieldErrors = []

    const fieldValidations = validations[key]
    if (fieldValidations) {
      fieldErrors = fieldValidations.map((validation: any) => {
        const valid = validation.isValid(value)
        return {
          isValid: valid,
          message: valid ? undefined : validation.message,
        }
      })
    }

    return fieldErrors
  }

  const update = (key: string, value: any) => {
    const state = { ...values }
    state[key] = value
    setValues(state)
  }

  const remove = (key: string) => {
    if (!Object.prototype.hasOwnProperty.call(values, key)) {
      return
    }

    const state = { ...values }
    delete state[key]
    setValues(state)
  }

  const validate = (key: string, value: any) => {
    const fieldErrors = getErrors(key, value).filter((error: any) => dirties[key] && error.message)

    if (fieldErrors.length > 0) {
      const state = { ...errors, [key]: fieldErrors }
      setErrors(state)
    } else if (Object.prototype.hasOwnProperty.call(errors, key)) {
      const state = { ...errors }
      delete state[key]
      setErrors(state)
    }
  }

  const validateAll = (valuesToValidate?: any) => {
    const currentValues = valuesToValidate || values
    const names = keys(currentValues)

    const state = {}

    forEach(names, (name: string) => {
      const fieldErrors = getErrors(name, currentValues[name]).filter((error: any) => error.message)

      if (fieldErrors.length > 0) {
        state[name] = fieldErrors
      }
    })

    setErrors(state)
  }

  const getAll = () => {
    if (validationType === 'onSubmit') {
      validateAll()
    }

    return { ...values }
  }

  const get = (key: string) => values[key]

  const onChange = (key: string, value: any) => {
    if (isEqual(previous[key], value)) {
      return
    }

    if (validationType === 'onChange') {
      validate(key, value)
    }

    // Flag the value as dirty if it is not already
    if (!dirties[key]) {
      const state = { ...dirties }
      state[key] = true
      setValues(state)
    }

    update(key, value)
  }

  const onBlur = (key: string, value: any) => {
    if (validationType === 'onBlur') {
      validate(key, value)
    }
  }

  const onSubmit = () => {
    validateAll()

    if (opts?.onSubmit) {
      opts?.onSubmit(getAll())
    }
  }

  const onReset = () => {
    const elementRef: any = opts?.ref
    if (elementRef && elementRef.current) {
      elementRef.current!.reset()
    }

    setValues(defaultValues)
    setErrors({})
    setDirties({})
  }

  return {
    get,
    getAll,
    getError,
    isValid,
    update,
    remove,
    onChange,
    onBlur,
    onSubmit,
    onReset,
  }
}

export default useForm
