import ListItemAvatarCore, { ListItemAvatarPublicProps } from '../../../../ListItemAvatar'
import Theme from '../../../../../../../themes/Developer/theme'
import { withStyle } from '../../../../../../../providers'
import type { ListItemAvatarContext } from '../../../../ListItemAvatar.composition'
import Styles from '../../../../themes/Developer/DevListItemAvatar.styles'

export const DevListInteractiveItemAvatar = withStyle<
  ListItemAvatarPublicProps,
  ListItemAvatarContext
>(ListItemAvatarCore, Theme, Styles, 'ListInteractiveItemAvatar')

export type DevListInteractiveItemAvatarProps = ListItemAvatarPublicProps
