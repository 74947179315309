import * as React from 'react'
import * as PropTypes from 'prop-types'
import type { LogoContext } from './Logo.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

export interface LogoPublicProps {
  /**
   * Logo to display
   */
  src?: string
  /**
   * Handler when the icon is clicked
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * Component to display as logo
   */
  children?: React.ReactNode
}

export interface LogoProps extends LogoPublicProps, StyledProps<LogoContext> {}

export interface LogoCoreStyle {
  isClickable: boolean
}

export const Logo = React.forwardRef((props: LogoProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const getCoreStyle = () => ({
    isClickable: typeof props.onClick !== 'undefined',
  })

  const renderImage = () => {
    if (props.children) {
      return props.children
    }

    const { Image } = props.styled!
    return props.src ? (
      <Image
        src={props.src}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'image')}
      />
    ) : null
  }

  const { Root } = props.styled!
  return (
    <Root
      className={props.className}
      ref={ref}
      onClick={props.onClick}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {renderImage()}
    </Root>
  )
})

Logo.propTypes = {
  src: PropTypes.string,
}

export default Logo
