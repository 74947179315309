import type { NativeCheckboxStyleProps } from '../DevNativeCheckbox.styles'
import * as theme from '../../../../../../themes/Developer/proxima-nova'
import { getBorderColour } from './colours'

const TickSize = {
  small: 12,
  medium: 16,
  large: 20,
}

/** Tick */
/* Border */
export const getTickBorder = (props: NativeCheckboxStyleProps) => {
  const customisations: any = theme.getCustomisations(props, 'Tick')
  return theme.getBorder(
    customisations,
    getBorderColour(props),
    'border',
    theme.getState(props.styleProps),
  )
}

/** Height */
export const getTickHeight = (props: NativeCheckboxStyleProps) =>
  `${TickSize[props.styleProps.size]}px`
