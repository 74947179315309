import { defaultBorderColour, defaultIconBorderColour } from './colours'
import type { TooltipStyleProps } from '../DevTooltip.styles'
import * as theme from '../../../../themes/Developer/proxima-nova'

export const getBorder = (props: TooltipStyleProps) =>
  theme.getBorder(props, defaultBorderColour(props))

export const getIconBorder = (props: TooltipStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Icon.border')

  return theme.getBorder(
    customisations,
    defaultIconBorderColour(props),
    'border',
    theme.getState(props.styleProps),
  )
}

export const getIconBorderRadius = (props: TooltipStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Icon')

  /**
   * TODO:
   * `props.theme.visuals.borderRadius` is a simple string/number.
   * This needs to be made an object with different sizes like `circular`, `rounded`
   */
  return theme.getBorderRadius(customisations, props.theme.spaces.xxl)
}
