import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import type { PaperCoreStyle } from '../../Paper'
import type { PaperContext } from '../../Paper.composition'
import type { StyleProps } from '../../../types'
import { getColour, PaperCustomisations } from './customisations'
import shadows from '../../Paper.utils'
import { includes } from '../../../utils/lodash'

export type PaperStyleProps = StyleProps<PaperCoreStyle, PaperCustomisations> & ThemeProps

const isOutlined = (props: PaperStyleProps) =>
  includes(['outlined', 'outlinedElevated'], props.styleProps.variant)
const isElevation = (props: PaperStyleProps) =>
  includes(['elevation', 'outlinedElevated'], props.styleProps.variant)

const OutlinedRoot = css<PaperStyleProps>`
  border: ${(props) => props.theme.visuals.borderSize} solid ${(props) => getColour(props)};
`

const ElevationRoot = css<PaperStyleProps>`
  box-shadow: ${(props) => shadows(getColour(props))[props.styleProps.elevation]};
`

const SquareRoot = css<PaperStyleProps>`
  border-radius: 0;
`

const Root = styled.div<PaperStyleProps>`
  background-color: ${(props) => props.theme.colours.tertiary.neutral.white};
  border-radius: ${(props) => props.theme.visuals.borderRadius};
  box-sizing: border-box;
  overflow: hidden;

  ${(props) => props.styleProps.square && SquareRoot};
  ${(props) => isOutlined(props) && OutlinedRoot};
  ${(props) => isElevation(props) && ElevationRoot};
`

const Components: PaperContext = {
  Root,
}

export default Components
