import Separator from '../../Separator'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevSeparator.styles'
import Customisations from './DevSeparator.customisations'
import type { SeparatorContext } from '../../Separator.composition'
import type { SeparatorCustomisations } from './customisations'

export const DevSeparator = withCustomisedStyle<unknown, SeparatorContext, SeparatorCustomisations>(
  Separator,
  Theme,
  Styles,
  Customisations,
  'Separator',
)

export type DevSeparatorCustomisations = SeparatorCustomisations
export type DevSeparatorProps = unknown & SeparatorCustomisations
