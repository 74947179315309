import * as theme from '../../../../../../../../themes/Developer/proxima-nova'
import type { ListInteractiveItemStyleProps } from '../DevListInteractiveItem.styles'

/** Color */
export const getColour = (props: ListInteractiveItemStyleProps) => {
  const currentState = theme.getState(props.styleProps)
  return theme.getColour(props, props.theme.colours.tertiary.neutral.charcoal, currentState)
}

/** Background color */
const defaultBgColour = (props: ListInteractiveItemStyleProps) => ({
  bgColour: props.theme.colours.tertiary.neutral.white,
  hoverBgColour: props.theme.colours.tints.azure[10],
  focusBgColour: props.theme.colours.tints.azure[10],
  selectedBgColour: props.theme.colours.tints.azure[10],
})

export const getBgColour = (props: ListInteractiveItemStyleProps, state?: theme.State) => {
  const currentState = state || theme.getState(props.styleProps)
  return theme.getBgColour(props, defaultBgColour(props), currentState)
}
