export const reflow = (node: HTMLElement) => node.scrollTop

export const getTransitionProps = (props: any, options: any) => {
  const { timeout, style = {} } = props

  return {
    duration:
      style.transitionDuration || typeof timeout === 'number'
        ? timeout
        : timeout[options.mode] || 0,
    delay: style.transitionDelay,
  }
}

export const getAutoHeightDuration = (height: number) => {
  if (!height) {
    return 0
  }

  const constant = height / 36

  // https://www.wolframalpha.com/input/?i=(4+%2B+15+*+(x+%2F+36+)+**+0.25+%2B+(x+%2F+36)+%2F+5)+*+10
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10)
}
