import {
  faLightAngleLeft,
  faLightAngleRight,
  faLightAngleDoubleRight,
  faLightAngleDoubleLeft,
} from '../../../utils/fontawesome'

const icons = {
  previous: faLightAngleLeft,
  next: faLightAngleRight,
  last: faLightAngleDoubleRight,
  first: faLightAngleDoubleLeft,
}

export default icons
