import * as React from 'react'
import type { DialogContext } from './Dialog.composition'
import type { StyledProps } from '../providers'
import type { PaperPublicProps } from '../Paper/Paper'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

type PaperProps = Omit<PaperPublicProps, 'children' | 'role'>

export interface DialogPublicProps {
  /**
   * If `true`, clicking the backdrop will not fire the `onClose` callback.
   * @default false
   */
  disableBackdropClick?: boolean
  /**
   * If `true`, hitting escape will not fire the `onClose` callback.
   * @default false
   */
  disableEscapeKeyDown?: boolean
  /**
   * If `true`, the dialog is full-screen.
   * @default false
   */
  fullScreen?: boolean
  /**
   * If `true`, the dialog stretches to `maxWidth`.
   *
   * Notice that the dialog width grow is limited by the default margin.
   * @default false
   */
  fullWidth?: boolean
  /**
   * Max width of the Dialog
   */
  maxWidth?: number | string
  /**
   * Dialog children, usually the included sub-components.
   */
  children?: React.ReactNode
  /**
   * Callback fired when the backdrop is clicked.
   */
  onBackdropClick?: (event: React.MouseEvent) => void
  /**
   * Callback fired when the component requests to be closed.
   * The `reason` parameter can optionally be used to control the response to `onClose`.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`.
   */
  onClose?: (event: React.MouseEvent | React.KeyboardEvent, reason: string) => void
  /**
   * Callback fired when the escape key is pressed,
   * `disableEscapeKeyDown` is false and the modal is in focus.
   */
  onEscapeKeyDown?: (event: React.KeyboardEvent) => void
  /**
   * If `true`, the modal is open.
   */
  open?: boolean
  /**
   * Properties to configure the Paper component
   */
  PaperProps?: PaperProps
  /**
   * Determine the container for scrolling the dialog.
   * @default 'paper'
   */
  scroll?: 'body' | 'paper'
}

export interface DialogProps extends DialogPublicProps, StyledProps<DialogContext> {}

export interface DialogCoreStyle {
  scroll: 'body' | 'paper'
  maxWidth?: number | string
  fullWidth: boolean
  fullScreen: boolean
}

export const Dialog = React.forwardRef(
  (props: DialogProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })
    const backdropClick = React.useRef(false)

    const getCoreStyle = (): DialogCoreStyle => ({
      scroll: props.scroll!,
      maxWidth: props.maxWidth!,
      fullWidth: props.fullWidth!,
      fullScreen: props.fullScreen!,
    })

    const handleBackdropClick = (event: React.MouseEvent) => {
      // Ignore the events not coming from the "backdrop".
      if (!backdropClick.current) {
        return
      }

      backdropClick.current = null

      if (props.onBackdropClick) {
        props.onBackdropClick(event)
      }

      if (!props.disableBackdropClick && props.onClose) {
        props.onClose(event, 'backdropClick')
      }
    }

    const handleMouseDown = (event: React.MouseEvent) => {
      // We don't want to close the dialog when clicking the dialog content.
      // Make sure the event starts and ends on the same DOM element.
      backdropClick.current = event.target === event.currentTarget
    }

    const { Container, Paper, Root } = props.styled!
    return (
      <Root
        disableBackdropClick={props.disableBackdropClick}
        disableEscapeKeyDown={props.disableEscapeKeyDown}
        onEscapeKeyDown={props.onEscapeKeyDown}
        onClose={props.onClose}
        open={props.open}
        onBackdropClick={handleBackdropClick}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        <Container
          className={props.className}
          ref={ref}
          onMouseDown={handleMouseDown}
          onClick={handleBackdropClick}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'container')}
        >
          <Paper
            role="dialog"
            square={props.PaperProps.square}
            elevation={props.PaperProps.elevation}
            variant={props.PaperProps.variant}
            styleProps={getCoreStyle()}
            customisations={props.customisations}
            {...getTestId(props, 'paper')}
          >
            {props.children}
          </Paper>
        </Container>
      </Root>
    )
  },
)

Dialog.defaultProps = {
  scroll: 'paper',
  fullWidth: false,
  PaperProps: {},
}

export default Dialog
