import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { MenuItemCoreStyle } from '../../MenuItem'
import type { MenuItemContext } from '../../MenuItem.composition'
import type { StyleProps } from '../../../../../types'
import { includes } from '../../../../../utils/lodash'
import { getValueAsPixel } from '../../../../../customisations'
import {
  getBgColour,
  getBorder,
  getColour,
  getPadding,
  MenuItemCustomisations,
} from './customisations'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type MenuItemStyleProps = StyleProps<MenuItemCoreStyle, MenuItemCustomisations> & ThemeProps

const titleAsItem = (props: MenuItemStyleProps) =>
  includes(['tab', 'fly-out', 'accordion'], props.styleProps.variant)

const SelectedRoot = css<MenuItemStyleProps>`
  font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.bold};
`

const DisabledRoot = css<MenuItemStyleProps>`
  user-select: none;
  opacity: ${(props) => props.theme.visuals.opacity.normal};
  pointer-events: none;
  cursor: default;
`

const BorderRoot = css<MenuItemStyleProps>`
  border-left: ${(props) => getBorder(props, 'borderLeft')};
  border-top: ${(props) => getBorder(props, 'borderTop')};
  border-right: ${(props) => getBorder(props, 'borderRight')};
  border-bottom: ${(props) => getBorder(props, 'borderBottom')};
`

const Label = styled.span<MenuItemStyleProps>`
  display: inline;

  ${(props) =>
    !props.styleProps.root &&
    css`
      flex-grow: 1;
    `}

  ${(props) =>
    props.styleProps.startAdornment &&
    css`
      padding-left: ${props.theme.spaces.xs};
    `}

    ${(props) =>
    props.styleProps.endAdornment &&
    css`
      padding-right: ${props.theme.spaces.xs};
    `}
`

const Root = styled.a<MenuItemStyleProps>`
  display: flex;
  align-items: center;
  font-size: ${(props) => getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyS.px)};
  padding: ${(props) => getPadding(props)};
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  ${BorderRoot};

  ${(props) => props.styleProps.disabled && DisabledRoot};
  ${(props) => props.styleProps.selected && SelectedRoot};

  ${(props) =>
    !titleAsItem(props) &&
    props.styleProps.root &&
    css`
      justify-content: center;
    `}

  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: MenuItemContext = {
  Root,
  Label,
}

export default Components
