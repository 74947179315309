import { ownerDocument } from './document'

export const getInnerHeight = () => {
  const element: any = document.getElementById('docz-root')
  return element ? element.offsetHeight : window.innerHeight
}

export const ownerWindow = (node: Node | null): Window => {
  const doc = ownerDocument(node)
  return doc.defaultView || window
}
