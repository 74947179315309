/**
 * Returns the percent opacity associated to the hex colour
 * @param hex hexa of the colour
 * @param percent percent of the requested opacity i.e 10, 30, etc
 */
export const getOpaque = (hex: string, percent: number) => {
  if (hex === 'transparent') {
    return hex
  }

  if (hex.startsWith('rgba')) {
    return hex
  }

  const value = hex.replace('#', '')
  const r = parseInt(value.substring(0, 2), 16)
  const g = parseInt(value.substring(2, 4), 16)
  const b = parseInt(value.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${percent / 100})`
}
