import * as React from 'react'
import * as PropTypes from 'prop-types'

import { StyledProps } from '../providers'
import { ButtonContext } from './Button.composition'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'
import { isEmpty, includes } from '../utils/lodash'

export interface ButtonPublicProps {
  /**
   * The id of the button
   */
  id?: string
  /**
   * The label of the button
   */
  label?: string
  /**
   * The type of the button
   */
  type?: 'primary' | 'secondary' | 'tertiary'
  /**
   * The html tag used to render the button
   */
  htmlTag?: 'a' | 'button' | 'submit' | 'reset'
  /**
   * Icon to display within the button
   */
  icon?: any
  /**
   * Position of the icon within the button
   */
  iconPosition?: 'right' | 'left' | 'bottom' | 'top'
  /**
   * True if the button is in disabled state, false otherwise
   */
  disabled?: boolean
  /**
   * It prevents the user from clicking
   */
  readOnly?: boolean
  /**
   * True if the button is in error state, false otherwise
   * Use it only for primary button
   */
  invalid?: boolean
  /**
   * -1 if the input is not keyboard accessible, index in the sequential keyboard navigation otherwise
   */
  tabIndex?: number
  /**
   * True if the button will fit the content, false otherwise
   */
  fitContent?: boolean
  /**
   * Handler when the button is clicked
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * Handler when the button loses the focus.
   */
  onBlur?: (event: React.FocusEvent) => void
  /**
   * Handler when the button gets the focus.
   */
  onFocus?: (event: React.FocusEvent) => void
  /**
   * Handler when the button is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the button is not hovered
   */
  onLeave?: (event: React.MouseEvent) => void
}

export interface ButtonProps extends ButtonPublicProps, StyledProps<ButtonContext> {}

export interface ButtonCoreStyle {
  iconPosition: string
  type: string
  hasLabel: boolean
  disabled?: boolean
  animation?: string
  iconSize: string
  height: number
  invalid: boolean
  readOnly: boolean
  fitContent: boolean
}

/**
 * Button is a clickable element that allows you to trigger an action. Buttons are typically used in navigations, menus, forms, dialogues, or CTAs.
 */
const Button = React.forwardRef((props: ButtonProps, forwardRef: React.Ref<HTMLElement>) => {
  const { disabled, readOnly } = props

  const { ref, height } = useResponsive({ ref: forwardRef })

  const isClickable = !disabled && !readOnly

  const getCoreStyle = (): ButtonCoreStyle => {
    const icon = props.icon || {}
    return {
      iconPosition: icon.position || props.iconPosition!,
      type: props.type!,
      hasLabel: !isEmpty(props.label),
      disabled: props.disabled,
      invalid: props.invalid,
      animation: icon.animation,
      iconSize: icon.size || 'small',
      height: height || 0,
      readOnly: props.readOnly,
      fitContent: props.fitContent,
    }
  }

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()

    if (props.onClick) {
      props.onClick(event)
    }
  }

  const renderIcon = () => {
    const { Icon } = props.styled!
    return props.icon ? (
      <Icon
        {...props.icon}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'icon')}
      />
    ) : null
  }

  const renderLabel = () => {
    const { Label } = props.styled!
    return props.label ? (
      <Label
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'label')}
      >
        {props.label}
      </Label>
    ) : null
  }

  const rootTag: any = includes(['a'], props.htmlTag) ? props.htmlTag : 'button'

  const properties = rootTag === 'button' && {
    disabled: props.disabled,
    type: props.htmlTag,
  }

  const { Root } = props.styled!
  return (
    <Root
      className={props.className}
      ref={ref}
      id={props.id}
      as={rootTag}
      {...properties}
      onClick={isClickable ? handleClick : undefined}
      onFocus={isClickable ? props.onFocus : undefined}
      onBlur={isClickable ? props.onBlur : undefined}
      onMouseEnter={isClickable ? props.onHover : undefined}
      onMouseLeave={isClickable ? props.onLeave : undefined}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {renderLabel()}
      {renderIcon()}
    </Root>
  )
})

Button.defaultProps = {
  htmlTag: 'button',
  type: 'primary',
  iconPosition: 'right',
  disabled: false,
  invalid: false,
  readOnly: false,
  tabIndex: 0,
  fitContent: false,
}

Button.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  htmlTag: PropTypes.oneOf(['a', 'button', 'submit', 'reset']),
  icon: PropTypes.any,
  iconPosition: PropTypes.oneOf(['right', 'left', 'bottom', 'top']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  invalid: PropTypes.bool,
  tabIndex: PropTypes.number,
  fitContent: PropTypes.bool,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  onLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default Button
