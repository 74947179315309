import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import type { FormLabelCoreStyle } from '../../FormLabel'
import type { FormLabelContext } from '../../FormLabel.composition'
import { FormLabelCustomisations, getFontSize, getBgColour, getColour } from './customisations'
import * as themeStyles from '../../../themes/Developer/styles'
import { StyleProps } from '../../../types'

export type FormLabelStyleProps = StyleProps<FormLabelCoreStyle, FormLabelCustomisations> &
  ThemeProps

export const getFilledShrinkLabelTop = (props: FormLabelStyleProps) =>
  ({
    // edge-case for small size
    small: '4px',
    medium: props.theme.spaces.xs,
    large: props.theme.spaces.xs,
  }[props.styleProps.size])

const StandardLabel = css<FormLabelStyleProps>`
  text-align: start;
`

const FilledShrinkLabel = css<FormLabelStyleProps>`
  z-index: 1;
  position: absolute;
  top: ${(props) => getFilledShrinkLabelTop(props)};
`

const DefaultFilledLabel = css<FormLabelStyleProps>`
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const FilledLabel = css<FormLabelStyleProps>`
  /** Visuals */
  line-height: ${(props) => props.theme.typography.proximaNova.lineHeight.captionXxs};

  /** Spaces */
  left: ${(props) => props.theme.spaces.xs};

  ${(props) => !props.styleProps.shrink && DefaultFilledLabel}
  ${(props) => props.styleProps.shrink && FilledShrinkLabel}
`

const OutlinedLabelPadding = css<FormLabelStyleProps>`
  padding-left: ${(props) => props.theme.spaces.xxs};
  padding-right: ${(props) => props.theme.spaces.xxs};
`

const OutlinedShrinkLabel = css<FormLabelStyleProps>`
  z-index: 1;
  position: absolute;
  left: ${(props) => props.theme.spaces.xxs};
  top: 0;
  transform: translateY(-50%);

  ${OutlinedLabelPadding}
`

const DefaultOutlinedLabel = css<FormLabelStyleProps>`
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const OutlinedLabel = css<FormLabelStyleProps>`
  /** Spaces */
  left: ${(props) => props.theme.spaces.xs};

  ${(props) => !props.styleProps.shrink && DefaultOutlinedLabel};
  ${(props) => props.styleProps.shrink && OutlinedShrinkLabel};
`

const RequiredIndicator = styled.span``

const Root = styled.label<FormLabelStyleProps>`
  display: block;
  text-align: start;

  /* Colour */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  /* Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};

  /* Variant */
  ${(props) => props.styleProps.variant === 'standard' && StandardLabel}
  ${(props) => props.styleProps.variant === 'filled' && FilledLabel}
  ${(props) => props.styleProps.variant === 'outlined' && OutlinedLabel}
`

const Components: FormLabelContext = {
  Root,
  RequiredIndicator,
}

export default Components
