import * as React from 'react'
import { faSolidCheckCircle, faSolidExclamationTriangle } from '../../../utils/fontawesome'
import StepContext from '../Step/Step.context'
import type { StepIconContext as StepIconComposition } from './StepIcon.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'

export interface StepIconPublicProps {
  /**
   * Mark the step as failed.
   * @default false
   */
  invalid?: boolean
}

export type StepIconProps = StepIconPublicProps & StyledProps<StepIconComposition>

export interface StepIconCoreStyle {
  active: boolean
  completed: boolean
  invalid: boolean
  disabled: boolean
  disableEffect: boolean
}

/**
 * Icon containing the step number
 * When completed the icon will become a check sign
 */
const StepIcon: React.FC<StepIconProps> = React.forwardRef(
  (props: StepIconProps, forwardRef: React.Ref<any>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const { active, completed, disabled, index, widthDisableEffectOnComplete } =
      React.useContext(StepContext) || {}

    const getCoreStyle = (): StepIconCoreStyle => ({
      active,
      completed,
      invalid: props.invalid!,
      disabled,
      disableEffect: completed && widthDisableEffectOnComplete,
    })

    const renderCircle = () => {
      const { Circle } = props.styled!
      return (
        <Circle
          cx="12"
          cy="12"
          r="12"
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'circle')}
        >
          {index + 1}
        </Circle>
      )
    }

    const renderText = () => {
      const { Text } = props.styled!
      return (
        <Text
          x="12"
          y="16"
          textAnchor="middle"
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'text')}
        >
          {index + 1}
        </Text>
      )
    }

    const renderFinalIcon = () => {
      const icon = props.invalid ? faSolidExclamationTriangle : faSolidCheckCircle

      const { Icon } = props.styled!
      return (
        <Icon
          className={props.className}
          ref={ref}
          src={icon}
          size={24}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
        />
      )
    }

    if (completed || props.invalid) {
      return renderFinalIcon()
    }

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderCircle()}
        {renderText()}
      </Root>
    )
  },
)

StepIcon.defaultProps = {
  invalid: false,
}

export default StepIcon
