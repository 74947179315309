import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../Icon'
import { getColour, getFontSize, getBorderColour, LinkCustomisations } from './customisations'
import { Customisations } from './DevLink.customisations'
import type { LinkContext } from '../../Link.composition'
import type { LinkCoreStyle } from '../../Link'
import type { StyleProps } from '../../../types'
import * as themeStyles from '../../../themes/Developer/styles'
import * as States from './DevLink.states'

export type LinkStyleProps = StyleProps<LinkCoreStyle, LinkCustomisations> & ThemeProps

const disabled = (props: LinkStyleProps) => props.styleProps.disabled

const PositionIconToTheLeft = (Components: LinkContext) => css`
  ${Components.Icon} {
    order: 0;
  }

  ${Components.Label} {
    order: 1;
  }
`

const PositionIconToTheRight = (Components: LinkContext) => css`
  ${Components.Icon} {
    order: 1;
  }

  ${Components.Label} {
    order: 0;
  }
`

const Icon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<LinkStyleProps>`
  margin: ${(props) => props.theme.spaces.xxs};
  cursor: inherit;
  opacity: 1;

  * {
    display: inline;
    vertical-align: unset;
  }
`

const Label = styled.span<LinkStyleProps>``

const Root = styled.a<LinkStyleProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  pointer-events: auto;

  /** Colour */
  color: ${(props) => getColour(props)};

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};

  /** Visuals */
  padding: 0 1px;

  /** Border */
  ${themeStyles.Border}
  border-color: ${(props) => getBorderColour(props)};

  ${(props) => props.styleProps.iconToLeft && PositionIconToTheLeft(Components)};
  ${(props) => !props.styleProps.iconToLeft && PositionIconToTheRight(Components)};
  ${(props) => disabled(props) && themeStyles.DisabledState};

  /* State */
  ${States.HoveredLink}
  ${States.FocusedLink}
  ${States.VisitedLink}
  ${States.DisabledLink}
`

const Components: LinkContext = {
  Root,
  Label,
  Icon,
}

export default Components
