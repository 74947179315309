import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevIcon } from '../../../../index'
import { FontAwesomeIcon as FontAwesomeIconCore } from '../../../../../utils/fontawesome'
import { FontAwesomeIconContext } from '../../FontAwesomeIcon.composition'
import { getSize } from './DevFontAwesomeIcon.utils'
import {
  FontAwesomeIconCustomisations,
  getBgColour,
  getColour,
  getBorder,
  getBorderColour,
  getBorderRadius,
  getPadding,
} from './customisations'
import type { FontAwesomeIconCoreStyle } from '../../FontAwesomeIcon'
import type { StyleProps } from '../../../../../types'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type FontAwesomeIconStyleProps = StyleProps<
  FontAwesomeIconCoreStyle,
  FontAwesomeIconCustomisations
> &
  ThemeProps

const EnabledRoot = css<FontAwesomeIconStyleProps>`
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};
`

const DisabledRoot = css<FontAwesomeIconStyleProps>`
  :disabled {
    ${themeStyles.DisabledState}
  }
`

const HoverRoot = css<FontAwesomeIconStyleProps>`
  :hover {
    color: ${(props) => getColour(props, 'hover')};
    background-color: ${(props) => getBgColour(props, 'hover')};
  }
`

const FocusRoot = css<FontAwesomeIconStyleProps>`
  :focus {
    border-color: ${(props) => getBorderColour(props, 'focus')};
  }
`

const FontAwesomeIcon = styled(FontAwesomeIconCore)<FontAwesomeIconStyleProps>`
  font-size: inherit;
  color: inherit;
  background-color: inherit;
  width: 1em !important;
`

const Root = styled(DevIcon)<FontAwesomeIconStyleProps>`
  position: relative;
  font-size: ${(props) => getSize(props)}px;

  /** Visuals */
  border: ${(props) => getBorder(props)};
  border-radius: ${(props) => getBorderRadius(props)};

  /** Spaces */
  padding: ${(props) => getPadding(props)};

  /** State */
  ${EnabledRoot}
  ${DisabledRoot}
  ${HoverRoot}
  ${FocusRoot}
`

const Components: FontAwesomeIconContext = {
  Root,
  FontAwesomeIcon,
}

export default Components
