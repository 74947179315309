import React, { useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Container,
    Dialog,
    Grid,
    Typography
} from "@material-ui/core";
import { DevButton, DevInputField } from "@tl-prisma/core";
import MockBankHeader from "../MockBankHeader";
import Mandate from "../Mandates/Mandate";
import { Username } from "../UserInformation/Username";
import {MandatesUserInformation} from "../UserInformation";
import AuthenticationInformation from "../AuthenticationInformation";
import { SecurityCodeInputs } from "./SecurityCodeInputs";
import { AnchorDialog } from "./AnchorDialog";

const usernameId = "username";
const securityCode3Id = "security-code-3";
const securityCode4Id = "security-code-4";
const securityCode6Id = "security-code-6";

export type FormValue = {
    username: string;
    "security-code-3": string;
    "security-code-4": string;
    "security-code-6": string;
};

type FormValueKey = Record<keyof FormValue, boolean>;

type MockBankLoginFormProps = {
    mandate: Mandate;
    onSubmit(values: FormValue): void;
    onCancel(): void;
};

export function MockBankMandatesForm({
                                      mandate,
                                      onSubmit,
                                      onCancel
                                  }: MockBankLoginFormProps) {
    const [openDialog, setOpenDialog] = useState<
        "user" | "auth" | undefined
    >();

    const [formValues, setFormValues] = useState<FormValue>({
        [usernameId]: "",
        [securityCode3Id]: "",
        [securityCode4Id]: "",
        [securityCode6Id]: ""
    });

    const [validValues, setValidValues] = useState<Partial<FormValueKey>>({});

    function handleSubmit() {
        const result = validateRequired(formValues, validationRules);

        if (Object.values(result).every(val => val)) {
            onSubmit(formValues);
        } else {
            setValidValues(result);
        }
    }

    return (
        <Container>
            <Grid container>
        <Grid item sm={1} md={2} lg={3} />
    <Grid item sm={10} md={8} lg={6}>
    <>
        <Box pt={8} pb={2}>
    <MockBankHeader name={mandate?.provider_ui_display_name ?? ""} />
    </Box>
    <Card variant="outlined">
    <Box p={5}>
    <CardContent>
        <Typography variant="h2" paragraph={true}>
        Online Banking Portal
    </Typography>
    <Typography paragraph={true}>
        Use your Online Banking details to log on.
    </Typography>

    <Typography variant="h3">Username</Typography>
        <DevInputField
    id={usernameId}
    label=""
    placeholder="Enter username"
    value={formValues[usernameId]}
    withValidityStatus={false}
    onChange={(event: React.ChangeEvent) => {
        event.persist();
        const val = (event.target as HTMLInputElement).value;
        setFormValues({
            ...formValues,
            [usernameId]: val
        });
    }}
    error={retrieveUsernameErrorMessage(formValues[usernameId])}
    invalid={validValues[usernameId] === false}
    />
    <Typography variant="subtitle2" paragraph={true}>
        To trigger different scenarios, see our
    <AnchorDialog onClick={() => setOpenDialog("user")}>
    test usernames
    </AnchorDialog>
    </Typography>

    <SecurityCodeInputs
        values={{
        [securityCode3Id]: formValues[securityCode3Id],
            [securityCode4Id]: formValues[securityCode4Id],
            [securityCode6Id]: formValues[securityCode6Id]
    }}
    validValues={{
        [securityCode3Id]: validValues[securityCode3Id],
            [securityCode4Id]: validValues[securityCode4Id],
            [securityCode6Id]: validValues[securityCode6Id]
    }}
    onChange={val => {
        setFormValues({
            ...formValues,
            ...val
        });
    }}
    />

    <Typography variant="subtitle2" paragraph={true}>
        Enter any 3 digits to login.
    <AnchorDialog onClick={() => setOpenDialog("auth")}>
    Learn more
    </AnchorDialog>
    </Typography>

    <Box pt={8}>
        <Grid container spacing={3}>
        <Grid item xs={6}>
    <DevButton
        id="cancel"
    type="secondary"
    label="Cancel"
    onClick={onCancel}
    />
    </Grid>
    <Grid item xs={6}>
    <DevButton
        id="continue"
    htmlTag="submit"
    type="primary"
    label="Continue"
    onClick={handleSubmit}
    />
    </Grid>
    </Grid>
    </Box>
    </CardContent>
    </Box>
    </Card>
    </>
    </Grid>
    </Grid>

    <Dialog
    open={openDialog !== undefined}
    onClose={() => setOpenDialog(undefined)}
>
    {openDialog === "user" && (
        <MandatesUserInformation onClose={() => setOpenDialog(undefined)} />
    )}

    {openDialog === "auth" && (
        <AuthenticationInformation onClose={() => setOpenDialog(undefined)} />
    )}
    </Dialog>
    </Container>
);
}

const validationRules: Record<string, (value: string) => boolean> = {
    username: isUsernameValid
};

function isUsernameValid(value: string) {
    return (
        (value === Username.Authorised ||
            value === Username.AuthorisationFailed) ??
        false
    );
}

function validateRequired(
    values: FormValue,
    extraValidationRules: typeof validationRules
) {
    return Object.entries(values).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]:
            !!value &&
            (extraValidationRules[key] ? extraValidationRules[key](value) : true)
        }),
        {} as FormValueKey
    );
}

function retrieveUsernameErrorMessage(value: string): string {
    if (!value) return "Required"
    if (!isUsernameValid(value)) return "Invalid username"
    return ""
}
