import * as React from 'react'
import * as PropTypes from 'prop-types'

import { useFormControl } from '../FormControl/FormControl.context'
import { CheckboxContext } from './Checkbox.composition'
import { isInputtable } from './Checkbox.utils'
import useResponsive from '../hooks/useResponsive'
import useControlled from '../hooks/useControlled'
import useCheckDirty from '../FormControl/hooks/useCheckDirty'
import { StyledProps } from '../providers'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { newId } from '../utils'
import { faSolidCheck } from '../utils/fontawesome'
import { attachIf } from '../utils/event-handler'
import { getTestId } from '../utils/test'

export interface CheckboxPublicProps {
  /**
   * Id of the checkbox.
   */
  id?: string
  /**
   * Label of the checkbox.
   */
  label?: string
  /**
   * Name of the checkbox.
   */
  name?: string
  /**
   * Value of the checkbox.
   */
  value?: string | boolean
  /**
   * True if the checkbox is ticked, false otherwise.
   */
  checked?: boolean
  /**
   * The default value if the checkbox is ticked or not. Use when the component is not controlled.
   */
  defaultChecked?: boolean
  /**
   * Icon representing the tick.
   */
  icon?: any
  /**
   * If `true`, the checkbox can be unticked, false otherwise
   */
  untickable?: boolean
  /**
   * If `true`, the `input` element will be disabled.
   */
  disabled?: boolean
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean
  /**
   * If `true`, the label will be displayed in an error state.
   */
  invalid?: boolean
  /**
   * If `true`, the component will be displayed in focused state.
   */
  focused?: boolean
  /**
   * If `true`, the component will be displayed in hovered state.
   */
  hovered?: boolean
  /**
   * Size of the input.
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * -1 if the checkbox is not keyboard accessible, index in the sequential keyboard navigation otherwise
   */
  tabIndex?: number
  /**
   * Handler when the value changes. The new value is passed as parameter.
   */
  onChange?: (value?: string | boolean) => void
  /**
   * Handler when the user clicks on the checkbox.
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * Handler when the input loses the focus. The new value is passed as parameter.
   */
  onBlur?: (event: React.FocusEvent) => void
  /**
   * Handler when the input gets the focus.
   */
  onFocus?: (event: React.FocusEvent) => void
  /**
   * Handler when the input is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the input is not hovered
   */
  onLeave?: (event?: React.MouseEvent) => void
  /**
   * Component used as label
   */
  children?: React.ReactNode
}

export interface CheckboxProps extends CheckboxPublicProps, StyledProps<CheckboxContext> {}

export interface CheckboxCoreStyle {
  checked: boolean
  disabled: boolean
  readOnly: boolean
  invalid: boolean
  variant: string
  size: string
  filled: boolean
  hovered: boolean
  focused: boolean
  active: boolean
}

const Checkbox = React.forwardRef((props: CheckboxProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const inputRef = React.useRef<HTMLInputElement>(null)

  const formControl = useFormControl(props)
  const checkDirty = useCheckDirty(formControl)

  const [checked, setChecked] = useControlled({
    value: props.checked,
    default: props.defaultChecked,
    name: 'Checkbox',
  })

  const [hovered, setHovered] = React.useState(props.hovered)
  const [focused, setFocused] = React.useState(props.focused)
  const [active, setActive] = React.useState(false)

  const isActionable = isInputtable(formControl)

  const tabIndex = formControl.readOnly || formControl.disabled ? -1 : props.tabIndex

  React.useEffect(() => {
    inputRef.current.checked = checked
    checkDirty({ value: checked })
  }, [checked])

  // Remove the focus when the input is disabled and has the focus
  React.useEffect(() => {
    if (!isInputtable(formControl) && props.focused) {
      inputRef.current!.blur()
    }
  }, [formControl, props.disabled, props.readOnly, props.focused])

  const generateId = React.useMemo(() => {
    return newId()
  }, [])

  const getCoreStyle = (): CheckboxCoreStyle => ({
    checked,
    disabled: formControl.disabled!,
    readOnly: formControl.readOnly!,
    invalid: formControl.invalid!,
    variant: formControl.variant!,
    size: formControl.size!,
    filled: formControl.filled!,
    focused,
    hovered,
    active,
  })

  const handleClick = (event: React.MouseEvent) => {
    if (props.onClick) {
      props.onClick(event)
    }

    handleChange(event)
  }

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return
    }

    handleChange(event)
  }

  const handleChange = (event: React.SyntheticEvent<any>) => {
    if (props.untickable || formControl.readOnly) {
      event.stopPropagation()
      return
    }

    const nextState = !checked
    setChecked(nextState)

    if (props.onChange) {
      const update = props.value === undefined ? nextState : props.value
      props.onChange(update)
    }
  }

  const handleFocus = (event: React.FocusEvent) => {
    if (props.onFocus) {
      props.onFocus(event)
    }

    setFocused(true)
  }

  const handleBlur = (event: React.FocusEvent) => {
    if (props.onBlur) {
      props.onBlur(event)
    }

    setFocused(false)
  }

  const handleHover = (event: React.MouseEvent) => {
    if (props.onHover) {
      props.onHover(event)
    }

    setHovered(true)
  }

  const handleLeave = (event?: React.MouseEvent) => {
    if (props.onLeave) {
      props.onLeave(event)
    }

    setHovered(false)
  }

  const renderTickIcon = () => {
    const icon = checked ? faSolidCheck : props.icon

    const { TickIcon: Icon } = props.styled!
    return icon ? (
      <Icon
        src={icon}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'tick-icon')}
      />
    ) : null
  }

  const renderTick = () => {
    const { Tick } = props.styled!
    return (
      <Tick
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'tick')}
      >
        {renderTickIcon()}
      </Tick>
    )
  }

  const renderLabel = () => {
    const label = props.label || props.children

    const { Label } = props.styled!
    return label ? (
      <Label
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'label')}
      >
        {label}
      </Label>
    ) : null
  }

  const renderInput = () => {
    const { Input } = props.styled!
    return (
      <Input
        ref={inputRef}
        type="checkbox"
        id={props.id || props.name || generateId}
        name={props.name || props.id || generateId}
        tabIndex={-1}
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        disabled={formControl.disabled}
        readOnly={formControl.readOnly}
        placeholder={props.label}
        onChange={() => null}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'input')}
      />
    )
  }

  const { Root } = props.styled!
  return (
    <Root
      className={props.className}
      ref={ref}
      tabIndex={tabIndex}
      onClick={attachIf(handleClick, isActionable)}
      onFocus={attachIf(handleFocus, isActionable)}
      onBlur={attachIf(handleBlur, isActionable)}
      onMouseEnter={attachIf(handleHover, isActionable)}
      onMouseLeave={attachIf(handleLeave, isActionable)}
      onKeyUp={attachIf(handleKeyUp, isActionable)}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {renderTick()}
      {renderLabel()}
      {renderInput()}
    </Root>
  )
})

Checkbox.defaultProps = {
  size: 'medium',
  disabled: false,
  tabIndex: 0,
  id: newId(),
}

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  icon: PropTypes.any,
  untickable: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  invalid: PropTypes.bool,
  focused: PropTypes.bool,
  hovered: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  tabIndex: PropTypes.number,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onHover: PropTypes.func,
  onLeave: PropTypes.func,
}

export default Checkbox
