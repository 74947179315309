import * as React from 'react'
import type { StyledProps } from '../providers'
import type { StepperContext as StepperComposition } from './Stepper.composition'
import StepperContext from './Stepper.context'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

export interface StepperPublicProps {
  /**
   * The stepper orientation (layout flow direction).
   * @default 'horizontal'
   */
  orientation?: 'horizontal' | 'vertical'
  /**
   * Set the active step (zero based index).
   * Set to -1 to disable all the steps.
   * @default 0
   */
  activeStep?: number
  /**
   * If set to 'true' and orientation is horizontal,
   * then the step label will be positioned under the icon.
   * @default false
   */
  alternativeLabel?: boolean
  /**
   * An element to be placed between each step.
   * @default <StepConnector />
   */
  connector?: React.ReactNode
  /**
   * If set the `Stepper` will not assist in controlling steps for linear flow.
   * @default false
   */
  nonLinear?: boolean
  /**
   * Two or more `<Step />` components.
   */
  children?: React.ReactNode
}

export interface StepperProps extends StepperPublicProps, StyledProps<StepperComposition> {}

export interface StepperCoreStyle {
  orientation: 'horizontal' | 'vertical'
  alternativeLabel: boolean
}

const Stepper: React.FC<StepperProps> = React.forwardRef(
  (props: StepperProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const children = React.Children.toArray(props.children)

    const steps = children.map((step: any, index: number) =>
      React.cloneElement(step, {
        index,
        last: index + 1 === children.length,
        ...step.props,
      }),
    )

    const getCoreStyle = (): StepperCoreStyle => ({
      orientation: props.orientation!,
      alternativeLabel: props.alternativeLabel!,
    })

    const renderConnector = () => {
      const { Connector } = props.styled!
      return (
        <Connector
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'connector')}
        />
      )
    }

    const context = React.useMemo(
      () => ({
        activeStep: props.activeStep!,
        alternativeLabel: props.alternativeLabel!,
        connector: props.connector || renderConnector(),
        nonLinear: props.nonLinear!,
        orientation: props.orientation!,
      }),
      [
        props.activeStep,
        props.alternativeLabel,
        props.connector,
        props.nonLinear,
        props.orientation,
      ],
    )

    const { Root } = props.styled!
    return (
      <StepperContext.Provider value={context}>
        <Root
          ref={ref}
          className={props.className}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getAttributes(props, DataAttributesPrefix)}
        >
          {steps}
        </Root>
      </StepperContext.Provider>
    )
  },
)

Stepper.defaultProps = {
  orientation: 'horizontal',
  activeStep: 0,
  alternativeLabel: false,
  nonLinear: false,
}

export default Stepper
