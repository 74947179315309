import type { FormControlContextProps } from '../FormControl/FormControl.context'

/**
 * Determine if the input is inputtable or not
 * @param formControl form control context
 * @returns {boolean} True the input is inputtable
 *                    False the input is not inputtable
 */
export const isInputtable = (formControl: FormControlContextProps) =>
  !formControl.disabled && !formControl.readOnly
