export const getValueAsPixel = (value: string | number) => {
  if (typeof value === 'undefined') {
    return value
  }

  if (typeof value === 'number') {
    return `${value}px`
  }

  return value
}

export const getValueAsNumber = (value: string | number) => {
  if (typeof value === 'undefined') {
    return value
  }

  if (typeof value === 'string') {
    const pieces = value?.match(/^(\d+(.\d+)?)(px|em|rem)$/i)
    if (pieces?.length > 0) {
      return +pieces[1]
    }
  }

  return +value
}
