import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../../../Icon/components/FontAwesomeIcon/index'
import { CheckboxCoreStyle } from '../../../../Checkbox'
import { CheckboxContext } from '../../../../Checkbox.composition'
import { Customisations } from './DevNativeCheckbox.customisations'
import { DisabledState, ReadOnlyState } from '../../../../../themes/Developer/styles'
import { StyleProps } from '../../../../../types'
import {
  NativeCheckboxCustomisations,
  getFontSize,
  getLabelBgColour,
  getLabelColour,
  getTickColour,
  getTickBorder,
  getTickBgColour,
  getTickHeight,
} from './customisations'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type NativeCheckboxStyleProps = StyleProps<CheckboxCoreStyle, NativeCheckboxCustomisations> &
  ThemeProps

const TickIcon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<NativeCheckboxStyleProps>`
  font-size: ${(props) => `calc(${getFontSize(props)} - 4px)`};
`

const Tick = styled.div<NativeCheckboxStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-shrink: 0;
  line-height: inherit;
  font-size: inherit;
  align-self: center;

  /** Colour */
  color: ${(props) => getTickColour(props)};
  background-color: ${(props) => getTickBgColour(props)};

  /** Size */
  width: ${(props) => getTickHeight(props)};
  height: ${(props) => getTickHeight(props)};

  /** Visual */
  border: ${(props) => getTickBorder(props)};
`

const Label = styled.label<NativeCheckboxStyleProps>`
  box-sizing: border-box;
  align-self: center;
  flex-grow: 1;
  line-height: inherit;
  font-size: inherit;
  text-align: left;
  padding: ${(props) => `0px ${props.theme.spaces.xxs}`};
  margin-left: ${(props) => props.theme.spaces.xxs};

  /** Colour */
  color: ${(props) => getLabelColour(props)};
  background-color: ${(props) => getLabelBgColour(props)};

  /** Typography */
  font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.regular};

  /** Visual */
  border-radius: 0px;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const Root = styled.div<NativeCheckboxStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  outline: none;

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};

  /* Visual */
  line-height: ${(props) => props.theme.visuals.lineHeight};

  ${(props) => props.styleProps.disabled && DisabledState};
  ${(props) => props.styleProps.readOnly && ReadOnlyState};

  * {
    cursor: inherit !important;
  }
`

const Components: CheckboxContext = {
  Root,
  Input,
  Label,
  Tick,
  TickIcon,
}

export default Components
