import type { FormLabelCustomisations } from '../../../FormLabel/themes/Developer/customisations'
import type { FormHelperTextCustomisations } from '../../../FormHelperText/themes/Developer/customisations'
import type { FormValidityTextCustomisations } from '../../../FormValidityText/themes/Developer/customisations'
import type { TooltipCustomisations } from '../../../Tooltip/themes/Developer/customisations'
import type { FontAwesomeIconCustomisations as IconCustomisations } from '../../../Icon/components/FontAwesomeIcon/themes/Developer/customisations'
import type { FieldStyleProps } from './DevField.styles'
import type { FontAwesomeIconPublicProps } from '../../../Icon/components/FontAwesomeIcon/FontAwesomeIcon'
import type { FieldCustomisations as BaseFieldCustomisations } from '../../Field.customisations'
import type { FieldPublicProps } from '../../Field'
import { omit } from '../../../utils/lodash'

export interface FieldValidityStatusCustomisations {
  /**
   * Colour of the Validity status when valid
   */
  colour?: string
  /**
   * Colour of the Validity status when invalid
   */
  invalidColour?: string
}

export interface DevFieldCustomisations {
  /**
   * Label customisations
   */
  Label?: FormLabelCustomisations
  /**
   * Helper text customisations
   */
  HelperText?: FormHelperTextCustomisations
  /**
   * Validity text customisations
   */
  ValidityText?: FormValidityTextCustomisations
  /**
   * Tooltip customisations
   */
  Tooltip?: TooltipCustomisations
  /**
   * Validity status customisations
   */
  ValidityStatus?: FieldValidityStatusCustomisations
}

const getTooltipIconColour = (props: FieldStyleProps) =>
  props.customisations.Tooltip?.Icon?.colour || props.theme.colours.tints.lightGrey[150]

const getTooltipIconhoverColour = (props: FieldStyleProps) =>
  props.customisations.Tooltip?.Icon?.hoverColour || props.theme.colours.tints.sun[50]

const getToolTipCustomisations = (
  props: FieldStyleProps & FontAwesomeIconPublicProps,
): TooltipCustomisations => ({
  ...omit(props.customisations.Tooltip, 'Icon'),
  Icon: {
    colour: getTooltipIconColour(props),
    hoverColour: getTooltipIconhoverColour(props),
  },
})

const getValidityStatusValidColour = (props: FieldStyleProps) =>
  props.customisations.ValidityStatus?.colour || props.theme.colours.tertiary.semantic.green

const getValidityStatusInvalidColour = (props: FieldStyleProps) =>
  props.customisations.ValidityStatus?.invalidColour || props.theme.colours.tertiary.semantic.red

const getValidityStatusColour = (props: FieldStyleProps) =>
  props.styleProps.invalid
    ? getValidityStatusInvalidColour(props)
    : getValidityStatusValidColour(props)

const getValidationStatusCustomisations = (
  props: FieldStyleProps & FontAwesomeIconPublicProps,
): IconCustomisations => ({
  colour: getValidityStatusColour(props),
  hoverColour: getValidityStatusColour(props),
})

export const Customisations = {
  Label: (props: FieldStyleProps) => props.customisations.Label!,
  HelperText: (props: FieldStyleProps) => props.customisations.HelperText!,
  ValidityText: (props: FieldStyleProps) => props.customisations.ValidityText!,
  Tooltip: getToolTipCustomisations,
  ValidityStatus: getValidationStatusCustomisations,
}

export type FieldCustomisations = BaseFieldCustomisations & DevFieldCustomisations

export default (props: FieldPublicProps & FieldCustomisations): FieldCustomisations => ({
  Label: props.Label,
  HelperText: props.HelperText,
  ValidityText: props.ValidityText,
  Tooltip: props.Tooltip,
  ValidityStatus: props.ValidityStatus,
})
