import * as theme from '../../../../../themes/Developer/proxima-nova'
import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevTablePaginationActions } from '../../components'
import { DevSelect } from '../../../../../Select'
import { Customisations } from './DevTablePagination.customisations'
import { getBgColour, getColour, TablePaginationCustomisations } from './customisations'
import type { TablePaginationContext } from '../../TablePagination.composition'
import type { StyleProps } from '../../../../../types'

export type TablePaginationStyleProps = StyleProps<unknown, TablePaginationCustomisations> &
  ThemeProps

const Actions = styled(DevTablePaginationActions).attrs(
  Customisations.Actions,
)<TablePaginationStyleProps>`
  flex-shrink: 0;
  margin-left: ${(props) => props.theme.spaces.md};
`

const LabelDisplayedRows = styled.p<TablePaginationStyleProps>`
  flex-shrink: 0;
`

const LabelRowsPerPage = styled.p<TablePaginationStyleProps>`
  flex-shrink: 0;
`

const Spacer = styled.div<TablePaginationStyleProps>`
  flex: 1 1 100%;
`

const PageSelection = styled(DevSelect).attrs(Customisations.Selection)<TablePaginationStyleProps>`
  width: 70px;
  min-width: 70px;
  margin-left: ${(props) => props.theme.spaces.xs};
  margin-right: ${(props) => props.theme.spaces.xl};
`

const Root = styled.div<TablePaginationStyleProps>`
  display: flex;
  position: relative;
  align-items: center;
  min-height: 52px;
  font-size: ${(props) =>
    theme.getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyXs.px)};
  padding: 0px ${(props) => props.theme.spaces.xs};
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};
`

const Components: TablePaginationContext = {
  Root,
  PageSelection,
  Actions,
  Spacer,
  LabelRowsPerPage,
  LabelDisplayedRows,
}

export default Components
