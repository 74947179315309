import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { DevIcon } from '../../../Icon/themes/Developer/DevIcon'
import type { DropzoneContext } from '../../Dropzone.composition'
import type { DropzoneCoreStyle } from '../../Dropzone'
import type { StyleProps } from '../../../types'
import { convertHex } from '../../../utils'
import * as themeStyles from '../../../themes/Developer/styles'

export type DropzoneStyleProps = StyleProps<DropzoneCoreStyle> & ThemeProps

const LabelDropZoneStyle = css`
  width: 100%;
  background-color: ${(props) => props.theme.colours.tints.lightGrey[100]};
`

const ImageDropZoneStyle = css`
  width: auto !important;
`

const Icon = styled(DevIcon)<DropzoneStyleProps>`
  background-color: ${(props) => props.theme.colours.tints.lightGrey[100]};
  padding: ${(props) => props.theme.spaces.xs} ${(props) => props.theme.spaces.sm};
  margin: auto;
  border: ${(props) => props.theme.visuals.borderSize} solid
    ${(props) => props.theme.colours.tints.lightGrey[100]};
  width: 100%;
  height: 100%;

  :hover {
    cursor: inherit;
  }
`

const Label = styled.p<DropzoneStyleProps>`
  text-align: center;
  font-size: ${(props) => props.theme.typography.proximaNova.fontSize.bodyXs.px};
`

const Input = styled.input<DropzoneStyleProps>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const Root = styled.div<DropzoneStyleProps>`
  ${themeStyles.FontFamily}
  ${(props) => (props.styleProps.hasIcon ? ImageDropZoneStyle : LabelDropZoneStyle)};

  :hover {
    cursor: pointer;

    ${Icon} {
      position: relative;

      :after {
        content: '\f093';
        font-family: 'Font Awesome\ 5 Pro';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) =>
          convertHex(
            props.theme.colours.tints.sun[100],
            +props.theme.visuals.opacity.normal * 100,
          )};
        border: ${(props) => props.theme.visuals.borderSize} solid
          ${(props) => props.theme.colours.tints.sun[100]};
        font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.bold};
      }
    }
  }
`

const Components: DropzoneContext = {
  Root,
  Input,
  Icon,
  Label,
}

export default Components
