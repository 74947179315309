import Title, { TitlePublicProps } from '../../Title'
import Theme from '../../../themes/Developer/theme'
import Styles from './DevTitle.styles'
import Customisations from './DevTitle.customisations'
import type { TitleContext } from '../../Title.composition'
import type { TitleCustomisations } from './customisations'

import { withCustomisedStyle } from '../../../providers'

export const DevTitle = withCustomisedStyle<TitlePublicProps, TitleContext, TitleCustomisations>(
  Title,
  Theme,
  Styles,
  Customisations,
  'Title',
)

export type DevTitleCustomisations = TitleCustomisations
export type DevTitleProps = TitlePublicProps & TitleCustomisations
