import * as React from 'react'
import PropTypes from 'prop-types'

import type { StyledProps } from '../../../providers'
import type { ListContext } from '../../List.composition'
import List, { ListPublicProps } from '../../List'
import useResponsive from '../../../hooks/useResponsive'

export interface ListInteractivePublicProps extends ListPublicProps {
  /**
   * The html tag used for the root node.
   */
  as?: 'ul' | 'nav'
  /**
   * True if the field is disabled, false otherwise
   */
  disabled?: boolean
}

export interface ListInteractiveProps
  extends ListInteractivePublicProps,
    StyledProps<ListContext> {}

export interface ListInteractiveCoreStyle {
  disabled: boolean
}

const ListInteractive = React.forwardRef(
  (props: ListInteractiveProps, forwardRef: React.Ref<HTMLUListElement | HTMLElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): ListInteractiveCoreStyle => ({
      disabled: props.disabled!,
    })

    return <List ref={ref as any} {...props} styleProps={getCoreStyle()} />
  },
)

ListInteractive.defaultProps = {
  as: 'ul',
  disabled: false,
}

ListInteractive.propTypes = {
  as: PropTypes.oneOf(['ul', 'nav']),
  disabled: PropTypes.bool,
  children: (props, propName, componentName) => {
    const prop = props[propName]

    let error = null
    React.Children.forEach(prop, (child) => {
      if (child.type.displayName !== 'ListInteractiveItem') {
        error = new Error(
          `\`${componentName}\` children should be of type \`ListInteractiveItem\`.`,
        )
      }
    })

    return error
  },
}

export default ListInteractive
