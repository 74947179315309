import * as React from 'react'
import type { CheckboxGroupContext } from './CheckboxGroup.composition'
import { StyledProps } from '../providers'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import useResponsive from '../hooks/useResponsive'
import { getBreakpointValue } from '../devices'
import { getTestId } from '../utils/test'
import { includes } from '../utils/lodash'

export interface CheckboxBreakpoint {
  [key: string]: number
}

export interface CheckboxGroupPublicProps {
  /**
   * Id of the group of checkbox
   */
  id: string
  /**
   * Name of the group of checkbox
   */
  name?: string
  /**
   * Current value of the group of checkbox
   */
  value?: string[]
  /**
   * Space between the checkbox
   */
  spacing?: number
  /**
   * True if the group of checkbox is disabled, false otherwise
   */
  disabled?: boolean
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean
  /**
   * If `true`, the label will be displayed in an error state.
   */
  invalid?: boolean
  /**
   * True, if at least one checkbox has to be checked, false otherwise
   */
  atLeastOne?: boolean
  /**
   * Size of the input.
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Orientation of the checkbox inside the group
   */
  orientation?: 'horizontal' | 'vertical'
  /**
   * List of breakpoint with the number of columns the group has to display per line
   */
  breakpoints?: CheckboxBreakpoint[]
  /**
   * Handler triggered when the current value of the group of checkbox changed
   */
  onChange?: (values: string[]) => void
  /**
   * List of checkbox components to display as a group
   */
  children?: React.ReactNode
}

interface CheckboxGroupProps extends CheckboxGroupPublicProps, StyledProps<CheckboxGroupContext> {}

const CheckboxGroup = React.forwardRef(
  (props: CheckboxGroupProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref, device } = useResponsive({ ref: forwardRef })

    const checkboxes = React.Children.toArray(props.children)

    const [values, setValues] = React.useState(props.value!)

    const direction = props.orientation === 'vertical' ? 'column' : 'row'

    React.useEffect(() => {
      setValues(props.value!)
    }, [props.value])

    const getCoreStyle = () => ({})

    const getPropertyValue = <T extends unknown>(child: any, name: string): T =>
      typeof child.props[name] === 'undefined' ? props[name] : child.props[name]

    const getItemProperties = () => {
      let itemProperties = {}

      if (props.breakpoints) {
        const numberOfColumn = getBreakpointValue(props.breakpoints, device)

        if (device) {
          itemProperties = {
            [device!]: 12 / numberOfColumn,
          }
        }
      } else {
        itemProperties = { xs: true }
      }

      return itemProperties
    }

    const handleChange = (value: string) => {
      let nextValues: string[] = []

      if (includes(values, value)) {
        const keepPrevValue = props.atLeastOne && values.length === 1
        nextValues = !keepPrevValue ? values.filter((x) => x !== value) : values
      } else {
        nextValues = values.concat([value])
      }

      setValues(nextValues)

      if (props.onChange) {
        props.onChange(nextValues)
      }
    }

    const renderItem = (child: any, index: number) => {
      const customisations: any = props.customisations || {}

      const component = React.cloneElement(child, {
        ...customisations,
        ...child.props,
        checked: includes(values, child.props.value),
        size: getPropertyValue(child, 'size'),
        disabled: getPropertyValue(child, 'disabled'),
        readOnly: getPropertyValue(child, 'readOnly'),
        invalid: getPropertyValue(child, 'invalid'),
        untickable: props.atLeastOne && values.length === 1 && values[0] === child.props.value,
        onChange: handleChange,
        ...getTestId(props, `checkbox-${index + 1}`),
      })

      const { Item } = props.styled!
      return (
        <Item
          key={`item-${index + 1}`}
          {...getItemProperties()}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
        >
          {component}
        </Item>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        container
        direction={direction}
        spacing={props.spacing}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {checkboxes.map(renderItem)}
      </Root>
    )
  },
)

CheckboxGroup.defaultProps = {
  value: [],
  size: 'medium',
  orientation: 'horizontal',
}

export default CheckboxGroup
