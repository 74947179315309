import { getIconColour, TableSortLabelCustomisations } from './customisations'
import type { TableSortLabelPublicProps } from '../../TableSortLabel'
import type { TableSortLabelStyleProps } from './DevTableSortLabel.styles'

export const Customisations = {
  Icon: (props: TableSortLabelStyleProps) => ({
    colour: getIconColour(props),
    hoverColour: getIconColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: TableSortLabelPublicProps & TableSortLabelCustomisations,
): TableSortLabelCustomisations => ({
  colour: props.colour,
})
