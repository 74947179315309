import * as theme from '../../../../themes/Developer/proxima-nova'
import type { ParagraphStyleProps } from '../DevParagraph.styles'

/** Font weight */
export const getFontWeight = (
  props: ParagraphStyleProps,
  defaultValue?: keyof theme.FontWeight,
) => {
  return theme.getFontWeight(
    props,
    defaultValue || ((props.styleProps.weight || 'regular') as keyof theme.FontWeight),
  )
}

/** Text align */
export const getTextAlign = (props: ParagraphStyleProps) => {
  return theme.getTextAlign(props)
}
