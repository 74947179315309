import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import type { TableContext } from '../../Table.composition'
import type { TableCoreStyle } from '../../Table'
import type { StyleProps } from '../../../types'
import * as themeStyles from '../../../themes/Developer/styles'

export type TableStyleProps = StyleProps<TableCoreStyle> & ThemeProps

const StickyRoot = css<TableStyleProps>`
  border-collapse: separate;
`

const Root = styled.table<TableStyleProps>`
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  ${(props) => props.styleProps.stickyHeader && StickyRoot};

  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: TableContext = {
  Root,
}

export default Components
