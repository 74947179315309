import { FontVariants } from '../../Common'

import { FontSize, LineHeight, LetterSpacing, TextAlign } from './models'

export const fontSize: FontSize = {
  h1: { rem: 4.5, px: 72 },
  h2: { rem: 3, px: 48 },
  h3: { rem: 2, px: 32 },
  h4: { rem: 1.5, px: 24 },
  h5: { rem: 1.125, px: 18 },
  h6: { rem: 1, px: 16 },
  bodyL: { rem: 1.125, px: 18 },
  bodyM: { rem: 1, px: 16 },
  bodyS: { rem: 0.875, px: 14 },
  bodyXs: { rem: 0.75, px: 12 },
  captionL: { rem: 1, px: 16 },
  captionM: { rem: 0.875, px: 14 },
  captionS: { rem: 0.6875, px: 11 },
  captionXs: { rem: 0.625, px: 10 },
  captionXxs: { rem: 0.5, px: 8 },
}

export const fontFamily = `proxima-nova, sans-serif`

export const fontWeight: FontVariants = {
  heading: 700,
  bold: 700,
  regular: 400,
  thin: 300,
}

export const textAlign: TextAlign = 'left'

export const lineHeight: LineHeight = {
  h1: '80px',
  h2: '56px',
  h3: '48px',
  h4: '36px',
  h5: '28px',
  h6: '24px',
  bodyL: '28px',
  bodyM: '24px',
  bodyS: '20px',
  bodyXs: '16px',
  captionL: '20px',
  captionM: '16px',
  captionS: '12px',
  captionXs: '12px',
  captionXxs: '8px',
}

export const letterSpacing: LetterSpacing = '0.8px'

export default {
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
  textAlign,
}

export interface ITypography {
  fontFamily?: string
  fontSize?: FontSize
  lineHeight?: LineHeight
  letterSpacing?: LetterSpacing
  fontWeight?: FontVariants
  textAlign?: TextAlign
}
