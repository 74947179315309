import { css } from '../../../themes/Developer/themed-component'
import { getColour, getBorderColour } from './customisations'
import { DisabledState } from '../../../themes/Developer/styles'
import { LinkStyleProps } from './DevLink.styles'
import * as themeStyles from '../../../themes/Developer/styles'

export const HoveredLink = css<LinkStyleProps>`
  :hover {
    ${themeStyles.HoveredState}

    /** Colour */
    color: ${(props) => getColour(props, 'hover')};

    /** Visual */
    text-decoration: underline;

    /**
      TODO: unfortunately, not supported by Opera. Need to find better way
     */
    text-decoration-thickness: 0.03125em;
    text-underline-offset: 2px;
  }
`

export const FocusedLink = css<LinkStyleProps>`
  :focus {
    ${themeStyles.FocusedState}

    /** Colour : take the hover one */
    color: ${(props) => getColour(props, 'focus')};

    /** Visual */
    border-color: ${(props) => getBorderColour(props, 'focus')};

    text-decoration: underline;

    /**
      TODO: unfortunately, not supported by Opera. Need to find better way
     */
    text-decoration-thickness: 0.03125em;
    text-underline-offset: 2px;
  }
`

export const VisitedLink = css<LinkStyleProps>`
  :visited {
    ${themeStyles.ActiveState}

    /** Colour */
    color: ${(props) => getColour(props, 'active')};

    /** Visual */
    text-decoration: underline;

    /**
      TODO: unfortunately, not supported by Opera. Need to find better way
    */
    text-decoration-thickness: 0.03125em;
    text-underline-offset: 2px;
  }
`

export const DisabledLink = css<LinkStyleProps>`
  :disabled {
    ${DisabledState}
  }
`
