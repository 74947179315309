import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { StyleProps } from '../../../../../types'
import type { ListItemAvatarContext } from '../../ListItemAvatar.composition'

export type ListItemAvatarStyleProps = StyleProps<unknown> & ThemeProps

const Root = styled.div<ListItemAvatarStyleProps>`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  min-width: ${(props) => `calc(${props.theme.spaces.xxs} * 8)`};
`

const Components: ListItemAvatarContext = {
  Root,
}

export default Components
