import type { InputPublicProps } from '../../Input'
import type { InputCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: InputPublicProps & InputCustomisations): InputCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
  fontSize: props.fontSize,
  fontWeight: props.fontWeight,
  border: props.border,
})
