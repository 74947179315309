import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { DialogTitleContext } from '../../DialogTitle.composition'
import type { StyleProps } from '../../../../../types'
import { getBgColour, getColour, getPadding, DialogTitleCustomisations } from './customisations'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type DialogTitleStyleProps = StyleProps<unknown, DialogTitleCustomisations> & ThemeProps

const Root = styled.div<DialogTitleStyleProps>`
  display: flex;
  align-items: center;
  margin: 0;
  flex: 0 0 auto;

  /** Colour */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  /** Spacing */
  padding: ${(props) => getPadding(props)};

  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: DialogTitleContext = {
  Root,
}

export default Components
