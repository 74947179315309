import * as React from 'react'
import styled, { css } from 'styled-components'

import { StyleProps } from '../types'

import { GridContainerPublicProps, GridItemPublicProps, GridProps } from './Grid'
import { getBreakpoint } from './Grid.utils'
import { getDevices } from '../devices'
import { GridContext } from './Grid.context'
import { Context as ResponsiveContext } from '../responsives'
import { find, keys, includes } from '../utils/lodash'

interface GridStyle {
  spacing: number
  container: boolean
  break?: boolean
  containerStyle?: GridContainerStyle
  itemStyle?: GridItemStyle
}

interface GridContainerStyle {
  direction: string
  justify: string
  alignItems: string
  alignContent: string
  nestedGrid?: boolean
  // Used only for offset on grid with a column direction
  containerSize?: number
  noWrap: boolean
  overflow: boolean
  height: string | number
}

interface GridItemStyle {
  numberOfColumn?: number | boolean
  offset: number
  offsetDirection: string
  offsetType: string
  spacing: number
  hasAtLeastOneBreakpoint: boolean
  isFirstRow?: boolean
  isLastRow?: boolean
  isFirstColumn?: boolean
  isLastColumn?: boolean
  fullWidth?: boolean
  direction: string
  alignItems: string
  // Used only for offset on grid with a column direction
  containerSize?: number
  noWrap: boolean
  overflow: boolean
  alignSelf?: string
}

export type GridStyleProps = StyleProps<GridStyle>

// TODO: TO BE FIXED - Upper case on the first letter needed otherwise you get an exception when running cypress test
export const GetItemStyle = (props: GridItemPublicProps, extra?: any): GridItemStyle => {
  const responsiveContext = React.useContext(ResponsiveContext)
  const context: any = React.useContext(GridContext)

  const breakpoint = getBreakpoint({ ...props, ...responsiveContext }, extra.device)

  const hasAtLeastOneBreakpoint = !!find(
    keys(getDevices(props)),
    (currentBreakpoint: string) => props[currentBreakpoint] != null,
  )

  let numberOfColumn = breakpoint ? props[breakpoint] : undefined
  if (numberOfColumn == null && hasAtLeastOneBreakpoint) {
    numberOfColumn = 12
  }

  const offset = (breakpoint ? props[`${breakpoint}Offset`] : undefined) || 0

  return {
    numberOfColumn,
    offset,
    offsetDirection: props.offsetDirection!,
    offsetType: props.offsetType!,
    spacing: (props as GridProps).spacing!,
    hasAtLeastOneBreakpoint,
    isFirstRow: (props as any).isFirstRow,
    isLastRow: (props as any).isLastRow,
    isFirstColumn: (props as any).isFirstColumn,
    isLastColumn: (props as any).isLastColumn,
    fullWidth: props.fullWidth,
    direction: context.direction,
    alignItems: context.alignItems,
    containerSize: context.containerSize,
    noWrap: context.noWrap,
    overflow: context.overflow,
    alignSelf: props.alignSelf,
  }
}

// TODO: TO BE FIXED - Upper case on the first letter needed otherwise you get an exception when running cypress test
export const GetContainerStyle = (
  props: GridContainerPublicProps,
  extra: any = {},
): GridContainerStyle => {
  return {
    direction: props.direction!,
    justify: props.justify!,
    alignItems: props.alignItems!,
    alignContent: props.alignContent!,
    nestedGrid: extra.nestedGrid,
    containerSize: extra.containerSize,
    noWrap: extra.noWrap,
    overflow: props.overflow,
    height: props.height!,
  }
}

export const getStyle = (props: GridProps, extra?: any): GridStyle => {
  const containerStyle = props.container
    ? GetContainerStyle(props as GridContainerPublicProps, extra)
    : undefined
  const itemStyle = !props.container ? GetItemStyle(props as GridItemPublicProps, extra) : undefined

  return {
    spacing: props.spacing!,
    container: props.container!,
    break: props.break,
    containerStyle,
    itemStyle,
  }
}

const containerStyle = (props: GridStyleProps): GridContainerStyle =>
  props.styleProps.containerStyle
const itemStyle = (props: GridStyleProps): GridItemStyle => props.styleProps.itemStyle!

const isAutoLayout = (props: GridItemStyle) =>
  typeof props.numberOfColumn === 'boolean' && props.numberOfColumn
const isWidthLayout = (props: GridItemStyle) =>
  props.hasAtLeastOneBreakpoint &&
  typeof props.numberOfColumn !== 'boolean' &&
  props.numberOfColumn !== undefined
const isEqualLayout = (props: GridItemStyle) =>
  !props.hasAtLeastOneBreakpoint && props.numberOfColumn === undefined

const isItemsIsStretchedAligned = (props: GridItemStyle) => props.alignItems === 'stretch'

const isRowDirection = (props: GridStyleProps) =>
  containerStyle(props).direction === 'row' || containerStyle(props).direction === 'row-reverse'

const isItemRowDirection = (props: GridStyleProps) =>
  itemStyle(props).direction === 'row' || itemStyle(props).direction === 'row-reverse'

const isFreeOffset = (props: GridItemStyle) => props.offsetType === 'free'
const isOffset = (value?: number) => typeof value !== 'undefined'
const getOffset = (props: GridItemStyle) => {
  if (props.offset === 0) {
    return 0
  }

  const rowDirection = includes(['row', 'row-reverse'], props.direction)
  const containerSize = !rowDirection && props.containerSize ? `${props.containerSize}px` : '100%'
  return isFreeOffset(props)
    ? `${props.offset}px`
    : `calc(calc(${containerSize} / calc(12 / ${props.offset})) + 0px)`
}

const getAlignContent = (props: GridStyleProps) => {
  return containerStyle(props).alignContent || containerStyle(props).alignItems
}

const HeightLayout = css<GridStyleProps>`
  box-sizing: border-box;
  flex-grow: 0;
  max-height: ${(props) => `${(+itemStyle(props).numberOfColumn / 12) * 100}%`};
  flex-basis: ${(props) => `${(+itemStyle(props).numberOfColumn / 12) * 100}%`};
`

const WidthLayout = css<GridStyleProps>`
  box-sizing: border-box;
  flex-grow: 0;
  max-width: ${(props) => `${(+itemStyle(props).numberOfColumn / 12) * 100}%`};
  flex-basis: ${(props) => `${(+itemStyle(props).numberOfColumn / 12) * 100}%`};
`

const RowOffset = css<GridStyleProps>`
  ${(props) =>
    itemStyle(props).offsetDirection === 'left' &&
    css`
      margin-left: ${getOffset(itemStyle(props))};
    `}
  ${(props) =>
    itemStyle(props).offsetDirection === 'right' &&
    css`
      margin-right: ${getOffset(itemStyle(props))};
    `}
`

const ColumnOffset = css<GridStyleProps>`
  ${(props) =>
    itemStyle(props).offsetDirection === 'left' &&
    css`
      margin-top: ${getOffset(itemStyle(props))};
    `}
  ${(props) =>
    itemStyle(props).offsetDirection === 'right' &&
    css`
      margin-bottom: ${getOffset(itemStyle(props))};
    `}
`

const GridBreak = css<GridStyleProps>`
  flex-basis: 100%;
  height: 0;
`

export const ContainerContent = styled.div<GridStyleProps>`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  flex-direction: ${(props) => containerStyle(props).direction};
  align-items: ${(props) => containerStyle(props).alignItems};
  align-content: ${(props) => getAlignContent(props)};
  justify-content: ${(props) => containerStyle(props).justify};
  margin: ${(props) => `calc(${props.styleProps.spacing * -1}px / 2)`};
  height: ${(props) => `calc(100% + ${props.styleProps.spacing}px)`};

  ${(props) =>
    containerStyle(props).noWrap &&
    css`
      flex-wrap: nowrap;
    `}

  ${(props) =>
    !isRowDirection(props) &&
    css`
      height: ${`calc(100% + ${props.styleProps.spacing}px)`};
    `}
`

export const Root = styled.div<GridStyleProps>`
  ${(props) =>
    props.styleProps.container &&
    css`
      display: flex;
      flex-flow: column;
      height: ${containerStyle(props).height};

      ${!isRowDirection(props) &&
      css`
        min-height: 100%;
      `}

      ${isRowDirection(props) &&
      css`
        min-width: 100%;
      `}
    `}

  ${(props) => props.styleProps.break && GridBreak}

  ${(props) =>
    !props.styleProps.container &&
    !props.styleProps.break &&
    css`
      padding: ${`calc(${props.styleProps.spacing}px / 2)`};
      display: flex;
      align-items: stretch;
      justify-content: stretch;
      position: relative;
      align-self: ${itemStyle(props).alignSelf};

      ${isItemRowDirection(props) &&
      css`
        ${isWidthLayout(itemStyle(props)) && WidthLayout}
        ${isOffset(itemStyle(props).offset) && RowOffset}

      ${isEqualLayout(itemStyle(props)) &&
        css`
          flex: 1 1 0;
          max-width: 100%;
          box-sizing: border-box;
        `}
      `}

      ${!isItemRowDirection(props) &&
      css`
        max-width: 100%;

        ${isWidthLayout(itemStyle(props)) && HeightLayout}
        ${isOffset(itemStyle(props).offset) && ColumnOffset}

      ${isEqualLayout(itemStyle(props)) &&
        css`
          flex: 1 1 auto;
          max-height: 100%;
          box-sizing: border-box;
        `}
      `}

    & > * {
        flex-grow: 1;
      }
    `}
`
