import type {
  // spacing
  Spacing2Values,
  Spacing3Values,
  Spacing4Values,
  Spacing,
  Property,

  // padding
  Padding,
  PaddingType,

  // margin
  Margin,
  MarginType,
} from '../models'
import { trim, isEmpty } from '../../../utils/lodash'
import { getValueAsPixel } from '../../../customisations'

const isSingleString = (value: Spacing) =>
  typeof value === 'string' && trim(value).toString().split(' ').length === 1

const isSingleNumber = (value: Spacing) => typeof value === 'number'

const isSpacing1Value = (value: Spacing): value is string | number =>
  isSingleString(value) || isSingleNumber(value)

const isSpacing2Values = (value: Spacing): value is Spacing2Values =>
  !isSpacing1Value(value) &&
  (value as Spacing4Values)?.top === undefined &&
  (value as Spacing4Values)?.right === undefined &&
  (value as Spacing4Values)?.bottom === undefined &&
  (value as Spacing4Values)?.left === undefined

const isSpacing3Values = (value: Spacing): value is Spacing3Values =>
  !isSpacing1Value(value) &&
  (value as Spacing2Values)?.topBottom === undefined &&
  (value as Spacing4Values)?.right === undefined &&
  (value as Spacing4Values)?.left === undefined

const isSpacing4Values = (value: Spacing): value is Spacing3Values =>
  !isSpacing1Value(value) &&
  (value as Spacing2Values)?.topBottom === undefined &&
  (value as Spacing2Values)?.leftRight === undefined

/**
 * Format the spacing requested to match the traditional 4 values
 * @param value value to format
 */
export const getSpacing = (value: Spacing) => {
  // Check value is provided correctly
  const mixCheck = [
    isSpacing1Value(value),
    isSpacing2Values(value),
    isSpacing3Values(value),
    isSpacing4Values(value),
  ]

  if (mixCheck.filter((x) => x === true).length !== 1) {
    console.warn('[PRISMA] You can not mix the available format.')
    return 0
  }

  if (isSpacing1Value(value)) {
    const currentValue = getValueAsPixel(value as string | number)
    return `${currentValue} ${currentValue} ${currentValue} ${currentValue}`
  }

  if (isSpacing2Values(value)) {
    return `${getValueAsPixel(value.topBottom as string | number)} ${getValueAsPixel(
      value.leftRight as string | number,
    )} ${getValueAsPixel(value.topBottom as string | number)} ${getValueAsPixel(
      value.leftRight as string | number,
    )}`
  }

  if (isSpacing3Values(value)) {
    return `${getValueAsPixel(value.top as string | number)} ${getValueAsPixel(
      value.leftRight as string | number,
    )} ${getValueAsPixel(value.bottom as string | number)} ${getValueAsPixel(
      value.leftRight as string | number,
    )}`
  }

  const margin4Values = value as Spacing4Values

  return `${getValueAsPixel(margin4Values.top as string | number)} ${getValueAsPixel(
    margin4Values.right as string | number,
  )} ${getValueAsPixel(margin4Values.bottom as string | number)} ${getValueAsPixel(
    margin4Values.left as string | number,
  )}`
}

/**
 * Return the padding as top-right-bottom-left i.e `10px 10px 10px 10px`
 * @param defaultValue Default padding
 * @param type Type of border requested
 */
export const getPadding = (
  value: Padding,
  defaultValue?: Spacing,
  type: PaddingType = 'padding',
) => {
  const hasIndividualBorder =
    value.paddingRight || value.paddingLeft || value.paddingTop || value.paddingBottom

  // Check padding and (paddingRight|paddingLeft|paddingTop|paddingBottom) are not used at the same time
  if (!isEmpty(value.padding) && hasIndividualBorder) {
    console.warn('You can not mix padding. Use padding or the other.')
    return 0
  }

  if (type !== 'padding') {
    return getSpecificPadding(value, defaultValue as Property, type)
  }

  const padding = value.padding!

  if (padding === 0) {
    // No border has been provided for the requested type
    return 0
  }

  if (!padding && defaultValue === 0) {
    // No default border has been provided
    return 0
  }

  if (padding) {
    return getSpacing(padding)
  }

  return getSpacing(defaultValue)
}

const getSpecificPadding = (
  value: Padding,
  defaultValue?: Property,
  type: PaddingType = 'padding',
) => {
  const currentDefaultValue = getValueAsPixel(defaultValue)

  const paddingTop =
    getValueAsPixel(value.paddingTop) ||
    (type === 'paddingTop' ? currentDefaultValue : undefined) ||
    '0px'
  const paddingRight =
    getValueAsPixel(value.paddingRight) ||
    (type === 'paddingRight' ? currentDefaultValue : undefined) ||
    '0px'
  const paddingBottom =
    getValueAsPixel(value.paddingBottom) ||
    (type === 'paddingBottom' ? currentDefaultValue : undefined) ||
    '0px'
  const paddingLeft =
    getValueAsPixel(value.paddingLeft) ||
    (type === 'paddingLeft' ? currentDefaultValue : undefined) ||
    '0px'

  return `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`
}

/**
 * Return the margin as top-right-bottom-left i.e `10px 10px 10px 10px`
 * @param defaultValue Default margin
 * @param type Type of border requested
 */
export const getMargin = (value: Margin, defaultValue?: Spacing, type: MarginType = 'margin') => {
  const hasIndividualBorder =
    value.marginRight || value.marginLeft || value.marginTop || value.marginBottom

  // Check margin and (marginRight|marginLeft|marginTop|marginBottom) are not used at the same time
  if (value.margin && hasIndividualBorder) {
    console.warn('You can not mix margin. Use margin or the other.')
    return 0
  }

  if (type !== 'margin') {
    return getSpecificMargin(value, defaultValue as Property, type)
  }

  const margin = value.margin!
  if (margin === 0) {
    // No border has been provided for the requested type
    return 0
  }

  if (!margin && defaultValue === 0) {
    // No default border has been provided
    return 0
  }

  if (margin) {
    return getSpacing(margin)
  }

  return getSpacing(defaultValue)
}

const getSpecificMargin = (
  value: Margin,
  defaultValue?: Property,
  type: MarginType = 'margin',
): Spacing => {
  const currentDefaultValue = getValueAsPixel(defaultValue)

  const marginTop =
    getValueAsPixel(value.marginTop) ||
    (type === 'marginTop' ? currentDefaultValue : undefined) ||
    '0px'
  const marginRight =
    getValueAsPixel(value.marginRight) ||
    (type === 'marginRight' ? currentDefaultValue : undefined) ||
    '0px'
  const marginBottom =
    getValueAsPixel(value.marginBottom) ||
    (type === 'marginBottom' ? currentDefaultValue : undefined) ||
    '0px'
  const marginLeft =
    getValueAsPixel(value.marginLeft) ||
    (type === 'marginLeft' ? currentDefaultValue : undefined) ||
    '0px'

  return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`
}
