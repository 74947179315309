import { useCallback } from 'react'
import type { FormControlContextProps } from '../FormControl.context'
import { isFilled } from '../../utils'

/**
 * Notify the form control when the input is filled or emptied
 * @param formControl context of the form control
 */
const useCheckDirty = (formControl: FormControlContextProps) => {
  const onFilled = formControl && formControl.onFilled
  const onEmpty = formControl && formControl.onEmpty

  return useCallback(
    (obj: any) => {
      if (isFilled(obj)) {
        if (onFilled) {
          onFilled()
        }
      } else if (onEmpty) {
        onEmpty()
      }
    },
    [onFilled, onEmpty],
  )
}

export default useCheckDirty
