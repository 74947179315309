import * as React from 'react'
import { faLightArrowDown } from '../../../utils/fontawesome'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'
import type { StyledProps } from '../../../providers'
import type { TableSortLabelContext } from './TableSortLabel.composition'

export interface TableSortLabelPublicProps {
  /**
   * If `true`, the label will have the active styling (should be true for the sorted column).
   * @default false
   */
  active?: boolean
  /**
   * The current sort direction.
   * @default 'asc'
   */
  direction?: 'asc' | 'desc'
  /**
   * Hide sort icon when active is false.
   * @default false
   */
  hideSortIcon?: boolean
  /**
   * True if the label can grow if necessary
   */
  labelCanGrow?: boolean
  /**
   * Position of the icon
   */
  iconPosition?: 'right' | 'left'
  /**
   * Handler when the user clicks on the label.
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * The table cell contents.
   */
  children?: React.ReactNode
}

export type TableSortLabelProps = TableSortLabelPublicProps & StyledProps<TableSortLabelContext>

export interface TableSortLabelCoreStyle {
  direction: 'asc' | 'desc'
  iconPosition: 'right' | 'left'
  labelCanGrow: boolean
}

const TableSortLabel = React.forwardRef(
  (props: TableSortLabelProps, forwardRef: React.Ref<HTMLSpanElement>) => {
    const { ref } = useResponsive<HTMLSpanElement>({
      ref: forwardRef,
    })

    const getCoreStyle = (): TableSortLabelCoreStyle => ({
      direction: props.direction!,
      labelCanGrow: props.labelCanGrow!,
      iconPosition: props.iconPosition!,
    })

    const renderIcon = () => {
      const { Icon } = props.styled!
      return !props.hideSortIcon || props.active ? (
        <Icon
          src={faLightArrowDown}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'icon')}
        />
      ) : null
    }

    const renderLabel = () => {
      const { Label } = props.styled!
      return (
        <Label
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label')}
        >
          {props.children}
        </Label>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        role="row"
        onClick={props.onClick}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderLabel()}
        {renderIcon()}
      </Root>
    )
  },
)

TableSortLabel.defaultProps = {
  active: false,
  direction: 'asc',
  hideSortIcon: false,
  labelCanGrow: false,
  iconPosition: 'right',
}

export default TableSortLabel
