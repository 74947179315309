import type { IconStyleProps } from './DevIcon.styles'
import * as theme from '../../../themes/Developer/common'

export const sizes: theme.Spacing1Value = {
  xxs: 8,
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 18,
  xxl: 24,
  xxxl: 32,
  xxxxl: 48,
}

/** Size */
export const getSize = (props: IconStyleProps) =>
  typeof props.styleProps.size === 'string' ? sizes[props.styleProps.size] : props.styleProps.size
