import ListInteractiveItem, { ListInteractiveItemPublicProps } from '../../ListInteractiveItem'
import Theme from '../../../../../../../themes/Developer/theme'

import { withCustomisedStyle } from '../../../../../../../providers'
import { ListItemContext } from '../../../../ListItem.composition'

import Customisations from './DevListInteractiveItem.customisations'
import { ListInteractiveItemCustomisations } from './customisations'

import Styles from './DevListInteractiveItem.styles'

export const DevListInteractiveItem = withCustomisedStyle<
  ListInteractiveItemPublicProps,
  ListItemContext,
  ListInteractiveItemCustomisations
>(ListInteractiveItem, Theme, Styles, Customisations, 'ListInteractiveItem')

export type DevListInteractiveItemCustomisations = ListInteractiveItemCustomisations
export type DevListInteractiveItemProps = ListInteractiveItemPublicProps &
  ListInteractiveItemCustomisations
