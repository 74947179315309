import Mandate from "./Mandate";
import MandateAction from "./MandateAction";
import {AccountIdentifierType} from "./AccountIdentifier";
import MandateStatus from "./MandateStatus";
import MandateSubmitActionResult from "./MandateSubmitActionResult";
import {PeriodAlignment} from "./PeriodAlignment";
import {PeriodType} from "./PeriodType";

class MandateService {
    async getMandate(
        id: string,
        token: string | null
    ): Promise<Mandate | null> {
        const response = await fetch(`/api/vrp-consents/${id}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } });
        switch (response.status) {
            case 200:
                return response.json();
            case 404:
                return null;
            default:
                throw new Error(response.statusText);
        }
    }

    async submitMandate(
        id: string,
        action: MandateAction,
        token: string | null
    ): Promise<MandateSubmitActionResult> {
        const response = await fetch(
            `/api/vrp-consents/${id}/action`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ action })
            }
        );
        switch (response.status) {
            case 202:
                return { type: "Accepted", return_uri: await response.text() };
            case 409:
                return { type: "AlreadyActioned", return_uri: await response.text() };
            default:
                throw new Error(response.statusText);
        }
    }
}

const singleImmediatePaymentService = new MandateService();

export default singleImmediatePaymentService;
