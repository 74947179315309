import React from 'react';
import ReactJson from 'react-json-view';

interface Props {
    readonly json: string | null | undefined;
}

const JsonViewer: React.FC<Props> = ({ json }) => {
    if (json === undefined) {
        return <pre>undefined</pre>;
    }
    if (json === null) {
        return <pre>null</pre>;
    }
    const src = JSON.parse(json);
    return <ReactJson src={src} theme="summerfruit" collapsed={1}  enableClipboard={false}/>
}

export default JsonViewer;