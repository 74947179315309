import MenuItem, { MenuItemPublicProps } from '../../MenuItem'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevMenuItem.styles'
import Customisations from './DevMenuItem.customisations'
import type { MenuItemContext } from '../../MenuItem.composition'
import type { MenuItemCustomisations } from './customisations'

export const DevMenuItem = withCustomisedStyle<
  MenuItemPublicProps,
  MenuItemContext,
  MenuItemCustomisations
>(MenuItem, Theme, Styles, Customisations, 'MenuItem')

export type DevMenuItemCustomisations = MenuItemCustomisations
export type DevMenuItemProps = MenuItemPublicProps & MenuItemCustomisations
