import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { TableFooterContext } from '../../TableFooter.composition'
import type { StyleProps } from '../../../../../types'

export type TableFooterStyleProps = StyleProps & ThemeProps

const Root = styled.tfoot<TableFooterStyleProps>`
  display: table-table-footer-group;
`

const Components: TableFooterContext = {
  Root,
}

export default Components
