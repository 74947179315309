import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'

import { DevFontAwesomeIcon } from '../../../Icon/components/FontAwesomeIcon/index'

import { CheckboxCoreStyle } from '../../Checkbox'
import {
  CheckboxCustomisations,
  getBgColour,
  getBorder,
  getFontSize,
  getHeight,
  getLabelColour,
  getTickColour,
} from './customisations'
import { Customisations } from './DevCheckbox.customisations'
import { CheckboxContext } from '../../Checkbox.composition'
import { DisabledState, ReadOnlyState } from '../../../themes/Developer/styles'
import * as themeStyles from '../../../themes/Developer/styles'

import { StyleProps } from '../../../types'

export type CheckboxStyleProps = StyleProps<CheckboxCoreStyle, CheckboxCustomisations> & ThemeProps

const TickIcon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<CheckboxStyleProps>`
  font-size: ${(props) => `calc(${getFontSize(props)} + 6px)`};
`

const Tick = styled.div<CheckboxStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-shrink: 0;
  line-height: inherit;
  font-size: inherit;
  align-self: center;

  /** Size */
  width: ${(props) => getHeight(props)};
  height: 100%;

  /** Visual */
  border-top: ${(props) => getBorder(props)};
  border-bottom: ${(props) => getBorder(props)};
  border-left: ${(props) => getBorder(props)};
  border-right: none;

  :hover {
    color: ${(props) => getTickColour(props)};
  }
`

const Label = styled.label<CheckboxStyleProps>`
  box-sizing: border-box;
  align-self: center;
  flex-grow: 1;
  line-height: inherit;
  font-size: inherit;
  text-align: center;
  height: 100%;

  /** Color */
  color: ${(props) => getLabelColour(props)};

  /** Typography */
  font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.regular};

  /** Visual */
  border: ${(props) => getBorder(props)};

  /** Spacing */
  padding: 0px ${(props) => props.theme.spaces.xs};
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const Root = styled.div<CheckboxStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  outline: none;

  /** Color */
  background-color: ${(props) => getBgColour(props)};

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};

  /* Visual */
  height: ${(props) => getHeight(props)};
  line-height: ${(props) => getHeight(props)};

  ${(props) => props.styleProps.disabled && DisabledState};
  ${(props) => props.styleProps.readOnly && ReadOnlyState};

  * {
    cursor: inherit !important;
  }
`

const Components: CheckboxContext = {
  Root,
  Input,
  Label,
  Tick,
  TickIcon,
}

export default Components
