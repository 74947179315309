import type { SelectFieldStyleProps } from './DevSelectField.styles'
import type { SelectFieldPublicProps } from '../../SelectField'
import type { SelectCustomisations } from '../../../Select/themes/Developer/customisations'
import type { FieldCustomisations } from '../../../Field/themes/Developer/DevField.customisations'

export interface SelectFieldCustomisations extends FieldCustomisations {
  Select?: SelectCustomisations
}

export const Customisations = {
  Select: (props: SelectFieldStyleProps) => props.customisations.Select!,
  Field: (props: SelectFieldStyleProps) => ({
    Label: props.customisations?.Label,
    HelperText: props.customisations.HelperText,
    ValidityText: props.customisations.ValidityText,
    Tooltip: props.customisations.Tooltip,
    ValidityStatus: props.customisations.ValidityStatus,
  }),
}

export default (
  props: SelectFieldPublicProps & SelectFieldCustomisations,
): SelectFieldCustomisations => ({
  Select: props.Select || {},
  Label: props.Label || {},
  HelperText: props.HelperText || {},
  ValidityText: props.ValidityText || {},
  Tooltip: props.Tooltip || {},
  ValidityStatus: props.ValidityStatus || {},
})
