import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevStepIcon } from '../../../StepIcon'
import { getColour, getFontSize, StepLabelCustomisations } from './customisations'
import type { StepLabelCoreStyle } from '../../StepLabel'
import type { StepLabelContext } from '../../StepLabel.composition'
import type { StyleProps } from '../../../../../types'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type StepLabelStyleProps = StyleProps<StepLabelCoreStyle, StepLabelCustomisations> &
  ThemeProps

const DisableEffectRoot = css<StepLabelStyleProps>`
  opacity: ${(props) => props.theme.visuals.opacity.disable};
`

const VerticalRoot = css<StepLabelStyleProps>`
  padding-bottom: ${(props) => props.theme.spaces.xs};
`

const AlternativeLabel = css<StepLabelStyleProps>`
  text-align: center;
  margin-top: 16px;
`

const ActiveLabel = css<StepLabelStyleProps>``

const CompletedLabel = css<StepLabelStyleProps>``

const AlternativeLabelIcon = css<StepLabelStyleProps>`
  padding-right: 0;
`

const AlternativeLabelRoot = css<StepLabelStyleProps>`
  flex-direction: column;
`

const Label = styled.span<StepLabelStyleProps>`
  width: 100%;
  color: ${(props) => getColour(props)};
  font-size: ${(props) => getFontSize(props)};
  padding-left: ${(props) => props.theme.spaces.xs};

  ${(props) => props.styleProps.alternativeLabel && AlternativeLabel};
  ${(props) => props.styleProps.active && ActiveLabel};
  ${(props) => props.styleProps.completed && CompletedLabel};
`

const Icon = styled(DevStepIcon)<StepLabelStyleProps>`
  display: flex;
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  ${(props) => props.styleProps.alternativeLabel && AlternativeLabelIcon};
`

const Root = styled.div<StepLabelStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  /** Typography */
  ${themeStyles.FontFamily}

  ${(props) => props.styleProps.alternativeLabel && AlternativeLabelRoot};
  ${(props) => props.styleProps.disableEffect && DisableEffectRoot}
  ${(props) => props.styleProps.orientation === 'vertical' && VerticalRoot}
`

const Components: StepLabelContext = {
  Root,
  Icon,
  Label,
}

export default Components
