import { CheckboxCustomisations, getTickColour } from './customisations'
import { FontAwesomeIconCustomisations as IconCustomisations } from '../../../Icon/components/FontAwesomeIcon/themes/Developer/customisations'
import type { CheckboxPublicProps } from '../../Checkbox'
import type { CheckboxStyleProps } from './DevCheckbox.styles'

export const Customisations = {
  Icon: (props: CheckboxStyleProps): IconCustomisations => ({
    colour: getTickColour(props),
    hoverColour: getTickColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: CheckboxPublicProps & CheckboxCustomisations): CheckboxCustomisations => ({
  bgColour: props.bgColour,
  border: props.border,
  Label: props.Label || {},
  Tick: props.Tick || {},
})
