export const getOffsetTop = (rect, vertical) => {
  let offset = 0

  if (typeof vertical === 'number') {
    offset = vertical
  } else if (vertical === 'center') {
    offset = rect.height / 2
  } else if (vertical === 'bottom') {
    offset = rect.height
  }

  return offset
}

export const getOffsetLeft = (rect, horizontal) => {
  let offset = 0

  if (typeof horizontal === 'number') {
    offset = horizontal
  } else if (horizontal === 'center') {
    offset = rect.width / 2
  } else if (horizontal === 'right') {
    offset = rect.width
  }

  return offset
}

export const getTransformOriginValue = (transformOrigin) => {
  return [transformOrigin.horizontal, transformOrigin.vertical]
    .map((n) => (typeof n === 'number' ? `${n}px` : n))
    .join(' ')
}

export const getScrollParent = (parent, child) => {
  let element = child
  let scrollTop = 0

  while (element && element !== parent) {
    element = element.parentElement
    scrollTop += element.scrollTop
  }
  return scrollTop
}

export const getAnchorEl = (anchorEl) => {
  return typeof anchorEl === 'function' ? anchorEl() : anchorEl
}
