import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { TableRowStyleProps } from '../DevTableRow.styles'

const hexOpacity = {
  100: 'FF',
  95: 'F2',
  90: 'E6',
  85: 'D9',
  80: 'CC',
  75: 'BF',
  70: 'B3',
  65: 'A6',
  60: '99',
  55: '8C',
  50: '80',
  45: '73',
  40: '66',
  35: '59',
  30: '4D',
  25: '40',
  20: '33',
  15: '26',
  10: '1A',
  5: '0D',
  0: '00',
}

/**
 * Adds opacity to hex colour:
 * source: https://stackoverflow.com/a/11019879/10518584
 * @param colour - the original colour
 * @param opacity - the opacity to apply
 * @returns filtered colour with opacity
 */
export const getColourWithOpacity = (colour: string, opacity?: keyof typeof hexOpacity) =>
  `${colour}${hexOpacity[opacity || 50]}`

/** Colour */
export const getColour = (props: TableRowStyleProps) =>
  theme.getColour(props, 'inherit', theme.getState(props.styleProps))

/** Background colour */
const defaultBgColour = (props: TableRowStyleProps) => ({
  bgColour: props.theme.colours.tertiary.neutral.white,
  hoverBgColour: getColourWithOpacity(props.theme.colours.tints.azure[100], 10),
  focusBgColour: getColourWithOpacity(props.theme.colours.tints.azure[100], 10),
})

export const getBgColour = (props: TableRowStyleProps) =>
  theme.getBgColour(props, defaultBgColour(props), theme.getState(props.styleProps))

const defaultEvenRowBgColour = (props: TableRowStyleProps) => ({
  ...defaultBgColour(props),
  bgColour: getColourWithOpacity(props.theme.colours.tints.charcoal[10]),
})

export const getEvenRowBgColour = (props: TableRowStyleProps) =>
  theme.getBgColour(props, defaultEvenRowBgColour(props), theme.getState(props.styleProps))
