import * as theme from '../../../../themes/Developer/proxima-nova'
import type { SelectStyleProps } from '../DevSelect.styles'
import { fontSizes } from '../../../../Input/themes/Developer/customisations'

/** Global */
/** Font size  */
export const getFontSize = (props: SelectStyleProps) => {
  // TODO: Why px and not rem?
  return `${props.theme.typography.proximaNova.fontSize[fontSizes[props.styleProps.size]].px}px`
}

/** Selection */
/** Font weight */
export const getSelectionFontWeight = (
  props: SelectStyleProps,
  defaultValue?: keyof theme.FontWeight,
) => {
  const customisations = theme.getCustomisations(props, 'Selection')
  return theme.getFontWeight(customisations, defaultValue || 'regular')
}

/** Option */
/** Font weight */
export const getOptionFontWeight = (
  props: SelectStyleProps,
  defaultValue?: keyof theme.FontWeight,
) => {
  const customisations = theme.getCustomisations(props, 'Option')
  return theme.getFontWeight(customisations, defaultValue || 'regular')
}
