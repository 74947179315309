import * as React from 'react'
import { faSolidCheck, faSolidQuestionCircle, faSolidTimes } from '../utils/fontawesome'
import { Grid } from '../Grid'
import type { FieldContext } from './Field.composition'
import type { FieldCustomisations } from './Field.customisations'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

export interface FieldPublicProps {
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id: string
  /**
   * The label content.
   */
  label: string | React.ReactNode
  /**
   * The helper text content.
   */
  helperText?: string | React.ReactNode
  /**
   * If `true`, the `input` element will be disabled.
   */
  disabled?: boolean
  /**
   * If `true`, the label will be displayed in an error state.
   */
  invalid?: boolean
  /**
   * If `true`, the label is displayed as required and the `input` element` will be required.
   */
  required?: boolean
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean
  /**
   * The variant to use.
   */
  variant?: 'filled' | 'outlined' | 'standard'
  /**
   * The size of the text field.
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Breakpoint the error and input components are displayed on the same line from
   */
  breakpoint?: string
  /**
   * Error message to display when the field is invalid
   */
  error?: string
  /**
   * Tooltip to display to the user
   */
  tooltip?: string | JSX.Element
  /**
   * True to display the validity status, false otherwise
   */
  withValidityStatus?: boolean
  /**
   * Attach a reference to the input component
   */
  inputRef?: React.RefObject<HTMLElement>
  /**
   * Component to display as input of the field
   */
  children?: React.ReactNode
}

interface FieldProps extends FieldPublicProps, StyledProps<FieldContext, FieldCustomisations> {}

export interface FieldCoreStyle {
  invalid?: boolean
  displayValidityStatus: boolean
}

const Field = React.forwardRef((props: FieldProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const isStandard = props.variant === 'standard'
  const displayValidityStatus = !props.readOnly && !props.disabled && props.withValidityStatus
  const hasIndicators = displayValidityStatus || props.tooltip

  const getCoreStyle = (): FieldCoreStyle => ({
    invalid: props.invalid,
    displayValidityStatus,
  })

  const renderLabel = () => {
    const { Label } = props.styled!
    return props.label ? (
      <Label
        htmlFor={props.id}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'label')}
      >
        {props.label}
      </Label>
    ) : null
  }

  const renderHelperText = () => {
    const { Helper } = props.styled!
    return props.helperText ? (
      <Helper
        id={`${props.id}-helper-text`}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'helper-text')}
      >
        {props.helperText}
      </Helper>
    ) : null
  }

  const renderTooltip = () => {
    if (!props.tooltip) {
      return null
    }

    const customisations = props.customisations?.Tooltip

    const { Tooltip } = props.styled!
    return typeof props.tooltip === 'string' ? (
      <Tooltip
        icon={customisations?.icon || faSolidQuestionCircle}
        message={props.tooltip}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'tooltip')}
      />
    ) : (
      props.tooltip
    )
  }

  const renderValidityStatus = () => {
    const { ValidationStatus } = props.styled!
    return displayValidityStatus ? (
      <ValidationStatus
        aria-label="Validation Status"
        src={props.invalid ? faSolidTimes : faSolidCheck}
        size="lg"
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'validation-status')}
      />
    ) : null
  }

  const renderIndicators = () => {
    const { Indicators } = props.styled!
    return (
      <Indicators styleProps={getCoreStyle()} customisations={props.customisations}>
        {renderTooltip()}
        {renderValidityStatus()}
      </Indicators>
    )
  }

  const renderInput = () => {
    const { Input } = props.styled!
    return (
      <Input
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'component')}
      >
        {!isStandard && renderLabel()}
        {props.children}
      </Input>
    )
  }

  const renderError = () => {
    const { Error } = props.styled!
    return props.error && props.invalid ? (
      <Error
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'validation-message')}
      >
        {props.error}
      </Error>
    ) : null
  }

  const renderInputCell = () => <Grid>{renderInput()}</Grid>

  const renderIndicatorsCell = () => {
    return hasIndicators ? <Grid xs>{renderIndicators()}</Grid> : null
  }

  const { Root } = props.styled!
  return (
    <Root
      ref={ref}
      className={props.className}
      disabled={props.disabled}
      invalid={props.invalid}
      required={props.required}
      variant={props.variant}
      readOnly={props.readOnly}
      size={props.size}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {isStandard && renderLabel()}
      <Grid container alignItems="center" spacing={5}>
        {renderInputCell()}
        {renderIndicatorsCell()}
      </Grid>
      {renderHelperText()}
      {renderError()}
    </Root>
  )
})

Field.defaultProps = {
  variant: 'standard',
  size: 'medium',
  breakpoint: 'md',
  withValidityStatus: true,
}

export default Field
