/* eslint-disable no-param-reassign */
import React from 'react'

interface IntercomProps {
  email?: string | null
  userId?: string
}

const useIntercom = (appID, userData: IntercomProps = {}) => {
  const [loaded, setLoaded] = React.useState(false)
  const { email, userId } = userData

  const currentWindow = window as any

  React.useEffect(() => {
    if (!currentWindow.Intercom) {
      ;((w: any, d: any, id: any, s?: any, x?: any) => {
        const i: any = function build() {
          // eslint-disable-next-line prefer-rest-params
          return (build as any).c(arguments)
        }
        i.q = []
        i.c = (args: any) => {
          i.q.push(args)
        }
        w.Intercom = i
        s = d.createElement('script')
        s.async = 1
        s.src = `https://widget.intercom.io/widget/${id}`
        d.head.appendChild(s)
      })(window, document, appID)
    }

    return () => {
      if (currentWindow.Intercom) {
        currentWindow.Intercom('shutdown')
        setLoaded(false)
        currentWindow.Intercom = null
      }
    }
  }, [appID, email, userId])

  React.useEffect(() => {
    if (currentWindow) {
      if (loaded) {
        currentWindow.Intercom('update', { app_id: appID, ...userData })
      } else {
        currentWindow.Intercom('boot', { app_id: appID, ...userData })
        setLoaded(true)
      }
    }
  }, [appID, userData])
}

export default useIntercom
