import type { CircularProgressPublicProps } from '../../CircularProgress'
import type { CircularProgressCustomisations } from './customisations'

export default (
  props: CircularProgressPublicProps & CircularProgressCustomisations,
): CircularProgressCustomisations => ({
  colour: props.colour,
  Spinner: {
    colour: props.Spinner?.colour,
  },
  Label: {
    colour: props.Label?.colour,
  },
})
