// utils
import * as common from '../../../../Common'

// types
import type { BorderProps, BorderType, Customisations } from '../../models'
import type { DeveloperTheme } from '../../../theme'
import type { ThemeProps } from '../../../themed-component'
import { State } from '../../../../Common'
import { CmBorder } from '../../customisations'

/** Borders */
const defaultThemedBorder = ({ visuals, colours }: DeveloperTheme) => ({
  width: visuals.borderSize,
  style: 'solid',
  colour: colours.tints.lightGrey[180],
})

export const getBorder = (
  props: Customisations<CmBorder, ThemeProps>,
  defaultValue?: BorderProps | 'none',
  type: BorderType = 'border',
  state?: State,
  asObject?: boolean,
) => {
  let currentDefaultValue = defaultValue || {}
  if (currentDefaultValue !== 'none') {
    currentDefaultValue = {
      ...defaultThemedBorder(props.theme),
      ...currentDefaultValue,
    }
  }

  return common.getBorder(props.customisations, currentDefaultValue, type, state, asObject)
}
