import Tab, { TabPublicProps } from '../../Tab'
import Theme from '../../../../../themes/Developer/theme'
import Styles from './DevTab.styles'
import Customisations from './DevTab.customisations'
import { withCustomisedStyle } from '../../../../../providers'
import type { TabContext } from '../../Tab.composition'
import type { TabCustomisations } from './customisations'

export const DevTab = withCustomisedStyle<TabPublicProps, TabContext, TabCustomisations>(
  Tab,
  Theme,
  Styles,
  Customisations,
  'Tab',
)

export type DevTabCustomisations = TabCustomisations
export type DevTabProps = TabPublicProps & TabCustomisations
