import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import type { CollapseContext } from '../../Collapse.composition'
import type { CollapseCoreStyle } from '../../Collapse'
import type { StyleProps } from '../../../types'
import * as theme from '../../../themes/Developer/proxima-nova'

export type CollapseStyleProps = StyleProps<CollapseCoreStyle> & ThemeProps

const getFontSize = (fontSize: keyof theme.FontSize) => css`
  font-size: ${(props) =>
    theme.getValueAsPixel(props.theme.typography.proximaNova.fontSize[fontSize].px)};
`

const HorizontalInnerWrapper = css<CollapseStyleProps>`
  width: auto;
  height: 100%;
`

const HorizontalWrapper = css<CollapseStyleProps>`
  width: auto;
  height: 100%;
`

const HorizontalRoot = css<CollapseStyleProps>`
  height: auto;
  width: 0;

  ${(props) =>
    props.styleProps.entered &&
    css`
      width: auto;
    `};
`

const VerticalRoot = css<CollapseStyleProps>`
  ${(props) =>
    props.styleProps.entered &&
    css`
      height: auto;
      overflow: visible;
    `};
`

const InnerWrapper = styled.div<CollapseStyleProps>`
  width: 100%;

  ${(props) => props.styleProps.orientation === 'horizontal' && HorizontalInnerWrapper};
`

const Wrapper = styled.div<CollapseStyleProps>`
  display: flex;
  width: 100%;

  ${(props) => props.styleProps.orientation === 'horizontal' && HorizontalWrapper};
`

const Root = styled.div<CollapseStyleProps>`
  height: 0;
  overflow: hidden;

  ${getFontSize('bodyM')}
  ${(props) => props.styleProps.orientation === 'horizontal' && HorizontalRoot};
  ${(props) => props.styleProps.orientation === 'vertical' && VerticalRoot};
`

const Components: CollapseContext = {
  Root,
  Wrapper,
  InnerWrapper,
}

export default Components
