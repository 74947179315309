import React from 'react'

export interface FormControlContextProps {
  required?: boolean
  disabled?: boolean
  invalid?: boolean
  readOnly?: boolean
  filled?: boolean
  focused?: boolean
  hovered?: boolean
  variant?: string
  size?: string
  onEmpty?: () => void
  onFilled?: () => void
  onFocus?: (event: React.FocusEvent) => void
  onBlur?: (event: React.FocusEvent) => void
  onHover?: (event: React.MouseEvent) => void
  onLeave?: (event?: React.MouseEvent) => void
}

export const FormControlContext = React.createContext<FormControlContextProps | undefined>(
  undefined,
)
FormControlContext.displayName = 'FormControlContext'

export const useFormControlContext = () => React.useContext(FormControlContext)
/**
 * Return the form control context if exist, otherwise the props of the component
 * @param props pros of the component
 */
export const useFormControl = (props: any): FormControlContextProps => {
  const context = useFormControlContext()
  if (!context) {
    return props
  }

  const keys = Object.keys(context)
  return keys.reduce((accumulator: any, currentValue: any) => {
    accumulator[currentValue] = props[currentValue]

    if (context) {
      accumulator[currentValue] = context![currentValue]
    }

    return accumulator
  }, {})
}
