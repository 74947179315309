import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { Customisations } from './DevListItemIcon.customisations'
import { DevFontAwesomeIcon } from '../../../../../Icon/components/FontAwesomeIcon'
import { DevIcon } from '../../../../../Icon'
import type { StyleProps } from '../../../../../types'
import type { ListItemIconContext } from '../../ListItemIcon.composition'
import type { ListItemIconCustomisations } from './customisations'

export type ListItemIconStyleProps = StyleProps<unknown, ListItemIconCustomisations> & ThemeProps

const FontAwesomeIcon = styled(DevFontAwesomeIcon).attrs(
  Customisations.FontAwesomeIcon,
)<ListItemIconStyleProps>`
  cursor: inherit;
`

const Icon = styled(DevIcon).attrs(Customisations.Icon)<ListItemIconStyleProps>`
  cursor: inherit;
`

const Root = styled.div<ListItemIconStyleProps>`
  margin-right: ${(props) => props.theme.spaces.xs};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Components: ListItemIconContext = {
  Root,
  FontAwesomeIcon,
  Icon,
}

export default Components
