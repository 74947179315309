import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { TablePaginationStyleProps } from '../DevTablePagination.styles'

/** Colour */
export const getColour = (props: TablePaginationStyleProps) =>
  theme.getColour(
    props,
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )

/** Background colour */
export const getBgColour = (props: TablePaginationStyleProps) =>
  theme.getBgColour(
    props,
    props.theme.colours.tertiary.neutral.white,
    theme.getState(props.styleProps),
  )

/** Select > Selection */
/** Colour */
export const getSelectionColour = (props: TablePaginationStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Select.Selection'),
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )
