import * as React from 'react'
import type { CheckboxGroupContext } from '../../CheckboxGroup.composition'
import type { StyledProps } from '../../../providers'
import CheckboxGroup, { CheckboxGroupPublicProps } from '../../CheckboxGroup'
import useResponsive from '../../../hooks/useResponsive'

export type NativeCheckboxGroupPublicProps = CheckboxGroupPublicProps

export interface NativeCheckboxGroupProps
  extends NativeCheckboxGroupPublicProps,
    StyledProps<CheckboxGroupContext> {}

const NativeCheckboxGroup = React.forwardRef(
  (props: NativeCheckboxGroupProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    // TODO: [Dev Only] check the children are NativeCheckbox

    return <CheckboxGroup className={props.className} ref={ref} {...props} />
  },
)

NativeCheckboxGroup.defaultProps = {
  spacing: 10,
}

export default NativeCheckboxGroup
