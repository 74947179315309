import * as React from 'react'
import type { DialogContentContext } from './DialogContent.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'

export interface DialogContentPublicProps {
  /**
   * Display the top and bottom dividers.
   * @default false
   */
  dividers?: boolean
  /**
   * Content of the title
   */
  children?: React.ReactNode
}

export type DialogContentProps = DialogContentPublicProps & StyledProps<DialogContentContext>

export interface DialogCoreStyle {
  dividers: boolean
}

const DialogContent = React.forwardRef(
  (props: DialogContentProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): unknown => ({
      dividers: props.dividers!,
    })

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

export default DialogContent
