import * as React from 'react'
import TableContext from '../../Table.context'
import TableLevel2Context from '../../TableLevel2.context'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'
import type { StyledProps } from '../../../providers'
import type { TableCellContext } from './TableCell.composition'

export interface TableCellPublicProps {
  /**
   * Set the text-align on the table cell content.
   *
   * Monetary or generally number fields **should be right aligned** as that allows
   * you to add them up quickly in your head without having to worry about decimals.
   * @default 'inherit'
   */
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  /**
   * Set aria-sort direction.
   */
  sortDirection?: 'asc' | 'desc'
  /**
   * Size of the table
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Set scope attribute.
   */
  scope?: string
  /**
   * Specify the cell type.
   * The prop defaults to the value inherited from the parent TableHead, TableBody, or TableFooter components.
   */
  variant?: 'body' | 'footer' | 'header'
  /**
   * Sets the number of rows a cell should span
   */
  rowSpan?: number
  /**
   * Specifies the number of columns a cell should span
   */
  colSpan?: number
  /**
   * Width of the cell
   */
  width?: number | string
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component?: string | React.ReactNode
  /**
   * The table cell contents.
   */
  children?: React.ReactNode
}

export type TableCellProps = TableCellPublicProps & StyledProps<TableCellContext>

export interface TableCellCoreStyle {
  variant?: 'body' | 'footer' | 'header'
  size: 'small' | 'medium'
  align: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  stickyHeader: boolean
  width: number | string
}

const TableCell = React.forwardRef((props: TableCellProps, forwardRef: React.Ref<HTMLElement>) => {
  const { ref } = useResponsive<HTMLElement>({ ref: forwardRef })

  const table = React.useContext(TableContext)
  const tableLevel2 = React.useContext(TableLevel2Context)

  const isHeaderCell = tableLevel2 && tableLevel2.variant === 'header'

  const size = props.size || (table && table.size ? table.size : 'medium')
  const variant = props.variant || (tableLevel2 && tableLevel2.variant)

  const stickyHeader = variant === 'header' && table && table.stickyHeader

  const getCoreStyle = (): unknown => ({
    size,
    variant,
    align: props.align!,
    stickyHeader,
    width: props.width!,
  })

  const getRole = () => {
    let role

    if (props.component) {
      role = isHeaderCell ? 'columnheader' : 'cell'
    }

    return role
  }

  const getTag = () => {
    let Component

    if (props.component) {
      Component = props.component
    } else {
      Component = isHeaderCell ? 'th' : 'td'
    }

    return Component
  }

  const getAriaSort = () => {
    let ariaSort = null

    if (props.sortDirection) {
      ariaSort = props.sortDirection === 'asc' ? 'ascending' : 'descending'
    }

    return ariaSort
  }

  const { Content, Root }: any = props.styled!
  return (
    <Root
      ref={ref}
      as={getTag()}
      className={props.className}
      aria-sort={getAriaSort()}
      role={getRole()}
      scope={props.scope}
      rowSpan={props.rowSpan}
      colSpan={props.colSpan}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      <Content styleProps={getCoreStyle()} {...getTestId(props, 'content')}>
        {props.children}
      </Content>
    </Root>
  )
})

TableCell.defaultProps = {
  align: 'inherit',
  width: 'unset',
}

export default TableCell
