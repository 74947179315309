import type { MenuPublicProps } from '../../Menu'
import type { MenuCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: MenuPublicProps & MenuCustomisations): MenuCustomisations => ({
  bgColour: props.bgColour,
  borderRight: props.borderRight,
})
