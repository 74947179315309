/* eslint-disable react/default-props-match-prop-types */
import * as React from 'react'

import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { PaperPublicProps } from '../Paper/Paper'
import type { StyledProps } from '../providers'
import { DataAttributesPrefix } from '../constants'
import {
  getAnchorEl,
  getOffsetLeft,
  getOffsetTop,
  getScrollParent,
  getTransformOriginValue,
} from './Popover.utils'
import { ownerDocument } from '../utils/document'
import { ownerWindow } from '../utils/window'
import { debounce } from '../utils/debounce'
import { getTestId } from '../utils/test'
import { PopoverContext } from './Popover.composition'

type PaperProps = Omit<PaperPublicProps, 'children' | 'role'>

export interface PopoverPublicProps {
  /**
   * A ref for imperative actions.
   * It currently only supports updatePosition() action.
   */
  action?: React.Ref<unknown>
  /**
   * A HTML element, or a function that returns it.
   * It's used to set the position of the popover.
   */
  anchorEl: HTMLElement
  /**
   * This is the position that may be used
   * to set the position of the popover.
   * The coordinates are relative to
   * the application's client area.
   */
  anchorPosition?: {
    left: number
    top: number
  }
  /**
   * This determines which anchor prop to refer to to set
   * the position of the popover.
   * @default 'anchorEl'
   */
  anchorReference?: 'anchorEl' | 'anchorPosition' | 'none'
  /**
   * This is the point on the anchor where the popover's
   * `anchorEl` will attach to. This is not used when the
   * anchorReference is 'anchorPosition'.
   *
   * Options:
   * vertical: [top, center, bottom];
   * horizontal: [left, center, right].
   * @default {
   *   vertical: 'top',
   *   horizontal: 'left',
   * }
   */
  anchorOrigin?: {
    horizontal: 'left' | 'center' | 'right' | number
    vertical: 'top' | 'center' | 'bottom' | number
  }
  /**
   * A HTML element, component instance, or function that returns either.
   * The `container` will have the portal children appended to it.
   *
   * By default, it uses the body of the top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container?: Element | (() => Element | null) | null | unknown
  /**
   * This is the point on the popover which
   * will attach to the anchor's origin.
   *
   * Options:
   * vertical: [top, center, bottom, x(px)];
   * horizontal: [left, center, right, x(px)].
   * @default {
   *   vertical: 'top',
   *   horizontal: 'left',
   * }
   */
  transformOrigin?: {
    horizontal: 'left' | 'center' | 'right' | string
    vertical: 'top' | 'center' | 'bottom' | string
  }
  /**
   * Max width of the Popover
   */
  maxWidth?: number | string
  /**
   * This function is called in order to retrieve the content anchor element.
   * It's the opposite of the `anchorEl` prop.
   * The content anchor element should be an element inside the popover.
   * It's used to correctly scroll and set the position of the popover.
   * The positioning strategy tries to make the content anchor element just above the
   * anchor element.
   */
  getContentAnchorEl?: (element: HTMLElement) => HTMLElement
  /**
   * Specifies how close to the edge of the window the popover can appear.
   * @default 16
   */
  marginThreshold?: number
  /**
   * Callback fired when the component requests to be closed.
   * The `reason` parameter can optionally be used to control the response to `onClose`.
   */
  onClose?: () => void
  /**
   * If `true`, the popover is visible.
   */
  open?: boolean
  /**
   * Properties to configure the Paper component
   */
  PaperProps?: PaperProps
  /**
   * The content of the component.
   */
  children?: React.ReactNode
}

interface PopoverProps extends PopoverPublicProps, StyledProps<PopoverContext> {
  /**
   * Styled Component className to plug to the Root element of the component to be able to override its style
   */
  className?: string
}

export interface PopoverCoreStyle {
  maxWidth?: string | number
}

const Popover = React.forwardRef((props: PopoverProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const paperRef = React.useRef(null)

  const getCoreStyle = (): PopoverCoreStyle => ({
    maxWidth: props.maxWidth!,
  })

  // Returns the top/left offset of the position
  // to attach to on the anchor element (or body if none is provided)
  const getAnchorOffset = React.useCallback(
    (contentAnchorOffset) => {
      if (props.anchorReference === 'anchorPosition') {
        if (process.env.NODE_ENV !== 'production') {
          if (!props.anchorPosition) {
            console.error(
              'Prisma: You need to provide a `anchorPosition` prop when using ' +
                '<Popover anchorReference="anchorPosition" />.',
            )
          }
        }
        return props.anchorPosition
      }

      const resolvedAnchorEl = getAnchorEl(props.anchorEl)

      // If an anchor element wasn't provided, just use the parent body element of this Popover
      const anchorElement =
        resolvedAnchorEl && resolvedAnchorEl.nodeType === 1
          ? resolvedAnchorEl
          : ownerDocument(paperRef.current).body
      const anchorRect = anchorElement.getBoundingClientRect()

      if (process.env.NODE_ENV !== 'production') {
        const box = anchorElement.getBoundingClientRect()

        if (
          process.env.NODE_ENV !== 'test' &&
          box.top === 0 &&
          box.left === 0 &&
          box.right === 0 &&
          box.bottom === 0
        ) {
          console.warn(
            [
              'Prisma: The `anchorEl` prop provided to the component is invalid.',
              'The anchor element should be part of the document layout.',
              "Make sure the element is present in the document or that it's not display none.",
            ].join('\n'),
          )
        }
      }

      const anchorVertical = contentAnchorOffset === 0 ? props.anchorOrigin.vertical : 'center'

      return {
        top: anchorRect.top + getOffsetTop(anchorRect, anchorVertical),
        left: anchorRect.left + getOffsetLeft(anchorRect, props.anchorOrigin.horizontal),
      }
    },
    [
      props.anchorEl,
      props.anchorOrigin.horizontal,
      props.anchorOrigin.vertical,
      props.anchorPosition,
      props.anchorReference,
    ],
  )

  // Returns the vertical offset of inner content to anchor the transform on if provided
  const getContentAnchorOffset = React.useCallback(
    (element) => {
      let contentAnchorOffset = 0

      if (props.getContentAnchorEl && props.anchorReference === 'anchorEl') {
        const contentAnchorEl = props.getContentAnchorEl(element)

        if (contentAnchorEl && element.contains(contentAnchorEl)) {
          const scrollTop = getScrollParent(element, contentAnchorEl)
          contentAnchorOffset =
            contentAnchorEl.offsetTop + contentAnchorEl.clientHeight / 2 - scrollTop || 0
        }

        // != the default value
        if (process.env.NODE_ENV !== 'production') {
          if (props.anchorOrigin.vertical !== 'top') {
            console.error(
              [
                'Prisma: You can not change the default `anchorOrigin.vertical` value ',
                'when also providing the `getContentAnchorEl` prop to the popover component.',
                'Only use one of the two props.',
                'Set `getContentAnchorEl` to `null | undefined`' +
                  ' or leave `anchorOrigin.vertical` unchanged.',
              ].join('\n'),
            )
          }
        }
      }

      return contentAnchorOffset
    },
    [props.anchorOrigin.vertical, props.anchorReference, props.getContentAnchorEl],
  )

  // Return the base transform origin using the element
  // and taking the content anchor offset into account if in use
  const getTransformOrigin = React.useCallback(
    (elemRect, contentAnchorOffset = 0) => {
      return {
        vertical: getOffsetTop(elemRect, props.transformOrigin.vertical) + contentAnchorOffset,
        horizontal: getOffsetLeft(elemRect, props.transformOrigin.horizontal),
      }
    },
    [props.transformOrigin.horizontal, props.transformOrigin.vertical],
  )

  const getPositioningStyle = React.useCallback(
    (element) => {
      // Check if the parent has requested anchoring on an inner content node
      const contentAnchorOffset = getContentAnchorOffset(element)
      const elementRect = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      }

      // Get the transform origin point on the element itself
      const elementTransformOrigin = getTransformOrigin(elementRect, contentAnchorOffset)

      if (props.anchorReference === 'none') {
        return {
          top: null,
          left: null,
          transformOrigin: getTransformOriginValue(elementTransformOrigin),
        }
      }

      // Get the offset of of the anchoring element
      const anchorOffset: any = getAnchorOffset(contentAnchorOffset)

      // Calculate element positioning
      let top = anchorOffset.top - elementTransformOrigin.vertical
      let left = anchorOffset.left - elementTransformOrigin.horizontal
      const bottom = top + elementRect.height
      const right = left + elementRect.width

      // Use the parent window of the anchorEl if provided
      const containerWindow = ownerWindow(getAnchorEl(props.anchorEl))

      // Window thresholds taking required margin into account
      const heightThreshold = containerWindow.innerHeight - props.marginThreshold
      const widthThreshold = containerWindow.innerWidth - props.marginThreshold

      // Check if the vertical axis needs shifting
      if (top < props.marginThreshold) {
        const diff = top - props.marginThreshold
        top -= diff
        elementTransformOrigin.vertical += diff
      } else if (bottom > heightThreshold) {
        const diff = bottom - heightThreshold
        top -= diff
        elementTransformOrigin.vertical += diff
      }

      if (process.env.NODE_ENV !== 'production') {
        if (elementRect.height > heightThreshold && elementRect.height && heightThreshold) {
          console.error(
            [
              'Material-UI: The popover component is too tall.',
              `Some part of it can not be seen on the screen (${
                elementRect.height - heightThreshold
              }px).`,
              'Please consider adding a `max-height` to improve the user-experience.',
            ].join('\n'),
          )
        }
      }

      // Check if the horizontal axis needs shifting
      if (left < props.marginThreshold) {
        const diff = left - props.marginThreshold
        left -= diff
        elementTransformOrigin.horizontal += diff
      } else if (right > widthThreshold) {
        const diff = right - widthThreshold
        left -= diff
        elementTransformOrigin.horizontal += diff
      }

      return {
        top: `${Math.round(top)}px`,
        left: `${Math.round(left)}px`,
        transformOrigin: getTransformOriginValue(elementTransformOrigin),
      }
    },
    [
      props.anchorEl,
      props.anchorReference,
      getAnchorOffset,
      getContentAnchorOffset,
      getTransformOrigin,
      props.marginThreshold,
    ],
  )

  const setPositioningStyles = React.useCallback(() => {
    const element: any = paperRef.current
    if (!element) {
      return
    }

    const positioning = getPositioningStyle(element)

    if (positioning.top !== null) {
      element.style.top = positioning.top
    }
    if (positioning.left !== null) {
      element.style.left = positioning.left
    }

    element.style.transformOrigin = positioning.transformOrigin
  }, [getPositioningStyle])

  const handlePaperRef = (element) => {
    paperRef.current = element

    setPositioningStyles()
  }

  React.useEffect(() => {
    setPositioningStyles()
  }, [props.anchorEl])

  React.useEffect(() => {
    if (props.open) {
      setPositioningStyles()
    }
  })

  React.useImperativeHandle(
    props.action,
    () =>
      props.open
        ? {
            updatePosition: () => {
              setPositioningStyles()
            },
          }
        : null,
    [props.open, setPositioningStyles],
  )

  React.useEffect(() => {
    if (!props.open) {
      return undefined
    }

    const handleResize = debounce(() => {
      setPositioningStyles()
    })

    const containerWindow = ownerWindow(props.anchorEl)
    containerWindow.addEventListener('resize', handleResize)

    return () => {
      handleResize.clear()
      containerWindow.removeEventListener('resize', handleResize)
    }
  }, [props.anchorEl, props.open, setPositioningStyles])

  // If the container prop is provided, use that
  // If the anchorEl prop is provided, use its parent body element as the container
  // If neither are provided let the Modal take care of choosing the container
  const container =
    props.container ||
    (props.anchorEl ? ownerDocument(getAnchorEl(props.anchorEl)).body : undefined)

  const renderPaper = () => {
    const { Paper } = props.styled
    return (
      <Paper
        ref={handlePaperRef}
        square={props.PaperProps.square}
        elevation={props.PaperProps.elevation}
        variant={props.PaperProps.variant}
        styleProps={getCoreStyle()}
        customisations={{}}
        {...getTestId(props, 'paper')}
      >
        {props.children}
      </Paper>
    )
  }

  const { Root } = props.styled
  return (
    <Root
      className={props.className}
      ref={ref}
      disableEnforceFocus
      container={container}
      BackdropProps={{ invisible: true }}
      open={props.open}
      onClose={props.onClose}
      styleProps={getCoreStyle()}
      customisations={{}}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {renderPaper()}
    </Root>
  )
})

Popover.defaultProps = {
  anchorReference: 'anchorEl',
  anchorOrigin: { vertical: 'top', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
  marginThreshold: 16,
  PaperProps: {},
}

export default Popover
