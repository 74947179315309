import NativeRadioField, { NativeRadioFieldPublicProps } from '../../NativeRadioField'
import Theme from '../../../../../themes/Developer/theme'
import type { RadioFieldContext } from '../../../../RadioField.composition'
import { withCustomisedStyle } from '../../../../../providers'

import Styles from './DevNativeRadioField.styles'
import Customisations, {
  NativeRadioFieldCustomisations,
} from './DevNativeRadioField.customisations'

export const DevNativeRadioField = withCustomisedStyle<
  NativeRadioFieldPublicProps,
  RadioFieldContext,
  NativeRadioFieldCustomisations
>(NativeRadioField, Theme, Styles, Customisations, 'NativeRadioField')

export type DevNativeRadioFieldCustomisations = NativeRadioFieldCustomisations
export type DevNativeRadioFieldProps = NativeRadioFieldPublicProps & NativeRadioFieldCustomisations
