import { StarsStyleProps } from '../DevStars.styles'
import * as theme from '../../../../themes/Developer/proxima-nova'

/** Global */
const defaultColour = (props: StarsStyleProps) => ({
  colour: props.theme.colours.tints.lightGrey[150],
  selectedColour: props.theme.colours.tints.sun[100],
})

export const getColour = (props: StarsStyleProps) =>
  theme.getColour(props, defaultColour(props), theme.getState(props.styleProps))

/** Label */
export const getLabelColour = (props: StarsStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Label'),
    props.theme.colours.tints.lightGrey[180],
    theme.getState(props.styleProps),
  )
