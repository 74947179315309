import RadioField, { RadioFieldPublicProps } from '../../RadioField'
import Theme from '../../../themes/Developer/theme'
import type { RadioFieldContext } from '../../RadioField.composition'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevRadioField.styles'
import Customisations, { RadioFieldCustomisations } from './DevRadioField.customisations'

export const DevRadioField = withCustomisedStyle<
  RadioFieldPublicProps,
  RadioFieldContext,
  RadioFieldCustomisations
>(RadioField, Theme, Styles, Customisations, 'RadioField')

export type DevRadioFieldCustomisations = RadioFieldCustomisations
export type DevRadioFieldProps = RadioFieldPublicProps & RadioFieldCustomisations
