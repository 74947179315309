import * as React from 'react'

export interface GridContextProps {
  direction: string
  alignItems: string
  // When using offset in a grid with a column direction the margin-bottom used with a calculation using 100% does not work. The width is used instead of the height
  // Solution => use the real height of the parent (grid container) to compute the margin-bottom
  containerSize?: number
  noWrap: boolean
  overflow: boolean
}

export const GridContext = React.createContext<GridContextProps | null>(null)
