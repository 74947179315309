import { getTickColour, NativeRadioCustomisations } from './customisations'
import { FontAwesomeIconCustomisations as IconCustomisations } from '../../../../../Icon/components/FontAwesomeIcon/themes/Developer/customisations'
import type { NativeRadioPublicProps } from '../../NativeRadio'
import type { NativeRadioStyleProps } from './DevNativeRadio.styles'

export const Customisations = {
  Icon: (props: NativeRadioStyleProps): IconCustomisations => ({
    colour: 'inherit',
    hoverColour: 'inherit',
  }),
  TickIcon: (props: NativeRadioStyleProps): IconCustomisations => ({
    colour: getTickColour(props),
    hoverColour: getTickColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: NativeRadioPublicProps & NativeRadioCustomisations,
): NativeRadioCustomisations => ({
  Label: props.Label || {},
  Tick: props.Tick || {},
})
