import SimpleBackdrop, { SimpleBackdropPublicProps } from '../../SimpleBackdrop'
import Theme from '../../../../../themes/Developer/theme'
import type { SimpleBackdropContext } from '../../SimpleBackdrop.composition'
import { withStyle } from '../../../../../providers'
import Styles from './DevSimpleBackdrop.styles'

export const DevSimpleBackdrop = withStyle<SimpleBackdropPublicProps, SimpleBackdropContext>(
  SimpleBackdrop,
  Theme,
  Styles,
  'SimpleBackdrop',
)

export type DevSimpleBackdropProps = SimpleBackdropPublicProps
