import Tabs, { TabsPublicProps } from '../../Tabs'
import Theme from '../../../themes/Developer/theme'
import Styles from './DevTabs.styles'
import Customisations from './DevTabs.customisations'
import { withCustomisedStyle } from '../../../providers'
import type { TabsContext } from '../../Tabs.composition'
import type { TabsCustomisations } from './customisations'

export const DevTabs = withCustomisedStyle<TabsPublicProps, TabsContext, TabsCustomisations>(
  Tabs,
  Theme,
  Styles,
  Customisations,
  'Tabs',
)

export type DevTabsCustomisations = TabsCustomisations
export type DevTabsProps = TabsPublicProps & TabsCustomisations
