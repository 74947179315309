import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { NativeRadioStyleProps } from '../DevNativeRadio.styles'
import { getTickBorderColour } from './colours'

const TickSize = {
  small: 16,
  medium: 18,
  large: 21,
}

/** Label */
/** Border Radius */
export const getLabelBorderRadius = (props: NativeRadioStyleProps) =>
  theme.getBorderRadius(theme.getCustomisations(props, 'Label'))

/** Tick */
/* Border */
export const getTickBorder = (props: NativeRadioStyleProps) =>
  theme.getBorder(
    theme.getCustomisations(props, 'Tick'),
    getTickBorderColour(props),
    'border',
    theme.getState(props.styleProps),
  )

/** Height */
export const getTickHeight = (props: NativeRadioStyleProps) =>
  `${TickSize[props.styleProps.size]}px`
