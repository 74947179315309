/* eslint-disable react/default-props-match-prop-types */
import * as React from 'react'
import FocusTrap from 'focus-trap-react'
import { DevSimpleBackdrop as SimpleBackdrop } from './components'
import Portal from '../Portal/Portal'
import Components from './Modal.styles'
import { getContainer, getHasTransition } from './Modal.utils'
import useResponsive from '../hooks/useResponsive'
import useEventCallback from '../hooks/useEventCallback'
import useForkRef from '../hooks/useForkRef'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { ownerDocument } from '../utils/document'
import { createChainedFunction } from '../utils/createChainedFunction'
import ModalManager, { ariaHidden } from '../utils/modalManager'
import { getTestId } from '../utils/test'

export interface ModalPublicProps {
  /**
   * A backdrop component. This prop enables custom backdrop rendering.
   * @default SimpleBackdrop
   */
  BackdropComponent?: any
  /**
   * Props applied to the [`Backdrop`](/api/backdrop/) element.
   */
  BackdropProps?: any
  /**
   * When set to true the Modal waits until a nested Transition is completed before closing.
   * @default false
   */
  closeAfterTransition?: boolean
  /**
   * A HTML element, component instance, or function that returns either.
   * The `container` will have the portal children appended to it.
   *
   * By default, it uses the body of the top-level document object,
   * so it's simply `document.body` most of the time.
   */
  container?: Element | (() => Element | null) | null | unknown
  /**
   * If `true`, the modal will not prevent focus from leaving the modal while open.
   *
   * Generally this should never be set to `true` as it makes the modal less
   * accessible to assistive technologies, like screen readers.
   * @default false
   */
  disableEnforceFocus?: boolean
  /**
   * If `true`, hitting escape will not fire `onClose`.
   * @default false
   */
  disableEscapeKeyDown?: boolean
  /**
   * The `children` will be inside the DOM hierarchy of the parent component.
   * @default false
   */
  disablePortal?: boolean
  /**
   * If `true`, the modal will not restore focus to previously focused element once
   * modal is hidden.
   * @default false
   */
  disableRestoreFocus?: boolean
  /**
   * Disable the scroll lock behavior.
   * @default false
   */
  disableScrollLock?: boolean
  /**
   * If `true`, clicking the backdrop will not fire `onClose`.
   * @default false
   */
  disableBackdropClick?: boolean
  /**
   * If `true`, the modal is full-screen.
   * @default false
   */
  fullScreen?: boolean
  /**
   * If `true`, the backdrop is not rendered.
   * @default false
   */
  hideBackdrop?: boolean
  /**
   * Callback fired when the backdrop is clicked.
   */
  onBackdropClick?: (event: React.MouseEvent) => void
  /**
   * Callback fired when the component requests to be closed.
   * The `reason` parameter can optionally be used to control the response to `onClose`.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`.
   */
  onClose?: (event: React.MouseEvent | React.KeyboardEvent, reason: string) => void
  /**
   * Callback fired when the escape key is pressed,
   * `disableEscapeKeyDown` is false and the modal is in focus.
   */
  onEscapeKeyDown?: (event: React.KeyboardEvent) => void
  /**
   * If `true`, the modal is open.
   */
  open?: boolean
  /**
   * Component to display as a content of the modal
   */
  children?: React.ReactElement<any>
}

interface ModalProps extends ModalPublicProps {
  /**
   * Styled Component className to plug to the Root element of the component to be able to override its style
   */
  className?: string
}

export interface ModalCoreStyle {
  open: boolean
}

// A modal manager used to track and manage the state of open Modals.
// Modals don't open on the server so this won't conflict with concurrent requests.
const manager = new ModalManager()

const Modal = React.forwardRef((props: ModalProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })
  const { children } = props

  const modal = React.useRef<any>({})
  const mountNodeRef = React.useRef(null)
  const modalRef = React.useRef(null)
  const handleRef = useForkRef(modalRef, ref)

  const hasTransition = getHasTransition(props)

  const [exited, setExited] = React.useState(true)

  const getCoreStyle = (): ModalCoreStyle => ({
    open: props.open!,
  })

  const getDocument = () => ownerDocument(mountNodeRef.current)

  const getModal = () => {
    modal.current.modalRef = (ref as React.RefObject<HTMLDivElement>).current
    modal.current.mountNode = mountNodeRef.current
    return modal.current
  }

  const handleMounted = () => {
    manager.mount(getModal(), { disableScrollLock: props.disableScrollLock })

    // Fix a bug on Chrome where the scroll isn't initially 0.
    modalRef.current.scrollTop = 0
  }

  const handleOpen = useEventCallback(() => {
    const resolvedContainer = getContainer(props.container) || getDocument().body

    manager.add(getModal(), resolvedContainer)

    const reference = ref as React.RefObject<HTMLDivElement>
    if (reference.current) {
      handleMounted()
    }
  })

  const handleClose = React.useCallback(() => {
    manager.remove(getModal())
  }, [manager])

  const isTopModal = React.useCallback(() => manager.isTopModal(getModal()), [manager])

  const handlePortalRef = useEventCallback((node) => {
    ;(ref as any).current = node
    mountNodeRef.current = node

    if (!node) {
      return
    }

    if (props.open && isTopModal()) {
      handleMounted()
    } else {
      ariaHidden(modalRef.current, true)
    }
  })

  React.useEffect(() => {
    return () => {
      handleClose()
    }
  }, [handleClose])

  React.useEffect(() => {
    if (props.open) {
      handleOpen()
    } else if (!hasTransition || !props.closeAfterTransition) {
      handleClose()
    }
  }, [props.open, handleClose, hasTransition, props.closeAfterTransition, handleOpen])

  const handleEnter = () => {
    setExited(false)
  }

  const handleExited = () => {
    setExited(true)

    if (props.closeAfterTransition) {
      handleClose()
    }
  }

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.target !== event.currentTarget) {
      return
    }

    if (props.onBackdropClick) {
      props.onBackdropClick(event)
    }

    if (!props.disableBackdropClick && props.onClose) {
      props.onClose(event, 'backdropClick')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== 'Escape') {
      return
    }

    if (props.onEscapeKeyDown) {
      props.onEscapeKeyDown(event)
    }

    if (!props.disableEscapeKeyDown) {
      // Swallow the event, in case someone is listening for the escape key on the body.
      event.stopPropagation()

      if (props.onClose) {
        props.onClose(event, 'escapeKeyDown')
      }
    }
  }

  const renderTrapFocus = () => {
    const childProps: any = {}
    if (children.props.tabIndex === undefined) {
      childProps.tabIndex = children.props.tabIndex || '-1'
    }

    // It's a Transition like component
    if (hasTransition) {
      childProps.onEnter = createChainedFunction(handleEnter, children.props.onEnter)
      childProps.onExited = createChainedFunction(handleExited, children.props.onExited)
    }

    if (props.disableEnforceFocus) {
      return React.cloneElement(children, childProps)
    }

    return props.open ? (
      <FocusTrap
        paused={props.disableEnforceFocus}
        focusTrapOptions={{
          escapeDeactivates: !props.disableEscapeKeyDown,
          clickOutsideDeactivates: !props.disableBackdropClick,
          returnFocusOnDeactivate: !props.disableRestoreFocus,
          onActivate: handleOpen,
          onDeactivate: handleClose,
        }}
        style={{ height: '100%' }}
      >
        {React.cloneElement(children, childProps)}
      </FocusTrap>
    ) : null
  }

  const renderBackdrop = () => {
    const { BackdropComponent } = props
    return !props.hideBackdrop ? (
      <BackdropComponent
        open={props.open}
        onClick={handleBackdropClick}
        {...props.BackdropProps}
        {...getTestId(props, 'backdrop')}
      />
    ) : null
  }

  if (!props.open && (!hasTransition || exited)) {
    return null
  }

  const { Root } = Components
  return (
    <Portal
      ref={handlePortalRef}
      container={props.container}
      disablePortal={props.disablePortal}
      {...getTestId(props, 'portal')}
    >
      <Root
        className={props.className}
        ref={handleRef}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        role="presentation"
        styleProps={getCoreStyle()}
        customisations={{}}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderBackdrop()}
        {renderTrapFocus()}
      </Root>
    </Portal>
  )
})

Modal.defaultProps = {
  BackdropComponent: SimpleBackdrop,
  BackdropProps: {},
  disableEnforceFocus: false,
  disableEscapeKeyDown: false,
  disablePortal: false,
  disableRestoreFocus: false,
  disableScrollLock: false,
  disableBackdropClick: false,
  hideBackdrop: false,
}

export default Modal
