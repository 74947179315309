import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import type { StyledProps } from '../../../providers'
import type { ListItemContext } from './ListItem.composition'

export interface ListItemPublicProps {
  /**
   * The html tag used for the root node.
   */
  as?: 'li' | 'div'
  /**
   * Component used as content of the li element
   */
  children?: React.ReactNode
}

export interface ListItemInternalProps {
  /**
   * List of properties that will be used by the styles
   */
  styleProps?: any
  /**
   * List of properties that will be plugged to the root element coming from the override
   */
  rootProps?: any
}

export interface ListItemProps
  extends ListItemPublicProps,
    ListItemInternalProps,
    StyledProps<ListItemContext> {}

const ListItem = React.forwardRef((props: ListItemProps, forwardRef: React.Ref<HTMLLIElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const { Root } = props.styled!
  return (
    <Root
      className={props.className}
      ref={ref}
      as={props.as}
      {...props.rootProps}
      styleProps={props.styleProps}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {props.children}
    </Root>
  )
})

ListItem.defaultProps = {
  as: 'li',
  rootProps: {},
}

export default ListItem
