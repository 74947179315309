import TableRow, { TableRowPublicProps } from '../../TableRow'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevTableRow.styles'
import Customisations from './DevTableRow.customisations'
import type { TableRowContext } from '../../TableRow.composition'
import type { TableRowCustomisations } from './customisations'

export const DevTableRow = withCustomisedStyle<
  TableRowPublicProps,
  TableRowContext,
  TableRowCustomisations
>(TableRow, Theme, Styles, Customisations, 'TableRow')

export type DevTableRowCustomisations = TableRowCustomisations
export type DevTableRowProps = TableRowPublicProps & TableRowCustomisations
