import * as React from 'react'
import TableContext, { DefaultProps as TableContextDefaultProps } from './Table.context'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import type { StyledProps } from '../providers'
import type { TableContext as TableComposition } from './Table.composition'

export interface TablePublicProps {
  /**
   * Size of the table
   */
  size?: 'small' | 'medium'
  /**
   * Set the header sticky.
   *
   * @default false
   */
  stickyHeader?: boolean
  /**
   * The content of the table, normally `TableHead` and `TableBody`.
   */
  children?: React.ReactNode
}

export interface TableProps extends TablePublicProps, StyledProps<TableComposition> {}

export interface TableCoreStyle {
  stickyHeader: boolean
}

const Table = React.forwardRef((props: TableProps, forwardRef: React.Ref<HTMLTableElement>) => {
  const { ref } = useResponsive<HTMLTableElement>({ ref: forwardRef })

  const context = React.useMemo(
    () => ({
      size: props.size,
      stickyHeader: props.stickyHeader,
    }),
    [props.size, props.stickyHeader],
  )

  const getCoreStyle = (): unknown => ({
    stickyHeader: props.stickyHeader!,
  })

  const { Root } = props.styled!
  return (
    <TableContext.Provider value={context}>
      <Root
        ref={ref}
        className={props.className}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    </TableContext.Provider>
  )
})

Table.defaultProps = {
  size: TableContextDefaultProps.size,
}

export default Table
