import * as React from 'react'
import type { StyledProps } from '../providers'
import type { PaperContext } from './Paper.composition'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'

export interface PaperPublicProps {
  /**
   * Shadow depth, corresponds to `dp` in the spec.
   * It accepts values between 0 and 24 inclusive.
   * @default 1
   */
  elevation?: number
  /**
   * Role of the paper component
   */
  role?: string
  /**
   * If `true`, rounded corners are disabled.
   * @default false
   */
  square?: boolean
  /**
   * The variant to use.
   * @default 'elevation'
   */
  variant?: 'elevation' | 'outlined' | 'outlinedElevated'
  /**
   * Component to display inside the paper
   */
  children?: React.ReactNode
}

export interface PaperProps extends PaperPublicProps, StyledProps<PaperContext> {}

export interface PaperCoreStyle {
  square: boolean
  elevation: number
  variant: string
}

const Paper: React.FC<PaperProps> = React.forwardRef(
  (props: PaperProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive<HTMLDivElement>({ ref: forwardRef })

    const getCoreStyle = (): PaperCoreStyle => ({
      square: props.square!,
      elevation: props.elevation!,
      variant: props.variant!,
    })

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        role={props.role}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

Paper.defaultProps = {
  square: false,
  elevation: 1,
  variant: 'elevation',
}

export default Paper
