import type { StarsStyleProps } from './DevStars.styles'
import type { StarsPublicProps } from '../../Stars'
import { getColour, StarsCustomisations } from './customisations'

export const Customisations = {
  Icon: (props: StarsStyleProps) => ({
    colour: getColour(props),
    hoverColour: getColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: StarsPublicProps & StarsCustomisations): StarsCustomisations => ({
  colour: props.colour,
  selectedColour: props.selectedColour,
  Label: props.Label || {},
})
