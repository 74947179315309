import * as theme from '../../../../themes/Developer/proxima-nova'
import type { SwitchStyleProps } from '../DevSwitch.styles'

/** Label */
/** Colour */
export const getLabelColour = (props: SwitchStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Label'),
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )

/** Track */
/** Background colour */
const trackDefaultBgColour = (props: SwitchStyleProps): theme.BgColourStates => ({
  bgColour: props.theme.colours.tints.charcoal[30],
  hoverBgColour: props.theme.colours.tints.charcoal[50],
  hoverSelectedBgColour: props.theme.colours.tints.azure[50],
  focusBgColour: props.theme.colours.tints.charcoal[30],
  selectedBgColour: props.theme.colours.tints.azure[30],
  invalidSelectedBgColour: props.theme.colours.tints.red[30],
})

export const getTrackBgColour = (props: SwitchStyleProps) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'Track'),
    trackDefaultBgColour(props),
    theme.getState(props.styleProps),
  )

/** Thumb */
/** Background colour */
const thumbDefaultBgColour = (props: SwitchStyleProps): theme.BgColourStates => ({
  bgColour: props.theme.colours.tertiary.neutral.white,
  hoverBgColour: props.theme.colours.tertiary.neutral.white,
  focusBgColour: props.theme.colours.tertiary.neutral.white,
  selectedBgColour: props.theme.colours.tints.azure[80],
  invalidBgColour: props.theme.colours.tertiary.semantic.red,
  invalidSelectedBgColour: props.theme.colours.tints.red[130],
})

export const getThumbBgColour = (props: SwitchStyleProps) =>
  theme.getBgColour(
    theme.getCustomisations(props, 'Thumb'),
    thumbDefaultBgColour(props),
    theme.getState(props.styleProps),
  )

/** Border colour */
export const thumbBorderColour = (props: SwitchStyleProps): theme.ColourStates => ({
  colour: props.theme.colours.tints.charcoal[30],
  hoverColour: props.theme.colours.tints.charcoal[30],
  selectedColour: props.theme.colours.tints.azure[80],
  hoverSelectedColour: props.theme.colours.tints.azure[80],
  activeColour: props.theme.colours.tints.sun[50],
  focusColour: props.theme.colours.tints.charcoal[30],
  focusSelectedColour: props.theme.colours.tints.azure[80],
  invalidColour: props.theme.colours.tertiary.semantic.red,
})

export const getThumbBoxShadowColour = (props: SwitchStyleProps) =>
  theme.getBorderColour(
    theme.getCustomisations(props, 'Thumb'),
    thumbBorderColour(props),
    theme.getState(props.styleProps),
  )
