import StepConnector from '../../StepConnector'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevStepConnector.styles'
import Customisations from './DevStepConnector.customisations'
import type { StepConnectorContext } from '../../StepConnector.composition'
import type { StepConnectorCustomisations } from './customisations'

export const DevStepConnector = withCustomisedStyle<
  unknown,
  StepConnectorContext,
  StepConnectorCustomisations
>(StepConnector, Theme, Styles, Customisations, 'StepConnector')

export type DevStepConnectorCustomisations = StepConnectorCustomisations
export type DevStepConnectorProps = unknown & StepConnectorCustomisations
