import type { RadioFieldStyleProps } from './DevRadioField.styles'
import type { RadioFieldPublicProps } from '../../RadioField'
import type { DevRadioGroupCustomisations as RadioGroupCustomisations } from '../../../RadioGroup/themes/Developer/DevRadioGroup.customisations'
import type { FieldCustomisations } from '../../../Field/themes/Developer/DevField.customisations'

export interface RadioFieldCustomisations extends FieldCustomisations {
  RadioGroup?: RadioGroupCustomisations
}

export const Customisations = {
  RadioGroup: (props: RadioFieldStyleProps) => props.customisations.RadioGroup!,
  Field: (props: RadioFieldStyleProps) => ({
    Label: props.customisations?.Label,
    HelperText: props.customisations.HelperText,
    ValidityText: props.customisations.ValidityText,
    Tooltip: props.customisations.Tooltip,
    ValidityStatus: props.customisations.ValidityStatus,
  }),
}

export default (
  props: RadioFieldPublicProps & RadioFieldCustomisations,
): RadioFieldCustomisations => ({
  RadioGroup: props.RadioGroup || {},
  Label: props.Label || {},
  HelperText: props.HelperText || {},
  ValidityText: props.ValidityText || {},
  Tooltip: props.Tooltip || {},
  ValidityStatus: props.ValidityStatus || {},
})
