import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { TabStyleProps } from '../DevTab.styles'

/** Colour */
const defaultColour = (props: TabStyleProps) => ({
  colour: props.theme.colours.tertiary.neutral.grey,
  hoverColour: props.theme.colours.tints.azure[50],
  focusColour: props.theme.colours.tints.azure[50],
  selectedColour: props.theme.colours.tints.azure[100],
})

export const getColour = (props: TabStyleProps) =>
  theme.getColour(props, defaultColour(props), theme.getState(props.styleProps))

/** Background colour */
export const getBgColour = (props: TabStyleProps) =>
  theme.getBgColour(props, 'transparent', theme.getState(props.styleProps))
