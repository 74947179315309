import type {
  Colours as CoreColours,
  Primary,
  Secondary,
  Tertiary,
  TintRange,
  Tints,
} from './models'

export enum Colours {
  Sun = '#F7AB1B',
  Azure = '#058ED8',
  DarkViolet = '#42448E',
  KashmirBlue = '#44689A',
  CloudBurst = '#093554',
  Cyan = '#38E2F8',
  SoftAzure = '#87BAFC',
  ElectricViolet = '#7A7FFF',
  BurntSun = '#F38D00',
  Charcoal = '#2D2D2D',
  LightGrey = '#F4F7F9',
  Grey = '#AAAAAA',
  White = '#FFFFFF',
  Green = '#399847',
  Red = '#C00023',
}

export const primary: Primary = {
  sun: Colours.Sun,
  azure: Colours.Azure,
  darkViolet: Colours.DarkViolet,
}

export const secondary: Secondary = {
  burntSun: Colours.BurntSun,
  cloudBurst: Colours.CloudBurst,
  cyan: Colours.Cyan,
  electricViolet: Colours.ElectricViolet,
  kashmirBlue: Colours.KashmirBlue,
  softAzure: Colours.SoftAzure,
}

export const tertiary: Tertiary = {
  neutral: {
    charcoal: Colours.Charcoal,
    lightGrey: Colours.LightGrey,
    grey: Colours.Grey,
    white: Colours.White,
  },
  semantic: {
    green: Colours.Green,
    red: Colours.Red,
  },
}

const sun = createColourWithTints({
  10: '#FEF0D6',
  30: '#FCE0AC',
  50: '#FBD183',
  80: '#F8BA44',
  100: '#F7AB1B',
})

const azure = createColourWithTints({
  10: '#D2EAF8',
  30: '#A4D6F1',
  50: '#77C1EA',
  80: '#32A3DF',
  100: '#058ED8',
  110: '#0480C2',
  130: '#036397',
})

const darkViolet = createColourWithTints({
  10: '#DDDDEA',
  30: '#BABBD6',
  50: '#9899C1',
  80: '#6466A3',
  100: '#42448E',
})

const kashmirBlue = createColourWithTints({
  10: '#DDE4ED',
  30: '#BBC8DA',
  50: '#99ADC8',
  80: '#6683AC',
  100: '#44689A',
})

const cloudBurst = createColourWithTints({
  10: '#D2DAE0',
  30: '#A6B6C1',
  50: '#7991A2',
  80: '#365A73',
  100: '#093554',
})

const cyan = createColourWithTints({
  10: '#EBFCFE',
  30: '#BEF6FD',
  50: '#92EFFB',
  80: '#65E9FA',
  100: '#38E2F8',
  110: '#0480C2',
  130: '#036397',
})

const softAzure = createColourWithTints({
  10: '#E9F2FE',
  30: '#D3E6FE',
  50: '#BED9FD',
  80: '#9DC7FD',
  100: '#87BAFC',
})

const electricViolet = createColourWithTints({
  10: '#E7E8FF',
  30: '#CFD0FF',
  50: '#B6B9FF',
  80: '#9296FF',
  100: '#7A7FFF',
})

const burntSun = createColourWithTints({
  10: '#FDEAD1',
  30: '#FBD6A2',
  50: '#F8C174',
  80: '#F5A22E',
  100: '#F38D00',
})

const charcoal = createColourWithTints({
  10: '#EAEAEA',
  30: '#C0C0C0',
  50: '#969696',
  80: '#575757',
  100: '#2D2D2D',
})

const lightGrey = createColourWithTints({
  100: '#F4F7F9',
  130: '#EFF3F6',
  150: '#CEDBE4',
  180: '#ADC3D2',
})

const green = createColourWithTints({
  30: '#74B77E',
  100: '#399847',
  130: '#286A32',
})

const red = createColourWithTints({
  30: '#D34D65',
  100: '#C00023',
  130: '#860019',
})

function createColourWithTints<T extends TintRange>(tints: T): T {
  return tints
}

export const tints: Tints = {
  sun,
  azure,
  darkViolet,
  kashmirBlue,
  cloudBurst,
  cyan,
  softAzure,
  electricViolet,
  burntSun,
  charcoal,
  lightGrey,
  green,
  red,
}

export const colours: CoreColours = {
  primary,
  secondary,
  tertiary,
  tints,
}
