import { CheckboxGroupPublicProps } from '../../CheckboxGroup'
import { CheckboxCustomisations } from '../../../Checkbox/themes/Developer/customisations'

export type DevCheckboxGroupCustomisations = CheckboxCustomisations

export default (
  props: CheckboxGroupPublicProps & DevCheckboxGroupCustomisations,
): DevCheckboxGroupCustomisations => ({
  bgColour: props.bgColour,
  border: props.border,
  Label: props.Label || {},
  Tick: props.Tick || {},
})
