import ItemCustomisations from '../../components/PaginationItem/themes/Developer/DevPaginationItem.customisations'
import type { PaginationItemCustomisations } from '../../components/PaginationItem/themes/Developer/customisations'
import type { PaginationStyleProps } from './DevPagination.styles'

export type PaginationCustomisations = PaginationItemCustomisations

export const Customisations = {
  ItemContent: (props: PaginationStyleProps): PaginationCustomisations => ({
    ...props.customisations,
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: PaginationCustomisations): PaginationCustomisations =>
  ItemCustomisations(props)
