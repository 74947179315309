import * as React from 'react'

import type { DialogActionsContext } from './DialogActions.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'

export interface DialogActionsPublicProps {
  /**
   * If `true`, the actions do not have additional margin.
   * @default false
   */
  disableSpacing?: boolean
  /**
   * Horizontal alignment of the children
   */
  justify?: 'flex-start' | 'center' | 'flex-end'
  /**
   * Content of the title
   */
  children?: React.ReactNode
}

export interface DialogActionsCoreStyle {
  disableSpacing: boolean
  justify: 'flex-start' | 'center' | 'flex-end'
}

export interface DialogActionsProps
  extends DialogActionsPublicProps,
    StyledProps<DialogActionsContext> {}

const DialogActions = React.forwardRef(
  (props: DialogActionsProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): DialogActionsCoreStyle => ({
      disableSpacing: props.disableSpacing,
      justify: props.justify!,
    })

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

DialogActions.defaultProps = {
  justify: 'flex-end',
}

export default DialogActions
