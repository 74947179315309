import * as React from 'react'
import type { CircularProgressContext } from './CircularProgress.composition'
import { getSize, getThickness } from './CircularProgress.utils'
import { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'

export interface CircularProgressPublicProps {
  /**
   * Percent of the progress
   */
  value?: number
  /**
   * Label displayed in the center of the progress
   */
  label?: string | React.ReactNode
  /**
   * Size of the loader
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Thickness of the loader
   */
  thickness?: 'small' | 'medium' | 'large'
}

export interface CircularProgressProps
  extends CircularProgressPublicProps,
    StyledProps<CircularProgressContext> {}

export interface CircularProgressCoreStyle {
  size: string | number
}

export const CircularProgress = React.forwardRef(
  (props: CircularProgressProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const indeterminate = () => typeof props.value === 'undefined'

    const getCoreStyle = (): CircularProgressCoreStyle => ({
      size: props.size!,
    })

    const renderAnimation = () => {
      const size = getSize(props.size!)

      return indeterminate() ? (
        <animateTransform
          attributeName="transform"
          type="rotate"
          from={`0 ${size / 2} ${size / 2}`}
          to={`360 ${size / 2} ${size / 2}`}
          dur="1s"
          repeatCount="indefinite"
        />
      ) : null
    }

    const renderSpinnerAnimation = () => {
      const size = getSize(props.size!)
      const thickness = getThickness(props.thickness!)

      const percent = !indeterminate() ? props.value! : 25
      const radius = (size - thickness) / 2
      const center = size / 2
      const dashArray = ((size - thickness) / 2) * Math.PI * 2
      const dashOffset = dashArray - (dashArray * percent) / 100

      const { SpinnerAnimation } = props.styled!
      return (
        <SpinnerAnimation
          cx={`${center}px`}
          cy={`${center}px`}
          r={`${radius}px`}
          strokeWidth={`${thickness}px`}
          transform={`rotate(-90 ${center} ${center})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'spinner-animation')}
        >
          {renderAnimation()}
        </SpinnerAnimation>
      )
    }

    const renderSpinnerBackground = () => {
      const size = getSize(props.size!)
      const thickness = getThickness(props.thickness!)

      const radius = (size - thickness) / 2
      const center = size / 2

      const { SpinnerBackground } = props.styled!
      return (
        <SpinnerBackground
          cx={`${center}px`}
          cy={`${center}px`}
          r={`${radius}px`}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'spinner-background')}
        />
      )
    }

    const renderLabel = () => {
      const { Label } = props.styled!
      return props.label ? (
        <Label
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label')}
        >
          {props.label}
        </Label>
      ) : null
    }

    const renderSpinner = () => {
      const size = getSize(props.size!)
      const thickness = getThickness(props.thickness!)

      const { Spinner } = props.styled!
      return (
        <Spinner
          viewBox={`0 0 ${size + 2} ${size + 2}`}
          xmlns="http://www.w3.org/2000/svg"
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'spinner')}
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth={thickness}>
              {renderSpinnerBackground()}
              {renderSpinnerAnimation()}
            </g>
          </g>
        </Spinner>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderSpinner()}
        {renderLabel()}
      </Root>
    )
  },
)

CircularProgress.defaultProps = {
  size: 'medium',
  thickness: 'medium',
}

export default CircularProgress
