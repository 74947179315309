import FormControl, { FormControlPublicProps } from '../../FormControl'
import Theme from '../../../themes/Developer/theme'
import type { FormControlContext } from '../../FormControl.composition'
import { withStyle } from '../../../providers'
import Styles from './DevFormControl.styles'

export const DevFormControl = withStyle<FormControlPublicProps, FormControlContext>(
  FormControl,
  Theme,
  Styles,
  'FormControl',
)

export type DevFormControlProps = FormControlPublicProps
