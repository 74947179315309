import * as React from 'react'
import type { StyledProps } from '../../../providers'
import type { SearchableSelectContext } from './SearchableSelect.composition'
import type { SearchProps, SelectPublicProps } from '../../Select'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { faLightSearch, faLightTimes } from '../../../utils/fontawesome'
import { isEmpty } from '../../../utils/lodash'
import { getTestId } from '../../../utils/test'

export type SearchableSelectPublicProps = Omit<
  SelectPublicProps,
  'startAdornment' | 'endAdornment' | keyof SearchProps
>

export interface SearchableSelectProps
  extends SearchableSelectPublicProps,
    StyledProps<SearchableSelectContext> {}

export interface SearchableSelectCoreStyle {
  size: string | number
}

const SearchableSelect: React.FC<SearchableSelectProps> = React.forwardRef(
  (props: SearchableSelectProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive<HTMLDivElement>({ ref: forwardRef })

    const [search, setSearch] = React.useState('')

    const getCoreStyle = (): SearchableSelectCoreStyle => ({
      size: props.size!,
    })

    const handleReset = (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      setSearch('')
    }

    const handleSearchChanged = (value: string) => {
      setSearch(value)
    }

    const renderCross = () => {
      const { Reset } = props.styled!
      return !isEmpty(search) ? (
        <Reset
          src={faLightTimes}
          onClick={handleReset}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'clear-icon')}
        />
      ) : null
    }

    const renderSearch = () => {
      const { Search } = props.styled!
      return (
        <Search
          src={faLightSearch}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'search-icon')}
        />
      )
    }

    const renderSelect = () => {
      const { Select } = props.styled!
      return (
        <Select
          {...props}
          type="search"
          search={search}
          startAdornment={renderSearch()}
          endAdornment={renderCross()}
          onSearchChange={handleSearchChanged}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'select')}
        />
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderSelect()}
      </Root>
    )
  },
)

SearchableSelect.defaultProps = {
  disabled: false,
  readOnly: false,
  size: 'medium',
  options: [],
  type: 'standard',
  tabIndex: 0,
  direction: 'ltr',
}

export default SearchableSelect
