import * as React from 'react'
import MenuContext from '../../Menu.context'
import type { MenuItemContext } from './MenuItem.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { attachIf } from '../../../utils/event-handler'
import { getTestId } from '../../../utils/test'

export interface MenuItemPublicProps {
  /**
   * True if the menu item is the default one selected when inside a menu group
   */
  default?: boolean
  /**
   * True if the menu item is at the root level of the menu, false otherwise
   */
  root?: boolean
  /**
   * True if the menu item is disabled, false otherwise
   */
  disabled?: boolean
  /**
   * Url or path to redirect the user to
   */
  href?: string
  /**
   * True if the menu item is selected, false otherwise
   */
  selected?: boolean
  /**
   * Start for this component.
   */
  startAdornment?: React.ReactNode
  /**
   * End for this component.
   */
  endAdornment?: React.ReactNode
  /**
   * Handler triggered when the menu item is clicked
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * Handler when the menu item loses the focus.
   */
  onBlur?: (event: React.FocusEvent) => void
  /**
   * Handler when the menu item gets the focus.
   */
  onFocus?: (event: React.FocusEvent) => void
  /**
   * Handler when the menu item is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the menu item is not hovered
   */
  onLeave?: (event: React.MouseEvent) => void
  /**
   * Component displayed as a label of the menu item
   */
  children?: React.ReactNode
}

export interface MenuItemProps extends MenuItemPublicProps, StyledProps<MenuItemContext> {}

export interface MenuItemCoreStyle {
  disabled: boolean
  selected: boolean
  startAdornment: boolean
  endAdornment: boolean
  hovered: boolean
  focused: boolean
  root: boolean
  variant: 'dropdown' | 'fly-out' | 'accordion' | 'tab'
}

const MenuItem = React.forwardRef(
  (props: MenuItemProps, forwardRef: React.Ref<HTMLAnchorElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const { variant } = React.useContext(MenuContext)

    const [hovered, setHovered] = React.useState(false)
    const [focused, setFocused] = React.useState(false)

    const isDefined = (value: any) => value !== undefined && value !== null

    const isActionnable = !props.disabled

    const getCoreStyle = (): MenuItemCoreStyle => ({
      disabled: props.disabled!,
      selected: props.selected!,
      startAdornment: isDefined(props.startAdornment),
      endAdornment: isDefined(props.endAdornment),
      hovered,
      focused,
      root: props.root!,
      variant,
    })

    const handleClick = (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      if (props.onClick) {
        props.onClick(event)
      }
    }

    const handleFocus = (event: React.FocusEvent) => {
      if (props.onFocus) {
        props.onFocus(event)
      }

      setFocused(true)
    }

    const handleBlur = (event: React.FocusEvent) => {
      if (props.onBlur) {
        props.onBlur(event)
      }

      setFocused(false)
    }

    const handleHover = (event: React.MouseEvent) => {
      if (props.onHover) {
        props.onHover(event)
      }

      setHovered(true)
    }

    const handleLeave = (event: React.MouseEvent) => {
      if (props.onLeave) {
        props.onLeave(event)
      }

      setHovered(false)
    }

    const renderLabel = () => {
      const { Label } = props.styled!
      return props.children ? (
        <Label
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label')}
        >
          {props.children}
        </Label>
      ) : null
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        href={props.href}
        onClick={attachIf(handleClick, isActionnable)}
        onFocus={attachIf(handleFocus, isActionnable)}
        onBlur={attachIf(handleBlur, isActionnable)}
        onMouseEnter={attachIf(handleHover, isActionnable)}
        onMouseLeave={attachIf(handleLeave, isActionnable)}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.startAdornment}
        {renderLabel()}
        {props.endAdornment}
      </Root>
    )
  },
)

MenuItem.defaultProps = {
  disabled: false,
  selected: false,
  root: false,
  href: '#',
}

export default MenuItem
