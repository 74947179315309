import styled from '../../../themes/Developer/themed-component'
import { GridContainerProps, GridItemProps } from '../../../Grid/Grid'
import { CheckboxGroupContext } from '../../CheckboxGroup.composition'
import { Grid } from '../../../Grid'

const Item = styled(Grid)<GridItemProps>``

const Root = styled(Grid)<GridContainerProps>``

const Components: CheckboxGroupContext = {
  Root,
  Item,
}

export default Components
