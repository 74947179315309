import NativeRadioGroup, { NativeRadioGroupPublicProps } from '../../NativeRadioGroup'
import Theme from '../../../../../themes/Developer/theme'
import type { RadioGroupContext } from '../../../../RadioGroup.composition'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevNativeRadioGroup.styles'
import Customisations, {
  DevNativeRadioGroupCustomisations as NativeRadioGroupCustomisations,
} from './DevNativeRadioGroup.customisations'

export const DevNativeRadioGroup = withCustomisedStyle<
  NativeRadioGroupPublicProps,
  RadioGroupContext,
  NativeRadioGroupCustomisations
>(NativeRadioGroup, Theme, Styles, Customisations, 'NativeRadioGroup')

export type DevNativeRadioGroupCustomisations = NativeRadioGroupCustomisations
export type DevNativeRadioGroupProps = NativeRadioGroupPublicProps & NativeRadioGroupCustomisations
