import * as React from 'react'
import * as PropTypes from 'prop-types'
import type { LegalNoteContext } from './LegalNote.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'

export interface LegalNotePublicProps {
  /**
   * Component to display as a content
   */
  children?: React.ReactNode
}

export interface LegalNoteProps extends LegalNotePublicProps, StyledProps<LegalNoteContext> {}

export const LegalNote = React.forwardRef(
  (props: LegalNoteProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): unknown => ({})

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

LegalNote.propTypes = {
  children: PropTypes.node,
}

export default LegalNote
