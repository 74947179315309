import Field, { FieldPublicProps } from '../../Field'
import Theme from '../../../themes/Developer/theme'
import type { FieldContext } from '../../Field.composition'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevField.styles'
import Customisations, { FieldCustomisations } from './DevField.customisations'

export const DevField = withCustomisedStyle<FieldPublicProps, FieldContext, FieldCustomisations>(
  Field,
  Theme,
  Styles,
  Customisations,
  'Field',
)

export type DevFieldCustomisations = FieldCustomisations
export type DevFieldProps = FieldPublicProps & FieldCustomisations
