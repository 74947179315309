import * as React from 'react'
import type { LinkContext } from './Link.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { attachIf } from '../utils/event-handler'
import { getTestId } from '../utils/test'

export interface LinkPublicProps {
  /**
   * The location of the linked document
   */
  href?: string
  /**
   * Specifies where the linked document is to be loaded
   */
  target?: string
  /**
   * THe path returned by the component when the user clicks on it
   */
  path?: string
  /**
   * Link size to display
   */
  size?: IconSizes | number
  /**
   * Icon to display within the link
   */
  icon?: any
  /**
   * Position of the icon within the link
   */
  iconPosition?: 'right' | 'left'
  /**
   * Disable the link
   */
  disabled?: boolean
  /**
   * Handler when the user clicks on the link
   */
  onClick?: (path?: string) => void
  /**
   * Handler when the input loses the focus. The new value is passed as parameter.
   */
  onBlur?: (event: React.FocusEvent) => void
  /**
   * Handler when the input gets the focus.
   */
  onFocus?: (event: React.FocusEvent) => void
  /**
   * Handler when the input is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the input is not hovered
   */
  onLeave?: (event?: React.MouseEvent) => void
  /**
   * Component to display as label
   */
  children?: React.ReactNode
}

type IconSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

interface LinkProps extends LinkPublicProps, StyledProps<LinkContext> {}

export interface LinkCoreStyle {
  iconToLeft: boolean
  size: string | number
  disabled?: boolean
  hovered: boolean
  focused: boolean
}

export const Link = React.forwardRef(
  (props: LinkProps, forwardRef: React.Ref<HTMLAnchorElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const [focused, setFocused] = React.useState(false)
    const [hovered, setHovered] = React.useState(false)

    const isActionable = !props.disabled

    const getCoreStyle = (): LinkCoreStyle => ({
      iconToLeft: props.iconPosition === 'left',
      size: props.size!,
      disabled: props.disabled,
      hovered,
      focused,
    })

    const handleClick = () => {
      if (props.onClick) {
        props.onClick(props.path)
      }
    }

    const handleFocus = (event: React.FocusEvent) => {
      if (props.onFocus) {
        props.onFocus(event)
      }

      setFocused(true)
    }

    const handleBlur = (event: React.FocusEvent) => {
      if (props.onBlur) {
        props.onBlur(event)
      }

      setFocused(false)
    }

    const handleHover = (event: React.MouseEvent) => {
      if (props.onHover) {
        props.onHover(event)
      }

      setHovered(true)
    }

    const handleLeave = (event?: React.MouseEvent) => {
      if (props.onLeave) {
        props.onLeave(event)
      }

      setHovered(false)
    }

    const renderIcon = () => {
      const { Icon } = props.styled!
      return props.icon ? (
        <Icon
          src={props.icon}
          size={props.size}
          disabled={props.disabled}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'icon')}
        />
      ) : null
    }

    const renderLabel = () => {
      const { Label } = props.styled!
      return (
        <Label
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label')}
        >
          {props.children}
        </Label>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        href={props.href}
        target={props.target}
        onClick={attachIf(handleClick, isActionable)}
        onTouchEnd={attachIf(handleClick, isActionable)}
        onFocus={attachIf(handleFocus, isActionable)}
        onBlur={attachIf(handleBlur, isActionable)}
        onMouseEnter={attachIf(handleHover, isActionable)}
        onMouseLeave={attachIf(handleLeave, isActionable)}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderIcon()}
        {renderLabel()}
      </Root>
    )
  },
)

Link.defaultProps = {
  href: '#',
  iconPosition: 'left',
  size: 'sm',
}

export default Link
