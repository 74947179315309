import * as theme from '../../../../themes/Developer/proxima-nova'
import { getBorderColour } from './colours'
import type { InputStyleProps } from '../DevInput.styles'

/** Height */
export const getHeight = (props: InputStyleProps) => `${theme.inputSize[props.styleProps.size]}px`

/** Border */
export const getBorder = (props: InputStyleProps) =>
  theme.getBorder(props, getBorderColour(props), 'border', theme.getState(props.styleProps))
