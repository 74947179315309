import * as theme from '../../../../themes/Developer/proxima-nova'
import type { BannerStyleProps } from '../DevBanner.styles'

/** Colour */
export const getColour = (props: BannerStyleProps) => {
  const currentState = theme.getState(props.styleProps)
  return theme.getColour(props, props.theme.colours.tertiary.neutral.charcoal, currentState)
}

const defaultBgColour = (props: BannerStyleProps) => ({
  bgColour: props.theme.colours.tertiary.neutral.white,
})

/** Background colour */
const getDefaultBgColour = (props: BannerStyleProps) =>
  theme.getBgColour(props, defaultBgColour(props), theme.getState(props.styleProps))

export const getBgColour = (props: BannerStyleProps) => {
  const currentState = theme.getState(props.styleProps)
  return theme.getBgColour(props, getDefaultBgColour(props), currentState)
}

/** Border colour */
export const getBorderColour = (props: BannerStyleProps) => {
  switch (props.styleProps.type) {
    case 'warning':
      return getBorderStateColour(props.theme.colours.tints.sun[100])
    case 'success':
      return getBorderStateColour(props.theme.colours.tertiary.semantic.green)
    case 'error':
      return getBorderStateColour(props.theme.colours.tertiary.semantic.red)
    case 'info':
      return getBorderStateColour(props.theme.colours.tints.azure[100])
    default:
      return getBorderStateColour(props.theme.colours.tertiary.neutral.charcoal)
  }
}

const getBorderStateColour = (value: string) => ({
  colour: value,
})
