import type { IconPublicProps } from '../../Icon'
import type { IconCustomisations } from './customisations'

export default (props: IconPublicProps & IconCustomisations): IconCustomisations => ({
  bgColour: props.bgColour,
  hoverBgColour: props.hoverBgColour,
  border: props.border,
  borderRadius: props.borderRadius,
  padding: props.padding,
})
