import type { LegalNotePublicProps } from '../../LegalNote'
import type { LegalNoteCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: LegalNotePublicProps & LegalNoteCustomisations,
): LegalNoteCustomisations => ({
  colour: props.colour,
  fontSize: props.fontSize,
})
