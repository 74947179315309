import * as theme from '../../../../themes/Developer/proxima-nova'
import { thumbBorderColour } from './colours'
import type { SwitchStyleProps } from '../DevSwitch.styles'

/** Border */
export const getThumbBorder = (props: SwitchStyleProps) =>
  theme.getBorder(
    theme.getCustomisations(props, 'Thumb'),
    thumbBorderColour(props),
    'border',
    theme.getState(props.styleProps),
  )
