import Paper, { PaperPublicProps } from '../../Paper'
import Theme from '../../../themes/Developer/theme'
import Styles from './DevPaper.styles'
import Customisations from './DevPaper.customisations'
import type { PaperContext } from '../../Paper.composition'
import type { PaperCustomisations } from './customisations'
import { withCustomisedStyle } from '../../../providers'

export const DevPaper = withCustomisedStyle<PaperPublicProps, PaperContext, PaperCustomisations>(
  Paper,
  Theme,
  Styles,
  Customisations,
  'Paper',
)

export type DevPaperCustomisations = PaperCustomisations
export type DevPaperProps = PaperPublicProps & PaperCustomisations
