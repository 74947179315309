import DialogTitle, { DialogTitlePublicProps } from '../../DialogTitle'
import type { DialogTitleContext } from '../../DialogTitle.composition'
import type { DialogTitleCustomisations } from './customisations'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevDialogTitle.styles'
import Customisations from './DevDialogTitle.customisations'

export const DevDialogTitle = withCustomisedStyle<
  DialogTitlePublicProps,
  DialogTitleContext,
  DialogTitleCustomisations
>(DialogTitle, Theme, Styles, Customisations, 'DialogTitle')

export type DevDialogTitleCustomisations = DialogTitleCustomisations
export type DevDialogTitleProps = DialogTitlePublicProps & DialogTitleCustomisations
