import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import type { StyledProps } from '../../../providers'
import type { TableContainerContext } from './TableContainer.composition'

export interface TableContainerPublicProps {
  /**
   * Maximum height of the container
   */
  maxHeight?: number
  /**
   * The table itself, normally `<Table />`.
   */
  children?: React.ReactNode
}

export type TableContainerProps = TableContainerPublicProps & StyledProps<TableContainerContext>

export interface TableContainerCoreStyle {
  maxHeight?: number
}

const TableContainer = React.forwardRef(
  (props: TableContainerProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive<HTMLDivElement>({
      ref: forwardRef,
    })

    const getCoreStyle = (): TableContainerCoreStyle => ({
      maxHeight: props.maxHeight,
    })

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

TableContainer.defaultProps = {}

export default TableContainer
