import Select, { SelectPublicProps } from '../../Select'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevSelect.styles'
import Customisations from './DevSelect.customisations'
import type { SelectContext } from '../../Select.composition'
import type { SelectCustomisations } from './customisations'

export const DevSelect = withCustomisedStyle<
  SelectPublicProps,
  SelectContext,
  SelectCustomisations
>(Select, Theme, Styles, Customisations, 'Select')

export type DevSelectCustomisations = SelectCustomisations
export type DevSelectProps = SelectPublicProps & SelectCustomisations
