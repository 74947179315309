import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'
import type { StyledProps } from '../../../providers'
import type { ListItemIconContext } from './ListItemIcon.composition'
import type { IconDefinition } from '../../../utils/fontawesome'

export interface ListItemIconPublicProps {
  /**
   * Icon to display
   */
  src?: string | IconDefinition
  /**
   * Icon size to display
   */
  size?: IconSizes | number
  /**
   * Component used as content of the li element
   */
  children?: React.ReactNode
}

type IconSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type ListItemIconProps = ListItemIconPublicProps & StyledProps<ListItemIconContext>

const ListItemIcon = React.forwardRef(
  (props: ListItemIconProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = () => ({})

    const renderFrontAwesomeIcon = () => {
      const { FontAwesomeIcon } = props.styled!
      return (
        <FontAwesomeIcon
          src={props.src as IconDefinition}
          size={props.size}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'fontawesome')}
        />
      )
    }

    const renderIcon = () => {
      if (props.children) {
        return props.children
      }

      if (typeof props.src !== 'string') {
        return renderFrontAwesomeIcon()
      }

      const { Icon } = props.styled!
      return (
        <Icon
          src={props.src as string}
          size={props.size}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'string')}
        />
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderIcon()}
      </Root>
    )
  },
)

ListItemIcon.defaultProps = {
  size: 'sm',
}

export default ListItemIcon
