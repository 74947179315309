import * as theme from '../../../../themes/Developer/proxima-nova'
import type { LinkStyleProps } from '../DevLink.styles'

const defaultColour = (props: LinkStyleProps) => ({
  colour: props.theme.colours.tints.azure[100],
  hoverColour: props.theme.colours.tints.azure[100],
  focusColour: props.theme.colours.tints.azure[100],
  activeColour: props.theme.colours.tints.cloudBurst[100],
})

/** Colour */
export const getColour = (props: LinkStyleProps, state?: theme.State) => {
  if (theme.getState(props.styleProps)) {
    return theme.getColour(props, defaultColour(props), theme.getState(props.styleProps))
  }

  return theme.getColour(props, defaultColour(props), state)
}

const defaultBorderColour = (props: LinkStyleProps) => ({
  colour: 'transparent',
  focusColour: props.theme.colours.tints.azure[100],
})

/** Border colour */
export const getBorderColour = (props: LinkStyleProps, state?: theme.State) => {
  return theme.getBorderColour(props, defaultBorderColour(props), state)
}

/** Icon */
export const getIconColour = (props: LinkStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Icon')
  return theme.getColour(customisations, 'inherit', theme.getState(props.styleProps))
}
