import Radio, { NativeRadioPublicProps } from '../../NativeRadio'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import type { NativeRadioContext } from '../../NativeRadio.composition'
import type { NativeRadioCustomisations } from './customisations'
import Customisations from './DevNativeRadio.customisations'
import Styles from './DevNativeRadio.styles'

export const DevNativeRadio = withCustomisedStyle<
  NativeRadioPublicProps,
  NativeRadioContext,
  NativeRadioCustomisations
>(Radio, Theme, Styles, Customisations, 'Radio')

export type DevNativeRadioCustomisations = NativeRadioCustomisations
export type DevNativeRadioProps = NativeRadioPublicProps & NativeRadioCustomisations
