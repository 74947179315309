import type { ListItemTextPublicProps } from '../../ListItemText'
import type { ListItemTextCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: ListItemTextPublicProps & ListItemTextCustomisations,
): ListItemTextCustomisations => ({
  colour: props.colour,
  Primary: {
    colour: props.Primary?.colour,
  },
  Secondary: {
    colour: props.Secondary?.colour,
  },
})
