import Menu, { MenuPublicProps } from '../../Menu'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevMenu.styles'
import Customisations from './DevMenu.customisations'
import type { MenuContext } from '../../Menu.composition'
import type { MenuCustomisations } from './customisations'

export const DevMenu = withCustomisedStyle<MenuPublicProps, MenuContext, MenuCustomisations>(
  Menu,
  Theme,
  Styles,
  Customisations,
  'Menu',
)

export type DevMenuCustomisations = MenuCustomisations
export type DevMenuProps = MenuPublicProps & MenuCustomisations
