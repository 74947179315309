import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { TableContainerContext } from '../../TableContainer.composition'
import type { TableContainerCoreStyle } from '../../TableContainer'
import type { StyleProps } from '../../../../../types'

export type TableContainerStyleProps = StyleProps<TableContainerCoreStyle> & ThemeProps

const MaxHeightRoot = css<TableContainerStyleProps>`
  max-height: ${(props) => props.styleProps.maxHeight}px;
`

const Root = styled.div<TableContainerStyleProps>`
  width: 100%;
  overflow-x: auto;

  ${(props) => props.styleProps.maxHeight != null && MaxHeightRoot};
`

const Components: TableContainerContext = {
  Root,
}

export default Components
