import type { NativeRadioStyleProps } from '../DevNativeRadio.styles'

export const fontSizes = {
  small: 12,
  medium: 14,
  large: 16,
}

/** Font size  */
export const getFontSize = (props: NativeRadioStyleProps) => `${fontSizes[props.styleProps.size]}px`
