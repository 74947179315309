import { Ref, useEffect, useRef, useState } from 'react'

const useHover = <T>(opts?: { ref?: Ref<T>; initialValue?: boolean }): any => {
  const ref: any = opts?.ref || useRef<T>(null)

  const [hovered, setHovered] = useState(opts?.initialValue || false)

  const handleMouseOver = () => {
    // console.log("HOVER")
    setHovered(true)
  }
  const handleMouseOut = () => {
    // console.log("LEAVE")

    setHovered(false)
  }

  useEffect(
    () => {
      const node: any = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }

      return () => null
    },
    [ref.current], // Recall only if ref changes
  )

  return [ref, hovered, setHovered]
}

export default useHover
