import * as React from 'react'

import useResponsive from '../../../../../hooks/useResponsive'
import { StyledProps } from '../../../../../providers'
import { ListItemContext } from '../../ListItem.composition'
import ListItemBase, { ListItemPublicProps } from '../../ListItem'

export interface ListItemStaticPublicProps extends ListItemPublicProps {
  /**
   * Level of the item translated to an intend
   */
  level?: number
}

export interface ListStaticItemProps
  extends ListItemStaticPublicProps,
    StyledProps<ListItemContext> {}

export interface ListItemStaticCoreStyle {
  level: number
}

const ListStaticItem = React.forwardRef(
  (props: ListStaticItemProps, forwardRef: React.Ref<HTMLLIElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): ListItemStaticCoreStyle => ({
      level: props.level!,
    })

    return <ListItemBase ref={ref} {...props} styleProps={getCoreStyle()} />
  },
)

ListStaticItem.defaultProps = {
  as: 'li',
  level: 1,
}

export default ListStaticItem
