import Stars, { StarsPublicProps } from '../../Stars'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevStars.styles'
import Customisations from './DevStars.customisations'
import type { StarsContext } from '../../Stars.composition'
import type { StarsCustomisations } from './customisations'

export const DevStars = withCustomisedStyle<StarsPublicProps, StarsContext, StarsCustomisations>(
  Stars,
  Theme,
  Styles,
  Customisations,
  'Stars',
)

export type DevStarsCustomisations = StarsCustomisations
export type DevStarsProps = StarsPublicProps & StarsCustomisations
