import { CheckboxFieldStyleProps } from './DevCheckboxField.styles'
import { CheckboxFieldPublicProps } from '../../CheckboxField'
import { DevCheckboxGroupCustomisations as CheckboxGroupCustomisations } from '../../../CheckboxGroup/themes/Developer/DevCheckboxGroup.customisations'
import ConvertFieldCustomisations, {
  FieldCustomisations,
} from '../../../Field/themes/Developer/DevField.customisations'

export interface CheckboxFieldCustomisations extends FieldCustomisations {
  CheckboxGroup?: CheckboxGroupCustomisations
}

export const Customisations = {
  CheckboxGroup: (props: CheckboxFieldStyleProps) => props.customisations.CheckboxGroup!,
  Field: (props: CheckboxFieldStyleProps) => ({
    Label: props.customisations?.Label,
    HelperText: props.customisations.HelperText,
    ValidityText: props.customisations.ValidityText,
    Tooltip: props.customisations.Tooltip,
    ValidityStatus: props.customisations.ValidityStatus,
  }),
}

export default (
  props: CheckboxFieldPublicProps & CheckboxFieldCustomisations,
): CheckboxFieldCustomisations => ({
  CheckboxGroup: props.CheckboxGroup || {},
  ...ConvertFieldCustomisations(props),
})
