import TabPanel, { TabPanelPublicProps } from '../../TabPanel'
import Theme from '../../../../../themes/Developer/theme'
import Styles from './DevTabPanel.styles'
import { withStyle } from '../../../../../providers'
import type { TabPanelContext } from '../../TabPanel.composition'

export const DevTabPanel = withStyle<TabPanelPublicProps, TabPanelContext>(
  TabPanel,
  Theme,
  Styles,
  'TabPanel',
)

export type DevTabPanelProps = TabPanelPublicProps
