import type { StepIconStyleProps } from './DevStepIcon.styles'
import type { StepIconPublicProps } from '../../StepIcon'
import { getCircleColour, StepIconCustomisations } from './customisations'

export const Customisations = {
  Icon: (props: StepIconStyleProps) => ({
    colour: getCircleColour(props),
    hoverColour: getCircleColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: StepIconPublicProps & StepIconCustomisations): StepIconCustomisations => ({
  Circle: props.Circle || {},
  Text: props.Text || {},
})
