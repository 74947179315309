import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { DevStepConnector } from '../../components'
import type { StepperCoreStyle } from '../../Stepper'
import type { StepperContext } from '../../Stepper.composition'
import type { StyleProps } from '../../../types'
import * as themeStyles from '../../../themes/Developer/styles'

export type StepperStyleProps = StyleProps<StepperCoreStyle> & ThemeProps

const HorizontalOrientation = css<StepperStyleProps>`
  flex-direction: row;
  align-items: center;
`

const VerticalOrientation = css<StepperStyleProps>`
  flex-direction: column;
`

const AlternativeLabel = css<StepperStyleProps>`
  align-items: flex-start;
`

const Connector = styled(DevStepConnector)<StepperStyleProps>``

const Root = styled.div<StepperStyleProps>`
  display: flex;

  ${(props) => props.styleProps.orientation === 'horizontal' && HorizontalOrientation};
  ${(props) => props.styleProps.orientation === 'vertical' && VerticalOrientation};
  ${(props) => props.styleProps.alternativeLabel && AlternativeLabel};

  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: StepperContext = {
  Root,
  Connector,
}

export default Components
