import * as React from 'react'
import { faLightAngleDown, faLightAngleUp } from '../utils/fontawesome'
import { useFormControl } from '../FormControl/FormControl.context'
import { StyledProps } from '../providers'
import { SelectContext } from './Select.composition'
import useRoveFocus from '../hooks/useRoveFocus'
import useComponentVisible from '../hooks/useComponentVisible'
import useResponsive from '../hooks/useResponsive'
import useControlled from '../hooks/useControlled'
import { getPlaceholder } from '../Input/Input.utils'
import useTruncate from '../hooks/useTruncate'
import useDidMount from '../hooks/useDidMount'
import useCheckDirty from '../FormControl/hooks/useCheckDirty'
import useEnhancedEffect from '../hooks/useEnhancedEffect'
import {
  createOptionFromTemplate,
  createSelectionFromTemplate,
  filter,
  format,
  getOptionsFromValues,
  getValue,
  getValues,
  isReadOnly,
  isSearch,
  isSelected,
  toArray,
} from './Select.utils'
import getStateValue from '../utils/state'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { newId } from '../utils'
import { isEmpty } from '../utils/lodash'
import { attachIf } from '../utils/event-handler'
import { getTestId } from '../utils/test'

export interface SearchProps {
  /**
   * Label displayed when the search return empty results.
   */
  searchNotFoundLabel?: string
  /**
   * Type of search to apply on the list of options
   */
  searchType?: 'startsWith' | 'includes'
  /**
   * Value of the search
   */
  search?: string
  /**
   * Handler when the search value changes.
   */
  onSearchChange?: (value: any) => void
}

export interface SelectPublicProps extends SearchProps {
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id?: string
  /**
   * Name attribute of the `input` element.
   */
  name?: string
  /**
   * Type of the component
   */
  type?: 'standard' | 'search'
  /**
   * The size of the text field.
   */
  size?: 'small' | 'medium' | 'large' | number
  /**
   * The option elements to populate the select with.
   */
  options?: any[]
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value?: any
  /**
   * Name of the property to display in the select
   */
  displayName?: string
  /**
   * Name of the property to return as value in the select
   */
  displayValue?: string
  /**
   * Direction of the selection
   */
  direction?: 'ltr' | 'rtl'
  /**
   * The default `input` element value. Use when the component is not controlled.
   */
  defaultValue?: any
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string
  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth?: boolean
  /**
   * If `true`, the `input` element will be disabled.
   */
  disabled?: boolean
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean
  /**
   * If `true`, the label will be displayed in an error state.
   */
  invalid?: boolean
  /**
   * If `true`, the component will be displayed in focused state.
   */
  focused?: boolean
  /**
   * If `true`, the component will be displayed in hovered state.
   */
  hovered?: boolean
  /**
   * If `true`, the label is displayed as required and the `input` element` will be required.
   */
  required?: boolean
  /**
   * True if the option keep selected when the user selects it another time in a raw, false otherwise
   */
  keepSelection?: boolean
  /**
   * Template to use for the selection component
   */
  selectionTemplate?: (option: any) => JSX.Element
  /**
   * Template to use for the option component
   */
  optionTemplate?: (option: any) => JSX.Element
  /**
   * If `true`, `value` must be an array and the menu will support multiple selections.
   */
  multiple?: boolean
  /**
   * -1 if the input is not keyboard accessible, index in the sequential keyboard navigation otherwise
   */
  tabIndex?: number
  /**
   * Start `Adornment` for this component.
   */
  startAdornment?: React.ReactNode
  /**
   * End `Adornment` for this component.
   */
  endAdornment?: React.ReactNode
  /**
   * Handler when the value changes. The new value is passed as parameter.
   */
  onChange?: (value: any) => void
  /**
   * Handler when the input loses the focus. The new value is passed as parameter.
   */
  onBlur?: (event: React.FocusEvent) => void
  /**
   * Handler when the input gets the focus.
   */
  onFocus?: (event: React.FocusEvent) => void
  /**
   * Handler when the input is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the input is not hovered
   */
  onLeave?: (event: React.MouseEvent) => void
  /**
   * Handler when the user clicks on the field
   */
  onClick?: (event?: React.MouseEvent) => void
}

export interface SelectProps extends SelectPublicProps, StyledProps<SelectContext> {}

export interface OptionStyle {
  selected?: boolean
  focused?: boolean
  hovered?: boolean
  active?: boolean
}

export interface SelectCoreStyle {
  disabled: boolean
  readOnly: boolean
  invalid: boolean
  variant: string
  size: string | number
  filled: boolean
  focused: boolean
  hovered: boolean
  active: boolean
  hasFormControlUsed: boolean
  option: OptionStyle
  isPlaceholderDisplayed?: boolean
  isOptionsOpened?: boolean
  type: string
  noBorder: boolean
  direction: string
}

const Select: React.FC<SelectProps> = React.forwardRef(
  (props: SelectProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { options: optionsProp = [] } = props

    const { ref } = useResponsive<HTMLDivElement>({ ref: forwardRef })

    const inputRef = React.useRef<HTMLInputElement>(null)
    const arrowRef = React.useRef<HTMLDivElement>(null)
    const optionsContainerRef = React.useRef<HTMLUListElement>(null)

    const optionsRef: any[] = [...Array(optionsProp.length)].map(() => React.createRef())

    const formControl = useFormControl(props)
    const checkDirty = useCheckDirty(formControl)

    const [value, setValue, isControlled] = useControlled({
      value: props.value,
      default: props.defaultValue,
      name: 'Select',
    })

    const { truncate } = useTruncate<HTMLInputElement>({
      ref: inputRef,
      separator: ',',
    })

    const { isComponentVisible, setIsComponentVisible } = useComponentVisible({
      ref,
    })

    const [optionFocused, setOptionFocused] = useRoveFocus(
      ref as React.RefObject<HTMLElement>,
      optionsProp.length,
      !isComponentVisible,
    )

    const [search, setSearch] = React.useState(props.search)

    const [hovered, setHovered] = React.useState(props.hovered)
    const [focused, setFocused] = React.useState(props.focused)
    const [active, setActive] = React.useState(false)

    const isActionable = !formControl.disabled && !formControl.readOnly

    const generateId = React.useMemo(() => {
      return newId()
    }, [])

    const keepSelection = () =>
      typeof props.keepSelection !== 'undefined' ? props.keepSelection : !props.multiple

    React.useEffect(() => {
      if (optionFocused > -1) {
        const optionRef = optionsRef[optionFocused]
        if (optionRef.current) {
          optionRef.current.focus()
        }
      }
    }, [optionFocused])

    useDidMount(() => {
      if (!isComponentVisible) {
        setOptionFocused(-1)

        if (inputRef.current) {
          inputRef.current.focus()
        }
      }
    }, [isComponentVisible])

    // Check dirty once
    React.useEffect(() => {
      checkDirty(inputRef.current)
    }, [])

    // Check dirty if the component is supposed to be filled but the input element has no value
    React.useEffect(() => {
      if (!isControlled && formControl.filled && !inputRef.current!.value) {
        checkDirty(inputRef.current)
      }
    }, [inputRef.current?.value])

    // Check dirty with the value is the input is controlled
    useEnhancedEffect(() => {
      if (isControlled) {
        checkDirty({ value: props.value })
      }
    }, [props.value, checkDirty, isControlled])

    React.useEffect(() => {
      setSearch(props.search)
    }, [props.search])

    React.useEffect(() => {
      if (props.search === search) {
        return
      }

      if (props.onSearchChange) {
        props.onSearchChange(search)
      }

      if (isEmpty(search)) {
        inputRef.current.focus()
      }
    }, [search])

    const getCoreStyle = (extra?: any): SelectCoreStyle => {
      return {
        disabled: formControl.disabled!,
        readOnly: formControl.readOnly!,
        invalid: formControl.invalid!,
        variant: formControl.variant!,
        size: formControl.size!,
        filled: formControl.filled!,
        focused: getStateValue(formControl.focused, focused),
        hovered: getStateValue(formControl.hovered, hovered),
        active,
        hasFormControlUsed: formControl !== props,
        isPlaceholderDisplayed: value == null,
        type: props.type!,
        isOptionsOpened: isComponentVisible,
        direction: props.direction!,
        ...extra,
      }
    }

    const getState = (extra: any = {}) => ({
      focused,
      hovered,
      ...extra,
    })

    const getNextValue = (valueSelected: any) => {
      let newValue

      if (props.multiple) {
        newValue = Array.isArray(value) ? value.slice() : []

        const itemIndex = newValue.indexOf(valueSelected)
        if (itemIndex === -1) {
          newValue.push(valueSelected)
        } else if (!keepSelection()) {
          newValue.splice(itemIndex, 1)
        }
      } else {
        newValue = !keepSelection() && value === valueSelected ? '' : valueSelected
      }

      return newValue
    }

    const getValueComponent = (hasTruncate: boolean = true) => {
      const component: any = { Component: null }

      const options = getOptionsFromValues(props, toArray(value))

      if (props.selectionTemplate) {
        const components = options.map((option, index) =>
          createSelectionFromTemplate(
            `selection-${index + 1}`,
            option,
            props,
            formControl,
            getState(),
          ),
        )

        component.Component = components
      }

      const names = getValues(options, props.displayName)

      const currentValue: any = format(names, ', ')
      component.value = hasTruncate ? truncate(currentValue || '') : currentValue

      return component
    }

    const handleOptionClick = (event: React.MouseEvent) => {
      const element: any = event.currentTarget

      handleLeave()
      handleOptionSelected(element)
    }

    const handleOptionKeyUp = (event: React.KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return
      }

      const element: any = event.currentTarget
      handleOptionSelected(element)
    }

    const handleOptionSelected = (element: any) => {
      const newValue = getNextValue(element.dataset.value)

      inputRef.current.focus()
      setSearch(undefined)

      if (isSelected(newValue, value)) {
        return
      }

      if (!isControlled) {
        checkDirty({ value: newValue })
      }

      setValue(newValue)

      if (props.onChange) {
        props.onChange(newValue)
      }
    }

    const handleClick = (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      if (formControl.readOnly) {
        return
      }

      setIsComponentVisible(!isComponentVisible)

      if (props.onClick) {
        props.onClick(event)
      }
    }

    const handleFocus = (event: React.FocusEvent) => {
      if (props.onFocus) {
        props.onFocus(event)
      }

      if (formControl && formControl.onFocus) {
        formControl.onFocus(event)
      } else {
        setFocused(true)
      }
    }

    const handleBlur = (event: React.FocusEvent) => {
      if (props.onBlur) {
        props.onBlur(event)
      }

      if (formControl && formControl.onBlur) {
        formControl.onBlur(event)
      } else {
        setFocused(false)
      }
    }

    const handleHover = (event: React.MouseEvent) => {
      if (props.onHover) {
        props.onHover(event)
      }

      if (formControl && formControl.onHover) {
        formControl.onHover(event)
      } else {
        setHovered(true)
      }
    }

    const handleLeave = (event?: React.MouseEvent) => {
      if (props.onLeave) {
        props.onLeave(event)
      }

      if (formControl && formControl.onLeave) {
        formControl.onLeave(event)
      } else {
        setHovered(false)
      }
    }

    const handleSearch = (event: React.ChangeEvent) => {
      if (!isComponentVisible) {
        setIsComponentVisible(true)
      }

      const element: any = event.currentTarget
      const previousSearch = element.dataset.search

      let { value: elementValue } = element
      if (!isEmpty(previousSearch)) {
        elementValue = isEmpty(elementValue) ? null : elementValue
      } else {
        let currentValue = value

        if (props.multiple) {
          const options = getOptionsFromValues(props, toArray(value))
          currentValue = getValues(options, props.displayName).join(', ')
        } else {
          const option = getOptionsFromValues(props, value)
          currentValue = getValue(option, props.displayName)
        }

        elementValue = elementValue.replace(currentValue, '')
      }

      setSearch(elementValue)
    }

    const renderOption = (option: any, index: number) => {
      const optionName = getValue(option, props.displayName)
      const optionValue = getValue(option, props.displayValue)

      const style = {
        selected: isSelected(optionValue, value),
      }

      const optionComponent = props.optionTemplate
        ? createOptionFromTemplate(option, props, formControl, style)
        : optionName

      const { Option } = props.styled!
      return (
        <Option
          key={optionValue}
          ref={optionsRef[index]}
          data-value={optionValue}
          data-index={index}
          tabIndex={index === optionFocused ? 0 : -1}
          onClick={handleOptionClick}
          onKeyDown={handleOptionKeyUp}
          styleProps={getCoreStyle({ option: style })}
          customisations={props.customisations}
          {...getTestId(props, `option-${index + 1}`)}
        >
          {optionComponent}
        </Option>
      )
    }

    const renderArrow = () => {
      const icon = isComponentVisible ? faLightAngleUp : faLightAngleDown

      const { Arrow } = props.styled!
      return (
        <Arrow
          ref={arrowRef}
          src={icon}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'arrow')}
        />
      )
    }

    const renderInput = () => {
      const placeholder = getPlaceholder(props.placeholder, formControl.required)

      const searchInProgress = isSearch(props) && !isEmpty(search)

      let component = null
      let currentValue = search
      if (!searchInProgress) {
        component = getValueComponent(false)
        currentValue = component.value
      }

      const onChange = isSearch(props) ? handleSearch : undefined
      const inputValue = component?.Component == null || searchInProgress ? currentValue : ''

      const tabIndex = formControl.readOnly || formControl.disabled ? -1 : props.tabIndex

      const { Input, SelectionValue } = props.styled!
      return (
        <SelectionValue styleProps={getCoreStyle()} customisations={props.customisations}>
          {!searchInProgress && component?.Component}
          <Input
            ref={inputRef}
            aria-autocomplete="list"
            aria-required={props.required}
            data-search={search}
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="off"
            id={props.id || props.name || generateId}
            name={props.name || props.id || generateId}
            type="text"
            tabIndex={tabIndex}
            value={inputValue}
            disabled={formControl.disabled}
            readOnly={isReadOnly(props, formControl)}
            placeholder={placeholder}
            onChange={onChange}
            styleProps={getCoreStyle()}
            customisations={props.customisations}
            {...getTestId(props, 'element')}
          />
        </SelectionValue>
      )
    }

    const renderSelection = () => {
      const { Selection } = props.styled!
      return (
        <Selection
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'selection')}
        >
          {props.startAdornment}
          {renderInput()}
          {props.endAdornment}
          {renderArrow()}
        </Selection>
      )
    }

    const renderNoNatch = () => {
      const noMatch = props.searchNotFoundLabel || 'Result not found'

      const { Option } = props.styled!
      return (
        <Option
          key="no-match"
          tabIndex={-1}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'no-found')}
        >
          {noMatch}
        </Option>
      )
    }

    const renderOptions = () => {
      const options =
        isSearch(props) && !isEmpty(search)
          ? filter(props.options!, search!, props.searchType!, props.displayValue)
          : props.options!

      const { Options } = props.styled!
      return isComponentVisible ? (
        <Options
          ref={optionsContainerRef}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'options')}
        >
          {options.map((option, index) => renderOption(option, index))}
          {options.length === 0 && renderNoNatch()}
        </Options>
      ) : null
    }

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        onClick={attachIf(handleClick, isActionable)}
        onFocus={attachIf(handleFocus, isActionable)}
        onBlur={attachIf(handleBlur, isActionable)}
        onMouseEnter={attachIf(handleHover, isActionable)}
        onMouseLeave={attachIf(handleLeave, isActionable)}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderSelection()}
        {renderOptions()}
      </Root>
    )
  },
)

Select.defaultProps = {
  disabled: false,
  readOnly: false,
  size: 'medium',
  options: [],
  type: 'standard',
  searchType: 'startsWith',
  tabIndex: 0,
  direction: 'ltr',
  id: newId(),
}

export default Select
