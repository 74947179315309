import * as React from 'react'
import type { StyledProps } from '../../../providers'
import type { OptionWithIconContext } from './OptionWithIcon.composition'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'

export interface OptionWithIconPublicProps {
  option: any
  /**
   * Name of the property to display in the select
   */
  displayName: string
  /**
   * Icon size to display
   */
  size?: IconSizes | number
}

type IconSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type OptionWithIconProps = OptionWithIconPublicProps & StyledProps<OptionWithIconContext>

export interface OptionWithIconCoreStyle {
  size: string | number
}

const OptionWithIcon: React.FC<OptionWithIconProps> = React.forwardRef(
  (props: OptionWithIconProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): OptionWithIconCoreStyle => ({
      size: props.size!,
    })

    const renderLabel = () => {
      const { option } = props

      const { Label } = props.styled!
      return (
        <Label styleProps={getCoreStyle()} customisations={props.customisations}>
          {option[props.displayName]}
        </Label>
      )
    }

    const renderIcon = () => {
      const { option, size } = props

      const { Icon } = props.styled!
      return (
        <Icon
          src={option.icon}
          size={size}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
        />
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderIcon()}
        {renderLabel()}
      </Root>
    )
  },
)

export default OptionWithIcon
