import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import type { StyledProps } from '../../../providers'
import type { TabPanelContext } from './TabPanel.composition'

export interface TabPanelPublicProps {
  /**
   * Value of the tab panel
   */
  value: string | number
  /**
   * Index of the tab panel
   */
  index: string | number
  /**
   * Component to display as a content of the panel
   */
  children?: React.ReactNode
}

export interface TabPanelProps extends TabPanelPublicProps, StyledProps<TabPanelContext> {}

const TabPanel: React.FC<TabPanelProps> = React.forwardRef(
  (props: TabPanelProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive<HTMLDivElement>({ ref: forwardRef })

    const display = props.value === props.index

    const getCoreStyle = () => ({})

    const { Root } = props.styled!
    return display ? (
      <Root
        ref={ref}
        className={props.className}
        role="tabpanel"
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    ) : null
  },
)

export default TabPanel
