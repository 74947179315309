import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { ButtonCoreStyle } from '../../Button'
import { Customisations } from './DevButton.customisations'
import { DevFontAwesomeIcon } from '../../../Icon'
import { StyleProps } from '../../../types'
import { DisabledState, ReadOnlyState } from '../../../themes/Developer/styles/state'
import { ButtonContext } from '../../Button.composition'
import { rotate } from '../../../utils/animation'
import {
  ButtonCustomisations,
  getBgColour,
  getBorderColour,
  getColour,
  getFontSize,
} from './customisations'
import * as themeStyles from '../../../themes/Developer/styles'

export type ButtonStyleProps = StyleProps<ButtonCoreStyle, ButtonCustomisations> & ThemeProps

const isPrimary = (props: ButtonStyleProps) => props.styleProps.type === 'primary'

const isTertiary = (props: ButtonStyleProps) => props.styleProps.type === 'tertiary'

const isInvalidPrimary = (props: ButtonStyleProps) => isPrimary(props) && props.styleProps.invalid

const EnabledButton = css<ButtonStyleProps>`
  /** Colour */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};
  border-color: ${(props) => getBorderColour(props)};
`

const DisabledButton = css<ButtonStyleProps>`
  :disabled {
    ${DisabledState}

    /** Colour */
    color: ${(props) => getColour(props, 'disabled')};
    background-color: ${(props) => getBgColour(props, 'disabled')};
    border-color: ${(props) => getBorderColour(props, 'disabled')};
  }
`

const HoverButton = css<ButtonStyleProps>`
  :hover {
    transition: 0.5s;

    /** Colour */
    color: ${(props) => getColour(props, 'hover')};
    background-color: ${(props) => getBgColour(props, 'hover')};
    border-color: ${(props) => getBorderColour(props, 'hover')};

    /** Visual */
    ${(props) =>
      isTertiary(props) &&
      css`
        text-decoration: underline;
      `}
  }
`

const FocusButton = css<ButtonStyleProps>`
  :focus {
    transition: 0.5s;

    /** Colour : take the hover one */
    color: ${(props) => getColour(props, 'focus')};
    background-color: ${(props) => getBgColour(props, 'hover')};
    border-color: ${(props) => getBorderColour(props, 'focus')};

    /** Visual */
    ${(props) =>
      isTertiary(props) &&
      css`
        text-decoration: underline;
      `}
  }
`

const InvalidButton = css<ButtonStyleProps>`
  border-color: ${(props) => props.theme.colours.tertiary.semantic.red} !important;
`

const BorderButton = css<ButtonStyleProps>`
  border-style: solid;
  border-radius: ${(props) => props.theme.spaces.xxs};
  border-width: ${(props) => props.theme.visuals.borderSize};
`

const FitContentButton = css<ButtonStyleProps>`
  width: auto;
`

const FadeInAnimation = (Components: any) => css<ButtonStyleProps>`
  position: relative;
  overflow: hidden;

  & > ${Components.Icon} {
    position: relative;

    ${(props) =>
      props.styleProps.iconPosition === 'top' &&
      css`
        top: -${props.styleProps.height}px;
      `}

    ${(props) =>
      props.styleProps.iconPosition === 'bottom' &&
      css`
        bottom: -${props.styleProps.height}px;
      `}

        ${(props) =>
      props.styleProps.iconPosition === 'right' &&
      css`
        right: -60%;
      `}

        ${(props) =>
      props.styleProps.iconPosition === 'left' &&
      css`
        left: -60%;
      `}
  }

  & > ${Components.Label} {
    position: relative;

    ${(props) =>
      props.styleProps.iconPosition === 'top' &&
      css`
        transform: translateY(calc(-50% - calc(${props.theme.spaces.xs} / 2)));
      `}

    ${(props) =>
      props.styleProps.iconPosition === 'bottom' &&
      css`
        transform: translateY(calc(50% + calc(${props.theme.spaces.xs} / 2)));
      `}

        ${(props) =>
      props.styleProps.iconPosition === 'right' &&
      css`
        transform: translateX(50%);
      `}

        ${(props) =>
      props.styleProps.iconPosition === 'left' &&
      css`
        transform: translateX(-50%);
      `}
  }

  :hover {
    & > ${Components.Label} {
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
      transform: unset;
    }

    & > ${Components.Icon} {
      position: relative;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;

      ${(props) =>
        props.styleProps.iconPosition === 'top' &&
        css`
          top: 0;
        `}

      ${(props) =>
        props.styleProps.iconPosition === 'bottom' &&
        css`
          bottom: 0;
        `}

            ${(props) =>
        props.styleProps.iconPosition === 'right' &&
        css`
          right: 0;
        `}

            ${(props) =>
        props.styleProps.iconPosition === 'left' &&
        css`
          left: 0;
        `}
    }
  }
`

const RotateAnimation = (Components: any) => css<ButtonStyleProps>`
  & > ${Components.Icon} {
    animation: ${rotate} ${`1s infinite linear`};
  }
`

const PositionIconToTheLeft = (Components: any) => css<ButtonStyleProps>`
  flex-direction: row-reverse;

  & > ${Components.Icon} {
    ${(props) =>
      props.styleProps.hasLabel &&
      css`
        margin-right: ${props.theme.spaces.xs};
      `};
  }
`

const PositionIconToTheRight = (Components: any) => css<ButtonStyleProps>`
  flex-direction: row;

  & > ${Components.Icon} {
    ${(props) =>
      props.styleProps.hasLabel &&
      css`
        margin-left: ${props.theme.spaces.xs};
      `};
  }
`

const PositionIconToTheTop = (Components: any) => css<ButtonStyleProps>`
  flex-direction: column-reverse;

  & > ${Components.Icon} {
    ${(props) =>
      props.styleProps.hasLabel &&
      css`
        margin-bottom: ${props.theme.spaces.xs};
      `};
  }
`

const PositionIconToTheBottom = (Components: any) => css<ButtonStyleProps>`
  flex-direction: column;

  & > ${Components.Icon} {
    ${(props) =>
      props.styleProps.hasLabel &&
      css`
        margin-top: ${props.theme.spaces.xs};
      `};
  }
`

const Icon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<ButtonStyleProps>`
  font-size: inherit;
`

const Label = styled.label<ButtonStyleProps>`
  text-transform: uppercase;
`

const Root = styled.button<ButtonStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  ${BorderButton}

  /* Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};
  font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.regular};

  /* Visual */
  ${themeStyles.LetterSpacing}
  line-height: ${(props) => props.theme.typography.proximaNova.lineHeight.bodyXs};

  /* Spacing */
  padding: ${(props) => props.theme.spaces.xs};

  /* State */
  ${EnabledButton}
  ${DisabledButton}
  ${HoverButton}
  ${FocusButton}
  ${(props) => props.styleProps.readOnly && ReadOnlyState}
  ${(props) => isInvalidPrimary(props) && InvalidButton}

  /* Positioning */
  ${(props) => props.styleProps.iconPosition === 'left' && PositionIconToTheLeft(Components)}
  ${(props) => props.styleProps.iconPosition === 'right' && PositionIconToTheRight(Components)}
  ${(props) => props.styleProps.iconPosition === 'top' && PositionIconToTheTop(Components)}
  ${(props) => props.styleProps.iconPosition === 'bottom' && PositionIconToTheBottom(Components)}

  /* Animation */
  ${(props) => props.styleProps.animation === 'fadeIn' && FadeInAnimation(Components)}
  ${(props) => props.styleProps.animation === 'rotate' && RotateAnimation(Components)}
  
  ${(props) => props.styleProps.fitContent && FitContentButton}

  * {
    cursor: inherit !important;
  }
`

const Components: ButtonContext = {
  Icon,
  Label,
  Root,
}

export default Components
