import ListItemTextCore, { ListItemTextPublicProps } from '../../ListItemText'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Customisations from '../../themes/Developer/DevListItemText.customisations'
import type { ListItemTextContext } from '../../ListItemText.composition'
import type { ListItemTextCustomisations } from '../../themes/Developer/customisations'
import Styles from '../../themes/Developer/DevListItemText.styles'

export const DevListInteractiveItemText = withCustomisedStyle<
  ListItemTextPublicProps,
  ListItemTextContext,
  ListItemTextCustomisations
>(ListItemTextCore, Theme, Styles, Customisations, 'ListInteractiveItemText')

export type DevListInteractiveItemTextCustomisations = ListItemTextCustomisations
export type DevListInteractiveItemTextProps = ListItemTextPublicProps & ListItemTextCustomisations
