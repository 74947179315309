import Tooltip, { TooltipPublicProps } from '../../Tooltip'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevTooltip.styles'
import Customisations from './DevTooltip.customisations'
import type { TooltipContext } from '../../Tooltip.composition'
import type { TooltipCustomisations } from './customisations'

export const DevTooltip = withCustomisedStyle<
  TooltipPublicProps,
  TooltipContext,
  TooltipCustomisations
>(Tooltip, Theme, Styles, Customisations, 'Tooltip')

export type DevTooltipCustomisations = TooltipCustomisations
export type DevTooltipProps = TooltipPublicProps & TooltipCustomisations
