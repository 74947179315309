import * as React from 'react'
import useRef from './useRef'

const useForkRef = (refA: any, refB: any) => {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior
   */
  return React.useMemo(() => {
    if (refA == null && refB == null) {
      return null
    }

    return (refValue: any) => {
      useRef(refA, refValue)
      useRef(refB, refValue)
    }
  }, [refA, refB])
}

export default useForkRef
