import SwitchGroup, { SwitchGroupPublicProps } from '../../SwitchGroup'
import Theme from '../../../themes/Developer/theme'
import type { SwitchGroupContext } from '../../SwitchGroup.composition'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevSwitchGroup.styles'
import Customisations, {
  DevSwitchGroupCustomisations as SwitchGroupCustomisations,
} from './DevSwitchGroup.customisations'

export const DevSwitchGroup = withCustomisedStyle<
  SwitchGroupPublicProps,
  SwitchGroupContext,
  SwitchGroupCustomisations
>(SwitchGroup, Theme, Styles, Customisations, 'SwitchGroup')

export type DevSwitchGroupCustomisations = SwitchGroupCustomisations
export type DevSwitchGroupProps = SwitchGroupPublicProps & SwitchGroupCustomisations
