import React, { useRef } from "react";

import { Grid, Typography } from "@material-ui/core";
import { DevInputField } from "@tl-prisma/core";

import { toOrdinal } from "../Utils/NumberHelper";

const securityCode3Id = "security-code-3";
const securityCode4Id = "security-code-4";
const securityCode6Id = "security-code-6";

type SecurityCodeInputsState = {
  "security-code-3": string;
  "security-code-4": string;
  "security-code-6": string;
};

export function SecurityCodeInputs({
  values,
  validValues,
  onChange
}: {
  values: SecurityCodeInputsState;
  validValues: Partial<Record<keyof SecurityCodeInputsState, boolean>>;
  onChange(inputs: SecurityCodeInputsState): void;
}): JSX.Element {
  const enabledNumbers = [3, 4, 6];

  const { current: securityCodeRefs } = useRef<{ [key: string]: any }>({});

  const focusNextSecurityCode = (id: string): void => {
    let nextId: string = "";
    switch (id) {
      case securityCode3Id:
        nextId = securityCode4Id;
        break;
      case securityCode4Id:
        nextId = securityCode6Id;
        break;
    }
    if (nextId) {
      let ref = securityCodeRefs[nextId];
      while (!(ref instanceof HTMLInputElement)) {
        ref = ref.firstChild;
      }
      ref.focus();
    }
  };

  const handleSecurityCodeChange = (key: string) => (
    event: React.ChangeEvent<Element>
  ) => {
    event.persist();
    const value = (event.target as HTMLInputElement).value;
    if (!value) {
      return onChange({
        ...values,
        [key]: ""
      });
    }
    const numericValue = Number(value);
    if (numericValue || numericValue === 0) {
      focusNextSecurityCode(event.currentTarget.id);
      return onChange({
        ...values,
        [key]: "•"
      });
    }
  };

  return (
    <>
      <Typography variant="h3">PIN</Typography>
      <Grid container spacing={3} justifyContent="flex-start">
        {[1, 2, 3, 4, 5, 6].map(inputNumber => {
          const inputId = `security-code-${inputNumber}` as keyof SecurityCodeInputsState;
          return (
            <Grid item sm={2} key={inputId}>
              {enabledNumbers.includes(inputNumber) ? (
                <DevInputField
                  ref={x => (securityCodeRefs[inputId] = x)}
                  id={inputId}
                  label=""
                  placeholder={toOrdinal(inputNumber)}
                  withValidityStatus={false}
                  onChange={handleSecurityCodeChange(inputId)}
                  error="Required"
                  invalid={
                    validValues[inputId as keyof SecurityCodeInputsState] ===
                    false
                  }
                  value={values[inputId]}
                />
              ) : (
                <DevInputField id={inputId} label="" disabled={true} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
