import * as theme from '../../../../themes/Developer/proxima-nova'
import type { TitleStyleProps } from '../DevTitle.styles'

/** Colour */
export const getColour = (props: TitleStyleProps) =>
  theme.getColour(
    props,
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )
