import * as React from 'react'
import * as PropTypes from 'prop-types'
import type { StyledProps } from '../providers'
import type { FormLabelContext } from './FormLabel.composition'
import { useFormControl } from '../FormControl/FormControl.context'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

export interface FormLabelPublicProps {
  /**
   * The id of the `label` element.
   */
  id?: string
  /**
   * HTML tag or a custom component that will used as Root element
   */
  as?: any
  /**
   * The id of the element the label is bound to
   */
  htmlFor?: string
  /**
   * The size of the text field.
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * If `true`, the `input` element will be disabled.
   */
  disabled?: boolean
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean
  /**
   * If `true`, the label will be displayed in an error state.
   */
  invalid?: boolean
  /**
   * If `true`, the component will be displayed in focused state.
   */
  focused?: boolean
  /**
   * If `true`, the label is displayed as required and the `input` element` will be required.
   */
  required?: boolean
  /**
   * Component used as message for the label
   */
  children?: React.ReactNode
}

interface FormLabelProps extends FormLabelPublicProps, StyledProps<FormLabelContext> {}

export interface FormLabelCoreStyle {
  shrink: boolean
  variant: string
  size: string
  invalid: boolean
}

const FormLabel = React.forwardRef(
  (props: FormLabelProps, forwardRef: React.Ref<HTMLLabelElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const formControl = useFormControl(props)

    const getCoreStyle = (): FormLabelCoreStyle => ({
      shrink: formControl.filled || formControl.focused || false,
      variant: formControl.variant!,
      size: formControl.size!,
      invalid: formControl.invalid!,
    })

    const renderRequireSymbol = () => {
      const { RequiredIndicator } = props.styled!
      return formControl.required ? (
        <RequiredIndicator
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'required')}
        >
          *
        </RequiredIndicator>
      ) : null
    }

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        as={props.as}
        id={props.id}
        htmlFor={props.htmlFor}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
        {renderRequireSymbol()}
      </Root>
    )
  },
)

FormLabel.propTypes = {
  id: PropTypes.string,
  as: PropTypes.any,
  htmlFor: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  invalid: PropTypes.bool,
  focused: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

FormLabel.defaultProps = {
  as: 'label',
  size: 'medium',
}

export default FormLabel
