import CheckboxField, { CheckboxFieldPublicProps } from '../../CheckboxField'
import type { CheckboxFieldContext } from '../../CheckboxField.composition'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevCheckboxField.styles'
import Customisations, { CheckboxFieldCustomisations } from './DevCheckboxField.customisations'

export const DevCheckboxField = withCustomisedStyle<
  CheckboxFieldPublicProps,
  CheckboxFieldContext,
  CheckboxFieldCustomisations
>(CheckboxField, Theme, Styles, Customisations, 'CheckboxField')

export type DevCheckboxFieldCustomisations = CheckboxFieldCustomisations
export type DevCheckboxFieldProps = CheckboxFieldPublicProps & CheckboxFieldCustomisations
