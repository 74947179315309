import * as React from 'react'
import type { InputFieldContext } from './InputField.composition'
import type { InputPublicProps } from '../Input/Input'
import type { FieldPublicProps } from '../Field/Field'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { newId } from '../utils'
import { getTestId } from '../utils/test'
import { isEmpty } from '../utils/lodash'

export type InputFieldPublicProps = FieldPublicProps & Omit<InputPublicProps, 'describedBy'>

interface InputFieldProps extends InputFieldPublicProps, StyledProps<InputFieldContext> {}

const InputField = React.forwardRef((props: InputFieldProps, forwardRef: any) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const getCoreStyle = () => ({})

  const getPlaceholder = () => {
    if (props.variant === 'standard') {
      return props.placeholder
    }

    if (isEmpty(props.label)) {
      return props.placeholder
    }

    return undefined
  }

  const renderInput = () => {
    const { Input } = props.styled!
    return (
      <Input
        key={props.id}
        ref={props.inputRef}
        id={props.id}
        describedBy={`${props.id}-helper-text`}
        type={props.type}
        size={props.size}
        name={props.name}
        value={props.value}
        defaultValue={props.defaultValue}
        placeholder={getPlaceholder()}
        fullWidth={props.fullWidth}
        startAdornment={props.startAdornment}
        endAdornment={props.endAdornment}
        blurContent={props.blurContent}
        tabIndex={props.tabIndex}
        direction={props.direction}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onHover={props.onHover}
        onLeave={props.onLeave}
        onClick={props.onClick}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, `input`)}
      />
    )
  }

  const { Root } = props.styled!
  return (
    <Root
      className={props.className}
      ref={ref}
      id={props.id}
      label={props.label}
      helperText={props.helperText}
      disabled={props.disabled}
      invalid={props.invalid}
      required={props.required}
      readOnly={props.readOnly}
      variant={props.variant}
      size={props.size}
      breakpoint={props.breakpoint}
      error={props.error}
      tooltip={props.tooltip}
      withValidityStatus={props.withValidityStatus}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {renderInput()}
    </Root>
  )
})

InputField.defaultProps = {
  id: newId(),
  variant: 'standard',
}

export default InputField
