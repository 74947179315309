import styled from '../../../themes/Developer/themed-component'
import type { FormControlContext } from '../../FormControl.composition'

const Root = styled.div`
  & > *:not(:first-child) {
    margin: ${(props) => `${props.theme.spaces.xs} 0`};
  }
`

const Components: FormControlContext = {
  Root,
}

export default Components
