import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../Icon'
import {
  getBgColour,
  getBorder,
  getColour,
  getFontSize,
  getIconColour,
  getIconBorder,
  getIconPadding,
  getIconBorderColour,
  getIconBorderRadius,
  TooltipCustomisations,
} from './customisations'
import { Customisations } from './DevTooltip.customisations'
import { includes } from '../../../utils/lodash'
import type { TooltipContext } from '../../Tooltip.composition'
import type { StyleProps } from '../../../types'
import type { TooltipCoreStyle } from '../../Tooltip'
import * as themeStyles from '../../../themes/Developer/styles'

export type TooltipStyleProps = StyleProps<TooltipCoreStyle, TooltipCustomisations> & ThemeProps

const arrowSize = 8

const ArrowRight = css`
  right: -${arrowSize / 2}px;
`

const ArrowBottom = css`
  bottom: -${arrowSize / 2}px;
`

const ArrowLeft = css`
  left: -${arrowSize / 2}px;
`

const ArrowTop = css`
  top: -${arrowSize / 2}px;
`

const FocusedIconState = css<TooltipStyleProps>`
  :focus {
    color: ${(props) => getIconColour(props, 'focus')};
    border-color: ${(props) => getIconBorderColour(props, 'focus')};
    padding: ${(props) => getIconPadding(props)};
    border-radius: ${(props) => getIconBorderRadius(props)};
  }
`

const HoveredIconState = css<TooltipStyleProps>`
  :hover {
    color: ${(props) => getIconColour(props, 'hover')};
  }
`

const Icon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<TooltipStyleProps>`
  /** Typography */
  font-size: ${(props) => getFontSize(props, 'bodyS')};

  /** Visuals */
  border: ${(props) => getIconBorder(props)};

  /** States */
  ${HoveredIconState}
  ${FocusedIconState}
`

const Arrow = styled.div<TooltipStyleProps>`
  &,
  &:before {
    position: absolute;
    width: ${arrowSize}px;
    height: ${arrowSize}px;
    z-index: -1;
  }

  ${(props) =>
    includes(['left-start', 'left', 'left-end'], props.styleProps.placement) && ArrowRight}
  ${(props) => includes(['top-start', 'top', 'top-end'], props.styleProps.placement) && ArrowBottom}
    ${(props) =>
    includes(['right-start', 'right', 'right-end'], props.styleProps.placement) && ArrowLeft}
    ${(props) =>
    includes(['bottom-start', 'bottom', 'bottom-end'], props.styleProps.placement) && ArrowTop}

    &:before {
    content: '';
    background-color: ${(props) => getBgColour(props)};
    transform: rotate(45deg);
    left: 0;
  }
`

const Message = styled.div<TooltipStyleProps>`
  z-index: ${(props) => props.styleProps.zIndex || 300};
  position: relative;
  border-radius: ${(props) => props.theme.visuals.borderRadius};
  max-width: ${(props) => props.styleProps.maxWidth}px;
  padding: ${(props) => props.theme.spaces.xs};
  text-align: center;
  margin: 0px;
  margin: 0;

  /** Colour */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};
  font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.regular};
  line-height: ${(props) => props.theme.typography.proximaNova.lineHeight.bodyS};

  /** Visual */
  border: ${(props) => getBorder(props)};
`

const Root = styled.div<TooltipStyleProps>`
  position: relative;
  display: inline-flex;
`

const Components: TooltipContext = {
  Root,
  Message,
  Arrow,
  Icon,
}

export default Components
