import * as React from 'react'
import StepperContext from '../../Stepper.context'
import StepContext from './Step.context'
import type { StepContext as StepComposition } from './Step.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'

export interface StepPublicProps {
  /**
   * @default false
   * True the icon will have a disable effect when the step is complete, false otherwise
   */
  widthDisableEffectOnComplete?: boolean
  /**
   * The position of the step.
   */
  index?: number
  /**
   * If `true`, the Step will be displayed as rendered last.
   */
  last?: boolean
  /**
   * Sets the step as active. Is passed to child components.
   */
  active?: boolean
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed?: boolean
  /**
   * Mark the step as disabled, will also disable the button if
   * `StepButton` is a child of `Step`. Is passed to child components.
   */
  disabled?: boolean
  /**
   * Expand the step.
   * @default false
   */
  expanded?: boolean
  /**
   * Should be `Step` sub-components such as `StepLabel`, `StepContent`.
   */
  children?: React.ReactNode
}

export interface StepProps extends StepPublicProps, StyledProps<StepComposition> {}

export interface StepCoreStyle {
  orientation: 'horizontal' | 'vertical'
  alternativeLabel: boolean
}

const Step: React.FC<StepProps> = React.forwardRef(
  (props: StepProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const { activeStep, connector, alternativeLabel, orientation, nonLinear } = React.useContext(
      StepperContext,
    )!

    let [active = false, completed = false, disabled = false] = [
      props.active,
      props.completed,
      props.disabled,
    ]

    if (activeStep === props.index) {
      active = props.active !== undefined ? props.active : true
    } else if (!nonLinear && activeStep > props.index!) {
      completed = props.completed !== undefined ? props.completed : true
    } else if (!nonLinear && activeStep < props.index!) {
      disabled = props.disabled !== undefined ? props.disabled : true
    }

    const context = React.useMemo(
      () => ({
        index: props.index!,
        last: props.last!,
        expanded: props.expanded!,
        icon: props.index! + 1,
        active,
        completed,
        disabled,
        widthDisableEffectOnComplete: props.widthDisableEffectOnComplete,
      }),
      [props.index, props.last, props.expanded, active, completed, disabled],
    )

    const getCoreStyle = (): StepCoreStyle => ({
      orientation,
      alternativeLabel,
    })

    const renderChildren = () => {
      const { Root } = props.styled!
      return (
        <Root
          className={props.className}
          ref={ref}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getAttributes(props, DataAttributesPrefix)}
        >
          {connector && alternativeLabel && props.index !== 0 ? connector : null}
          {props.children}
        </Root>
      )
    }

    return (
      <StepContext.Provider value={context}>
        {connector && !alternativeLabel && props.index !== 0 ? (
          <>
            {connector}
            {renderChildren()}
          </>
        ) : (
          renderChildren()
        )}
      </StepContext.Provider>
    )
  },
)

Step.defaultProps = {
  index: 0,
}

export default Step
