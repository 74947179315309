import { getDevices } from '../devices'
import { head } from '../utils/lodash'

export const getBreakpoint = (props: any, device?: string) => {
  const devices = getDevices(props)
  const breakpoints = Object.keys(devices)

  const currentDevice = device || props.device
  let i = currentDevice ? breakpoints.indexOf(currentDevice) : breakpoints.length

  for (; i >= 0; i -= 1) {
    const key = breakpoints[i]
    if (props[key] !== undefined) {
      return key
    }
  }

  return head(breakpoints) || 'xs'
}

export const getProperties = (child: any) => {
  const attributes = child.type ? child.type.attrs || [] : []
  if (attributes.length > 0) {
    let properties = {}

    attributes.forEach((x: any) => {
      properties = { ...properties, ...x(child.props) }
    })

    return properties
  }

  return child.props
}
