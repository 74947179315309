import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { DevField } from '../../../Field'
import { DevInput } from '../../../Input'
import { Customisations, InputFieldCustomisations } from './DevInputField.customisations'
import type { InputFieldContext } from '../../InputField.composition'
import type { StyleProps } from '../../../types'

export type InputFieldStyleProps = StyleProps<{}, InputFieldCustomisations> & ThemeProps

const Input = styled(DevInput).attrs(Customisations.Input)<InputFieldStyleProps>``

const Root = styled(DevField).attrs(Customisations.Field)<InputFieldStyleProps>``

const Components: InputFieldContext = {
  Root,
  Input,
}

export default Components
