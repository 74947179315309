import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { ListItemTextContext } from '../../ListItemText.composition'
import type { StyleProps } from '../../../../../types'
import { getValueAsPixel } from '../../../../../customisations'
import {
  getColour,
  getPrimaryColour,
  getSecondaryColour,
  ListItemTextCustomisations,
} from './customisations'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type ListItemTextStyleProps = StyleProps<unknown, ListItemTextCustomisations> & ThemeProps

const PrimaryTextLineHeight = css<ListItemTextStyleProps>`
  /** Visuals */
  line-height: ${(props) => getValueAsPixel(props.theme.typography.proximaNova.lineHeight.bodyS)};
`

const SecondaryTextLineHeight = css<ListItemTextStyleProps>`
  /** Visuals */
  line-height: ${(props) => getValueAsPixel(props.theme.typography.proximaNova.lineHeight.bodyXs)};
`

const Secondary = styled.span<ListItemTextStyleProps>`
  /** Typography */
  font-size: ${(props) => getValueAsPixel(props.theme.typography.proximaNova.fontSize.captionS.px)};

  /** Colours */
  color: ${(props) => getSecondaryColour(props)};

  /** Visuals */
  ${SecondaryTextLineHeight}
`

const Primary = styled.span<ListItemTextStyleProps>`
  /** Typography */
  font-size: ${(props) => getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyS.px)};

  /** Colours */
  color: ${(props) => getPrimaryColour(props)};

  /** Visuals */
  ${PrimaryTextLineHeight}
`

const Root = styled.div<ListItemTextStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  color: ${(props) => getColour(props)};

  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: ListItemTextContext = {
  Root,
  Primary,
  Secondary,
}

export default Components
