import { getArrowColour, MenuGroupCustomisations } from './customisations'
import type { MenuGroupPublicProps } from '../../MenuGroup'
import type { MenuGroupStyleProps } from './DevMenuGroup.styles'

export const Customisations = {
  Arrow: (props: MenuGroupStyleProps) => ({
    colour: getArrowColour(props),
    hoverColour: getArrowColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: MenuGroupPublicProps & MenuGroupCustomisations): MenuGroupCustomisations => {
  const header = props.Header || {}
  const content = props.Content || {}
  return {
    Header: {
      colour: header.colour,
      hoverColour: header.hoverColour,
      focusColour: header.focusColour,
      activeColour: header.activeColour,
      selectedColour: header.selectedColour,
      hoverSelectedColour: header.hoverSelectedColour,
      focusSelectedColour: header.focusSelectedColour,
      bgColour: header.bgColour,
      hoverBgColour: header.hoverBgColour,
      focusBgColour: header.focusBgColour,
      activeBgColour: header.activeBgColour,
      selectedBgColour: header.selectedBgColour,
      hoverSelectedBgColour: header.hoverSelectedBgColour,
      focusSelectedBgColour: header.focusSelectedBgColour,
      padding: header.padding,
      border: header.border,
      borderTop: header.borderTop,
      borderRight: header.borderRight,
      borderBottom: header.borderBottom,
      borderLeft: header.borderLeft,
      Arrow: header.Arrow || {},
    },
    Content: {
      colour: content.colour,
      bgColour: content.bgColour,
      padding: content.padding,
      border: content.border,
      borderTop: content.borderTop,
      borderRight: content.borderRight,
      borderBottom: content.borderBottom,
      borderLeft: content.borderLeft,
    },
  }
}
