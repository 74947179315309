import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import { getValue } from '../../../../../style'
import type { TableCellContext } from '../../TableCell.composition'
import type { TableCellCoreStyle } from '../../TableCell'
import type { StyleProps } from '../../../../../types'
import {
  getBgColour,
  getBorder,
  getColour,
  getPadding,
  getHeaderColour,
  getFontSize,
  TableCellCustomisations,
} from './customisations'

export type TableCellStyleProps = StyleProps<TableCellCoreStyle, TableCellCustomisations> &
  ThemeProps

const StickyRoot = css<TableCellStyleProps>`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
`

const AlignRightRoot = css<TableCellStyleProps>`
  text-align: right;
  flex-direction: row-reverse;
`

const AlignJustifyRoot = css<TableCellStyleProps>`
  text-align: justify;
`

const AlignCenterRoot = css<TableCellStyleProps>`
  text-align: center;
`

const AlignLeftRoot = css<TableCellStyleProps>`
  text-align: left;
`

const HeaderRoot = css<TableCellStyleProps>`
  /** Visuals */
  border-bottom: ${(props) => getBorder(props)};

  /** Colours */
  color: ${(props) => getHeaderColour(props)};
`

const BodyRoot = css<TableCellStyleProps>``

const FooterRoot = css<TableCellStyleProps>``

const Content = styled.div<TableCellStyleProps>`
  ${(props) =>
    props.styleProps.align !== 'justify' &&
    css`
      vertical-align: top; /* Removes the extra white space below the baseline */
      display: inline-block;
    `};
`

const Root = styled.td<TableCellStyleProps>`
  display: table-cell;
  vertical-align: inherit;
  width: ${(props) => getValue(props.styleProps.width)};
  max-width: ${(props) => getValue(props.styleProps.width)};

  /** Typography */
  font-size: ${(props) => getFontSize(props)};
  font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.regular};

  /** Colours */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  /** Spacing */
  padding: ${(props) => getPadding(props)};

  /** Visuals */
  line-height: ${(props) => props.theme.typography.proximaNova.lineHeight.bodyS};

  ${(props) => props.styleProps.variant === 'header' && HeaderRoot};
  ${(props) => props.styleProps.variant === 'body' && BodyRoot};
  ${(props) => props.styleProps.variant === 'footer' && FooterRoot};

  ${(props) => props.styleProps.align === 'left' && AlignLeftRoot};
  ${(props) => props.styleProps.align === 'center' && AlignCenterRoot};
  ${(props) => props.styleProps.align === 'justify' && AlignJustifyRoot};
  ${(props) => props.styleProps.align === 'right' && AlignRightRoot};

  ${(props) => props.styleProps.stickyHeader && StickyRoot};
`

const Components: TableCellContext = {
  Root,
  Content,
}

export default Components
