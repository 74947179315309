import type { TablePaginationPublicProps } from '../../TablePagination'
import type { TablePaginationStyleProps } from './DevTablePagination.styles'
import { getSelect, TablePaginationCustomisations } from './customisations'

export const Customisations = {
  Actions: (props: TablePaginationStyleProps) => props.customisations.Actions,
  Selection: (props: TablePaginationStyleProps) => getSelect(props),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: TablePaginationPublicProps & TablePaginationCustomisations,
): TablePaginationCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
  Select: props.Select || {},
  Actions: props.Actions || {},
})
