import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { CircularProgressStyleProps } from '../DevCircularProgress.styles'

/** Colour */
export const getColour = (props: CircularProgressStyleProps) =>
  theme.getColour(props, props.theme.colours.tints.cloudBurst[30], theme.getState(props.styleProps))

/** Label */
/** Colour */
export const getLabelColour = (props: CircularProgressStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Label'),
    props.theme.colours.tints.cloudBurst[100],
    theme.getState(props.styleProps),
  )

/** Spinner */
/** Colour */
export const getSpinnerColour = (props: CircularProgressStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Spinner'),
    props.theme.colours.tints.cloudBurst[100],
    theme.getState(props.styleProps),
  )
