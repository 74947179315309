import * as React from 'react'
import MenuContext from './Menu.context'
import type { MenuContext as MenuComposition } from './Menu.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

export interface MenuPublicProps {
  /**
   * Variant of the menu group
   */
  variant?: 'dropdown' | 'fly-out' | 'accordion' | 'tab'
  /**
   * True if the items will fill and justify the container, false otherwise
   */
  fitContainer?: boolean
  /**
   * Header to display
   */
  header?: React.ReactNode
  /**
   * Footer to display
   */
  footer?: React.ReactNode
  /**
   * Handler when the menu is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the menu is not hovered
   */
  onLeave?: (event: React.MouseEvent) => void
  /**
   * List of items
   */
  children?: React.ReactNode
}

export interface MenuProps extends MenuPublicProps, StyledProps<MenuComposition> {}

export interface MenuCoreStyle {
  variant: 'dropdown' | 'fly-out' | 'accordion' | 'tab'
  count: number
  fitContainer: boolean
  header: boolean
  footer: boolean
}

const Menu = React.forwardRef((props: MenuProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const itemsRef = React.useRef<HTMLUListElement>(undefined)
  const children = React.Children.toArray(props.children)

  const isDefined = (value: any) => value !== undefined && value !== null

  const context = {
    variant: props.variant!,
    adjustSize: () => {
      const reference = ref as React.RefObject<HTMLDivElement>
      if (!reference || !reference.current) {
        return
      }

      if (itemsRef && itemsRef.current) {
        const items = Array.from(itemsRef.current.children)
        if (!items) {
          return
        }

        const item = items.find(
          (currentItem: any) => currentItem.getAttribute('data-adjust') === 'true',
        )

        if (item) {
          const header = item.children[0].getBoundingClientRect().width
          const content = item.children[1].getBoundingClientRect().width
          reference.current.style.width = `${header + content}px`
        } else {
          reference.current.style.width = null
        }
      }
    },
  }

  const getCoreStyle = (): MenuCoreStyle => ({
    variant: props.variant!,
    count: children.length,
    fitContainer: props.fitContainer!,
    header: isDefined(props.header),
    footer: isDefined(props.footer),
  })

  const renderFooter = () => {
    const { Footer } = props.styled!
    return props.footer ? (
      <Footer
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'footer')}
      >
        {props.footer}
      </Footer>
    ) : null
  }

  const renderHeader = () => {
    const { Header } = props.styled!
    return props.header ? (
      <Header
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, 'header')}
      >
        {props.header}
      </Header>
    ) : null
  }

  const { Root, Items } = props.styled!
  return (
    <MenuContext.Provider value={context}>
      <Root
        className={props.className}
        ref={ref}
        onMouseEnter={props.onHover}
        onMouseLeave={props.onLeave}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderHeader()}
        <Items
          ref={itemsRef}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'items')}
        >
          {children}
        </Items>
        {renderFooter()}
      </Root>
    </MenuContext.Provider>
  )
})

Menu.defaultProps = {
  variant: 'dropdown',
  fitContainer: false,
}

export default Menu
