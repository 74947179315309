import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { TableBodyContext } from '../../TableBody.composition'
import type { StyleProps } from '../../../../../types'

export type TableBodyStyleProps = StyleProps & ThemeProps

const Root = styled.tbody<TableBodyStyleProps>`
  display: table-row-group;
`

const Components: TableBodyContext = {
  Root,
}

export default Components
