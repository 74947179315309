import ListInteractive, { ListInteractivePublicProps } from '../../ListInteractive'
import Theme from '../../../../../themes/Developer/theme'
import { ListContext } from '../../../../List.composition'

import { withStyle } from '../../../../../providers'

import Styles from './DevListInteractive.styles'

export const DevListInteractive = withStyle<ListInteractivePublicProps, ListContext>(
  ListInteractive,
  Theme,
  Styles,
  'ListInteractive',
)

export type DevListInteractiveProps = ListInteractivePublicProps
