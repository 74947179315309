import type { RadioGroupPublicProps } from '../../RadioGroup'
import type { RadioCustomisations } from '../../../Radio/themes/Developer/customisations'

export type DevRadioGroupCustomisations = RadioCustomisations

export default (
  props: RadioGroupPublicProps & DevRadioGroupCustomisations,
): DevRadioGroupCustomisations => ({
  colour: props.colour,
  hoverColour: props.hoverColour,
  focusColour: props.focusColour,
  selectedColour: props.selectedColour,
  bgColour: props.bgColour,
  hoverBgColour: props.hoverBgColour,
  focusBgColour: props.focusBgColour,
  selectedBgColour: props.selectedBgColour,
  border: props.border,
})
