import * as theme from '../../../../themes/Developer/proxima-nova'
import type { RadioStyleProps } from '../DevRadio.styles'

type DefaultColour = Pick<
  theme.ColourStates,
  'colour' | 'hoverColour' | 'focusColour' | 'selectedColour'
>

/** Global */
/** Colour  */
const defaultColour = (props: RadioStyleProps): DefaultColour => ({
  colour: props.theme.colours.tints.charcoal[80],
  hoverColour: props.theme.colours.tints.charcoal[100],
  focusColour: props.theme.colours.tints.charcoal[100],
  selectedColour: props.theme.colours.tints.charcoal[100],
})

export const getColour = (props: RadioStyleProps) => {
  const currentState = theme.getState(props.styleProps)
  return theme.getColour(props, defaultColour(props), currentState)
}

type DefaultBgColour = Pick<
  theme.BgColourStates,
  'bgColour' | 'hoverBgColour' | 'focusBgColour' | 'selectedBgColour'
>

/* Background colour */
const defaultBgColour = (props: RadioStyleProps): DefaultBgColour => ({
  bgColour: props.theme.colours.tertiary.neutral.white,
  hoverBgColour: props.theme.colours.tints.sun[50],
  focusBgColour: props.theme.colours.tints.sun[80],
  selectedBgColour: props.theme.colours.tints.sun[30],
})

export const getBgColour = (props: RadioStyleProps) =>
  theme.getBgColour(props, defaultBgColour(props), theme.getState(props.styleProps))

type BorderColour = DefaultColour & Pick<theme.ColourStates, 'activeColour' | 'invalidColour'>

/** Border colour */
export const getBorderColour = (props: RadioStyleProps): BorderColour => ({
  colour: props.theme.colours.tints.charcoal[80],
  hoverColour: props.theme.colours.tints.charcoal[80],
  activeColour: props.theme.colours.tints.lightGrey[180],
  focusColour: props.theme.colours.tints.charcoal[80],
  invalidColour: props.theme.colours.tertiary.semantic.red,
  selectedColour: props.theme.colours.tints.charcoal[80],
})
