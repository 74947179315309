import { getSelectionColour } from './colours'
import { merge } from '../../../../../../utils/lodash'
import type { TablePaginationStyleProps } from '../DevTablePagination.styles'
import type { SelectCustomisations } from '../../../../../../Select/themes/Developer/customisations'

/** Select */
export const getSelect = (props: TablePaginationStyleProps): SelectCustomisations =>
  merge(props.customisations.Select, {
    ...{ Selection: { colour: getSelectionColour(props) } },
  })
