import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Box, Card, CardContent, Container, Grid, Typography} from "@material-ui/core";
import {DevButton} from "@tl-prisma/core";
import MockBankHeader from "../MockBankHeader";
import MockBankAccounts from "../MockBankAccounts";
import Mandate from "../Mandates/Mandate";
import mandateService from "../Mandates/MandatesService";
import MandateAction from "../Mandates/MandateAction";
import {AccountIdentifierType} from "../Mandates/AccountIdentifier";
import LoadingPanel from "../LoadingPanel";
import {redirectAfterSubmitMandate} from "../Mandates/RedirectAfterSubmitMandate";
import mandatesService from "../Mandates/MandatesService";
import ControlParameters from "../ControlParameters";

export const MockBankMandatesConfirmation: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { id } = useParams<{ id: string }>();
    const [mandate, setMandate] = useState<Mandate | null>();
    const { hash, search, state } = useLocation();
    const history = useHistory();

    const getAction = (): string | null => {
        const matches = search.match(/action=([^&]*)/)
        return matches && matches.length > 1 ? matches[1] : null
    }

    const getToken = (): string | null => {
        const matches = hash.match(/#token=([^=/]+)$/);
        return matches && matches.length > 1 ? matches[1] : null;
    }

    const getFromState = (key: string) => {
        return state ? (state as any)[key] : null;
    }

    const navigateToErrorPage = (): void => {
        history.push('/error');
    }

    const token = getToken();
    const action = getAction() as MandateAction;

    useEffect(() => {
        if (!token || !action) {
            navigateToErrorPage();
        }
    }, []);

    useEffect(() => {
        const stateMandate = getFromState('mandate');
        if (stateMandate) {
            setMandate(stateMandate);
            setIsLoading(false);
        } else {
            mandateService.getMandate(id, token).then((result) => {
                if (!result || result.status !== 'Initiated') {
                    navigateToErrorPage();
                }
                setMandate(result);
                setIsLoading(false);
            }).catch(() => {
                navigateToErrorPage();
            });
        }
    }, []);

    const mandate1: Mandate = mandate!;

    const getAccountType = (): AccountIdentifierType => mandate1.remitter_account?.type ?? mandate1.beneficiary_account.type;

    const submit = (event: React.MouseEvent) => actionMandate(action);

    const cancel = (event: React.MouseEvent) => actionMandate("Cancel");

    const actionMandate = (action: MandateAction) => {
        mandatesService
            .submitMandate(mandate1.id, action, token)
            .then(redirectAfterSubmitMandate(navigateToErrorPage))
            .catch(navigateToErrorPage)
    }

    return (
        <Container>
            <Grid container>
                <Grid item sm={1} md={2} lg={3} />
                <Grid item sm={10} md={8} lg={6}>
                    {isLoading
                        ? <LoadingPanel/>
                        :
                        <>
                            <Box pt={8} pb={2}>
                                <MockBankHeader name={mandate1.provider_ui_display_name} />
                            </Box>
                            <Card variant="outlined">
                                <Box p={5}>
                                    <CardContent>
                                        <Typography variant="h2" paragraph={true}>Select account and confirm consent</Typography>
                                      
                                        <Box pb={3}>
                                          <ControlParameters parameters={mandate1.control_parameters} currency={mandate1.currency} beneficiaryName={mandate1.beneficiary_name} reference={mandate1.reference} />
                                        </Box>
                                      
                                        <MockBankAccounts currency={mandate1.currency} accountType={getAccountType()} remitterAccount={mandate1.remitter_account} />

                                        <Box pt={10}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                    <DevButton id="cancel" type="secondary" label="Cancel" onClick={cancel} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DevButton id="continue" type="primary" label="Continue" onClick={submit} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CardContent>
                                </Box>
                            </Card>
                        </>
                    }
                </Grid>
                <Grid item sm={1} md={2} lg={3} />
            </Grid>
        </Container>
    );
}
