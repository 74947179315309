/* eslint-disable prefer-promise-reject-errors */
export const getImage = (file: File): Promise<{ image; blob }> => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    const blob = URL.createObjectURL(file)

    image.onload = () => {
      // const { naturalWidth: width, naturalHeight: height } = img
      resolve({ image, blob })
    }

    image.onerror = () => {
      reject('There is something wrong with this image')
    }

    image.src = blob
  })
}
