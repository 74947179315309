import * as theme from '../../../../themes/Developer/proxima-nova'
import type { MenuStyleProps } from '../DevMenu.styles'

/** Background colour */
export const getBgColour = (props: MenuStyleProps) =>
  theme.getBgColour(
    props,
    props.theme.colours.tertiary.neutral.white,
    theme.getState(props.styleProps),
  )
