import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { dimensions, innerDimensions } from '../utils'

const truncateFormat = {
  numberOfWord: '(+{0})',
}

const createCanvas = (style: any) => {
  const canvas: any = document.createElement('canvas')
  const docFragment = document.createDocumentFragment()
  const font = [
    style['font-weight'],
    style['font-style'],
    style['font-size'],
    style['font-family'],
  ].join(' ')

  docFragment.appendChild(canvas)
  const canvasElement = canvas.getContext('2d')
  canvasElement.font = font
  return canvasElement
}

const OverflowComponent = ({
  truncateBy,
  numberOfOverflow,
  notStyled,
}: {
  truncateBy: any
  numberOfOverflow: number
  notStyled?: boolean
}) => {
  const style: any = notStyled
    ? {}
    : {
        flexGrow: 1,
        textAlign: 'right',
      }

  return numberOfOverflow > 0 ? (
    <div style={style}>{truncateFormat[truncateBy].replace('{0}', numberOfOverflow)}</div>
  ) : null
}

let canvas: any = null

const useTruncate = <T extends Element>(opts?: {
  ref?: React.RefObject<T>
  separator?: string
  truncateBy?: string
}): any => {
  const defaultRef = React.useRef(null)
  const ref: any = opts?.ref || defaultRef

  let node: any
  let container: any
  let loaded = false

  React.useEffect(() => {
    node = ref.current
  }, [ref.current])

  const measureWidth = (text: string) => Math.ceil(canvas.measureText(text).width)

  const truncateString = (text: any) => {
    const style = window.getComputedStyle(ref.current)
    if (!canvas) {
      canvas = createCanvas(style)
    }

    const separator = opts?.separator || ' '
    const truncateBy = opts?.truncateBy || 'numberOfWord'

    const size = innerDimensions(ref.current)
    if (Number.isNaN(size.width) || size.width === 0) {
      return text
    }

    let truncatedText = ''
    let i = 0

    const words = text!.split(separator)

    for (i = 0; i < words.length; i += 1) {
      const nextItem = (i > 0 ? `${separator} ` : '') + words[i]
      const nextTruncated = `${truncatedText + nextItem} ${truncateFormat[truncateBy].replace(
        '{0}',
        words.length - (i + 1),
      )}`

      const width = measureWidth(i === 0 ? nextItem : nextTruncated)

      if (width > size.width) {
        break
      } else {
        truncatedText += nextItem
      }
    }

    if (i === words.length) {
      return truncatedText
    }

    return `${truncatedText} ${truncateFormat[truncateBy].replace('{0}', words.length - i)}`
  }

  const checkFlag = () => {
    if (!loaded) {
      window.setTimeout(checkFlag, 100) /* this checks the flag every 100 milliseconds */
    } else {
      /* do something */
    }
  }

  const truncateComponents = (components: any) => {
    const truncateBy = opts?.truncateBy || 'numberOfWord'

    const children: any[] = Array.from(components.props.children || [])

    const nodeSize = innerDimensions(node)

    // Render the components + the overflow one
    container.style.height = `${nodeSize.height}px`
    ReactDOM.render(
      [
        ...children,
        <OverflowComponent notStyled truncateBy={truncateBy} numberOfOverflow={children.length} />,
      ],
      container,
      () => {
        loaded = true
      },
    )

    checkFlag()

    if (container.childNodes.length - 1 !== children.length) {
      // We don't have the components rendered yet in the temporary container
      return components
    }

    const overflowDimensions = dimensions(container.childNodes[container.childNodes.length - 1])!
    if (Number.isNaN(overflowDimensions.width)) {
      return components
    }

    const childNodes = Array.from(container.childNodes).slice(0, container.childNodes.length - 1)

    let totalWidth = 0
    let i = 0
    for (; i < childNodes.length; i += 1) {
      const childNode = childNodes[i]

      const size = dimensions(childNode)
      if (size) {
        if (totalWidth + size.width + overflowDimensions.width > nodeSize.width) {
          break
        }

        totalWidth += size.width
      }
    }

    // Remove the temporary container and release resources
    ReactDOM.unmountComponentAtNode(container)
    document.body.removeChild(container)
    container = null

    loaded = false

    return [
      ...children.slice(0, i),
      <OverflowComponent truncateBy={truncateBy} numberOfOverflow={childNodes.length - i} />,
    ]
  }

  const truncate = (value: any) => {
    if (!ref.current || !value) {
      return value
    }

    let truncated = null
    if (typeof value !== 'object') {
      truncated = truncateString(value)
    } else {
      truncated = value
    }

    return truncated
  }

  return React.useMemo(() => ({ ref, truncate }), [ref.current])
}

export default useTruncate
