import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { MenuItemStyleProps } from '../DevMenuItem.styles'

/** Colour */
export const getColour = (props: MenuItemStyleProps) =>
  theme.getColour(props, 'inherit', theme.getState(props.styleProps))

/** Background colour */
export const getBgColour = (props: MenuItemStyleProps) =>
  theme.getBgColour(props, 'inherit', theme.getState(props.styleProps))
