import { SwitchStyleProps } from './DevSwitch.styles'
import { includes } from '../../../utils/lodash'

export const ThumbSize = {
  small: 16,
  medium: 20,
  large: 24,
}

export const FontSize = {
  small: 12,
  medium: 14,
  large: 17,
}

const TrackWidth = {
  small: 26,
  medium: 36,
  large: 40,
}

const TrackHeight = {
  small: 10,
  medium: 12,
  large: 14,
}

export const getFontSize = (props: SwitchStyleProps) => `${FontSize[props.styleProps.size!]}px`

export const getThumbSize = (props: SwitchStyleProps) => `${ThumbSize[props.styleProps.size!]}px`

export const getTrackWidth = (props: SwitchStyleProps) => `${TrackWidth[props.styleProps.size!]}px`

export const getTrackHeight = (props: SwitchStyleProps) =>
  `${TrackHeight[props.styleProps.size!]}px`

export const getOrientation = (props: SwitchStyleProps) =>
  includes(['top', 'bottom'], props.styleProps.labelPosition) ? 'column' : 'row'
