import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { LinearProgressStyleProps } from '../DevLinearProgress.styles'

/** Label */
/** Font size  */
export const getLabelFontSize = (
  props: LinearProgressStyleProps,
  defaultValue?: keyof theme.FontSize,
) => {
  const customisations = theme.getCustomisations(props, 'Label')
  return theme.getFontSize(customisations, defaultValue || 'bodyXs')
}
