import type { FontAwesomeIconStyleProps } from '../DevFontAwesomeIcon.styles'
import * as theme from '../../../../../../themes/Developer/proxima-nova'

/** Colour */
export const getColour = (props: FontAwesomeIconStyleProps, state?: theme.State) =>
  theme.getColour(props, props.theme.colours.tints.charcoal[50], state)

/** Background colour */
export const getBgColour = (props: FontAwesomeIconStyleProps, state?: theme.State) =>
  theme.getBgColour(props, 'transparent', state)

/** Background colour */
export const getBorderColour = (props: FontAwesomeIconStyleProps, state?: theme.State) =>
  theme.getBorderColour(props, 'transparent', state)
