import ListItemIconCore, { ListItemIconPublicProps } from '../../../../ListItemIcon'
import Theme from '../../../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../../../providers'
import Customisations from '../../../../themes/Developer/DevListItemIcon.customisations'
import type { ListItemIconContext } from '../../../../ListItemIcon.composition'
import type { ListItemIconCustomisations } from '../../../../themes/Developer/customisations'
import Styles from '../../../../themes/Developer/DevListItemIcon.styles'

export const DevListStaticItemIcon = withCustomisedStyle<
  ListItemIconPublicProps,
  ListItemIconContext,
  ListItemIconCustomisations
>(ListItemIconCore, Theme, Styles, Customisations, 'ListStaticItemIcon')

export type DevListStaticItemIconCustomisations = ListItemIconCustomisations
export type DevListStaticItemIconProps = ListItemIconPublicProps & ListItemIconCustomisations
