import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { DevPaginationItem } from '../../components'
import type { PaginationContext } from '../../Pagination.composition'
import type { StyleProps } from '../../../types'
import { Customisations, PaginationCustomisations } from './DevPagination.customisations'

export type PaginationStyleProps = StyleProps<unknown, PaginationCustomisations> & ThemeProps

const ItemContent = styled(DevPaginationItem).attrs(
  Customisations.ItemContent,
)<PaginationStyleProps>``

const Item = styled.li<PaginationStyleProps>``

const Items = styled.ul<PaginationStyleProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
`

const Root = styled.nav<PaginationStyleProps>``

const Components: PaginationContext = {
  Root,
  Items,
  Item,
  ItemContent,
}

export default Components
