import { TablePaginationActionsStyleProps } from '../DevTablePaginationActions.styles'
import * as theme from '../../../../../../../../themes/Developer/proxima-nova'

/** Colour */
const defaultColour = (props: TablePaginationActionsStyleProps) => ({
  colour: props.theme.colours.tertiary.neutral.charcoal,
  hoverColour: props.theme.colours.tints.sun[30],
})

export const getColour = (props: TablePaginationActionsStyleProps, state?: theme.State) =>
  theme.getColour(props, defaultColour(props), state)
