import type { FontAwesomeIconPublicProps } from '../../FontAwesomeIcon'
import type { FontAwesomeIconCustomisations } from './customisations'

export default (
  props: FontAwesomeIconPublicProps & FontAwesomeIconCustomisations,
): FontAwesomeIconCustomisations => ({
  colour: props.colour,
  hoverColour: props.hoverColour,
  focusColour: props.focusColour,
  bgColour: props.bgColour,
  hoverBgColour: props.hoverBgColour,
  border: props.border,
  borderRadius: props.borderRadius,
  padding: props.padding,
})
