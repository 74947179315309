import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { TableSortLabelStyleProps } from '../DevTableSortLabel.styles'

/** Colour */
export const getColour = (props: TableSortLabelStyleProps) =>
  theme.getColour(props, 'inherit', theme.getState(props.styleProps))

/** Icon */
export const getIconColour = (props: TableSortLabelStyleProps) =>
  theme.getColour(props, 'inherit', theme.getState(props.styleProps))
