import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Dialog,
  Grid,
  Typography
} from "@material-ui/core";
import { DevButton, DevInputField, DevSelect } from "@tl-prisma/core";
import MockBankHeader from "../MockBankHeader";
import SingleImmediatePayment from "../SingleImmediatePayments/SingleImmediatePayment";
import { Username } from "../UserInformation/Username";
import UserInformation from "../UserInformation";
import AuthenticationInformation from "../AuthenticationInformation";

import ExecutionDelayInformation from "../ExecutionDelayInformation";

import { SecurityCodeInputs } from "./SecurityCodeInputs";
import { AnchorDialog } from "./AnchorDialog";
import { defaultDelayId, delays } from "./utils";

const usernameId = "username";
const securityCode3Id = "security-code-3";
const securityCode4Id = "security-code-4";
const securityCode6Id = "security-code-6";
const executionDelayId = "execution-delay";
const settlementDelayId = "settlement-delay";

export type FormValue = {
  username: string;
  "execution-delay": string;
  "settlement-delay": string;
  "security-code-3": string;
  "security-code-4": string;
  "security-code-6": string;
};

type FormValueKey = Record<keyof FormValue, boolean>;

type MockBankLoginFormProps = {
  payment: SingleImmediatePayment;
  onSubmit(values: FormValue): void;
  onCancel(): void;
};

export function MockBankLoginForm({
  payment,
  onSubmit,
  onCancel
}: MockBankLoginFormProps) {
  const [openDialog, setOpenDialog] = useState<
    "user" | "execution" | "auth" | undefined
  >();

  const [formValues, setFormValues] = useState<FormValue>({
    [usernameId]: "",
    [executionDelayId]: defaultDelayId,
    [settlementDelayId]: defaultDelayId,
    [securityCode3Id]: "",
    [securityCode4Id]: "",
    [securityCode6Id]: ""
  });

  const [validValues, setValidValues] = useState<Partial<FormValueKey>>({});

  function handleSubmit() {
    const result = validateRequired(formValues, validationRules);

    if (Object.values(result).every(val => val)) {
      onSubmit(formValues);
    } else {
      setValidValues(result);
    }
  }

  const usernameValue = formValues[usernameId];
  const isExecutionDelayEnabled: boolean = useMemo(
    () =>
      usernameValue === Username.Executed ||
      usernameValue === Username.ExecutionRejected,
    [usernameValue]
  );

  const isSettlementDelayEnabled: boolean = useMemo(
    () => usernameValue === Username.Executed,
    [usernameValue]
  );

  // Resets delay's selection after enabling/disabling the dropdown
  useEffect(() => {
    if (!isExecutionDelayEnabled) {
      setFormValues({
        ...formValues,
        [executionDelayId]: defaultDelayId
      });
    }
  }, [isExecutionDelayEnabled]);

  // Resets delay's selection after enabling/disabling the dropdown
  useEffect(() => {
    if (!isSettlementDelayEnabled) {
      setFormValues({
        ...formValues,
        [executionDelayId]: defaultDelayId,
        [settlementDelayId]: defaultDelayId
      });
    }
  }, [isSettlementDelayEnabled]);

  const executionDelayValue = formValues[executionDelayId];
  // On ExecutionDelay's change sets the same delay in SettlementDelay
  useEffect(() => {
    if (isSettlementDelayEnabled) {
      setFormValues({
        ...formValues,
        [settlementDelayId]: executionDelayValue
      });
    }
  }, [executionDelayValue]);
  
  const renderSettlementDelay = (): JSX.Element => {
      if (getEnvironment() === 'production') {
          return <></>
      }
      return <>
          <Typography
              variant="h3"
              color={
                  !isSettlementDelayEnabled ? "textSecondary" : "inherit"
              }
          >
              Settlement delay
          </Typography>
          <DevSelect
              id="settlement-delay"
              disabled={!isSettlementDelayEnabled}
              displayName="name"
              displayValue="id"
              value={formValues[settlementDelayId]}
              options={delays}
              onChange={(val: string) => {
                  setFormValues({
                      ...formValues,
                      [settlementDelayId]: val
                  });
              }}
          />
          <Typography variant="subtitle2" paragraph={true}>
              Applies only in case of payment to the merchant account.
              <AnchorDialog
                  onClick={() => {
                      window.open(
                          `https://docs.truelayer.com/docs/merchant-accounts-for-payments-v3`,
                          "_blank"
                      );
                  }}
              >
                  Learn more
              </AnchorDialog>
          </Typography>
      </>
  }

  return (
    <Container>
      <Grid container>
        <Grid item sm={1} md={2} lg={3} />
        <Grid item sm={10} md={8} lg={6}>
          <>
            <Box pt={8} pb={2}>
              <MockBankHeader name={payment?.provider_ui_display_name ?? ""} />
            </Box>
            <Card variant="outlined">
              <Box p={5}>
                <CardContent>
                  <Typography variant="h2" paragraph={true}>
                    Online Banking Portal
                  </Typography>
                  <Typography paragraph={true}>
                    Use your Online Banking details to log on.
                  </Typography>

                  <Typography variant="h3">Username</Typography>
                  <DevInputField
                    id={usernameId}
                    label=""
                    placeholder="Enter username"
                    value={formValues[usernameId]}
                    withValidityStatus={false}
                    onChange={(event: React.ChangeEvent) => {
                      event.persist();
                      const val = (event.target as HTMLInputElement).value;
                      setFormValues({
                        ...formValues,
                        [usernameId]: val
                      });
                    }}
                    error={retrieveUsernameErrorMessage(formValues[usernameId])}
                    invalid={validValues[usernameId] === false}
                  />
                  <Typography variant="subtitle2" paragraph={true}>
                    To trigger different scenarios, see our
                    <AnchorDialog onClick={() => setOpenDialog("user")}>
                      test usernames
                    </AnchorDialog>
                  </Typography>
                  <Typography
                    variant="h3"
                    color={
                      !isExecutionDelayEnabled ? "textSecondary" : "inherit"
                    }
                  >
                    Execution delay
                  </Typography>
                  <DevSelect
                    id="execution-delay"
                    disabled={!isExecutionDelayEnabled}
                    displayName="name"
                    displayValue="id"
                    options={delays}
                    value={formValues[executionDelayId]}
                    onChange={(val: string) => {
                      setFormValues({
                        ...formValues,
                        [executionDelayId]: val
                      });
                    }}
                  />
                  <Typography variant="subtitle2" paragraph={true}>
                    More info on
                    <AnchorDialog onClick={() => setOpenDialog("execution")}>
                      execution delay
                    </AnchorDialog>
                  </Typography>

                  {renderSettlementDelay()}

                  <SecurityCodeInputs
                    values={{
                      [securityCode3Id]: formValues[securityCode3Id],
                      [securityCode4Id]: formValues[securityCode4Id],
                      [securityCode6Id]: formValues[securityCode6Id]
                    }}
                    validValues={{
                      [securityCode3Id]: validValues[securityCode3Id],
                      [securityCode4Id]: validValues[securityCode4Id],
                      [securityCode6Id]: validValues[securityCode6Id]
                    }}
                    onChange={val => {
                      setFormValues({
                        ...formValues,
                        ...val
                      });
                    }}
                  />

                  <Typography variant="subtitle2" paragraph={true}>
                    Enter any 3 digits to login.
                    <AnchorDialog onClick={() => setOpenDialog("auth")}>
                      Learn more
                    </AnchorDialog>
                  </Typography>

                  <Box pt={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <DevButton
                          id="cancel"
                          type="secondary"
                          label="Cancel"
                          onClick={onCancel}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DevButton
                          id="continue"
                          htmlTag="submit"
                          type="primary"
                          label="Continue"
                          onClick={handleSubmit}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog !== undefined}
        onClose={() => setOpenDialog(undefined)}
      >
        {openDialog === "user" && (
          <UserInformation onClose={() => setOpenDialog(undefined)} />
        )}
        {openDialog === "execution" && (
          <ExecutionDelayInformation onClose={() => setOpenDialog(undefined)} />
        )}

        {openDialog === "auth" && (
          <AuthenticationInformation onClose={() => setOpenDialog(undefined)} />
        )}
      </Dialog>
    </Container>
  );
}

const validationRules: Record<string, (value: string) => boolean> = {
  username: isUsernameValid
};

function isUsernameValid(value: string) {
  return (
    (value === Username.AuthorisationFailed ||
      value === Username.ExecutionRejected ||
      value === Username.Executed) ??
    false
  );
}

function validateRequired(
  values: FormValue,
  extraValidationRules: typeof validationRules
) {
  return Object.entries(values).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]:
        !!value &&
        (extraValidationRules[key] ? extraValidationRules[key](value) : true)
    }),
    {} as FormValueKey
  );
}

function retrieveUsernameErrorMessage(value: string): string {
  if (!value) return "Required"
  if (!isUsernameValid(value)) return "Invalid username"
  return ""
}

const isBrowser = typeof window !== `undefined`;

const getEnvironment = (): 'localhost' | 'development' | 'sandbox' | 'production' => {
    const locationHost = isBrowser ? window.location.hostname : 'localhost';
    switch (locationHost) {
        case 'localhost': {
            return 'localhost';
        }
        case 'pay-mock-connect.t7r.dev': {
            return 'development';
        }
        case 'pay-mock-connect.truelayer-sandbox.com': {
            return 'sandbox';
        }
        case 'pay-mock-connect.truelayer.com': {
            return 'production';
        }
        default: {
            throw new Error(`cannot infer environment from URL (${locationHost})`);
        }
    }
};
