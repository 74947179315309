import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { getBgColour, getColour, ListSubheaderCustomisations } from './customisations'
import type { ListSubheaderContext } from '../../ListSubheader.composition'
import type { StyleProps } from '../../../../../types'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type ListSubheaderStyleProps = StyleProps<unknown, ListSubheaderCustomisations> & ThemeProps

const Root = styled.li<ListSubheaderStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;

  /** Colour */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  /* Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => props.theme.typography.proximaNova.fontSize.bodyS.px};

  /** Spacing */
  padding: ${(props) => props.theme.spaces.xxs} ${(props) => props.theme.spaces.xs};
`

const Components: ListSubheaderContext = {
  Root,
}

export default Components
