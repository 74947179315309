import Icon, { IconPublicProps } from '../../Icon'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Customisations from './DevIcon.customisations'
import type { IconContext } from '../../Icon.composition'
import Styles from './DevIcon.styles'
import { IconCustomisations } from './customisations'

export const DevIcon = withCustomisedStyle<IconPublicProps, IconContext, IconCustomisations>(
  Icon,
  Theme,
  Styles,
  Customisations,
  'Icon',
)

export type DevIconCustomisations = IconCustomisations
export type DevIconProps = IconPublicProps & IconCustomisations
