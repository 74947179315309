import * as React from 'react'
import StepperContext from '../../Stepper.context'
import StepContext from '../Step/Step.context'
import { DevCollapse as Collapse } from '../../../Collapse'
import type { StepContentContext as StepContentComposition } from './StepContent.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import CheckOrientation from './StepContent.checks'
import { getTestId } from '../../../utils/test'

export interface StepContentPublicProps {
  /**
   * Adjust the duration of the content expand transition.
   * Passed as a prop to the transition component.
   *
   * Set to 'auto' to automatically calculate transition time based on height.
   * @default 'auto'
   */
  transitionDuration?: 'auto' | number | { appear?: number; enter?: number; exit?: number }
  /**
   * Component to display as content
   */
  children?: React.ReactNode
}

export type StepContentProps = StepContentPublicProps & StyledProps<StepContentComposition>

export interface StepContentCoreStyle {
  last: boolean
  orientation: string
}

const StepContent: React.FC<StepContentProps> = React.forwardRef(
  (props: StepContentProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const { orientation } = React.useContext(StepperContext)!
    const { active, last, expanded } = React.useContext(StepContext)!

    CheckOrientation(orientation!)

    const getCoreStyle = (): StepContentCoreStyle => ({
      last: last!,
      orientation: orientation!,
    })

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        <Collapse
          in={active || expanded}
          timeout={props.transitionDuration}
          {...getTestId(props, 'collapse')}
        >
          {props.children}
        </Collapse>
      </Root>
    )
  },
)

StepContent.defaultProps = {
  transitionDuration: 'auto',
}

export default StepContent
