import { Spacing1Value } from './models'

export const spaces: Spacing1Value = {
  xxs: '4px',
  xs: '8px',
  sm: '12px',
  md: '16px',
  lg: '24px',
  xl: '32px',
  xxl: '40px',
  xxxl: '48px',
  xxxxl: '56px',
}
