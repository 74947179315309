import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'

import { ListContext } from '../../../../List.composition'
import { ListInteractiveCoreStyle } from '../../ListInteractive'
import { StyleProps } from '../../../../../types'
import { DisabledState } from '../../../../../themes/Developer/styles'
import * as Styles from '../../../../List.styles'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type ListStyleProps = StyleProps<ListInteractiveCoreStyle> & ThemeProps

const Root = styled.ul<ListStyleProps>`
  ${Styles.Root}
  ${themeStyles.FontFamily}

  /** State */
  ${(props) => props.styleProps.disabled && DisabledState}
`

const Components: ListContext = {
  Root,
}

export default Components
