import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { LinearProgressCoreStyle } from '../../LinearProgress'
import type { LinearProgressContext } from '../../LinearProgress.composition'
import {
  getColour,
  getLabelColour,
  getLabelFontSize,
  getSpinnerColour,
  getThickness,
  LinearProgressCustomisations,
} from './customisations'
import { StyleProps } from '../../../../../types'
import * as Animations from '../../LinearProgress.animations'

export type LinearProgressStyleProps = StyleProps<
  LinearProgressCoreStyle,
  LinearProgressCustomisations
> &
  ThemeProps

const LineBase = css<LinearProgressStyleProps>`
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
  transition: transform 0.2s linear;
  transform-origin: left;
  flex-grow: 1;
  height: ${(props) => getThickness(props)}px;
  background-color: ${(props) => getSpinnerColour(props)};
`

const FirstLineIndeterminate = css<LinearProgressStyleProps>`
  width: auto;
  animation: ${Animations.Indeterminate1} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
`

const FirstLineDeterminate = css<LinearProgressStyleProps>`
  transition: transform 4s linear;
  transform: ${(props) => `translateX(${props.styleProps.value}%)`};
`

const SecondLineIndeterminate = css<LinearProgressStyleProps>`
  width: auto;
  animation: ${Animations.Indeterminate2} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
`

const SecondLineDeterminate = css<LinearProgressStyleProps>`
  background-color: transparent;
  width: 100%;
`

const Label = styled.div<LinearProgressStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => getLabelFontSize(props)};
  color: ${(props) => getLabelColour(props)};
  margin-left: ${(props) => props.theme.spaces.xs};
`

const Line1 = styled.div<LinearProgressStyleProps>`
  ${LineBase};
  ${(props) => props.styleProps.indeterminate && FirstLineIndeterminate};
  ${(props) => !props.styleProps.indeterminate && FirstLineDeterminate};
`

const Line2 = styled.div<LinearProgressStyleProps>`
  ${LineBase};
  ${(props) => props.styleProps.indeterminate && SecondLineIndeterminate};
  ${(props) => !props.styleProps.indeterminate && SecondLineDeterminate};
`

const Spinner = styled.div<LinearProgressStyleProps>`
  position: relative;
  height: ${(props) => getThickness(props)}px;
  overflow: hidden;
  background-color: ${(props) => getColour(props)};
  flex-grow: 1;
`

const Root = styled.div<LinearProgressStyleProps>`
  ${(props) =>
    props.styleProps.hasLabel &&
    css`
      display: flex;
      align-items: center;
    `}
`

const Components: LinearProgressContext = {
  Root,
  Spinner,
  Line1,
  Line2,
  Label,
}

export default Components
