import * as React from 'react'
import StepperContext from '../../Stepper.context'
import StepContext from '../Step/Step.context'
import type { StepLabelContext as StepLabelComposition } from './StepLabel.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'

export interface StepLabelPublicProps {
  /**
   * Mark the step as failed.
   * @default false
   */
  invalid?: boolean
  /**
   * Override the default icon of the step label.
   */
  icon?: React.ReactNode
  /**
   * In most cases will simply be a string containing a title for the label.
   */
  children?: React.ReactNode
}

export type StepLabelProps = StepLabelPublicProps & StyledProps<StepLabelComposition>

export interface StepLabelCoreStyle {
  orientation: 'horizontal' | 'vertical'
  alternativeLabel: boolean
  active: boolean
  disabled: boolean
  completed: boolean
  invalid: boolean
  disableEffect: boolean
}

const StepLabel: React.FC<StepLabelProps> = React.forwardRef(
  (props: StepLabelProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const { alternativeLabel, orientation } = React.useContext(StepperContext) || {}
    const { active, disabled, completed, widthDisableEffectOnComplete } =
      React.useContext(StepContext) || {}

    const getCoreStyle = (): StepLabelCoreStyle => ({
      orientation,
      alternativeLabel,
      active,
      disabled,
      completed,
      invalid: props.invalid!,
      disableEffect: completed && widthDisableEffectOnComplete,
    })

    const renderIcon = () => {
      if (props.icon) {
        return props.icon
      }

      const { Icon } = props.styled!
      return (
        <Icon
          invalid={props.invalid}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'icon')}
        />
      )
    }

    const renderLabel = () => {
      const { Label } = props.styled!
      return (
        <Label
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'label')}
        >
          {props.children}
        </Label>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderIcon()}
        {renderLabel()}
      </Root>
    )
  },
)

StepLabel.defaultProps = {
  invalid: false,
}

export default StepLabel
