import InputField, { InputFieldPublicProps } from '../../InputField'
import type { InputFieldContext } from '../../InputField.composition'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevInputField.styles'
import Customisations, { InputFieldCustomisations } from './DevInputField.customisations'

export const DevInputField = withCustomisedStyle<
  InputFieldPublicProps,
  InputFieldContext,
  InputFieldCustomisations
>(InputField, Theme, Styles, Customisations, 'InputField')

export type DevInputFieldCustomisations = InputFieldCustomisations
export type DevInputFieldProps = InputFieldPublicProps & InputFieldCustomisations
