import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevField } from '../../../../../Field'
import { DevNativeCheckboxGroup } from '../../../../../CheckboxGroup'
import type { CheckboxFieldContext } from '../../../../CheckboxField.composition'
import type { StyleProps } from '../../../../../types'
import {
  Customisations,
  NativeCheckboxFieldCustomisations,
} from './DevNativeCheckboxField.customisations'

export type CheckboxFieldStyleProps = StyleProps<{}, NativeCheckboxFieldCustomisations> & ThemeProps

const CheckboxGroup = styled(DevNativeCheckboxGroup).attrs(
  Customisations.CheckboxGroup,
)<CheckboxFieldStyleProps>``

const Root = styled(DevField).attrs(Customisations.Field)<CheckboxFieldStyleProps>``

const Components: CheckboxFieldContext = {
  Root,
  CheckboxGroup,
}

export default Components
