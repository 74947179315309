import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import { Grid } from '../../../../../Grid'
import type { GridContainerProps, GridItemProps } from '../../../../../Grid/Grid'
import type { CheckboxGroupContext } from '../../../../CheckboxGroup.composition'
import type { StyleProps } from '../../../../../types'

export type CheckboxGroupStyleProps = StyleProps & ThemeProps

const Item = styled(Grid)<CheckboxGroupStyleProps & GridItemProps>``

const Root = styled(Grid)<CheckboxGroupStyleProps & GridContainerProps>``

const Components: CheckboxGroupContext = {
  Root,
  Item,
}

export default Components
