import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { ListItemTextStyleProps } from '../DevListItemText.styles'

/** Global */
/** Colour */
export const getColour = (props: ListItemTextStyleProps) => {
  const currentState = theme.getState(props.styleProps)
  return theme.getColour(props, 'inherit', currentState)
}

/** Primary */
export const getPrimaryColour = (props: ListItemTextStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Primary')
  const currentState = theme.getState(props.styleProps)
  return theme.getColour(customisations, 'inherit', currentState)
}

/** Secondary */
export const getSecondaryColour = (props: ListItemTextStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Secondary')
  const currentState = theme.getState(props.styleProps)
  return theme.getColour(customisations, props.theme.colours.tints.charcoal[80], currentState)
}
