import React from 'react';
import logo from '../logo.svg';
import './Home.css';
import { Box } from '@material-ui/core';

const Home: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <img src={logo} className="Home-logo" alt="logo" />
        <h1>Payments Mock Bank</h1>
      </Box>
    </Box>
  );
};

export default Home;
