import Dropzone, { DropzonePublicProps } from '../../Dropzone'
import Theme from '../../../themes/Developer/theme'
import type { DropzoneContext } from '../../Dropzone.composition'
import { withStyle } from '../../../providers'
import Styles from './DevDropzone.styles'

export const DevDropzone = withStyle<DropzonePublicProps, DropzoneContext>(
  Dropzone,
  Theme,
  Styles,
  'Dropzone',
)

export type DevDropzoneProps = DropzonePublicProps
