import type { ListSubheaderPublicProps } from '../../ListSubheader'
import type { ListSubheaderCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: ListSubheaderPublicProps & ListSubheaderCustomisations,
): ListSubheaderCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
})
