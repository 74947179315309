// dev theme based design tokens
import consolas, { ITypography as IConsolasTypography } from './consolas/typography'
import proximaNova, { ITypography as IProximaNovaTypography } from './proxima-nova/typography'
import { Colours, Spacing1Value, IVisuals, spaces, colours, visuals } from './common'

export interface DeveloperTheme {
  colours: Colours
  spaces: Spacing1Value
  typography: {
    consolas: IConsolasTypography
    proximaNova: IProximaNovaTypography
  }
  visuals: IVisuals
}

/**
 * the entry-point for developer theme
 */
const theme: DeveloperTheme = {
  colours,
  typography: {
    consolas,
    proximaNova,
  },
  spaces,
  visuals,
}

export default theme
