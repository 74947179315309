import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../Icon'
import { BannerContext } from '../../Banner.composition'
import { Customisations } from './DevBanner.customisations'
import type { BannerCoreStyle } from '../../Banner'
import type { StyleProps } from '../../../types'
import {
  getBgColour,
  getBorder,
  getBorderColour,
  getColour,
  BannerCustomisations,
} from './customisations'
import * as theme from '../../../themes/Developer/proxima-nova'
import * as themeStyles from '../../../themes/Developer/styles'

export type BannerStyleProps = StyleProps<BannerCoreStyle, BannerCustomisations> & ThemeProps

const StandardRoot = css<BannerStyleProps>`
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.theme.spaces.xxs};
    height: 100%;
    border-radius: ${(props) =>
      `${props.theme.visuals.borderRadius} 0px 0px ${props.theme.visuals.borderRadius}`};
    background-color: ${(props) => getBorderColour(props).colour};
  }
`

const OutlinedRoot = css<BannerStyleProps>`
  border: ${(props) => getBorder(props)};
`

const CrossIcon = styled(DevFontAwesomeIcon).attrs(Customisations.CrossIcon)<BannerStyleProps>`
  margin-left: ${(props) => props.theme.spaces.sm};
  margin-right: ${(props) => props.theme.spaces.xs};
`

const Icon = styled(DevFontAwesomeIcon).attrs(Customisations.Icon)<BannerStyleProps>`
  margin-left: ${(props) => props.theme.spaces.md};
  margin-right: ${(props) => props.theme.spaces.xs};
`

const Message = styled.div<BannerStyleProps>`
  margin-right: ${(props) => props.theme.spaces.xs};
  flex-grow: 1;
`

const Root = styled.div<BannerStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: ${(props) => props.theme.visuals.borderRadius};

  /** Colour */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) =>
    theme.getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyM.px)};
  font-weight: ${(props) => props.theme.typography.proximaNova.fontWeight.regular};

  /** Spacing */
  padding: ${(props) => props.theme.spaces.md} 0px;

  ${(props) => props.styleProps.variant === 'standard' && StandardRoot};
  ${(props) => props.styleProps.variant === 'outlined' && OutlinedRoot};
`

const Components: BannerContext = {
  Root,
  Message,
  Icon,
  CrossIcon,
}

export default Components
