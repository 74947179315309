import * as React from 'react'

import { ResponsiveProvider } from '../responsives'

import { Container, Measure, Slick as SlickStyled } from './Slick.styles'
import useResponsive from '../hooks/useResponsive'
import { convertHex } from '../utils'

interface SlickOwnProps {
  id?: string
  fullPage?: boolean
  fitContent?: boolean
  profiler?: boolean
  height?: string
  bgColour?: string
  dark?: boolean
  children?: React.ReactNode
}

type SlickProps = SlickOwnProps

const Slick = React.memo((props: SlickProps) => {
  const { ref, width, height, device } = useResponsive<HTMLDivElement>()

  // Official Link: https://reactjs.org/docs/profiler.html
  const handleRender = (
    id: string,
    phase: string,
    actualDuration: number,
    baseDuration: number,
    startTime: number,
    commitTime: number,
    interactions: any,
  ) => {
    // console.table({
    //     Id: id,
    //     'Phase': phase,
    //     'Actual Duration': actualDuration,
    //     'Base Duration': baseDuration,
    //     'Start Time': startTime,
    //     'Commit Time': commitTime,
    //     'Interactions': interactions
    // })
  }

  function renderItem(item: any, index: number) {
    return (
      <ResponsiveProvider key={`slick-${index + 1}`} value={{ device }}>
        {item}
      </ResponsiveProvider>
    )
  }

  function renderMeasure() {
    const measure = `${width}x${height}`
    return (
      <Measure>
        <p>{device}</p>
        <p>{measure}</p>
      </Measure>
    )
  }

  function renderContent() {
    const items = React.Children.toArray(props.children)
    return device ? (
      <SlickStyled
        dots
        infinite
        fade
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        adaptiveHeight
        fitContent={props.fitContent}
        {...props}
      >
        {items.map((item, index) => renderItem(item, index))}
      </SlickStyled>
    ) : null
  }

  const renderWithProfiler = () =>
    props.profiler ? (
      <React.Profiler id={props.id!} onRender={handleRender}>
        {renderContent()}
      </React.Profiler>
    ) : (
      renderContent()
    )

  const bgColour = props.dark ? convertHex('#AAAAAA', 50) : props.bgColour
  return (
    <Container
      ref={ref}
      id="docz-root"
      style={{ height: props.height!, backgroundColor: bgColour }}
    >
      {renderMeasure()}
      {renderWithProfiler()}
    </Container>
  )
})

export default Slick
