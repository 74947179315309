import { createTheme } from '@material-ui/core';

const Sun = '#F7AB1B';
const KashmirBlue = '#44689A';
const CloudBurst = '#093554';

const colourConfig = {
    // Primary Colours
    Sun: Sun,
    KashmirBlue: KashmirBlue,
    CloudBurst: CloudBurst,

    Yellow: Sun,
    Blue: KashmirBlue,
    DarkBlue: CloudBurst,

    // Secondary Colours
    SecondaryBlue: '#058ED8',
    LightBlue: '#87BAFC',
    Purple: '#42448E',
    LightPurple: '#7A7FFF',
    LightYellow: '#F4EBC9',

    // Tertiary Colours
    Green: '#399847',
    Black: '#2D2D2D',
    Grey: '#AAAAAA',
    LightGrey: '#EEEEEE',
    Red: '#C00023',
};

const colours: Readonly<typeof colourConfig> = colourConfig;

const theme = createTheme({
    palette: {
        background: {
            default: 'rgb(245,245,245)'
        },
        text: {
            primary: colours.Black,
            secondary: colours.LightGrey,
        },
        primary: {
            main: colours.Yellow,
            light: colours.LightYellow,
            contrastText: colours.DarkBlue,
        },
        secondary: {
            main: colours.Blue,
            light: colours.LightBlue,
            dark: colours.DarkBlue,
            contrastText: colours.Yellow,
        },
        error: {
            main: colours.Red,
        },
        warning: {
            main: colours.Purple,
            light: colours.LightPurple,
        },
        info: {
            main: colours.SecondaryBlue,
        },
        success: {
            main: colours.Green,
        },
    },
    typography: {
      fontFamily: [
        'proxima-nova',
        'sans-serif'
      ].join(','),
      h1: {
        fontSize: "2.5rem",
        fontWeight: 600
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: 600
      },
      h3: {
        fontSize: "1rem",
        fontWeight: 700
      },
      subtitle2: {
        color: colours.Grey
      }
    },
    overrides: {
      MuiTableCell: {
        body: {
          color: colours.Yellow
        }
      }
    }
});

export { colours };
export default theme;