import { css } from '../themed-component'

const NoSelection = css`
  * {
    ::selection {
      background: transparent;
    }
  }
`

export const DisabledState = css`
  ${NoSelection};

  cursor: default;
  user-select: none;
  pointer-events: none;
  opacity: ${({ theme: { visuals } }) => visuals.opacity.disable};
`

export const ReadOnlyState = css`
  ${NoSelection};

  cursor: default;
  user-select: none;
  pointer-events: none;
`

/**
 * TODO: to populate common props across all components based on hovered state
 */
export const HoveredState = css``

/**
 * TODO: to populate common props across all components based on focused state
 */
export const FocusedState = css``

/**
 * TODO: to populate common props across all components based on active state
 */
export const ActiveState = css``
