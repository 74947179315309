const getAttributes = (props: any, prefix: string, match?: string) => {
  const newProps = {}

  let matchRegex
  if (match) {
    matchRegex = new RegExp(`[^-]+-${match}-.+`, 'i')
  }

  Object.keys(props).forEach((key) => {
    if (matchRegex && !matchRegex.test(key)) {
      return
    }

    if (key.startsWith(prefix)) {
      newProps[key] = props[key]
    }
  })

  return newProps
}

export default getAttributes
