import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { OptionWithIconCoreStyle } from '../../OptionWithIcon'
import type { OptionWithIconContext } from '../../OptionWithIcon.composition'
import type { StyleProps } from '../../../../../types'
import { DevIcon } from '../../../../../Icon'

export type OptionWithIconStyleProps = StyleProps<OptionWithIconCoreStyle> & ThemeProps

const Label = styled.label<OptionWithIconStyleProps>`
  flex-grow: 1;
  text-align: start;
`

const Icon = styled(DevIcon)<OptionWithIconStyleProps>`
  margin-right: ${(props) => props.theme.spaces.xs};
`

const Root = styled.div<OptionWithIconStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: inherit;

  * {
    cursor: inherit;
  }
`

const Components: OptionWithIconContext = {
  Root,
  Icon,
  Label,
}

export default Components
