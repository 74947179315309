import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {AppBar, Box, Button, CircularProgress, IconButton, SvgIcon, Table, TableBody, TableCell, TableRow, Toolbar, Typography} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ReactComponent as LogoIcon } from "../icon.svg";
import SingleImmediatePayment from './SingleImmediatePayment';
import { Currency, JsonViewer } from '../Utils';
import singleImmediatePaymentService from './SingleImmediatePaymentsService';
import SingleImmediatePaymentAction from './SingleImmediatePaymentAction';
import {toKebabCase} from "../Utils/StringHelper";


const SingleImmediatePayments: React.FC = () => {

  const { id } = useParams<{ id: string }>();
  const { hash } = useLocation();

  const getToken = (): string | null => {
    const matches = hash.match(/#token=([^=/]+)$/);
    return matches && matches.length > 1 ? matches[1] : null;
  }
  const token = getToken();

  const [loadState, setLoadState] = useState<'Loading' | 'Loaded' | 'Errored'>('Loading');
  const [submitState, setSubmitState] = useState<'Submitting' | 'NotSubmitting' | 'AlreadySubmitted' | 'Errored'>('NotSubmitting');
  const [payment, setPayment] = useState<SingleImmediatePayment | null>(null);
  const [returnUri, setReturnUri] = useState<string | null>(null);

  useEffect(() => {
    singleImmediatePaymentService.getPayment(id, token).then((result) => {
      if (result && result.status !== 'Initiated') {
        setSubmitState('AlreadySubmitted');
      }
      setPayment(result);
      setLoadState('Loaded');
    }).catch(() => {
      setLoadState('Errored');
    });
  }, []);

  const submitPayment = (action: SingleImmediatePaymentAction) => {
    setSubmitState('Submitting');
    singleImmediatePaymentService.submitPayment(id, action, token).then((result) => {
      switch (result.type) {
        case 'Accepted':
          window.location.href = result.return_uri;
          return;
        case 'AlreadyActioned':
          setReturnUri(result.return_uri);
          setSubmitState('AlreadySubmitted');
      }
    }).catch(() => {
      setSubmitState('Errored');
    })
  };

  const renderTable = (): JSX.Element => {
    switch (loadState) {
      case 'Loading':
        return (<CircularProgress />);
      case 'Loaded':
        if (!payment) {
          return (<Alert severity="warning">Could not find payment {id}</Alert>);
        }
        return (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><strong>ID</strong></TableCell>
                <TableCell>{payment.external_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Amount</strong></TableCell>
                <TableCell><Currency currency={payment.currency} amount={payment.amount} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Status</strong></TableCell>
                <TableCell>{payment.status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Request</strong></TableCell>
                <TableCell>
                  <JsonViewer json={payment.initiation_request_json} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        );
      case 'Errored':
        return (<Alert severity="error">Error fetching payment {id}</Alert>);
    }
  };

  const renderActionButton = (action: SingleImmediatePaymentAction): JSX.Element => (
    <Box p={1}>
      <Button
        id={toKebabCase(action)}
        variant="contained"
        color="primary"
        onClick={() => submitPayment(action)}
        disabled={submitState === 'Submitting'}
      >
        {action.replace(/([a-z])([A-Z])/g, '$1 $2')}
      </Button>
    </Box>
  );

  const renderActionButtons = (): JSX.Element => {
    return (
      <>
        {renderActionButton('Execute')}
        {renderActionButton('Cancel')}
        {renderActionButton('RejectAuthorisation')}
        {renderActionButton('RejectExecution')}
      </>
    );
  };

  const renderActions = (): JSX.Element => {
    if (!payment) {
      return (<></>);
    }
    switch (submitState) {
      case 'NotSubmitting':
        return renderActionButtons();
      case 'Submitting':
        return (
          <>
            {renderActionButtons()}
            <Box p={1}><CircularProgress /></Box>
          </>
        );
      case 'AlreadySubmitted':
        return (
          <>
            <Box p={1}>
              <Alert severity="warning">Payment already submitted!</Alert>
            </Box>
            {!!returnUri && <Box p={1}>
              <Button variant="contained" color="secondary" href={returnUri}>Return to merchant</Button>
            </Box>}
          </>
        );
      case 'Errored':
        return (
          <Box p={1}>
            <Alert severity="error">Error submitting payment!</Alert>
          </Box>
        );
    }
  };

  return (
    <Box minWidth="100vw" minHeight="100vh" bgcolor="#2D2D2D" style={{color: '#F7AB1B'}}>
      <AppBar>
        <Toolbar>
          <IconButton>
            <SvgIcon component={LogoIcon} viewBox="0 0 158.4 158.4" />
          </IconButton>
          <Typography variant="h6">Payments Mock Bank</Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box p={1}>
          <Typography variant="h6">Single Immediate Payment</Typography>
        </Box>
        <Box p={1}>
          {renderTable()}
        </Box>
        {renderActions()}
      </Box>
    </Box>
  );
};

export default SingleImmediatePayments;
