import React from "react";
import {Box, Grid, makeStyles, Popover, Typography} from "@material-ui/core";
import {AccountBalance, Help} from "@material-ui/icons";

interface Props {
  name: string;
}

export const MockBankHeader: React.FC<Props> = ({ name }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const popoverStyles = makeStyles({
    paper: {
      background: '#44689A',
      color: 'white'
    },
  });
  const colouredTextStyles = makeStyles({
    root: {
      color: '#44689A'
    },
  });

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Box display="inline" pr={1}>
          <AccountBalance fontSize="large" />
        </Box>
        <Typography variant="h1" display="inline">{name}</Typography>
      </Grid>
      <Grid item onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        <Typography variant="subtitle1" display="inline" classes={{root: colouredTextStyles().root}}><strong><u>What is this?</u></strong></Typography>
        <Box display="inline" pl={1}>
          <Help fontSize="small" classes={{root: colouredTextStyles().root}} />
        </Box>
        <Popover
          id="help-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          classes={{paper: popoverStyles().paper}}
        >
          <Typography align="left" style={{'padding':'10px'}}>{name} is a demo bank that<br />TrueLayer created to help you<br />test the product and your integration.<br />With this bank, you can test all<br />the different scenarios that could happen<br />to a Single Immediate Payment.<br />{name} is only available in<br />TrueLayer’s Sandbox environment.</Typography>
        </Popover>
      </Grid>
    </Grid>
  );
}
