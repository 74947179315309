import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { getColour, getFontWeight, getTextAlign, TitleCustomisations } from './customisations'
import type { TitleContext } from '../../Title.composition'
import type { TitleCoreStyle } from '../../Title'
import type { StyleProps } from '../../../types'
import * as themeStyles from '../../../themes/Developer/styles'

export type TitleStyleProps = StyleProps<TitleCoreStyle, TitleCustomisations> & ThemeProps

const Root = styled.h1<TitleStyleProps>`
  margin: 0;
  color: ${(props) => getColour(props)};
  font-size: ${(props) => props.theme.typography[props.styleProps.type]};

  /** Typography */
  ${themeStyles.FontFamily}
  font-weight: ${(props) => getFontWeight(props)};
  text-align: ${(props) => getTextAlign(props)};
`

const Components: TitleContext = {
  Root,
}

export default Components
