import * as React from 'react'
import type { StyledProps } from '../../../providers'
import type { OptionWithCheckboxContext } from './OptionWithCheckbox.composition'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'

export interface OptionWithCheckboxPublicProps {
  option: any
  /**
   * Name of the property to display in the select
   */
  displayName: string
  size: 'superindex' | 'small' | 'medium' | 'large'
  selected?: boolean
}

export interface OptionWithCheckboxProps
  extends OptionWithCheckboxPublicProps,
    StyledProps<OptionWithCheckboxContext> {}

export interface OptionWithCheckboxCoreStyle {
  size: string
}

const OptionWithCheckbox: React.FC<OptionWithCheckboxProps> = React.forwardRef(
  (props: OptionWithCheckboxProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): OptionWithCheckboxCoreStyle => ({
      size: props.size!,
    })

    const renderLabel = () => {
      const { option } = props

      const { Label } = props.styled!
      return (
        <Label styleProps={getCoreStyle()} customisations={props.customisations}>
          {option[props.displayName]}
        </Label>
      )
    }

    const renderCheckbox = () => {
      const fakeHandler = (e: any) => null

      const { Checkbox } = props.styled!
      return (
        <Checkbox
          type="checkbox"
          checked={props.selected}
          onChange={fakeHandler}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
        />
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderCheckbox()}
        {renderLabel()}
      </Root>
    )
  },
)

export default OptionWithCheckbox
