import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { MenuGroupStyleProps } from '../DevMenuGroup.styles'

/** Header */
export const getHeaderPadding = (props: MenuGroupStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Header')
  return theme.getPadding(customisations, {
    topBottom: props.theme.spaces.xs,
    leftRight: props.theme.spaces.sm,
  })
}

/** Content */
export const getContentPadding = (props: MenuGroupStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Content')
  return theme.getPadding(customisations, '0px')
}
