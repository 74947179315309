import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { TableHeaderContext } from '../../TableHeader.composition'
import type { StyleProps } from '../../../../../types'
import { getBgColour, getColour, TableHeaderCustomisations } from './customisations'

export type TableHeaderStyleProps = StyleProps<{}, TableHeaderCustomisations> & ThemeProps

const Root = styled.thead<TableHeaderStyleProps>`
  display: table-header-group;
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};
`

const Components: TableHeaderContext = {
  Root,
}

export default Components
