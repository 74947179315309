import TableBody, { TableBodyPublicProps } from '../../TableBody'
import Theme from '../../../../../themes/Developer/theme'
import type { TableBodyContext } from '../../TableBody.composition'
import { withStyle } from '../../../../../providers'
import Styles from './DevTableBody.styles'

export const DevTableBody = withStyle<TableBodyPublicProps, TableBodyContext>(
  TableBody,
  Theme,
  Styles,
  'TableBody',
)

export type DevTableBodyProps = TableBodyPublicProps
