import getStateValue from '../utils/state'
import type { SelectProps } from './Select'
import type { FormControlContextProps } from '../FormControl/FormControl.context'
import { find, isEmpty, isNil, reject } from '../utils/lodash'

export const isSelected = (value: string, selectedValue: string | string[]) => {
  if (Array.isArray(selectedValue)) {
    return find(selectedValue, (x: string) => x === value)
  }

  return value === selectedValue
}

export const isReadOnly = (props: SelectProps, formControl: FormControlContextProps) =>
  formControl.readOnly || props.type === 'standard'

export const isSearch = (props: SelectProps) => props.type === 'search'

export const isSearching = (props: SelectProps, formControl: FormControlContextProps, extra: any) =>
  props.type === 'search' && getStateValue(formControl.focused, extra.focused)

export const getValue = (option: string | object, propertyName?: string) => {
  if (!option) {
    return option
  }

  if (typeof option === 'number') {
    return +option
  }

  if (typeof option === 'string') {
    return option
  }

  const value = propertyName ? option[propertyName!] : option
  if (!isEmpty(value)) {
    return value
  }

  return Array.isArray(option) ? [] : ''
}

export const getValues = (options: (string | object)[], propertyName?: string) =>
  options.map((option) => getValue(option, propertyName))

export const getOptionsFromValues = (
  props: SelectProps,
  values: string | string[],
): any[] | any => {
  const options = props.options || []

  if (Array.isArray(values)) {
    const currentValues = Array.isArray(values) ? values : [values]
    const items = currentValues.map((value) =>
      options.find((option: any) => getValue(option, props.displayValue) === value),
    )

    return reject(items, isNil)
  }

  return options.find((option: any) => getValue(option, props.displayValue) === values)
}

export const format = (value?: string | string[], separator = ',') => {
  if (Array.isArray(value)) {
    return value?.join(separator) || ''
  }

  return value || ''
}

export const toArray = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return isEmpty(value) ? [] : value
  }

  return value == null ? [] : [value]
}

export const filter = (
  options: (string | object)[],
  search: string,
  searchType: string,
  propertyName?: string,
) => {
  const invariantSearch = search.toLowerCase()

  let action: any = null
  switch (searchType) {
    case 'startsWith':
      action = (value: string) => value.startsWith(invariantSearch)
      break
    case 'includes':
      action = (value: string) => value.includes(invariantSearch)
      break
    default:
      action = (value: string) => value === invariantSearch
  }

  return options!.filter((option) => action(getValue(option, propertyName).toLowerCase()))
}

export const createOptionFromTemplate = (
  option: any,
  props: SelectProps,
  formControl: FormControlContextProps,
  extra?: any,
) =>
  props.optionTemplate!({
    option,
    displayName: props.displayName,
    size: formControl.size!,
    selected: extra?.selected,
  })

export const createSelectionFromTemplate = (
  key: string,
  option: any,
  props: SelectProps,
  formControl: FormControlContextProps,
  extra?: any,
) =>
  props.selectionTemplate!({
    key,
    option,
    displayName: props.displayName,
    size: formControl.size!,
  })
