const easeInOutSin = (time: number): number => (1 + Math.sin(Math.PI * time - Math.PI / 2)) / 2

const animate = (
  property: any,
  element: any,
  to: any,
  options: any = {},
  cb = (error?: Error) => {},
) => {
  const {
    ease = easeInOutSin,
    duration = 300, // standard
  } = options

  let start: number | null = null
  const from = element[property]
  let cancelled = false

  const cancel = () => {
    cancelled = true
  }

  const step = (timestamp: number) => {
    if (cancelled) {
      cb(new Error('Animation cancelled'))
      return
    }

    if (start === null) {
      start = timestamp
    }
    const time = Math.min(1, (timestamp - start) / duration)

    /* eslint-disable-next-line no-param-reassign */
    element[property] = ease(time) * (to - from) + from

    if (time >= 1) {
      requestAnimationFrame(() => {
        cb()
      })
      return
    }

    requestAnimationFrame(step)
  }

  if (from === to) {
    cb(new Error('Element already at target position'))
    return cancel
  }

  requestAnimationFrame(step)
  return cancel
}

export default animate
