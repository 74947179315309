import type { ListItemIconPublicProps } from '../../ListItemIcon'
import type { ListItemIconStyleProps } from './DevListItemIcon.styles'
import { getColour, ListItemIconCustomisations } from './customisations'

export const Customisations = {
  Icon: (props: ListItemIconStyleProps) => ({
    colour: getColour(props),
    hoverColour: getColour(props),
  }),
  FontAwesomeIcon: (props: ListItemIconStyleProps) => ({
    colour: getColour(props),
    hoverColour: getColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: ListItemIconPublicProps & ListItemIconCustomisations,
): ListItemIconCustomisations => ({
  colour: props.colour,
})
