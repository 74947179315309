import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { StepContentCoreStyle } from '../../StepContent'
import type { StepContentContext } from '../../StepContent.composition'
import type { StyleProps } from '../../../../../types'
import * as theme from '../../../../../themes/Developer/proxima-nova'

export type StepContentStyleProps = StyleProps<StepContentCoreStyle> & ThemeProps

const LastRoot = css<StepContentStyleProps>`
  border-left: none;
`

const HorizontalRoot = css<StepContentStyleProps>`
  margin-top: ${(props) => props.theme.spaces.sm};
  padding-left: ${(props) => props.theme.spaces.xs};
  padding-right: ${(props) => props.theme.spaces.xs};
`

const VerticalRoot = css<StepContentStyleProps>`
  border-left: ${(props) => props.theme.visuals.borderSize} solid
    ${(props) => props.theme.colours.tints.lightGrey[150]};
  margin-left: ${(props) => props.theme.spaces.xs};
  padding-left: ${(props) => props.theme.spaces.md};
  padding-right: ${(props) => props.theme.spaces.xs};
`

const Root = styled.div<StepContentStyleProps>`
  font-size: ${(props) =>
    theme.getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyM.px)};
  color: ${(props) => props.theme.colours.tertiary.neutral.charcoal};

  ${(props) => props.styleProps.last && LastRoot};
  ${(props) => props.styleProps.orientation === 'horizontal' && HorizontalRoot};
  ${(props) => props.styleProps.orientation === 'vertical' && VerticalRoot};
`

const Components: StepContentContext = {
  Root,
}

export default Components
