// utils
import * as theme from '../../../../themes/Developer/proxima-nova'

// types
import type { BreadCrumbsStyleProps } from '../DevBreadCrumbs.styles'
import type { State } from '../../../../themes/Common'

/**
 * func. which returns the colour.
 * @param props the breadcrumbs style props
 * @param state the possible component states
 * @returns
 */
export const getColour = (props: BreadCrumbsStyleProps, state?: State) =>
  theme.getColour(props, props.theme.colours.tints.charcoal[50], state)
