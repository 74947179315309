import type { TitlePublicProps } from '../../Title'
import type { TitleCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: TitlePublicProps & TitleCustomisations): TitleCustomisations => ({
  colour: props.colour,
  fontWeight: props.fontWeight,
  textAlign: props.textAlign,
})
