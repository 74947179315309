import DialogActions, { DialogActionsPublicProps } from '../../DialogActions'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevDialogActions.styles'
import Customisations from './DevDialogActions.customisations'
import type { DialogActionsContext } from '../../DialogActions.composition'
import type { DialogActionsCustomisations } from './customisations'

export const DevDialogActions = withCustomisedStyle<
  DialogActionsPublicProps,
  DialogActionsContext,
  DialogActionsCustomisations
>(DialogActions, Theme, Styles, Customisations, 'DialogActions')

export type DevDialogActionsCustomisations = DialogActionsCustomisations
export type DevDialogActionsProps = DialogActionsPublicProps & DialogActionsCustomisations
