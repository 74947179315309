import * as React from 'react'
import type { StyledProps } from '../../../providers'
import type { TableHeaderContext as TableHeaderComposition } from './TableHeader.composition'
import TableLevel2Context, { TableLevel2ContextProps } from '../../TableLevel2.context'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'

export interface TableHeaderPublicProps {
  /**
   * The html tag used for the root node.
   * @default thead
   */
  as?: any
  /**
   * The content of the component, normally `TableRow`.
   */
  children?: React.ReactNode
}

const context: TableLevel2ContextProps = { variant: 'header' }

export type TableHeaderProps = TableHeaderPublicProps & StyledProps<TableHeaderComposition>

const TableHeader = React.forwardRef(
  (props: TableHeaderProps, forwardRef: React.Ref<HTMLElement>) => {
    const { ref } = useResponsive<HTMLElement>({ ref: forwardRef })

    const getCoreStyle = (): unknown => ({})

    const getRole = () => {
      let role

      if (props.as !== TableHeader.defaultProps.as) {
        role = 'rowgroup'
      }

      return role
    }

    const { Root } = props.styled!
    return (
      <TableLevel2Context.Provider value={context}>
        <Root
          ref={ref as any}
          className={props.className}
          as={props.as!}
          role={getRole()}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getAttributes(props, DataAttributesPrefix)}
        >
          {props.children}
        </Root>
      </TableLevel2Context.Provider>
    )
  },
)

TableHeader.defaultProps = {
  as: 'thead',
}

export default TableHeader
