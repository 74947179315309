import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { TableHeaderStyleProps } from '../DevTableHeader.styles'

/** Colour */
export const getColour = (props: TableHeaderStyleProps) =>
  theme.getColour(
    props,
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )

/** Background colour */
export const getBgColour = (props: TableHeaderStyleProps) =>
  theme.getBgColour(
    props,
    props.theme.colours.tertiary.neutral.white,
    theme.getState(props.styleProps),
  )
