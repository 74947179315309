
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";

import mandatesService from "../Mandates/MandatesService";
import Mandate from "../Mandates/Mandate";
import LoadingPanel from "../LoadingPanel";
import { FormValue, MockBankMandatesForm } from "./MockBankMandatesForm";
import {
    mapUsernameToActionForMandates,
    useRoutes,
    useToken
} from "./utils";
import { redirectAfterSubmitMandate } from "../Mandates/RedirectAfterSubmitMandate";

export function MockBankMandatesLogin() {
  const [mandate, setMandate] = useState<Mandate | null>(null);

  const { navigateToErrorPage, navigateToPage } = useRoutes();

  const token = useToken();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {

    mandatesService
      .getMandate(id, token)
      .then(result => {
        if (!result || result.status !== "Initiated") {
          navigateToErrorPage();
        }
        setMandate(result);
      })
      .catch(navigateToErrorPage);
  }, []);

  function onCancel() {
    mandatesService
      .submitMandate(id, "Cancel", token)
      .then(redirectAfterSubmitMandate(navigateToErrorPage))
      .catch(navigateToErrorPage);
  }
  
  function onSubmit(formValues: FormValue) {
    const username = formValues.username;
    const action = mapUsernameToActionForMandates(username);
    navigateToPage(
      `/mandates/confirm/${mandate?.id}?action=${action}#token=${token}`, 
      {
            mandate: mandate
        }
    );
  }

  return (
    <Container>
      <Grid container>
        {mandate !== null ? (
          <MockBankMandatesForm
            mandate={mandate}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        ) : (
          <LoadingPanel />
        )}
      </Grid>
    </Container>
  );
}
