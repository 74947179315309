import Theme from '../../../../../../../themes/Developer/theme'
import Styles from './DevTablePaginationActions.styles'
import Customisations from './DevTablePaginationActions.customisations'
import TablePaginationActions, {
  TablePaginationActionsPublicProps,
} from '../../TablePaginationActions'
import { withCustomisedStyle } from '../../../../../../../providers'
import type { TablePaginationActionsContext } from '../../TablePaginationActions.composition'
import type { TablePaginationActionsCustomisations } from './customisations'

export const DevTablePaginationActions = withCustomisedStyle<
  TablePaginationActionsPublicProps,
  TablePaginationActionsContext,
  TablePaginationActionsCustomisations
>(TablePaginationActions, Theme, Styles, Customisations, 'TablePaginationActions')

export type DevTablePaginationActionsCustomisations = TablePaginationActionsCustomisations
export type DevTablePaginationActionsProps = TablePaginationActionsPublicProps &
  TablePaginationActionsCustomisations
