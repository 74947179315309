import type { IconStyleProps } from '../DevIcon.styles'
import * as theme from '../../../../themes/Developer/proxima-nova'

/** Background colour */
export const getBgColour = (props: IconStyleProps, state?: theme.State) =>
  theme.getBgColour(props, 'transparent', state)

/** Background colour */
export const getBorderColour = (props: IconStyleProps, state?: theme.State) =>
  theme.getBorderColour(props, 'transparent', state)
