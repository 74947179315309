import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import type { FormHelperTextCoreStyle } from '../../FormHelperText'
import type { FormHelperTextContext } from '../../FormHelperText.composition'
import type { StyleProps } from '../../../types'
import {
  FormHelperTextCustomisations,
  getColour,
  getFontSize,
  getPaddingLeft,
} from './customisations'
import * as themeStyles from '../../../themes/Developer/styles'

export interface FormHelperTextStyle {
  variant: string
  size: string
  invalid: boolean
  color?: string
}

export type FormHelperTextStyleProps = StyleProps<
  FormHelperTextCoreStyle,
  FormHelperTextCustomisations
> &
  ThemeProps

const Root = styled.p<FormHelperTextStyleProps>`
  display: block;
  text-align: start;
  pointer-events: none;
  margin: 0;

  /* Colour */
  color: ${(props) => getColour(props)};

  /* Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};
`

const Components: FormHelperTextContext = {
  Root,
}

export default Components
