import { trim } from '../../../utils/lodash'
import { BgColourStates, ColourStates, State } from '../models'

/**
 * Return the colour for the requested state
 * @param value list of colour
 * @param defaultValue List of default colour
 * @param state requested state
 */
export const getColour = (
  value: ColourStates,
  defaultValue: string | ColourStates,
  state?: State,
): string => {
  if (!state) {
    // Enable state is requested
    return value.colour || getDefaultColour(defaultValue, 'colour')
  }

  let colour
  let previousFallbackState
  let fallbackState: string = state
  while (!colour && previousFallbackState !== fallbackState) {
    colour =
      value[`${fallbackState}Colour`] ||
      (typeof defaultValue !== 'string'
        ? defaultValue[fallbackState] || defaultValue[`${fallbackState}Colour`]
        : undefined)

    previousFallbackState = fallbackState
    fallbackState = getFallbackState(state)
  }

  return colour || value.colour || getDefaultColour(defaultValue, 'colour')
}

/**
 * Return the background colour for the requested state
 * @param value list of background colour
 * @param defaultValue List of default background colour
 * @param state requested state
 */
export const getBgColour = (
  value: BgColourStates,
  defaultValue: string | BgColourStates,
  state?: State,
): string => {
  if (!state) {
    return value.bgColour || getDefaultColour(defaultValue, 'bgColour')
  }

  let colour
  let previousFallbackState
  let fallbackState: string = state
  while (!colour && previousFallbackState !== fallbackState) {
    colour =
      value[`${fallbackState}BgColour`] ||
      (typeof defaultValue !== 'string'
        ? defaultValue[fallbackState] || defaultValue[`${fallbackState}BgColour`]
        : undefined)

    previousFallbackState = fallbackState
    fallbackState = getFallbackState(state)
  }

  return colour || value.bgColour || getDefaultColour(defaultValue, 'bgColour')
}

export const getDefaultColour = (
  defaultValue: string | ColourStates | BgColourStates,
  state: string,
) => (typeof defaultValue === 'string' ? defaultValue : defaultValue[state])

export function getValueAsPixel(value: string | number): string {
  return typeof value === 'number' ? `${value}px` : trim(value)
}

export function getValueAsNumber(value: string | number) {
  if (typeof value === 'string') {
    const pieces = trim(value)?.match(/^(\d+(.\d+)?)(px|em|rem)$/i)

    if (pieces?.length > 0) return +pieces[1]
  }

  return +value
}

export const getState = (props: any): State => {
  if (props.disabled) {
    if (props.selected || props.checked) {
      return 'selected'
    }

    return 'disabled'
  }

  if (props.hovered) {
    if (props.selected || props.checked) {
      return 'hoverSelected'
    }

    return 'hover'
  }

  if (props.focused) {
    if (props.selected || props.checked) {
      return 'focusSelected'
    }

    return 'focus'
  }

  if (props.active) {
    return 'active'
  }

  if (props.invalid) {
    if (props.selected || props.checked) {
      return 'invalidSelected'
    }

    return 'invalid'
  }

  if (props.selected || props.checked) {
    return 'selected'
  }

  return undefined
}

export const getFallbackState = (state?: State, defaultState?: string) => {
  switch (state) {
    case 'disabled':
      return defaultState
    case 'hoverSelected':
      return 'selected'
    case 'focusSelected':
      return 'selected'
    case 'invalidSelected':
      return 'invalid'
    default:
      return state
  }
}
