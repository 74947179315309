import Switch, { SwitchPublicProps } from '../../Switch'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Customisations from './DevSwitch.customisations'
import Styles from './DevSwitch.styles'
import type { SwitchContext } from '../../Switch.composition'
import type { SwitchCustomisations } from './customisations'

export const DevSwitch = withCustomisedStyle<
  SwitchPublicProps,
  SwitchContext,
  SwitchCustomisations
>(Switch, Theme, Styles, Customisations, 'Switch')

export type DevSwitchCustomisations = SwitchCustomisations
export type DevSwitchProps = SwitchPublicProps & SwitchCustomisations
