import { Ref, useEffect, useMemo, useRef, useState } from 'react'

const useComponentVisible = <T>(opts?: {
  ref?: Ref<T>
  isMobile?: boolean
  initialIsVisible?: boolean
}): any => {
  const [isComponentVisible, setIsComponentVisible] = useState(false)

  const defaultRef = useRef<T>(null)
  const ref: any = opts?.ref || defaultRef

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false)
    } else if (event.key === 'Enter') {
      setIsComponentVisible((prev) => !prev)
    }
  }

  useEffect(() => {
    const event = opts?.isMobile ? 'touchstart' : 'mousedown'
    document.addEventListener(event, handleClickOutside, true)

    return () => {
      document.removeEventListener(event, handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('keyup', handleKeyDown)
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('keyup', handleKeyDown)
      }
    }
  }, [ref.current])

  return useMemo(
    () => ({
      ref,
      isComponentVisible,
      setIsComponentVisible,
    }),
    [ref.current, isComponentVisible, setIsComponentVisible],
  )
}

export default useComponentVisible
