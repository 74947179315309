import * as React from 'react'
import type { DialogTitleContext } from './DialogTitle.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'

export interface DialogTitlePublicProps {
  /**
   * Content of the title
   */
  children?: React.ReactNode
}

export interface DialogTitleProps extends DialogTitlePublicProps, StyledProps<DialogTitleContext> {}

const DialogTitle = React.forwardRef(
  (props: DialogTitleProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): unknown => ({})

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

export default DialogTitle
