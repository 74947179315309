import { ThemeProps } from '../../themed-component'
import * as utils from '../../../Common/utils'
import { ICustomisations, State, Border, BgColourStates, ColourStates } from '../../../Common'

export {
  getState,
  getFallbackState,
  getValueAsNumber,
  getValueAsPixel,
} from '../../../Common/utils/colours'

/** Colour */
const defaultColour = ({ theme }: ThemeProps) => ({
  colour: theme.colours.tertiary.neutral.charcoal,
})

export const getColour = (
  props: ICustomisations<ColourStates, ThemeProps>,
  defaultValue?: string | ColourStates,
  state?: State,
): string => {
  let currentDefaultColour: any = defaultColour(props)
  if (defaultValue) {
    currentDefaultColour =
      typeof defaultValue === 'string' ? defaultValue : { ...currentDefaultColour, ...defaultValue }
  }

  return utils.getColour(props.customisations, currentDefaultColour, state)
}

/** Background colour */
const defaultBgColour = ({ theme }: ThemeProps) => ({
  bgColour: theme.colours.tertiary.neutral.white,
})

export const getBgColour = (
  props: ICustomisations<BgColourStates, ThemeProps>,
  defaultValue?: string | BgColourStates,
  state?: State,
): string => {
  let currentDefaultBgColour: any = defaultBgColour(props)
  if (defaultValue) {
    currentDefaultBgColour =
      typeof defaultValue === 'string'
        ? defaultValue
        : { ...currentDefaultBgColour, ...defaultValue }
  }

  return utils.getBgColour(props.customisations, currentDefaultBgColour, state)
}

/** Border colour */
export const defaultBorderColours = ({ theme: { colours } }: ThemeProps): ColourStates => ({
  colour: colours.tints.lightGrey[180],
  hoverColour: colours.tints.sun[50],
  focusColour: colours.tints.sun[50],
  activeColour: colours.tints.lightGrey[180],
  selectedColour: colours.tints.lightGrey[180],
  hoverSelectedColour: colours.tints.sun[50],
  focusSelectedColour: colours.tints.sun[50],
  disabledColour: colours.tints.lightGrey[180],
  invalidColour: colours.tertiary.semantic.red,
})

export const getBorderColour = (
  props: ICustomisations<Border, ThemeProps>,
  defaultValue?: string | ColourStates,
  state?: State,
): string => {
  // TODO: appended string to satisfy ts typing, otherwise 'string' is not possible
  let currentDefaultBorderColour: ColourStates | string = defaultBorderColours(props)
  if (defaultValue) {
    currentDefaultBorderColour =
      typeof defaultValue === 'string'
        ? defaultValue
        : { ...currentDefaultBorderColour, ...defaultValue }
  }

  const border = (props.customisations.border as ColourStates) || {}
  return utils.getColour(border, currentDefaultBorderColour, state)
}
