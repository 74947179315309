import * as React from 'react'
import type { StyledProps } from '../../../providers'
import type { RadioFieldContext } from '../../RadioField.composition'
import type { RadioFieldPublicProps } from '../../RadioField'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'

export type NativeRadioFieldPublicProps = RadioFieldPublicProps

export interface NativeRadioFieldProps
  extends RadioFieldPublicProps,
    StyledProps<RadioFieldContext> {}

const NativeRadioField = React.forwardRef((props: NativeRadioFieldProps, forwardRef: any) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const getCoreStyle = () => ({})

  const renderRadioGroup = () => {
    const { RadioGroup } = props.styled!
    return (
      <RadioGroup
        key={props.id}
        id={props.id || props.name}
        name={props.name || props.id}
        value={props.value}
        spacing={props.spacing}
        size={props.size}
        orientation={props.orientation}
        breakpoints={props.breakpoints}
        onChange={props.onChange}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getTestId(props, `group`)}
      >
        {props.children}
      </RadioGroup>
    )
  }

  const { Root } = props.styled!
  return (
    <Root
      className={props.className}
      ref={ref}
      id={props.id}
      label={props.label}
      helperText={props.helperText}
      disabled={props.disabled}
      invalid={props.invalid}
      required={props.required}
      readOnly={props.readOnly}
      size={props.size}
      breakpoint={props.breakpoint}
      error={props.error}
      tooltip={props.tooltip}
      withValidityStatus={props.withValidityStatus}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {renderRadioGroup()}
    </Root>
  )
})

NativeRadioField.defaultProps = {}

export default NativeRadioField
