import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { getColour, getFontWeight, ParagraphCustomisations, getTextAlign } from './customisations'
import { getValueAsPixel } from '../../../customisations'
import type { ParagraphContext } from '../../Paragraph.composition'
import type { ParagraphCoreStyle } from '../../Paragraph'
import type { StyleProps } from '../../../types'
import * as themeStyles from '../../../themes/Developer/styles'

export type ParagraphStyleProps = StyleProps<ParagraphCoreStyle, ParagraphCustomisations> &
  ThemeProps

const Root = styled.p<ParagraphStyleProps>`
  color: ${(props) => getColour(props)};

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyXs.px)};
  font-weight: ${(props) => getFontWeight(props)};
  text-align: ${(props) => getTextAlign(props)};
`

const Components: ParagraphContext = {
  Root,
}

export default Components
