import { RefObject, SetStateAction, useCallback, useEffect, useState } from 'react'

// Webpack compilation error so for now we return any
// Reference: https://stackoverflow.com/questions/62079477/line-0-parsing-error-cannot-read-property-map-of-undefined/63652345#63652345
// export type HookResponse = [
//   number,
//   React.Dispatch<SetStateAction<number>>
// ];

/**
 * https://a11y-solutions.stevenwoodson.com/solutions/focus/roving-focus/
 * @param ref
 * @param size
 * @param silent
 */
//  [number, (currentFocus: number) => void]
const useRoveFocus = (ref: RefObject<HTMLElement>, size: number, silent?: boolean): any => {
  const [currentFocus, setCurrentFocus] = useState(-1)

  const ArrowDown = (event: KeyboardEvent) => event.key === 'ArrowDown'
  const ArrowUp = (event: KeyboardEvent) => event.key === 'ArrowUp'
  const Tab = (event: KeyboardEvent) => !event.shiftKey && event.key === 'Tab'
  const ShiftTab = (event: KeyboardEvent) => event.shiftKey && event.key === 'Tab'

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (silent) {
        return
      }

      if (ArrowDown(event) || Tab(event)) {
        event.preventDefault()
        setCurrentFocus((prev) => (prev === size - 1 ? 0 : prev + 1))
      } else if (ArrowUp(event) || ShiftTab(event)) {
        event.preventDefault()
        setCurrentFocus((prev) => (prev <= 0 ? size - 1 : prev - 1))
      }
    },
    [size, silent],
  )

  useEffect(() => {
    const node = ref?.current as HTMLElement
    if (node) {
      node.addEventListener('keydown', handleKeyDown)

      return () => {
        node.removeEventListener('keydown', handleKeyDown)
      }
    }

    return () => null
  }, [ref?.current, handleKeyDown])

  return [currentFocus, setCurrentFocus]
}

export default useRoveFocus
