import React from 'react';

interface Props {
    readonly currency: string;
    readonly amount: number;
    readonly locale?: string;
}

const Currency: React.FC<Props> = (props: Props) => {
    const numberFormat = new Intl.NumberFormat(props.locale ?? 'en-GB', {
        style: 'currency',
        currency: props.currency,
    });
    const minorUnitDigits = numberFormat.resolvedOptions().maximumFractionDigits;
    const number = props.amount / Math.pow(10, minorUnitDigits);
    const formattedNumber = numberFormat.format(number);
    return (<>{formattedNumber}</>);
}

export default Currency;