import * as React from 'react'

export interface TableContextProps {
  size: 'small' | 'medium'
  stickyHeader: boolean
}

export const DefaultProps: TableContextProps = {
  size: 'small',
  stickyHeader: false,
}

const TableContext = React.createContext<TableContextProps>(DefaultProps)
TableContext.displayName = 'TableContext'

export default TableContext
