import DialogContent, { DialogContentPublicProps } from '../../DialogContent'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevDialogContent.styles'
import Customisations from './DevDialogContent.customisations'
import type { DialogContentContext } from '../../DialogContent.composition'
import type { DialogContentCustomisations } from './customisations'

export const DevDialogContent = withCustomisedStyle<
  DialogContentPublicProps,
  DialogContentContext,
  DialogContentCustomisations
>(DialogContent, Theme, Styles, Customisations, 'DialogContent')

export type DevDialogContentCustomisations = DialogContentCustomisations
export type DevDialogContentProps = DialogContentPublicProps & DialogContentCustomisations
