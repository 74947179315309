import * as React from 'react'
import { includes } from '../utils/lodash'

const useControlled = ({
  value,
  default: defaultProp,
  name,
  state = 'value',
}: {
  value: any
  default: any
  name: string
  state?: string
}) => {
  const { current: isControlled } = React.useRef(value != null)
  const [valueState, setValue] = React.useState(defaultProp)
  const currentValue = isControlled ? value : valueState

  const executeChecks = !includes(['production', 'test'], process.env.NODE_ENV)

  React.useEffect(() => {
    if (!executeChecks) {
      return
    }

    if (isControlled !== (value !== undefined)) {
      console.error(
        [
          `Prisma: A component is changing the ${
            isControlled ? '' : 'un'
          }controlled ${state} state of ${name} to be ${isControlled ? 'un' : ''}controlled.`,
          'Elements should not switch from uncontrolled to controlled (or vice versa).',
          `Decide between using a controlled or uncontrolled ${name} ` +
            'element for the lifetime of the component.',
          "The nature of the state is determined during the first render, it's considered controlled if the value is not `undefined`.",
          'More info: https://fb.me/react-controlled-components',
        ].join('\n'),
      )
    }
  }, [executeChecks, value])

  const { current: defaultValue } = React.useRef(defaultProp)

  React.useEffect(() => {
    if (!executeChecks) {
      return
    }

    if (defaultValue !== defaultProp) {
      console.error(
        [
          `Prisma: A component is changing the default ${state} state of an uncontrolled ${name} after being initialized. ` +
            `To suppress this warning opt to use a controlled ${name}.`,
        ].join('\n'),
      )
    }
  }, [executeChecks, JSON.stringify(defaultProp)])

  const setValueIfUncontrolled = React.useCallback((newValue) => {
    if (!isControlled) {
      setValue(newValue)
    }
  }, [])

  return [currentValue, setValueIfUncontrolled, isControlled]
}

export default useControlled
