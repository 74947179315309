import * as theme from '../../../../themes/Developer/proxima-nova'
import type { LoaderStyleProps } from '../DevLoader.styles'

/** Colour */
export const getColour = (props: LoaderStyleProps) =>
  theme.getColour(props, props.theme.colours.tints.lightGrey[100], theme.getState(props.styleProps))

/** Spinner */
export const getSpinnerColour = (props: LoaderStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Spinner'),
    props.theme.colours.tints.azure[100],
    theme.getState(props.styleProps),
  )
