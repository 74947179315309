import type { TooltipStyleProps } from './DevTooltip.styles'
import type { TooltipPublicProps } from '../../Tooltip'
import { TooltipCustomisations } from './customisations'
import type { FontAwesomeIconCustomisations as IconCustomisations } from '../../../Icon/components/FontAwesomeIcon/themes/Developer/customisations'

export const Customisations = {
  Icon: (props: TooltipStyleProps): IconCustomisations => props.customisations.Icon!,
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: TooltipPublicProps & TooltipCustomisations): TooltipCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
  border: props.border,
  fontSize: props.fontSize,
  Icon: props.Icon || {},
})
