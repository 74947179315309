import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import { getTestId } from '../../../utils/test'
import type { IconDefinition } from '../../../utils/fontawesome'
import type { StyledProps } from '../../../providers'
import type { FontAwesomeIconContext } from './FontAwesomeIcon.composition'
import type { IconPublicProps } from '../../Icon'

import { omit } from '../../../utils/lodash'

type IconPublicWithoutSrcProps = Omit<IconPublicProps, 'src'>

export interface FontAwesomeIconPublicProps extends IconPublicWithoutSrcProps {
  /**
   * Icon to display
   */
  src: IconDefinition
}

type FontAwesomeIconProps = FontAwesomeIconPublicProps & StyledProps<FontAwesomeIconContext>

export interface FontAwesomeIconCoreStyle {
  size: string | number
  readOnly: boolean
}

const FontAwesomeIcon = React.forwardRef(
  (props: FontAwesomeIconProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): FontAwesomeIconCoreStyle => ({
      size: props.size!,
      readOnly: props.readOnly,
    })

    const renderFontAwesomeIcon = () => {
      const { FontAwesomeIcon: FontAwesomeIconCore } = props.styled!
      return (
        <FontAwesomeIconCore
          icon={props.src}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'image')}
        />
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        {...omit(props, 'src')}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderFontAwesomeIcon()}
      </Root>
    )
  },
)

FontAwesomeIcon.defaultProps = {
  size: 'sm',
  readOnly: false,
}

export default FontAwesomeIcon
