import * as theme from '../../../../themes/Developer/proxima-nova'
import type { PaperStyleProps } from '../DevPaper.styles'

/** Colour */
export const getColour = (props: PaperStyleProps) =>
  theme.getColour(
    props,
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )
