import * as React from 'react'
import type { StyledProps } from '../providers'
import type { ListContext } from './List.composition'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'

export interface ListPublicProps {
  /**
   * The content of the subheader, normally `ListSubheader`.
   */
  subheader?: React.ReactNode
  /**
   * List of items to display
   */
  children?: React.ReactNode
}

export interface ListInternalProps {
  as?: string
  styleProps?: any
}

type ListProps = ListPublicProps & ListInternalProps & StyledProps<ListContext>

const List = React.forwardRef((props: ListProps, forwardRef: React.Ref<HTMLUListElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const { Root } = props.styled!
  return (
    <Root
      className={props.className}
      as={props.as as never}
      ref={ref}
      styleProps={props.styleProps}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {props.subheader}
      {props.children}
    </Root>
  )
})

List.defaultProps = {
  as: 'ul',
}

export default List
