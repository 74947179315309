import * as React from 'react'

import { flatten, forEach } from './utils/lodash'

let lastId = 0

export const newId = (prefix = 'id') => {
  lastId += 1
  return `${prefix}${lastId}`
}

export function getChildren(children: any, type: any) {
  return React.Children.map(children, (child: any) => {
    const currentType: string | undefined = child.type
      ? child.type.displayName || child.type
      : undefined
    if (currentType === type) {
      return child
    }

    const result: any[] = []
    if (React.isValidElement(child)) {
      const properties: any = child.props
      forEach(getChildren(properties.children, type), (nestedChild: any) => {
        result.push(nestedChild)
      })
    }

    return result
  })
}

export const convertHex = (hex: string, opacity: number) => {
  if (hex.startsWith('rgba')) {
    return hex
  }

  const value = hex.replace('#', '')
  const r = parseInt(value.substring(0, 2), 16)
  const g = parseInt(value.substring(2, 4), 16)
  const b = parseInt(value.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity / 100})`
}

export const getRootElement = (node?: any): HTMLElement | null => {
  if (node) {
    return (
      node.closest('[data-testid=playground]') ||
      node.closest('[id=root]') ||
      node.closest('[id=docz-root]') ||
      node.closest('[id=modal]')
    )
  }

  return (
    document.getElementById('root') ||
    document.getElementById('docz-root') ||
    document.getElementById('modal')
  )
}

export const getInnerHeight = (node?: any) => {
  const root = getRootElement(node)
  return root ? root.getBoundingClientRect().height : window.innerHeight
}

export const innerDimensions = (node: any) => {
  const computedStyle = getComputedStyle(node)

  let width = node.clientWidth // width with padding
  let height = node.clientHeight // height with padding

  height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom)
  width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight)
  return { height, width }
}

export const getWidthDimensions = (node: any) => {
  if (!node) {
    return undefined
  }

  const style = node.currentStyle || window.getComputedStyle(node)
  const width = node.offsetWidth // or use style.width
  const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight)
  const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)
  const border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth)

  return {
    width,
    margin,
    padding,
    border,
  }
}

export const getHeightDimensions = (node: any) => {
  if (!node) {
    return undefined
  }

  const style = node.currentStyle || window.getComputedStyle(node)
  const height = node.offsetHeight // or use style.width
  const margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom)
  const padding = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom)
  const border = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth)

  return {
    height,
    margin,
    padding,
    border,
  }
}

export const dimensions = (node: any) => {
  if (!node) {
    return undefined
  }

  const widthDimensions = getWidthDimensions(node)!
  const heightDimensions = getHeightDimensions(node)!

  return {
    width:
      widthDimensions.width +
      widthDimensions.margin +
      widthDimensions.padding +
      widthDimensions.border,
    height:
      heightDimensions.height +
      heightDimensions.margin +
      heightDimensions.padding +
      heightDimensions.border,
  }
}

export const isReactFragment = (component: any) => {
  if (component.type) {
    return component.type === React.Fragment
  }

  return component === React.Fragment
}

export const getChild = (children: any) => {
  const result = React.Children.toArray(children)
  const items = result.map((x: any) =>
    isReactFragment(x) ? React.Children.toArray(x.props.children) : x,
  )
  return flatten(items)
}

/**
 * Determine if the value is empty or not
 * @param value value to check
 * @returns {boolean} True if the value is not empty
 *                    False if the value is empty
 */
export const hasValue = (value: any) =>
  value != null && !(Array.isArray(value) && value.length === 0)

/**
 * Determine if field is empty or filled.
 * Response determines if label is presented above field or as placeholder.
 * @param obj
 * @param SSR
 * @returns {boolean} False when not present or empty string.
 *                    True when any number or string with length.
 */
export const isFilled = (obj: any, SSR = false) =>
  obj &&
  ((hasValue(obj.value) && obj.value !== '') ||
    (SSR && hasValue(obj.defaultValue) && obj.defaultValue !== ''))

export const isDefined = (value: any) => {
  return value !== undefined && value != null
}
