import * as React from 'react'
import { isLiveEnvironment } from './utils/environment'

export type ResponsiveContext = {
  /**
   * @ignore
   * Breakpoint point of the component. Possible values are 'xs', 'sm', 'md', 'lg' and 'xl'
   */
  device?: string
  /**
   * @ignore
   * List of breakpoints available. Possible values are 'xs', 'sm', 'md', 'lg' and 'xl'
   */
  breakpoints?: any
  /**
   * @ignore
   * List of breakpoints defining a short device. Possible values are 'xs', 'sm', 'md', 'lg' and 'xl'
   */
  shortBreakpoints?: any
}

export const Context = React.createContext<ResponsiveContext>({} as any)
if (!isLiveEnvironment()) {
  Context.displayName = 'ResponsiveContext'
}

export const ResponsiveConsumer = Context.Consumer

export const ResponsiveProvider = Context.Provider
