import * as React from 'react'
import defaultGetAriaLabel from './Pagination.utils'
import type { PaginationContext } from './Pagination.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import usePagination from '../hooks/usePagination'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

export interface PaginationPublicProps {
  /**
   * Number of always visible pages at the beginning and end.
   * @default 1
   */
  boundaryCount?: number
  /**
   * The total number of pages.
   * @default 1
   */
  count?: number
  /**
   * The page selected by default when the component is uncontrolled.
   * @default 1
   */
  defaultPage?: number
  /**
   * If `true`, the pagination component will be disabled.
   * @default false
   */
  disabled?: boolean
  /**
   * If `true`, hide the next-page button.
   * @default false
   */
  hideNextButton?: boolean
  /**
   * If `true`, hide the previous-page button.
   * @default false
   */
  hidePrevButton?: boolean
  /**
   * Callback fired when the page is changed.
   *
   * @param {object} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onChange?: (event: React.ChangeEvent, page: number) => void
  /**
   * The current page.
   */
  page?: number
  /**
   * The shape of the pagination items.
   * @default 'circular'
   */
  shape?: 'circular' | 'rounded'
  /**
   * If `true`, show the first-page button.
   * @default false
   */
  showFirstButton?: boolean
  /**
   * If `true`, show the last-page button.
   * @default false
   */
  showLastButton?: boolean
  /**
   * Number of always visible pages before and after the current page.
   * @default 1
   */
  siblingCount?: number
  /**
   * The size of the pagination component.
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * The variant to use.
   * @default 'text'
   */
  variant?: 'text' | 'outlined'
  /**
   * Render the item.
   *
   * @param {PaginationRenderItemParams} params The props to spread on a PaginationItem.
   * @returns {ReactNode}
   * @default (item) => <PaginationItem {...item} />
   */
  renderItem?: (item: any) => React.ReactNode
  /**
   * Accepts a function which returns a string value that provides a user-friendly name for the current page.
   *
   * For localization purposes, you can use the provided [translations](/guides/localization/).
   *
   * @param {string} type The link or button type to format ('page' | 'first' | 'last' | 'next' | 'previous'). Defaults to 'page'.
   * @param {number} page The page number to format.
   * @param {bool} selected If true, the current page is selected.
   * @returns {string}
   */
  getItemAriaLabel?: (type: string, page: number, selected: boolean) => string
}

export type PaginationProps = PaginationPublicProps & StyledProps<PaginationContext>

export const Pagination = React.forwardRef(
  (props: PaginationProps, forwardRef: React.Ref<HTMLElement>) => {
    const { ref } = useResponsive<HTMLElement>({ ref: forwardRef })

    const { items } = usePagination({ ...props, componentName: 'Pagination' })

    const getCoreStyle = (): unknown => ({})

    const renderItemContent = (item: any, index: number) => {
      const { getItemAriaLabel = defaultGetAriaLabel } = props

      if (props.renderItem) {
        return props.renderItem({
          ...item,
          'aria-label': getItemAriaLabel(item.type, item.page, item.selected),
          shape: props.shape,
          size: props.size,
          variant: props.variant,
        })
      }

      const { ItemContent } = props.styled!
      return (
        <ItemContent
          {...item}
          aria-label={getItemAriaLabel(item.type, item.page, item.selected)}
          shape={props.shape}
          size={props.size}
          variant={props.variant}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, `item-${index + 1}`)}
        />
      )
    }

    const renderItem = (item: any, index: number) => {
      const { Item } = props.styled!
      return (
        <Item
          key={`pagination-item-${index + 1}`}
          role="listitem"
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, `list-item-${index + 1}`)}
        >
          {renderItemContent(item, index)}
        </Item>
      )
    }

    const renderItems = () => {
      const { Items } = props.styled!
      return (
        <Items
          role="list"
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'list')}
        >
          {items.map(renderItem)}
        </Items>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        aria-label="pagination navigation"
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderItems()}
      </Root>
    )
  },
)

Pagination.defaultProps = {
  boundaryCount: 1,
  count: 1,
  defaultPage: 1,
  disabled: false,
  hideNextButton: false,
  hidePrevButton: false,
  shape: 'rounded',
  showFirstButton: false,
  showLastButton: false,
  siblingCount: 1,
  size: 'medium',
  variant: 'text',
}

export default Pagination
