import { ThemeProps } from '../../themed-component'

import { FontSize, FontWeight, LetterSpacing, LineHeight, TextAlign } from '../models/typography'

import {
  CmFontSize,
  CmFontWeight,
  CmLetterSpacing,
  CmLineHeight,
  CmTextAlign,
} from '../customisations/typography'

import { Customisations } from '../../common/models'

export function getFontSize(
  props: Customisations<CmFontSize, ThemeProps>,
  defaultValue?: keyof FontSize,
) {
  const fontSize = props.customisations.fontSize || defaultValue || 'bodyM'
  // TODO: Why px and not rem?
  return `${props.theme.typography.proximaNova.fontSize[fontSize].px}px`
}

export function getFontWeight(
  props: Customisations<CmFontWeight, ThemeProps>,
  defaultValue?: keyof FontWeight,
) {
  const fontWeight = props.customisations.fontWeight || defaultValue || 'regular'
  return props.theme.typography.proximaNova.fontWeight[fontWeight]
}

export function getLetterSpacing(
  props: Customisations<CmLetterSpacing, ThemeProps>,
  defaultValue?: LetterSpacing,
) {
  return props.customisations.letterSpacing || defaultValue || '1.3px'
}

export function getLineHeight(
  props: Customisations<CmLineHeight, ThemeProps>,
  defaultValue?: keyof LineHeight,
) {
  const lineHeight = props.customisations.lineHeight || defaultValue || 'bodyXs'
  return props.theme.typography.proximaNova.lineHeight[lineHeight]
}

/** Text Align */
export const getTextAlign = (
  props: Customisations<CmTextAlign, ThemeProps>,
  defaultValue?: TextAlign,
) => props.customisations.textAlign || defaultValue || 'left'
