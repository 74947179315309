import styled, { css } from 'styled-components'
import type { ModalCoreStyle } from './Modal'
import type { ModalContext } from './Modal.composition'
import type { StyleProps } from '../types'

export type ModalStyleProps = StyleProps<ModalCoreStyle>

const Hidden = css<ModalStyleProps>`
  visibility: hidden;
`

const Root = styled.div<ModalStyleProps>`
  z-index: 1000;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  outline: 0;

  /** State */
  ${(props) => !props.styleProps.open && Hidden}
`

const Components: ModalContext = {
  Root,
}

export default Components
