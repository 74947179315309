import * as React from 'react'

export interface MenuContextProps {
  variant: 'dropdown' | 'fly-out' | 'accordion' | 'tab'
  adjustSize?: () => void
}

const MenuContext = React.createContext<MenuContextProps>({
  variant: 'dropdown',
})

MenuContext.displayName = 'MenuContext'

export default MenuContext
