import * as React from 'react'

import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'
import { includes } from '../utils/lodash'

import { GridContext } from './Grid.context'
import { ContainerContent, getStyle, Root } from './Grid.styles'

export interface GridPublicProps {
  className?: string
  /**
   * True if the component represent a Grid container, false for an Grid item
   */
  container?: boolean
  /**
   * True if the next items will start at the next line, false otherwise
   */
  break?: boolean
  /**
   * Spacing of the Grid
   */
  spacing?: number
}

export interface GridContainerPublicProps {
  /**
   * Direction of the cells in the row
   */
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  /**
   * Main alignment of the cells in the row
   */
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
  /**
   * Secondary alignment of the container's cells in the row
   */
  alignContent?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
  /**
   * Secondary alignment of the cells in the row
   */
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
  /**
   * True if the child are not wrapped when reaching the width of the parent, false otherwise
   */
  noWrap?: boolean
  /**
   *
   */
  overflow?: boolean
  /**
   *
   */
  height?: string | number
  /**
   * Grid Item to display
   */
  children?: React.ReactNode
}

export interface GridItemPublicProps {
  /**
   * Secondary alignment of the cells in the row
   */
  alignSelf?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
  /**
   * Number of column a cell takes when its current breakpoint is xs
   */
  xs?: number | boolean
  /**
   * Number of column a cell takes when its current breakpoint is sm
   */
  sm?: number | boolean
  /**
   * Number of column a cell takes when its current breakpoint is md
   */
  md?: number | boolean
  /**
   * Number of column a cell takes when its current breakpoint is lg
   */
  lg?: number | boolean
  /**
   * Number of column a cell takes when its current breakpoint is xl
   */
  xl?: number | boolean
  /**
   * Number of offset column a cell takes when its current breakpoint is xs
   */
  xsOffset?: number
  /**
   * Number of offset column a cell takes when its current breakpoint is sm
   */
  smOffset?: number
  /**
   * Number of offset column a cell takes when its current breakpoint is sm
   */
  mdOffset?: number
  /**
   * Number of offset column a cell takes when its current breakpoint is md
   */
  lgOffset?: number
  /**
   * Number of offset column a cell takes when its current breakpoint is xl
   */
  xlOffset?: number
  /**
   * Direction of the offset columns
   */
  offsetDirection?: 'left' | 'right'
  /**
   * Type of the offset columns
   */
  offsetType?: 'numberOfColumn' | 'free'
  /**
   * True if the content has to fit the cell, false otherwise
   */
  fullWidth?: boolean
  /**
   * Component to display
   */
  children?: React.ReactNode
}

export type GridItemProps = GridPublicProps & GridItemPublicProps

export type GridContainerProps = GridPublicProps & GridContainerPublicProps

export type GridProps = GridItemProps | GridContainerProps

const Grid = React.forwardRef((props: GridProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref, device } = useResponsive({ ref: forwardRef })

  const containerRef = React.useRef(null)
  const context = React.useContext(GridContext)

  const [containerSize, setContainerSize] = React.useState()
  const [nestedGrid, setNestedGrid] = React.useState(false)
  const [noWrapped, setNoWrapped] = React.useState(false)

  if (props.container) {
    // Check if the grid container is a nested one or not
    React.useEffect(() => {
      const rootElement: any = ref.current
      if (!rootElement) {
        return
      }

      const closestContainer = rootElement?.parentNode.closest("[data-type='container']")
      setNestedGrid(closestContainer != null)

      const closestWrapContainer = rootElement?.parentNode.closest("[data-nowrap='true']")
      let defaultNoWrap = (props as GridContainerPublicProps).noWrap
      if (closestContainer && defaultNoWrap == null) {
        defaultNoWrap = context?.noWrap || false
      }
      setNoWrapped(defaultNoWrap)
    }, [ref.current])

    React.useEffect(() => {
      const element = containerRef?.current
      if (!element) {
        return
      }

      const { direction } = props as GridContainerProps
      if (includes(['row', 'row-reverse'], direction)) {
        return
      }

      if (element.clientHeight === containerSize) {
        return
      }

      setContainerSize(element.clientHeight)
    }, [containerRef?.current])
  }

  const renderItem = (child: any, index: number) => {
    return React.cloneElement(child, {
      ...child.props,
      spacing: props.spacing,
    })
  }

  const renderContainer = () => {
    const children = React.Children.toArray(props.children)
    return (
      props.container && (
        <GridContext.Provider
          value={Object.freeze({
            direction: (props as GridContainerPublicProps).direction,
            alignItems: (props as GridContainerPublicProps).alignItems,
            noWrap: noWrapped,
            overflow: (props as GridContainerPublicProps).overflow,
            containerSize,
          })}
        >
          <ContainerContent
            ref={containerRef}
            data-nowrap={noWrapped}
            data-size={containerSize}
            styleProps={getStyle(props, { device, nestedGrid, containerSize, noWrap: noWrapped })}
            customisations={{}}
            {...getTestId(props, 'container')}
          >
            {children.map(renderItem)}
          </ContainerContent>
        </GridContext.Provider>
      )
    )
  }

  const renderCell = () => {
    return (
      !props.container && <GridContext.Provider value={null}>{props.children}</GridContext.Provider>
    )
  }
  const gridExtra = props.container
    ? { nestedGrid, noWrap: noWrapped }
    : { containerHeight: containerRef.current?.clientHeight }

  return (
    <Root
      ref={ref}
      data-type={props.container ? 'container' : 'item'}
      className={props.className}
      styleProps={getStyle(props, { device, ...gridExtra })}
      customisations={{}}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {renderContainer()}
      {renderCell()}
    </Root>
  )
})

Grid.defaultProps = {
  direction: 'row',
  justify: 'flex-start',
  alignItems: 'flex-start',
  spacing: 10,
  offsetDirection: 'right',
  offsetType: 'numberOfColumn',
  overflow: false,
  height: 'inherit',
}

export default Grid
