import NativeCheckboxGroup, { NativeCheckboxGroupPublicProps } from '../../NativeCheckboxGroup'
import Theme from '../../../../../themes/Developer/theme'
import { CheckboxGroupContext } from '../../../../CheckboxGroup.composition'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevNativeCheckboxGroup.styles'
import Customisations, {
  DevNativeCheckboxGroupCustomisations as NativeCheckboxGroupCustomisations,
} from './DevNativeCheckboxGroup.customisations'

export const DevNativeCheckboxGroup = withCustomisedStyle<
  NativeCheckboxGroupPublicProps,
  CheckboxGroupContext,
  NativeCheckboxGroupCustomisations
>(NativeCheckboxGroup, Theme, Styles, Customisations, 'NativeCheckboxGroup')

export type DevNativeCheckboxGroupCustomisations = NativeCheckboxGroupCustomisations
export type DevNativeCheckboxGroupProps = NativeCheckboxGroupPublicProps &
  NativeCheckboxGroupCustomisations
