import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { StepCoreStyle } from '../../Step'
import type { StepContext } from '../../Step.composition'
import type { StyleProps } from '../../../../../types'

export type StepStyleProps = StyleProps<StepCoreStyle> & ThemeProps

const AlternativeLabel = css<StepStyleProps>`
  flex: 1;
  position: relative;
`

const HorizontalRoot = css<StepStyleProps>`
  padding: 0px ${(props) => props.theme.spaces.xs};
`

const Root = styled.div<StepStyleProps>`
  ${(props) => props.styleProps.orientation === 'horizontal' && HorizontalRoot};
  ${(props) => props.styleProps.alternativeLabel && AlternativeLabel};
`

const Components: StepContext = {
  Root,
}

export default Components
