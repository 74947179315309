import type { StarsStyleProps } from '../DevStars.styles'

export const sizes = {
  small: 12,
  medium: 16,
  large: 20,
}

/** Label */
export const getLabelFontSize = (props: StarsStyleProps) => {
  return `${sizes[props.styleProps.size]}px`
}
