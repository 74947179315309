import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import type { FieldContext } from '../../Field.composition'
import type { FieldCoreStyle } from '../../Field'
import type { StyleProps } from '../../../types'
import { Customisations, DevFieldCustomisations } from './DevField.customisations'
import { DevFormHelperText } from '../../../FormHelperText'
import { DevFormControl } from '../../../FormControl'
import { DevFormValidityText } from '../../../FormValidityText'
import { DevFormLabel } from '../../../FormLabel'
import { DevFontAwesomeIcon } from '../../../Icon'
import { DevTooltip } from '../../../Tooltip'

export type FieldStyleProps = StyleProps<FieldCoreStyle, DevFieldCustomisations> & ThemeProps

const Tooltip = styled(DevTooltip).attrs(Customisations.Tooltip)<FieldStyleProps>`
  ${(props) =>
    props.styleProps.displayValidityStatus &&
    css`
      margin-right: ${props.theme.spaces.xs};
    `}
`

const ValidationStatus = styled(DevFontAwesomeIcon).attrs(
  Customisations.ValidityStatus,
)<FieldStyleProps>``

const Indicators = styled.div<FieldStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: ${(props) => props.theme.spaces.xxs};
`

const Error = styled(DevFormValidityText).attrs(Customisations.ValidityText)<FieldStyleProps>``

const Helper = styled(DevFormHelperText).attrs(Customisations.HelperText)<FieldStyleProps>``

const Label = styled(DevFormLabel).attrs(Customisations.Label)<FieldStyleProps>``

const Input = styled.div<FieldStyleProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Root = styled(DevFormControl)<FieldStyleProps>``

const Components: FieldContext = {
  Root,
  Input,
  Label,
  Helper,
  Error,
  Indicators,
  ValidationStatus,
  Tooltip,
}

export default Components as FieldContext
