import type { LinkPublicProps } from '../../Link'
import type { LinkStyleProps } from './DevLink.styles'
import { getFontSize, getIconColour, LinkCustomisations } from './customisations'

export const Customisations = {
  Icon: (props: LinkStyleProps) => ({
    colour: getIconColour(props),
    hoverColour: getIconColour(props),
    fontSize: getFontSize(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: LinkPublicProps & LinkCustomisations): LinkCustomisations => ({
  colour: props.colour,
  hoverColour: props.hoverColour,
  fontSize: props.fontSize,
  border: props.border,
  Icon: props.Icon || {},
})
