import { ListItemStaticPublicProps } from '../../ListStaticItem'
import { ListItemStaticCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: ListItemStaticPublicProps & ListItemStaticCustomisations,
): ListItemStaticCustomisations => ({
  colour: props.colour,
  padding: props.padding,
})
