import { TableCellStyleProps } from '../DevTableCell.styles'
import * as theme from '../../../../../../themes/Developer/proxima-nova'

export const fontSizes: { [k: string]: keyof theme.FontSize } = {
  small: 'bodyS',
  medium: 'bodyS',
  large: 'bodyM',
}

/** Font size  */
export const getFontSize = (props: TableCellStyleProps) =>
  theme.getFontSize(props, fontSizes[props.styleProps.size])
