import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import type { FormValidityTextCoreStyle } from '../../FormValidityText'
import type { FormValidityTextContext } from '../../FormValidityText.composition'
import {
  FormValidityTextCustomisations,
  getColour,
  getFontSize,
  getPaddingLeft,
} from './customisations'
import { StyleProps } from '../../../types'
import * as themeStyles from '../../../themes/Developer/styles'

export type FormValidityTextStyleProps = StyleProps<
  FormValidityTextCoreStyle,
  FormValidityTextCustomisations
> &
  ThemeProps

const Root = styled.p<FormValidityTextStyleProps>`
  display: block;
  text-align: start;
  pointer-events: none;
  margin: 0;

  /* Colour */
  color: ${(props) => getColour(props)};

  /* Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) => getFontSize(props)};
`

const Components: FormValidityTextContext = {
  Root,
}

export default Components
