import { getScrollButtonColour, TabsCustomisations } from './customisations'
import type { TabsPublicProps } from '../../Tabs'
import type { TabsStyleProps } from './DevTabs.styles'

export const Customisations = {
  ScrollButton: (props: TabsStyleProps) => ({
    colour: getScrollButtonColour(props),
    hoverColour: getScrollButtonColour(props),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: TabsPublicProps & TabsCustomisations): TabsCustomisations => ({
  Indicator: props.Indicator || {},
  Tab: props.Tab || {},
  ScrollButton: props.ScrollButton || {},
  MoreButton: props.MoreButton || {},
})
