import Input, { InputPublicProps } from '../../Input'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevInput.styles'
import Customisations from './DevInput.customisations'
import type { InputContext } from '../../Input.composition'
import type { InputCustomisations } from './customisations/models'

export const DevInput = withCustomisedStyle<InputPublicProps, InputContext, InputCustomisations>(
  Input,
  Theme,
  Styles,
  Customisations,
  'Input',
)

export type DevInputCustomisations = InputCustomisations
export type DevInputProps = InputPublicProps & InputCustomisations
