import type { FontAwesomeIconStyleProps } from '../DevFontAwesomeIcon.styles'
import * as theme from '../../../../../../themes/Developer/proxima-nova'

/* Border */
export const getBorder = (props: FontAwesomeIconStyleProps) => {
  return theme.getBorder(props, 'none', 'border')
}

export const getBorderRadius = (props: FontAwesomeIconStyleProps, defaultValue?: string | number) =>
  theme.getBorderRadius(props, theme.getValueAsPixel(defaultValue) || 0)
