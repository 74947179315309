import type { NativeCheckboxGroupPublicProps } from '../../NativeCheckboxGroup'
import type { NativeCheckboxCustomisations } from '../../../../../Checkbox/components/NativeCheckbox/themes/Developer/customisations'

export type DevNativeCheckboxGroupCustomisations = NativeCheckboxCustomisations

export default (
  props: NativeCheckboxGroupPublicProps & DevNativeCheckboxGroupCustomisations,
): DevNativeCheckboxGroupCustomisations => ({
  Label: props.Label || {},
  Tick: props.Tick || {},
})
