import type { SwitchGroupPublicProps } from '../../SwitchGroup'
import type { SwitchCustomisations } from '../../../Switch/themes/Developer/customisations'

export type DevSwitchGroupCustomisations = SwitchCustomisations

export default (
  props: SwitchGroupPublicProps & DevSwitchGroupCustomisations,
): DevSwitchGroupCustomisations => ({
  Label: props.Label || {},
  Track: props.Track || {},
  Thumb: props.Thumb || {},
})
