import styled, { css, ThemeProps } from '../../../../../../../themes/Developer/themed-component'
import type { ListItemContext } from '../../../../ListItem.composition'
import type { ListInteractiveItemCoreStyle } from '../../ListInteractiveItem'
import type { StyleProps } from '../../../../../../../types'
import { DisabledState } from '../../../../../../../themes/Developer/styles'
import { getValueAsPixel } from '../../../../../../../customisations'
import {
  getBgColour,
  getColour,
  getPadding,
  ListInteractiveItemCustomisations,
} from './customisations'
import * as themeStyles from '../../../../../../../themes/Developer/styles'

export type ListInteractiveItemStyleProps = StyleProps<
  ListInteractiveItemCoreStyle,
  ListInteractiveItemCustomisations
> &
  ThemeProps

const Focus = css<ListInteractiveItemStyleProps>`
  :focus {
    background-color: ${(props) => getBgColour(props, 'focus')};
    border-color: ${(props) => props.theme.colours.tints.azure[100]};
  }
`

const Hover = css<ListInteractiveItemStyleProps>`
  :hover {
    background-color: ${(props) => getBgColour(props, 'hover')};
  }
`

const Root = styled.li<ListInteractiveItemStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  list-style-type: none;
  cursor: pointer;
  outline: none;

  /** Color */
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  /** Visual */
  border: solid 1px transparent;
  line-height: ${(props) => getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyM.px)};

  /** Spacing */
  padding: ${(props) => getPadding(props)};

  /** State */
  ${Hover}
  ${Focus}
  ${(props) => props.styleProps.disabled && DisabledState}

  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: ListItemContext = {
  Root,
}

export default Components
