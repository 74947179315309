import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'

import type { DialogCoreStyle } from '../../DialogContent'
import type { DialogContentContext } from '../../DialogContent.composition'
import { StyleProps } from '../../../../../types'
import * as themeStyles from '../../../../../themes/Developer/styles'
import { getPadding, DialogContentCustomisations } from './customisations'

export type DialogContentStyleProps = StyleProps<DialogCoreStyle, DialogContentCustomisations> &
  ThemeProps

const Dividers = css<DialogContentStyleProps>`
  /** border-top */
  border-top-width: ${(props) => props.theme.visuals.borderSize};
  border-top-style: solid;
  border-top-color: ${(props) => props.theme.colours.tertiary.neutral.grey};

  /** border-bottom */
  border-bottom-width: ${(props) => props.theme.visuals.borderSize};
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.colours.tertiary.neutral.grey};
`

const Root = styled.div<DialogContentStyleProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: ${(props) => getPadding(props)};

  &:first-child {
    padding-top: 20px;
  }

  ${(props) => props.styleProps.dividers && Dividers};

  /** Typography */
  ${themeStyles.FontFamily}
`

const Components: DialogContentContext = {
  Root,
}

export default Components
