import Placeholder, { PlaceholderPublicProps } from '../../Placeholder'
import Theme from '../../../themes/Developer/theme'
import type { PlaceholderContext } from '../../Placeholder.composition'
import { withStyle } from '../../../providers'
import Styles from './DevPlaceholder.styles'

export const DevPlaceholder = withStyle<PlaceholderPublicProps, PlaceholderContext>(
  Placeholder,
  Theme,
  Styles,
  'Placeholder',
)

export type DevPlaceholderProps = PlaceholderPublicProps
