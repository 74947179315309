import * as theme from '../../../../../themes/Developer/proxima-nova'
import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { TabPanelContext } from '../../TabPanel.composition'
import type { StyleProps } from '../../../../../types'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type TabPanelStyleProps = StyleProps & ThemeProps

const Root = styled.div<TabPanelStyleProps>`
  /** Spacings */
  padding: ${(props) => props.theme.spaces.md};

  /** Typography */
  ${themeStyles.FontFamily}
  font-size: ${(props) =>
    theme.getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyS.px)};
`

const Components: TabPanelContext = {
  Root,
}

export default Components
