import * as theme from '../../../../../themes/Developer/proxima-nova'
import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../../../Icon'
import { Customisations } from './DevMenuGroup.customisations'
import type { MenuGroupCoreStyle } from '../../MenuGroup'
import type { MenuGroupContext } from '../../MenuGroup.composition'
import type { StyleProps } from '../../../../../types'
import { includes } from '../../../../../utils/lodash'
import {
  getContentColour,
  getContentBgColour,
  getContentBorder,
  getContentPadding,
  getHeaderBgColour,
  getHeaderBorder,
  getHeaderColour,
  getHeaderPadding,
  MenuGroupCustomisations,
} from './customisations'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type MenuGroupStyleProps = StyleProps<MenuGroupCoreStyle, MenuGroupCustomisations> &
  ThemeProps

const titleAsItem = (props: MenuGroupStyleProps) =>
  includes(['tab', 'fly-out', 'accordion'], props.styleProps.variant)

const BorderHeader = css<MenuGroupStyleProps>`
  border-left: ${(props) => getHeaderBorder(props, 'borderLeft')};
  border-top: ${(props) => getHeaderBorder(props, 'borderTop')};
  border-right: ${(props) => getHeaderBorder(props, 'borderRight')};
  border-bottom: ${(props) => getHeaderBorder(props, 'borderBottom')};
`

const BorderContent = css<MenuGroupStyleProps>`
  border-left: ${(props) => getContentBorder(props, 'borderLeft')};
  border-top: ${(props) => getContentBorder(props, 'borderTop')};
  border-right: ${(props) => getContentBorder(props, 'borderRight')};
  border-bottom: ${(props) => getContentBorder(props, 'borderRight')};
`

const VerticalContent = css<MenuGroupStyleProps>`
  top: 100%;
  left: 0;
`

const HorizontalContent = css<MenuGroupStyleProps>`
  left: 100% !important;
  top: 0 !important;
`

const FloatingContent = css<MenuGroupStyleProps>`
  position: absolute;
  min-width: 200px;
`

const DropDownRoot = (Components: MenuGroupContext) => css<MenuGroupStyleProps>`
  position: relative;

  ${Components.Header} {
    flex-grow: 1;
  }

  ${Components.Content} {
    ${FloatingContent}
    ${VerticalContent}
  }
`

const FlayOutRoot = (Components: MenuGroupContext) => css<MenuGroupStyleProps>`
  ${Components.Header} {
    display: inline-flex;
  }

  ${Components.Content} {
    height: 100%;
    ${FloatingContent}
    ${HorizontalContent}
  }
`

const AccordionRoot = (Components: MenuGroupContext) => css<MenuGroupStyleProps>``

const TabRoot = (Components: MenuGroupContext) => css<MenuGroupStyleProps>`
  ${Components.Content} {
    height: 100%;
    ${FloatingContent}
    ${HorizontalContent}

    ${Components.Title} {
      padding: ${(props) => props.theme.spaces.md} ${(props) => props.theme.spaces.sm};
    }
  }
`

const DisabledRoot = css<MenuGroupStyleProps>`
  user-select: none;
  opacity: ${(props) => props.theme.visuals.opacity.normal};
  pointer-events: none;
`

const Arrow = styled(DevFontAwesomeIcon).attrs(Customisations.Arrow)<MenuGroupStyleProps>``

const Title = styled.span<MenuGroupStyleProps>`
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  color: ${(props) => getHeaderColour(props)};

  ${(props) =>
    props.styleProps.startAdornment &&
    css`
      padding-left: ${props.theme.spaces.xs};
    `}

  ${(props) =>
    props.styleProps.endAdornment &&
    css`
      padding-right: ${props.theme.spaces.xs};
    `}
    
    ${(props) =>
    !titleAsItem(props) &&
    props.styleProps.root &&
    css`
      justify-content: center;
    `}
`

const Content = styled.ul<MenuGroupStyleProps>`
  margin: 0;
  padding: ${(props) => getContentPadding(props)};
  color: ${(props) => getContentColour(props)};
  background-color: ${(props) => getContentBgColour(props)};

  ${BorderContent};

  ${(props) =>
    props.styleProps.elapsed &&
    css`
      z-index: 100;
    `}

  ${(props) => props.styleProps.orientation === 'horizontal' && HorizontalContent};
  ${(props) => props.styleProps.orientation === 'vertical' && VerticalContent};

  /** Typography */
  ${themeStyles.FontFamily}
`

const Header = styled.button<MenuGroupStyleProps>`
  display: flex;
  align-items: center;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  border: none;
  padding: ${(props) => getHeaderPadding(props)};
  color: ${(props) => getHeaderColour(props)};
  background-color: ${(props) => getHeaderBgColour(props)};
  ${BorderHeader};

  ${Content} {
    flex-grow: 1;
  }

  /** Typography */
  ${themeStyles.FontFamily}
`

const Root = styled.li<MenuGroupStyleProps>`
  display: flex;
  flex-direction: column;
  font-size: ${(props) =>
    theme.getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyS.px)};
  box-sizing: border-box;

  ${(props) => props.styleProps.variant === 'dropdown' && DropDownRoot(Components)};
  ${(props) => props.styleProps.variant === 'fly-out' && FlayOutRoot(Components)};
  ${(props) => props.styleProps.variant === 'tab' && TabRoot(Components)};
  ${(props) => props.styleProps.variant === 'accordion' && AccordionRoot(Components)};

  ${(props) => props.styleProps.disabled && DisabledRoot};
`

const Components: MenuGroupContext = {
  Root,
  Header,
  Content,
  Title,
  Arrow,
}

export default Components
