import * as React from 'react'
import RadioGroup, { RadioGroupPublicProps } from '../../RadioGroup'
import type { RadioGroupContext } from '../../RadioGroup.composition'
import type { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'

export type NativeRadioGroupPublicProps = RadioGroupPublicProps

export interface NativeRadioGroupProps
  extends NativeRadioGroupPublicProps,
    StyledProps<RadioGroupContext> {}

const NativeRadioGroup = React.forwardRef(
  (props: NativeRadioGroupProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    // TODO: [Dev Only] check the children are NativeRadio

    return <RadioGroup className={props.className} ref={ref} {...props} />
  },
)

NativeRadioGroup.defaultProps = {
  spacing: 10,
}

export default NativeRadioGroup
