import ListItemIconCore, { ListItemIconPublicProps } from '../../../../ListItemIcon'
import Theme from '../../../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../../../providers'
import { ListItemIconContext } from '../../../../ListItemIcon.composition'
import Customisations from '../../../../themes/Developer/DevListItemIcon.customisations'
import { ListItemIconCustomisations } from '../../../../themes/Developer/customisations'
import Styles from '../../../../themes/Developer/DevListItemIcon.styles'

export const DevListInteractiveItemIcon = withCustomisedStyle<
  ListItemIconPublicProps,
  ListItemIconContext,
  ListItemIconCustomisations
>(ListItemIconCore, Theme, Styles, Customisations, 'ListInteractiveItemIcon')

export type DevListInteractiveItemIconCustomisations = ListItemIconCustomisations
export type DevListInteractiveItemIconProps = ListItemIconPublicProps & ListItemIconCustomisations
