import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import type { MenuCoreStyle } from '../../Menu'
import type { MenuContext } from '../../Menu.composition'
import type { StyleProps } from '../../../types'
import { getBgColour, getBorderRight, MenuCustomisations } from './customisations'
import { includes } from '../../../utils/lodash'
import * as themeStyles from '../../../themes/Developer/styles'

export type MenuStyleProps = StyleProps<MenuCoreStyle, MenuCustomisations> & ThemeProps

const verticalRoot = (props: MenuStyleProps) =>
  includes(['fly-out', 'accordion'], props.styleProps.variant)

const FillRoot = css<MenuStyleProps>`
  width: 100%;

  & > * {
    flex-grow: 1;
    flex-shrink: 0;
    width: ${(props) => `${100 / props.styleProps.count}%`};
  }
`

const DropDownItems = css<MenuStyleProps>`
  flex-direction: row;
  width: fit-content;

  ${(props) => props.styleProps.fitContainer && FillRoot};
`

const FlayOutItems = css<MenuStyleProps>`
  flex-direction: column;
`

const AccordionItems = css<MenuStyleProps>`
  flex-direction: column;
`

const TabItems = css<MenuStyleProps>`
  flex-direction: column;
`

const HorizontalRoot = css<MenuStyleProps>`
  flex-direction: row;
`

const VerticalRoot = css<MenuStyleProps>`
  flex-direction: column;
`

const DropDownRoot = (Components: MenuContext) => css<MenuStyleProps>`
  ${(props) =>
    props.styleProps.header &&
    props.styleProps.footer &&
    css`
      ${Components.Items} {
        flex-grow: 1;
      }
    `}

  ${(props) =>
    props.styleProps.header &&
    !props.styleProps.footer &&
    css`
      ${Components.Header} {
        flex-grow: 1;
      }
    `}

    ${(props) =>
    !props.styleProps.header &&
    props.styleProps.footer &&
    css`
      ${Components.Items} {
        flex-grow: 1;
      }
    `}
`

const FlayOutRoot = (Components: MenuContext) => css<MenuStyleProps>`
  position: relative;
  border-right: ${(props) => getBorderRight(props)};

  ${Components.Items} {
    position: unset;
  }

  /** Typography */
  ${themeStyles.FontFamily}
`

const AccordionRoot = css<MenuStyleProps>`
  border-right: ${(props) => getBorderRight(props)};
`

const TabRoot = css<MenuStyleProps>`
  border-right: ${(props) => getBorderRight(props)};

  /** Typography */
  ${themeStyles.FontFamily}
`

const Items = styled.ul<MenuStyleProps>`
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;

  ${(props) => props.styleProps.variant === 'dropdown' && DropDownItems};
  ${(props) => props.styleProps.variant === 'fly-out' && FlayOutItems};
  ${(props) => props.styleProps.variant === 'accordion' && AccordionItems};
  ${(props) => props.styleProps.variant === 'tab' && TabItems};
`

const Header = styled.div<MenuStyleProps>``

const Footer = styled.div<MenuStyleProps>``

const Root = styled.div<MenuStyleProps>`
  display: flex;
  flex-shrink: 0;
  background-color: ${(props) => getBgColour(props)};

  ${(props) => verticalRoot(props) && VerticalRoot};
  ${(props) => !verticalRoot(props) && HorizontalRoot};

  ${(props) => props.styleProps.variant === 'dropdown' && DropDownRoot(Components)};
  ${(props) => props.styleProps.variant === 'fly-out' && FlayOutRoot(Components)};
  ${(props) => props.styleProps.variant === 'accordion' && AccordionRoot};
  ${(props) => props.styleProps.variant === 'tab' && TabRoot};
`

const Components: MenuContext = {
  Root,
  Header,
  Items,
  Footer,
}

export default Components
