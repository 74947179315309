import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import type { PlaceholderContext } from '../../Placeholder.composition'
import type { StyleProps } from '../../../types'

export type PlaceholderStyleProps = StyleProps & ThemeProps

const Root = styled.img<PlaceholderStyleProps>`
  height: inherit;
`

const Components: PlaceholderContext = {
  Root,
}

export default Components
