import * as React from 'react'
import type { SimpleBackdropContext } from './SimpleBackdrop.composition'
import { StyledProps } from '../../../providers'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'

export interface SimpleBackdropPublicProps {
  /**
   * If `true`, the backdrop is open.
   */
  open: boolean
  /**
   * If `true`, the backdrop is invisible.
   * It can be used when rendering a popover or a custom select component.
   */
  invisible?: boolean
  /**
   *
   */
  onClick?: (event: React.MouseEvent) => void
}

export type SimpleBackdropProps = SimpleBackdropPublicProps & StyledProps<SimpleBackdropContext>

export interface SimpleBackdropCoreStyle {
  invisible: boolean
}

const SimpleBackdrop = React.forwardRef(
  (props: SimpleBackdropProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): SimpleBackdropCoreStyle => ({
      invisible: props.invisible!,
    })

    const { Root } = props.styled!
    return props.open ? (
      <Root
        ref={ref}
        className={props.className}
        aria-hidden
        onClick={props.onClick}
        styleProps={getCoreStyle()}
        customisations={{}}
        {...getAttributes(props, DataAttributesPrefix)}
      />
    ) : null
  },
)

SimpleBackdrop.defaultProps = {
  invisible: false,
}

export default SimpleBackdrop
