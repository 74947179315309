import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import { DevFontAwesomeIcon } from '../../../../../Icon'
import type { PaginationItemCoreStyle } from '../../PaginationItem'
import type { StyleProps } from '../../../../../types'
import { PaginationItemContext } from '../../PaginationItem.composition'
import {
  getColour,
  getBgColour,
  getBorderColour,
  PaginationItemCustomisations,
} from './customisations'
import { DisabledState, ReadOnlyState } from '../../../../../themes/Developer/styles'
import * as themeStyles from '../../../../../themes/Developer/styles'

export type PaginationItemStyleProps = StyleProps<
  PaginationItemCoreStyle,
  PaginationItemCustomisations
> &
  ThemeProps

const CircularRoot = css<PaginationItemStyleProps>``

const RoundedRoot = css<PaginationItemStyleProps>`
  border-radius: ${(props) => props.theme.visuals.borderRadius};
`

const OutlinedRoot = css<PaginationItemStyleProps>`
  border: ${(props) => props.theme.visuals.borderSize} solid ${(props) => getBorderColour(props)};
`

const SmallRoot = css<PaginationItemStyleProps>`
  min-width: 26px;
  height: 26px;
  border-radius: calc(26px / 2);
  padding: 0px 4px;
  margin: 0px 1px;
`

const MediumRoot = css<PaginationItemStyleProps>`
  min-width: 32px;
  height: 32px;
  border-radius: calc(32px / 2);
  padding: 0px 6px;
  margin: 0px 3px;
`

const LargeRoot = css<PaginationItemStyleProps>`
  min-width: 40px;
  height: 40px;
  border-radius: calc(40px / 2);
  padding: 0px ${(props) => props.theme.spaces.xs};
  margin: 0px 5px;
`

const Icon = styled(DevFontAwesomeIcon)<PaginationItemStyleProps>`
  cursor: inherit;
`

const Ellipsis = styled.div<PaginationItemStyleProps>``

const Page = styled.div<PaginationItemStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Root = styled.button<PaginationItemStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  border: none;
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};
  outline: none;
  cursor: pointer;

  /** Typography */
  ${themeStyles.FontFamily}

  ${(props) => props.styleProps.size === 'small' && SmallRoot};
  ${(props) => props.styleProps.size === 'medium' && MediumRoot};
  ${(props) => props.styleProps.size === 'large' && LargeRoot};

  ${(props) => props.styleProps.variant === 'outlined' && OutlinedRoot};

  ${(props) => props.styleProps.shape === 'circular' && CircularRoot};
  ${(props) => props.styleProps.shape === 'rounded' && RoundedRoot};

  ${(props) => props.styleProps.disabled && DisabledState};
  ${(props) => props.styleProps.readOnly && ReadOnlyState};
`

const Components: PaginationItemContext = {
  Root,
  Page,
  Ellipsis,
  Icon,
}

export default Components
