import Popover, { PopoverPublicProps } from '../../Popover'
import Theme from '../../../themes/Developer/theme'
import Styles from './DevPopover.styles'
import type { PopoverContext } from '../../Popover.composition'
import { withStyle } from '../../../providers'

export const DevPopover = withStyle<PopoverPublicProps, PopoverContext>(
  Popover,
  Theme,
  Styles,
  'Popover',
)

export type DevPopoverProps = PopoverPublicProps
