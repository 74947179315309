import * as React from 'react'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'
import type { TitleContext } from './Title.composition'
import type { StyledProps } from '../providers'

export interface TitlePublicProps {
  /**
   * Title to display
   */
  title?: string
  /**
   * Type of the title
   */
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  /**
   * Component used as title
   */
  children?: React.ReactNode
}

export interface TitleCoreStyle {
  type: string
}

export interface TitleProps extends TitlePublicProps, StyledProps<TitleContext> {}

const Title = React.forwardRef((props: TitleProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const getCoreStyle = (): TitleCoreStyle => ({
    type: props.type!,
  })

  const title = props.title?.split('\n').map((item, index) => (
    <span key={`title-${index + 1}`} {...getTestId(props, `content-${index + 1}`)}>
      {item}
      <br />
    </span>
  ))

  const { Root } = props.styled!
  return (
    <Root
      ref={ref}
      as={props.type}
      className={props.className}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {title || props.children}
    </Root>
  )
})

Title.defaultProps = {
  type: 'h1',
}

export default Title
