import type { BannerPublicProps } from '../../Banner'
import type { BannerStyleProps } from './DevBanner.styles'
import { getBorderColour, BannerCustomisations } from './customisations'

export const Customisations = {
  Icon: (props: BannerStyleProps) => ({
    colour: getBorderColour(props).colour,
    hoverColour: getBorderColour(props).colour,
  }),
  CrossIcon: (props: BannerStyleProps) => ({
    colour: props.theme.colours.tints.lightGrey[150],
    hoverColour: props.theme.colours.tints.lightGrey[150],
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (props: BannerPublicProps & BannerCustomisations): BannerCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
  border: props.border,
})
