import BreadCrumbs, { BreadCrumbsPublicProps } from '../../BreadCrumbs'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevBreadCrumbs.styles'
import Customisations from './DevBreadCrumbs.customisations'

// types
import type { BreadCrumbsCustomisations } from './customisations'
import type { BreadCrumbsContext } from '../../BreadCrumbs.composition'

export const DevBreadCrumbs = withCustomisedStyle<
  BreadCrumbsPublicProps,
  BreadCrumbsContext,
  BreadCrumbsCustomisations
>(BreadCrumbs, Theme, Styles, Customisations, 'BreadCrumbs')

export type DevBreadCrumbsCustomisations = BreadCrumbsCustomisations
export type DevBreadCrumbsProps = BreadCrumbsPublicProps & BreadCrumbsCustomisations
