import * as React from 'react'
import { getSize, getThickness } from './Loader.utils'
import type { LoaderContext } from './Loader.composition'
import type { StyledProps } from '../providers'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getTestId } from '../utils/test'

export interface LoaderPublicProps {
  /**
   * Size of the loader
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Thickness of the loader
   */
  thickness?: 'small' | 'medium' | 'large'
  /**
   * Component to display inside the loader
   */
  children?: React.ReactNode
}

export interface LoaderProps extends LoaderPublicProps, StyledProps<LoaderContext> {}

export interface LoaderCoreStyle {
  size: string | number
}

export const Loader = React.forwardRef(
  (props: LoaderProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = (): LoaderCoreStyle => ({
      size: props.size!,
    })

    const renderContent = () => {
      const { Content } = props.styled!
      return (
        <Content
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'content')}
        >
          {props.children}
        </Content>
      )
    }

    const renderSpinnerAnimation = () => {
      const size = getSize(props.size!)
      const thickness = getThickness(props.thickness!)

      const percent = 25
      const radius = (size - thickness) / 2
      const center = size / 2
      const dashArray = ((size - thickness) / 2) * Math.PI * 2
      const dashOffset = dashArray - (dashArray * percent) / 100

      const { SpinnerAnimation } = props.styled!
      return (
        <SpinnerAnimation
          cx={`${center}px`}
          cy={`${center}px`}
          r={`${radius}px`}
          strokeWidth={`${thickness}px`}
          transform={`rotate(-90 ${center} ${center})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'spinner-animation')}
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from={`0 ${size / 2} ${size / 2}`}
            to={`360 ${size / 2} ${size / 2}`}
            dur="1s"
            repeatCount="indefinite"
          />
        </SpinnerAnimation>
      )
    }

    const renderSpinnerBackground = () => {
      const size = getSize(props.size!)
      const thickness = getThickness(props.thickness!)

      const radius = (size - thickness) / 2
      const center = size / 2

      const { SpinnerBackground } = props.styled!
      return (
        <SpinnerBackground
          cx={`${center}px`}
          cy={`${center}px`}
          r={`${radius}px`}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'spinner-background')}
        />
      )
    }

    const renderSpinner = () => {
      const { Spinner } = props.styled!

      const size = getSize(props.size!)
      const thickness = getThickness(props.thickness!)

      return (
        <Spinner
          viewBox={`0 0 ${size + 2} ${size + 2}`}
          xmlns="http://www.w3.org/2000/svg"
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'spinner')}
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(1 1)" strokeWidth={thickness}>
              {renderSpinnerBackground()}
              {renderSpinnerAnimation()}
            </g>
          </g>
        </Spinner>
      )
    }

    const renderLoader = () => {
      const { Loader: LoaderCore } = props.styled!
      return (
        <LoaderCore
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'container')}
        >
          {renderSpinner()}
          {renderContent()}
        </LoaderCore>
      )
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {renderLoader()}
      </Root>
    )
  },
)

Loader.defaultProps = {
  size: 'medium',
  thickness: 'medium',
}

export default Loader
