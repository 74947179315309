import { getColour, TablePaginationActionsCustomisations } from './customisations'
import type { TablePaginationActionsPublicProps } from '../../TablePaginationActions'
import type { TablePaginationActionsStyleProps } from './DevTablePaginationActions.styles'

export const Customisations = {
  Icon: (props: TablePaginationActionsStyleProps) => ({
    colour: getColour(props),
    hoverColour: getColour(props, 'hover'),
  }),
}

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: TablePaginationActionsPublicProps & TablePaginationActionsCustomisations,
): TablePaginationActionsCustomisations => ({
  colour: props.colour,
  hoverColour: props.hoverColour,
})
