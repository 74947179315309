import styled, { css, ThemeProps } from '../../../../../themes/Developer/themed-component'
import type { StepConnectorCoreStyle } from '../../StepConnector'
import type { StyleProps } from '../../../../../types'
import type { StepConnectorContext } from '../../StepConnector.composition'
import { getBgColour, StepConnectorCustomisations } from './customisations'

export type StepConnectorStyleProps = StyleProps<
  StepConnectorCoreStyle,
  StepConnectorCustomisations
> &
  ThemeProps

const AlternativeLabel = css<StepConnectorStyleProps>`
  position: absolute;
  top: 12px;
  left: calc(-50% + 20px);
  right: calc(50% + 20px);
`

const HorizontalLine = css<StepConnectorStyleProps>`
  height: 1px;
  min-width: 24px;
`

const VerticalLine = css<StepConnectorStyleProps>`
  width: 1px;
  min-height: 24px;
`

const HorizontalRoot = css<StepConnectorStyleProps>`
  flex: 1 1 auto;
`

const VerticalRoot = css<StepConnectorStyleProps>`
  margin-left: ${(props) => props.theme.spaces.xs};
  padding: 0px 0px ${(props) => props.theme.spaces.xs};
`

const Line = styled.span<StepConnectorStyleProps>`
  display: block;
  border: none;
  background-color: ${(props) => getBgColour(props)};

  ${(props) => props.styleProps.orientation === 'horizontal' && HorizontalLine};
  ${(props) => props.styleProps.orientation === 'vertical' && VerticalLine};
`

const Root = styled.div<StepConnectorStyleProps>`
  ${(props) => props.styleProps.alternativeLabel && AlternativeLabel};
  ${(props) => props.styleProps.orientation === 'horizontal' && HorizontalRoot};
  ${(props) => props.styleProps.orientation === 'vertical' && VerticalRoot};
`

const Components: StepConnectorContext = {
  Root,
  Line,
}

export default Components
