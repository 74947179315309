import * as theme from '../../../../themes/Developer/proxima-nova'
import type { InputStyleProps } from '../DevInput.styles'

/** Colour  */
export const getColour = (props: InputStyleProps) =>
  theme.getColour(props, props.theme.colours.tints.charcoal[100], theme.getState(props.styleProps))

/** Background colour */
export const getBgColour = (props: InputStyleProps) =>
  theme.getBgColour(
    props,
    props.theme.colours.tertiary.neutral.white,
    theme.getState(props.styleProps),
  )

type BorderColours = Pick<
  theme.ColourStates,
  'colour' | 'hoverColour' | 'activeColour' | 'focusColour' | 'invalidColour' | 'selectedColour'
>

/** Border colour */
export const getBorderColour = (props: InputStyleProps): BorderColours => ({
  colour: props.theme.colours.tertiary.neutral.grey,
  hoverColour: props.theme.colours.tints.sun[50],
  activeColour: props.theme.colours.tints.sun[50],
  focusColour: props.theme.colours.tints.sun[50],
  invalidColour: props.theme.colours.tertiary.semantic.red,
  selectedColour: props.theme.colours.tints.sun[50],
})

/** Placeholder colour */
export const getPlaceholderColour = (props: InputStyleProps) =>
  theme.getColour(
    props,
    props.theme.colours.tertiary.neutral.grey,
    theme.getState(props.styleProps),
  )
