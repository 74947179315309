import * as React from 'react'

import { FormControlContext, useFormControl } from '../FormControl/FormControl.context'
import { InputContext } from './Input.composition'

import { StyledProps } from '../providers'
import { getPlaceholder, isInputtable } from './Input.utils'
import useResponsive from '../hooks/useResponsive'
import useCheckDirty from '../FormControl/hooks/useCheckDirty'
import useEnhancedEffect from '../hooks/useEnhancedEffect'
import getValue from '../utils/state'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { newId } from '../utils'
import { attachIf } from '../utils/event-handler'
import { getTestId } from '../utils/test'

export interface InputPublicProps {
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id: string
  /**
   * The id of the element that describe the object.
   */
  describedBy?: string
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
   */
  type?: string
  /**
   * The size of the text field.
   */
  size?: 'small' | 'medium' | 'large' | number
  /**
   * Name attribute of the `input` element.
   */
  name?: string
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value?: any
  /**
   * The default `input` element value. Use when the component is not controlled.
   */
  defaultValue?: any
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string
  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth?: boolean
  /**
   * If `true`, the `input` element will be disabled.
   */
  disabled?: boolean
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readOnly?: boolean
  /**
   * If `true`, the label will be displayed in an error state.
   */
  invalid?: boolean
  /**
   * If `true`, the component will be displayed in focused state.
   */
  focused?: boolean
  /**
   * If `true`, the component will be displayed in hovered state.
   */
  hovered?: boolean
  /**
   * If `true`, the label is displayed as required and the `input` element` will be required.
   */
  required?: boolean
  /**
   * Start `InputAdornment` for this component.
   */
  startAdornment?: React.ReactNode
  /**
   * End `InputAdornment` for this component.
   */
  endAdornment?: React.ReactNode
  /**
   * The component used for the `input` element.
   * Either a string to use a HTML element or a component.
   */
  component?: JSX.Element | string
  /**
   * True if the input's content is blur, false otherwise.
   */
  blurContent?: boolean
  /**
   * -1 if the input is not keyboard accessible, index in the sequential keyboard navigation otherwise
   */
  tabIndex?: number
  /**
   * Direction of the text
   */
  direction?: 'ltr' | 'rtl'
  /**
   * Handler when the value changes. The new value is passed as parameter.
   */
  onChange?: (event: React.ChangeEvent) => void
  /**
   * Handler when the input loses the focus. The new value is passed as parameter.
   */
  onBlur?: (event: React.FocusEvent) => void
  /**
   * Handler when the input gets the focus.
   */
  onFocus?: (event: React.FocusEvent) => void
  /**
   * Handler when the input is hovered
   */
  onHover?: (event: React.MouseEvent) => void
  /**
   * Handler when the input is not hovered
   */
  onLeave?: (event: React.MouseEvent) => void
  /**
   * Handler when the user clicks on the field
   */
  onClick?: (event: React.MouseEvent) => void
}

export interface InputProps extends InputPublicProps, StyledProps<InputContext> {}

export interface InputCoreStyle {
  disabled: boolean
  readOnly: boolean
  invalid: boolean
  variant: string
  size: string
  filled: boolean
  focused: boolean
  hovered: boolean
  active: boolean
  hasFormControlUsed: boolean
  blurContent?: boolean
}

const Input = React.forwardRef((props: InputProps, forwardRef: React.Ref<HTMLDivElement>) => {
  const { ref } = useResponsive({ ref: forwardRef })

  const { current: isControlled } = React.useRef(props.value != null)

  const inputRef = React.useRef<HTMLInputElement>(null)

  const formControl = useFormControl(props)

  const checkDirty = useCheckDirty(formControl)

  const [hovered, setHovered] = React.useState(props.hovered)
  const [focused, setFocused] = React.useState(props.focused)
  const [active, setActive] = React.useState(false)

  const isActionable = isInputtable(formControl)

  // Check dirty once
  React.useEffect(() => {
    if (inputRef.current) {
      checkDirty(inputRef.current)
    }
  }, [])

  // Check dirty if the component is supposed to be filled but the input element has no value
  React.useEffect(() => {
    if (!isControlled && formControl.filled && !inputRef.current!.value) {
      checkDirty(inputRef.current)
    }
  }, [inputRef.current?.value])

  // Check dirty with the value is the input is controlled
  useEnhancedEffect(() => {
    if (isControlled) {
      checkDirty({ value: props.value })
    }
  }, [props.value, checkDirty, isControlled])

  // Remove the focus when the input is disabled/readOnly and has the focus
  React.useEffect(() => {
    if (!isInputtable(formControl) && props.focused) {
      inputRef.current!.blur()
    }
  }, [formControl, props.disabled, props.readOnly, props.focused])

  const generateId = React.useMemo(() => {
    return newId()
  }, [])

  const getCoreStyle = (): InputCoreStyle => ({
    disabled: formControl.disabled!,
    readOnly: formControl.readOnly!,
    invalid: formControl.invalid!,
    variant: formControl.variant!,
    size: formControl.size!,
    filled: formControl.filled!,
    focused: getValue(formControl.focused, focused),
    hovered: getValue(formControl.hovered, hovered),
    active,
    hasFormControlUsed: formControl !== props,
    blurContent: props.blurContent,
  })

  const handleFocus = (event: React.FocusEvent) => {
    if (!isInputtable(formControl)) {
      event.stopPropagation()
      return
    }

    if (inputRef.current) {
      inputRef.current.select()
    }

    if (props.onFocus) {
      props.onFocus(event)
    }

    if (formControl && formControl.onFocus) {
      formControl.onFocus(event)
    } else {
      setFocused(true)
    }
  }

  const handleBlur = (event: React.FocusEvent) => {
    if (!isControlled) {
      const element: HTMLInputElement = inputRef.current!

      checkDirty({
        value: element.value,
      })
    }

    if (props.onBlur) {
      props.onBlur(event)
    }

    if (formControl && formControl.onBlur) {
      formControl.onBlur(event)
    } else {
      setFocused(false)
    }
  }

  const handleHover = (event: React.MouseEvent) => {
    if (props.onHover) {
      props.onHover(event)
    }

    if (formControl && formControl.onHover) {
      formControl.onHover(event)
    } else {
      setHovered(true)
    }
  }

  const handleLeave = (event: React.MouseEvent) => {
    if (props.onLeave) {
      props.onLeave(event)
    }

    if (formControl && formControl.onLeave) {
      formControl.onLeave(event)
    } else {
      setHovered(false)
    }
  }

  const handleChange = (event: React.ChangeEvent) => {
    if (!isControlled) {
      const element: any = event.target || inputRef.current
      checkDirty({ value: element.value })
    }

    if (props.onChange) {
      props.onChange(event)
    }
  }

  const renderComponent = () => {
    const placeholder = getPlaceholder(props.placeholder, formControl.required)
    const tabIndex = formControl.readOnly || formControl.disabled ? -1 : props.tabIndex

    const { Input: InputCore } = props.styled!
    return (
      <FormControlContext.Provider value={undefined}>
        <InputCore
          ref={inputRef}
          aria-describedby={props.describedBy}
          aria-required={props.required}
          type={props.type}
          id={props.id || props.name || generateId}
          name={props.name || props.id || generateId}
          tabIndex={tabIndex}
          defaultValue={props.defaultValue}
          value={props.value}
          disabled={formControl.disabled}
          readOnly={formControl.readOnly}
          placeholder={placeholder}
          onChange={attachIf(handleChange, isActionable)}
          styleProps={getCoreStyle()}
          customisations={props.customisations}
          {...getTestId(props, 'element')}
        />
      </FormControlContext.Provider>
    )
  }

  const { Root } = props.styled!
  return (
    <Root
      ref={ref}
      className={props.className}
      onClick={attachIf(props.onClick, isActionable)}
      onFocus={attachIf(handleFocus, isActionable)}
      onBlur={attachIf(handleBlur, isActionable)}
      onMouseEnter={attachIf(handleHover, isActionable)}
      onMouseLeave={attachIf(handleLeave, isActionable)}
      styleProps={getCoreStyle()}
      customisations={props.customisations}
      {...getAttributes(props, DataAttributesPrefix)}
    >
      {props.startAdornment}
      {renderComponent()}
      {props.endAdornment}
    </Root>
  )
})

Input.defaultProps = {
  disabled: false,
  readOnly: false,
  type: 'text',
  size: 'medium',
  component: 'input',
  direction: 'ltr',
  tabIndex: 0,
}

export default Input
