import type { ButtonPublicProps } from '../../Button'
import type { ButtonCustomisations } from './customisations'
import type { ButtonStyleProps } from './DevButton.styles'

/** Icon */
export const Customisations = {
  Icon: (props: ButtonStyleProps) => ({
    colour: 'inherit',
    hoverColour: 'inherit',
  }),
}

const check = (props: ButtonPublicProps & ButtonCustomisations) => {
  if (props.type === 'secondary') {
    if (props.hoverColour) {
      console.warn('The hover colour customisations is not available for secondary button')
    }

    if (props.bgColour) {
      console.warn('The background colour customisations is not available for secondary button')
    }

    if (props.hoverBgColour) {
      console.warn(
        'The hover background colour customisations is not available for secondary button',
      )
    }
  }

  if (props.type === 'tertiary') {
    if (props.bgColour) {
      console.warn('The background colour customisations is not available for tertiary button')
    }

    if (props.hoverBgColour) {
      console.warn(
        'The hover background colour customisations is not available for tertiary button',
      )
    }
  }
}

/**
 * Extract the customisations from the Developer component properties
 */
export default (props: ButtonPublicProps & ButtonCustomisations): ButtonCustomisations => {
  check(props)

  return {
    colour: props.colour,
    hoverColour: props.hoverColour,
    bgColour: props.bgColour,
    hoverBgColour: props.hoverBgColour,
    fontSize: props.fontSize,
  }
}
