import type { TableHeaderPublicProps } from '../../TableHeader'
import type { TableHeaderCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: TableHeaderPublicProps & TableHeaderCustomisations,
): TableHeaderCustomisations => ({
  colour: props.colour,
  bgColour: props.bgColour,
})
