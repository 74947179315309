import { includes } from '../../../utils/lodash'

const CheckOrientation = (orientation: string) => {
  const executeChecks = !includes(['production', 'test'], process.env.NODE_ENV)
  if (!executeChecks) {
    return
  }

  if (orientation !== 'vertical') {
    console.error('Prisma: <StepContent /> is only designed for use with the vertical stepper.')
  }
}

export default CheckOrientation
