import * as styledComponents from 'styled-components'

import { DeveloperTheme } from './theme'

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<DeveloperTheme>

export { css, createGlobalStyle, keyframes, ThemeProvider }

// Wrap the native styled component to remove the property styleProps that is used to add properties to be used in the styles
// but we don't want them to be forward to the the real component
const customStyled = (target: any) =>
  styled(target).withConfig({
    shouldForwardProp: (prop: any, defaultValidatorFn: any) =>
      !['styleProps', 'customisations'].includes(prop),
  })

Object.keys(styled).forEach((domElement: any) => {
  customStyled[domElement] = customStyled(domElement)
})

export type ThemeProps = styledComponents.ThemeProps<DeveloperTheme>

export default customStyled as typeof styled
