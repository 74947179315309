import * as theme from '../../../../themes/Developer/proxima-nova'
import type { CheckboxStyleProps } from '../DevCheckbox.styles'
import { getBorderColour } from './colours'

/** Height */
export const getHeight = (props: CheckboxStyleProps) =>
  `${theme.inputSize[props.styleProps.size]}px`

/* Border */
export const getBorder = (props: CheckboxStyleProps) => {
  const state = theme.getState(props.styleProps) || 'default'
  return theme.getBorder(props, getBorderColour(props), 'border', state)
}
