import SearchableSelect, { SearchableSelectPublicProps } from '../../SearchableSelect'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevSearchableSelect.styles'
import Customisations from './DevSearchableSelect.customisations'
import type { SearchableSelectContext } from '../../SearchableSelect.composition'
import type { SearchableSelectCustomisations } from './customisations'

export const DevSearchableSelect = withCustomisedStyle<
  SearchableSelectPublicProps,
  SearchableSelectContext,
  SearchableSelectCustomisations
>(SearchableSelect, Theme, Styles, Customisations, 'SearchableSelect')

export type DevSearchableSelectCustomisations = SearchableSelectCustomisations
export type DevSearchableSelectProps = SearchableSelectPublicProps & SearchableSelectCustomisations
