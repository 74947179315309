import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { LinearProgressStyleProps } from '../DevLinearProgress.styles'

/** Colour */
export const getColour = (props: LinearProgressStyleProps) =>
  theme.getColour(
    props,
    props.theme.colours.tertiary.neutral.lightGrey,
    theme.getState(props.styleProps),
  )

/** Label */
/** Colour */
export const getLabelColour = (props: LinearProgressStyleProps) =>
  theme.getColour(
    theme.getCustomisations(props, 'Label'),
    props.theme.colours.tertiary.neutral.charcoal,
    theme.getState(props.styleProps),
  )

/** Spinner */
/** Colour */
export const getSpinnerColour = (props: LinearProgressStyleProps) => {
  return theme.getColour(
    theme.getCustomisations(props, 'Spinner'),
    props.theme.colours.tints.azure[100],
    theme.getState(props.styleProps),
  )
}
