import * as theme from '../../../../../../themes/Developer/proxima-nova'
import type { StepConnectorStyleProps } from '../DevStepConnector.styles'

/** Background colour */
export const getBgColour = (props: StepConnectorStyleProps) =>
  theme.getBgColour(
    props,
    props.theme.colours.tints.lightGrey[150],
    theme.getState(props.styleProps),
  )
