import type { ParagraphPublicProps } from '../../Paragraph'
import type { ParagraphCustomisations } from './customisations'

/**
 * Extract the customisations from the TrueLayer component properties
 */
export default (
  props: ParagraphPublicProps & ParagraphCustomisations,
): ParagraphCustomisations => ({
  colour: props.colour,
  fontWeight: props.fontWeight,
  textAlign: props.textAlign,
})
