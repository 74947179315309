import styled, { ThemeProps } from '../../../../../themes/Developer/themed-component'
import {
  getBgColour,
  getEvenRowBgColour,
  getColour,
  TableRowCustomisations,
} from './customisations'
import type { TableRowContext } from '../../TableRow.composition'
import type { TableRowCoreStyle } from '../../TableRow'
import type { StyleProps } from '../../../../../types'

export type TableRowStyleProps = StyleProps<TableRowCoreStyle, TableRowCustomisations> & ThemeProps

const Root = styled.tr<TableRowStyleProps>`
  display: table-row;
  vertical-align: middle;
  outline: 0;
  color: ${(props) => getColour(props)};
  background-color: ${(props) => getBgColour(props)};

  :nth-child(even) {
    background-color: ${(props) => getEvenRowBgColour(props)};
  }
`

const Components: TableRowContext = {
  Root,
}

export default Components
