import { useLocation, useHistory } from "react-router-dom";
import SingleImmediatePaymentAction from "../SingleImmediatePayments/SingleImmediatePaymentAction";
import { Username } from "../UserInformation/Username";
import MandateAction from "../Mandates/MandateAction";

export function useToken(): string | null {
  const { hash } = useLocation();
  const getToken = (): string | null => {
    const matches = hash.match(/#token=([^=/]+)$/);
    return matches && matches.length > 1 ? matches[1] : null;
  };
  return getToken();
}

export function useRoutes() {
  const history = useHistory();

  const navigateToErrorPage = () => {
    history.push("/error");
  };

  const navigateToPage = (location: string, state?: object) => {
    history.push(location, state);
  };

  return {
    navigateToPage,
    navigateToErrorPage
  };
}

export const defaultDelayId = "no-delay";

export const delays = [
  {
    id: "no-delay",
    name: "No delay",
    valueInSeconds: 0
  },
  {
    id: "one-minute",
    name: "1 minute",
    valueInSeconds: 60
  },
  {
    id: "ten-minutes",
    name: "10 minutes",
    valueInSeconds: 60 * 10
  },
  {
    id: "one-hour",
    name: "1 hour",
    valueInSeconds: 60 * 60
  },
  {
    id: "three-hours",
    name: "3 hours",
    valueInSeconds: 60 * 60 * 3
  },
  {
    id: "one-day",
    name: "1 day",
    valueInSeconds: 60 * 60 * 24
  }
];

export const getDelayInSeconds = (executionDelay: string): number =>
  delays.find(option => option.id === executionDelay)?.valueInSeconds || 0;

export function mapUsernameToActionForPayments(
  username?: string
): SingleImmediatePaymentAction | null {
  switch (username) {
    case Username.AuthorisationFailed:
      return "RejectAuthorisation";

    case Username.ExecutionRejected:
      return "RejectExecution";

    case Username.Executed:
      return "Execute";
  }
  return null;
}

export function mapUsernameToActionForMandates(
    username?: string
): MandateAction | null {
  switch (username) {
    case Username.Authorised:
      return "Authorise";
    case Username.AuthorisationFailed:
      return "RejectAuthorisation";
  }
  return null;
}
