import * as React from 'react'
import { isFragment } from 'react-is'

const checkChildIsNotAFragment = (child: React.ReactNode) => {
  if (process.env.NODE_ENV !== 'production') {
    if (isFragment(child)) {
      console.error(
        [
          "Prisma: The Tabs component doesn't accept a Fragment as a child.",
          'Consider providing an array instead.',
        ].join('\n'),
      )
    }
  }
}

export default checkChildIsNotAFragment
