import TablePagination, { TablePaginationPublicProps } from '../../TablePagination'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevTablePagination.styles'
import Customisations from './DevTablePagination.customisations'
import type { TablePaginationContext } from '../../TablePagination.composition'
import type { TablePaginationCustomisations } from './customisations'

export const DevTablePagination = withCustomisedStyle<
  TablePaginationPublicProps,
  TablePaginationContext,
  TablePaginationCustomisations
>(TablePagination, Theme, Styles, Customisations, 'TablePagination')

export type DevTablePaginationCustomisations = TablePaginationCustomisations
export type DevTablePaginationProps = TablePaginationPublicProps & TablePaginationCustomisations
