import { IconProps } from './Icon'

import { filter, includes, isEmpty, pick, values } from '../utils/lodash'

const executeChecks = !includes(['production', 'test'], process.env.NODE_ENV)

/**
 * The icon won't be clickable if all handlers are undefined
 * The icon will be clickable it all handlers are not undefined
 * Otherwise, error
 */
export const checkClickableFeature = (props: IconProps) => {
  if (!executeChecks) {
    return
  }

  const handlers = filter(values(pick(props, ['onClick', 'onTouchStart', 'onTouchEnd'])), !isEmpty)

  if (handlers.length !== 0 && handlers.length !== 3) {
    console.error(
      [
        'Prisma: The clickable feature is not configured correctly.',
        'Add an handler for onClick, onTouchStart and onTouchEnd.',
      ].join('\n'),
    )
  }
}

export const checkImageFeature = (props: IconProps) => {
  if (!executeChecks) {
    return
  }

  if (!props.children && !props.src) {
    console.error(
      [
        'Prisma: The image feature is not configured correctly.',
        'Use src property for a uri or children property for a component',
      ].join('\n'),
    )
  }
}
