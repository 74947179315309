export const TestId = 'data-testid'

export const getTestId = (props, name) => {
  if (process.env.NODE_ENV !== 'test') {
    return {}
  }

  if (!props[TestId]) {
    return {}
  }

  return { [TestId]: `${props[TestId]}-${name}` }
}
