import Button, { ButtonPublicProps } from '../../Button'
import Theme from '../../../themes/Developer/theme'
import Styles from './DevButton.styles'
import Customisations from './DevButton.customisations'
import { withCustomisedStyle } from '../../../providers'
import type { ButtonContext } from '../../Button.composition'
import type { ButtonCustomisations } from './customisations'

export const DevButton = withCustomisedStyle<
  ButtonPublicProps,
  ButtonContext,
  ButtonCustomisations
>(Button, Theme, Styles, Customisations, 'Button')

export type DevButtonCustomisations = ButtonCustomisations
export type DevButtonProps = ButtonPublicProps & ButtonCustomisations
