/* eslint-disable no-param-reassign */
import * as React from 'react'
import * as PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'
import Components from './themes/Developer/Collapse.styles'
import useResponsive from '../hooks/useResponsive'
import getAttributes from '../attributes'
import { DataAttributesPrefix } from '../constants'
import { getAutoHeightDuration, getTransitionProps } from '../utils/transition'
import { getTestId } from '../utils/test'

export interface CollapsePublicProps {
  /**
   * If `true`, the component will transition in.
   */
  in?: boolean
  /**
   * The collapse transition orientation.
   * @default 'vertical'
   */
  orientation?: 'horizontal' | 'vertical'
  /**
   * The width (horizontal) or height (vertical) of the container when collapsed.
   * @default '0px'
   */
  collapsedSize?: number | string
  /**
   * The duration for the transition, in milliseconds.
   * You may specify a single timeout for all transitions, or individually with an object.
   *
   * Set to 'auto' to automatically calculate transition time based on height.
   * @default duration.standard
   */
  timeout?: 'auto' | number | { appear?: number; enter?: number; exit?: number }
  /**
   * Handler when the transition enters
   */
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void
  /**
   * Handler when the transition is entered
   */
  onEntered?: (node: HTMLElement, isAppearing: boolean) => void
  /**
   * Handler when the transition is entering
   */
  onEntering?: (node: HTMLElement, isAppearing: boolean) => void
  /**
   * Handler when the transition exit
   */
  onExit?: (node: HTMLElement) => void
  /**
   * Handler when the transition is exited
   */
  onExited?: (node: HTMLElement) => void
  /**
   * Handler when the transition is exiting
   */
  onExiting?: (node: HTMLElement) => void
  /**
   * Component to display as a content of the collapse
   */
  children?: React.ReactNode
}

interface CollapseProps extends CollapsePublicProps {
  className?: string
}

export interface CollapseCoreStyle {
  orientation: 'horizontal' | 'vertical'
  entered?: boolean
}

export const Collapse = React.forwardRef(
  (props: CollapseProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const timer = React.useRef<any>()
    const autoTransitionDuration = React.useRef<number>()
    const wrapperRef = React.useRef<HTMLDivElement>(null)

    const collapsedSize =
      typeof props.collapsedSize === 'number' ? `${props.collapsedSize}px` : props.collapsedSize
    const isHorizontal = props.orientation === 'horizontal'
    const size = isHorizontal ? 'width' : 'height'
    const timeout: any = props.timeout === 'auto' ? null : props.timeout

    const getCoreStyle = (extra?: any): CollapseCoreStyle => ({
      orientation: props.orientation!,
      ...extra,
    })

    const getWrapperSize = () =>
      wrapperRef.current ? wrapperRef.current[isHorizontal ? 'clientWidth' : 'clientHeight'] : 0

    const normalizedTransitionCallback = (
      callback: (node: HTMLElement, isAppearing?: boolean) => void,
    ) => (maybeIsAppearing: boolean) => {
      if (callback) {
        const node = (ref as any)?.current
        if (!node) {
          return
        }

        // onEnterXxx and onExitXxx callbacks have a different arguments.length value.
        if (maybeIsAppearing === undefined) {
          callback(node)
        } else {
          callback(node, maybeIsAppearing)
        }
      }
    }

    const handleEnter = normalizedTransitionCallback((node: HTMLElement, isAppearing?: boolean) => {
      if (wrapperRef.current && isHorizontal) {
        // Set absolute position to get the size of collapsed content
        wrapperRef.current.style.position = 'absolute'
      }

      node.style[size] = collapsedSize!

      if (props.onEnter) {
        props.onEnter(node, isAppearing!)
      }
    })

    const handleEntered = normalizedTransitionCallback(
      (node: HTMLElement, isAppearing?: boolean) => {
        node.style[size] = 'auto'

        if (props.onEntered) {
          props.onEntered(node, isAppearing!)
        }
      },
    )

    const handleEntering = normalizedTransitionCallback(
      (node: HTMLElement, isAppearing?: boolean) => {
        const wrapperSize = getWrapperSize()

        if (wrapperRef.current && isHorizontal) {
          // After the size is read reset the position back to default
          wrapperRef.current.style.position = ''
        }

        const { duration: transitionDuration } = getTransitionProps(
          { timeout: props.timeout },
          { mode: 'enter' },
        )

        if (props.timeout === 'auto') {
          const duration2 = getAutoHeightDuration(wrapperSize)
          node.style.transitionDuration = `${duration2}ms`
          autoTransitionDuration.current = duration2
        } else {
          node.style.transitionDuration =
            typeof transitionDuration === 'string' ? transitionDuration : `${transitionDuration}ms`
        }

        node.style[size] = `${wrapperSize}px`

        if (props.onEntering) {
          props.onEntering(node, isAppearing!)
        }
      },
    )

    const handleExit: any = normalizedTransitionCallback((node: HTMLElement) => {
      node.style[size] = `${getWrapperSize()}px`

      if (props.onExit) {
        props.onExit(node)
      }
    })

    const handleExited: any = props.onExited && normalizedTransitionCallback(props.onExited)

    const handleExiting: any = normalizedTransitionCallback((node: HTMLElement) => {
      const wrapperSize = getWrapperSize()

      const { duration: transitionDuration } = getTransitionProps(
        { timeout: props.timeout! },
        { mode: 'exit' },
      )

      if (props.timeout === 'auto') {
        // TODO: rename getAutoHeightDuration to something more generic (width support)
        // Actually it just calculates animation duration based on size
        const duration2 = getAutoHeightDuration(wrapperSize)
        node.style.transitionDuration = `${duration2}ms`
        autoTransitionDuration.current = duration2
      } else {
        node.style.transitionDuration =
          typeof transitionDuration === 'string' ? transitionDuration : `${transitionDuration}ms`
      }

      node.style[size] = collapsedSize!

      if (props.onExiting) {
        props.onExiting(node)
      }
    })

    const addEndListener = (next: Function) => {
      if (props.timeout === 'auto') {
        timer.current = setTimeout(next, autoTransitionDuration.current || 0)
      }
    }

    const renderInnerWrapper = () => {
      const { InnerWrapper } = Components
      return (
        <InnerWrapper
          styleProps={getCoreStyle()}
          customisations={{}}
          {...getTestId(props, 'inner-wrapper')}
        >
          {props.children}
        </InnerWrapper>
      )
    }

    const renderOuterWrapper = (childProps: any) => {
      const { Wrapper } = Components
      return (
        <Wrapper
          ref={wrapperRef}
          {...childProps}
          styleProps={getCoreStyle()}
          customisations={{}}
          {...getTestId(props, 'outer-wrapper')}
        >
          {renderInnerWrapper()}
        </Wrapper>
      )
    }

    const renderContent = (state: any, childProps: any) => {
      const style = {
        entered: state === 'entered',
      }

      const { Root } = Components
      return (
        <Root
          className={props.className}
          ref={ref}
          styleProps={getCoreStyle(style)}
          customisations={{}}
          {...getAttributes(props, DataAttributesPrefix)}
        >
          {renderOuterWrapper(childProps)}
        </Root>
      )
    }

    return (
      <Transition
        in={props.in}
        onEnter={handleEnter}
        onEntered={handleEntered}
        onEntering={handleEntering}
        onExit={handleExit}
        onExited={handleExited}
        onExiting={handleExiting}
        addEndListener={addEndListener}
        timeout={timeout}
        {...getTestId(props, 'transition')}
      >
        {renderContent}
      </Transition>
    )
  },
)

Collapse.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  collapsedSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  timeout: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']) as any,
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),
}

Collapse.defaultProps = {
  orientation: 'vertical',
  collapsedSize: '0px',
  timeout: 300,
}

export default Collapse
