import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { Modal } from '../../../Modal'
import { DevPaper } from '../../../Paper'
import type { PopoverCoreStyle } from '../../Popover'
import type { PopoverContext } from '../../Popover.composition'
import type { StyleProps } from '../../../types'
import { getValue } from '../../../style'
import * as themeStyles from '../../../themes/Developer/styles'

export type PopoverStyleProps = StyleProps<PopoverCoreStyle> & ThemeProps

const margin = (props: PopoverStyleProps) => '30px'

const isMaxWidth = (props: PopoverStyleProps) => props.styleProps.maxWidth

const PaperMaxWidth = css<PopoverStyleProps>`
  max-width: ${(props) =>
    `calc(${getValue(props.styleProps.maxWidth)} - calc(2 * ${margin(props)}))`};
`

const Paper = styled(DevPaper)<PopoverStyleProps>`
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 16px;
  min-height: 16px;
  outline: 0;
  max-width: ${(props) => `calc(100% - calc(2 * ${margin(props)}))`};
  max-height: ${(props) => `calc(100% - ${margin(props)})`};
  color: ${(props) => props.theme.colours.tints.charcoal[100]};

  ${(props) => isMaxWidth(props) && PaperMaxWidth};

  /** Spacing */
  padding: ${(props) => props.theme.spaces.lg};

  /** Typography */
  ${themeStyles.FontFamily}
`

const Root = styled(Modal)<PopoverStyleProps>``

const Components: PopoverContext = {
  Root,
  Paper,
}

export default Components
