import styled, { css, ThemeProps } from '../../../themes/Developer/themed-component'
import { getColour, BreadCrumbsCustomisations } from './customisations'

// types
import type { BreadCrumbsCoreStyle } from '../../BreadCrumbs'
import type { BreadCrumbsContext } from '../../BreadCrumbs.composition'
import type { StyleProps } from '../../../types'
import * as theme from '../../../themes/Developer/proxima-nova'
import * as themeStyles from '../../../themes/Developer/styles'

export type BreadCrumbsStyleProps = StyleProps<BreadCrumbsCoreStyle, BreadCrumbsCustomisations> &
  ThemeProps

const Separator = css<BreadCrumbsStyleProps>`
  display: flex;
  user-select: none;
  color: ${(props) => props.theme.colours.tints.cloudBurst[100]};
  margin: ${(props) => `0 ${props.theme.spaces.xxs}`};
`

const Item = styled.li<BreadCrumbsStyleProps>`
  color: inherit;
  font-size: inherit;

  ${(props) => props.styleProps.separator && Separator}
`

const Root = styled.ol<BreadCrumbsStyleProps>`
  /** font-family */
  ${themeStyles.FontFamily}

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: ${(props) =>
    theme.getValueAsPixel(props.theme.typography.proximaNova.fontSize.bodyM.px)};
  color: ${(props) => getColour(props)};
`

const Components: BreadCrumbsContext = {
  Root,
  Item,
}

export default Components
