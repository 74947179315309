import styled, { ThemeProps } from '../../../themes/Developer/themed-component'
import { DevField } from '../../../Field'
import { DevCheckboxGroup } from '../../../CheckboxGroup'
import { CheckboxFieldCustomisations, Customisations } from './DevCheckboxField.customisations'
import type { CheckboxFieldContext } from '../../CheckboxField.composition'
import type { StyleProps } from '../../../types'

export type CheckboxFieldStyleProps = StyleProps<{}, CheckboxFieldCustomisations> & ThemeProps

const CheckboxGroup = styled(DevCheckboxGroup).attrs(
  Customisations.CheckboxGroup,
)<CheckboxFieldStyleProps>``

const Root = styled(DevField).attrs(Customisations.Field)<CheckboxFieldStyleProps>``

const Components: CheckboxFieldContext = {
  Root,
  CheckboxGroup,
}

export default Components
