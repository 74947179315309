import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import type { StyledProps } from '../../../providers'
import type { ListItemSecondaryActionContext } from './ListItemSecondaryAction.composition'

export interface ListItemSecondaryActionPublicProps {
  /**
   * Component used as content of the li element
   */
  children?: React.ReactNode
}

export interface ListItemSecondaryActionProps
  extends ListItemSecondaryActionPublicProps,
    StyledProps<ListItemSecondaryActionContext> {}

const ListItemSecondaryAction = React.forwardRef(
  (props: ListItemSecondaryActionProps, forwardRef: React.Ref<HTMLDivElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = () => ({})

    const stopPropagation = (event: React.SyntheticEvent<Element>) => {
      event.stopPropagation()
    }

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        onKeyUp={stopPropagation}
        onClick={stopPropagation}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

export default ListItemSecondaryAction
