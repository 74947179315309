import * as theme from '../../../../themes/Developer/proxima-nova'
import type { FormLabelStyleProps } from '../DevFormLabel.styles'
import { fontSizes } from '../../../../Input/themes/Developer/customisations'

const shrinkFontSize = {
  small: 'captionXs',
  medium: 'bodyXs',
  large: 'bodyS',
}

/** Font size  */
const getFilledFontSize = (props: FormLabelStyleProps) => {
  const value = props.styleProps.shrink
    ? shrinkFontSize[props.styleProps.size]
    : fontSizes[props.styleProps.size]

  return theme.getFontSize(props, value)
}

const defaultFontSize = {
  small: 'bodyXs',
  medium: 'bodyS',
  large: 'bodyM',
}

const defaultFilledFontSize = {
  small: 'captionXs',
  medium: 'captionXs',
  large: 'captionS',
}

export const getDefaultFontSize = (props: FormLabelStyleProps) => {
  return theme.getFontSize(props, defaultFontSize[props.styleProps.size])
}

export const getFontSize = (props: FormLabelStyleProps) => {
  switch (props.styleProps.variant) {
    case 'filled':
      return theme.getFontSize(props, defaultFilledFontSize[props.styleProps.size])
    case 'outlined':
      return getFilledFontSize(props)
    default:
      return getDefaultFontSize(props)
  }
}
