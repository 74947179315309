import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import type { StyledProps } from '../../../providers'
import type { ListSubheaderContext } from './ListSubheader.composition'

export interface ListSubheaderPublicProps {
  as?: 'li' | 'div'
  /**
   * Component used as content of the li element
   */
  children?: React.ReactNode
}

type ListSubheaderProps = ListSubheaderPublicProps & StyledProps<ListSubheaderContext>

const ListSubheader = React.forwardRef(
  (props: ListSubheaderProps, forwardRef: React.Ref<HTMLLIElement>) => {
    const { ref } = useResponsive({ ref: forwardRef })

    const getCoreStyle = () => ({})

    const { Root } = props.styled!
    return (
      <Root
        className={props.className}
        ref={ref}
        as={props.as}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

ListSubheader.defaultProps = {
  as: 'li',
}

export default ListSubheader
