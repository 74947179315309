import Checkbox, { NativeCheckboxPublicProps } from '../../NativeCheckbox'
import Theme from '../../../../../themes/Developer/theme'
import Styles from './DevNativeCheckbox.styles'
import Customisations from './DevNativeCheckbox.customisations'
import { withCustomisedStyle } from '../../../../../providers'
import type { CheckboxContext } from '../../../../Checkbox.composition'
import type { NativeCheckboxCustomisations } from './customisations'

export const DevNativeCheckbox = withCustomisedStyle<
  NativeCheckboxPublicProps,
  CheckboxContext,
  NativeCheckboxCustomisations
>(Checkbox, Theme, Styles, Customisations, 'Checkbox')

export type DevNativeCheckboxCustomisations = NativeCheckboxCustomisations
export type DevNativeCheckboxProps = NativeCheckboxPublicProps & NativeCheckboxCustomisations
