import * as React from 'react'
import TableLevel2Context from '../../TableLevel2.context'
import useResponsive from '../../../hooks/useResponsive'
import getAttributes from '../../../attributes'
import { DataAttributesPrefix } from '../../../constants'
import type { StyledProps } from '../../../providers'
import type { TableRowContext } from './TableRow.composition'

export interface TableRowPublicProps {
  /**
   * Handler triggered when the row is clicked
   */
  onClick?: (event: React.MouseEvent) => void
  /**
   * The table cell contents.
   */
  children?: React.ReactNode
}

export interface TableRowProps extends TableRowPublicProps, StyledProps<TableRowContext> {}

export interface TableRowCoreStyle {
  header: boolean
  footer: boolean
  hovered: boolean
  focused: boolean
  active: boolean
}

const TableRow = React.forwardRef(
  (props: TableRowProps, forwardRef: React.Ref<HTMLTableRowElement>) => {
    const { ref } = useResponsive<HTMLTableRowElement>({ ref: forwardRef })

    const tableLevel2 = React.useContext(TableLevel2Context)

    const [hovered, setHovered] = React.useState(false)
    const [focused, setFocused] = React.useState(false)
    const [active, setActive] = React.useState(false)

    const getCoreStyle = (): TableRowCoreStyle => ({
      header: tableLevel2 && tableLevel2.variant === 'header',
      footer: tableLevel2 && tableLevel2.variant === 'footer',
      hovered,
      focused,
      active,
    })

    const handleFocus = (event: React.FocusEvent) => {
      setFocused(true)
    }

    const handleBlur = (event: React.FocusEvent) => {
      setFocused(false)
    }

    const handleHover = (event: React.MouseEvent) => {
      setHovered(true)
    }

    const handleLeave = (event: React.MouseEvent) => {
      setHovered(false)
    }

    const { Root } = props.styled!
    return (
      <Root
        ref={ref}
        className={props.className}
        role="row"
        onClick={props.onClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
        styleProps={getCoreStyle()}
        customisations={props.customisations}
        {...getAttributes(props, DataAttributesPrefix)}
      >
        {props.children}
      </Root>
    )
  },
)

export default TableRow
