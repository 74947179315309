import Dialog, { DialogPublicProps } from '../../Dialog'
import Theme from '../../../themes/Developer/theme'

import Styles from './DevDialog.styles'
import type { DialogContext } from '../../Dialog.composition'

import { withStyle } from '../../../providers'

export const DevDialog = withStyle<DialogPublicProps, DialogContext>(
  Dialog,
  Theme,
  Styles,
  'Dialog',
)

export type DevDialogProps = DialogPublicProps
