import Pagination, { PaginationPublicProps } from '../../Pagination'
import Theme from '../../../themes/Developer/theme'
import type { PaginationContext } from '../../Pagination.composition'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevPagination.styles'
import Customisations, { PaginationCustomisations } from './DevPagination.customisations'

export const DevPagination = withCustomisedStyle<
  PaginationPublicProps,
  PaginationContext,
  PaginationCustomisations
>(Pagination, Theme, Styles, Customisations, 'Pagination')

export type DevPaginationCustomisations = PaginationCustomisations
export type DevPaginationProps = PaginationPublicProps & PaginationCustomisations
