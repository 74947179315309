import * as common from '../../../../Common'
import type { Customisations } from '../../models'
import type { ThemeProps } from '../../../themed-component'
import { CmBorderRadius } from '../../customisations'

/** Border radius */
export const getBorderRadius = (
  props: Customisations<CmBorderRadius, ThemeProps>,
  defaultValue?: common.Property,
) => common.getBorderRadius(props.customisations, defaultValue || 0)
