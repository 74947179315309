import type { NativeRadioGroupPublicProps } from '../../NativeRadioGroup'
import type { NativeRadioCustomisations } from '../../../../../Radio/components/NativeRadio/themes/Developer/customisations'

export type DevNativeRadioGroupCustomisations = NativeRadioCustomisations

export default (
  props: NativeRadioGroupPublicProps & DevNativeRadioGroupCustomisations,
): DevNativeRadioGroupCustomisations => ({
  Label: props.Label || {},
  Tick: props.Tick || {},
})
