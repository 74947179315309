import * as theme from '../../../../themes/Developer/proxima-nova'
import type { SelectStyleProps } from '../DevSelect.styles'
import { defaultBorderColour } from './colours'

/* Border */
export const getBorder = (props: SelectStyleProps) => {
  return theme.getBorder(
    props,
    defaultBorderColour(props.theme),
    'border',
    theme.getState(props.styleProps),
  )
}

/** Arrow */
export const getArrowBorderLeft = (props: SelectStyleProps) => {
  const customisations = theme.getCustomisations(props, 'Selection.Arrow')
  const currentState = theme.getState(props.styleProps)
  const defaultBorderColours = theme.hasCustomisations(customisations, 'borderLeft')
    ? defaultBorderColour(props.theme)
    : 'none'
  return theme.getBorder(customisations, defaultBorderColours, 'borderLeft', currentState)
}

/** Selection */
/** Height */
export const getSelectionHeight = (props: SelectStyleProps) =>
  `${theme.inputSize[props.styleProps.size]}px`

/** Option */
/** Height */
export const getOptionHeight = (props: SelectStyleProps) =>
  `${theme.inputSize[props.styleProps.size]}px`
