import Paragraph, { ParagraphPublicProps } from '../../Paragraph'
import Theme from '../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../providers'
import Styles from './DevParagraph.styles'
import Customisations from './DevParagraph.customisations'
import type { ParagraphContext } from '../../Paragraph.composition'
import type { ParagraphCustomisations } from './customisations'

export const DevParagraph = withCustomisedStyle<
  ParagraphPublicProps,
  ParagraphContext,
  ParagraphCustomisations
>(Paragraph, Theme, Styles, Customisations, 'Paragraph')

export type DevParagraphCustomisations = ParagraphCustomisations
export type DevParagraphProps = ParagraphPublicProps & ParagraphCustomisations
