import StepIcon, { StepIconPublicProps } from '../../StepIcon'
import Theme from '../../../../../themes/Developer/theme'
import { withCustomisedStyle } from '../../../../../providers'
import Styles from './DevStepIcon.styles'
import Customisations from './DevStepIcon.customisations'
import type { StepIconContext } from '../../StepIcon.composition'
import type { StepIconCustomisations } from './customisations'

export const DevStepIcon = withCustomisedStyle<
  StepIconPublicProps,
  StepIconContext,
  StepIconCustomisations
>(StepIcon, Theme, Styles, Customisations, 'StepIcon')

export type DevStepIconCustomisations = StepIconCustomisations
export type DevStepIconProps = StepIconPublicProps & StepIconCustomisations
